<script setup lang="ts">
import InputTextFloating from '../InputTextFloating.vue';

withDefaults(
  defineProps<{
    label?: string;
    value?: string;
    placeholder?: string;
    isRequired?: boolean;
  }>(),
  {
    label: 'Title',
    placeholder: 'Type text here...',
    isRequired: true,
  }
);

function handleInput(onInput, value: any) {
  if (typeof onInput === 'function') {
    onInput(value.trim());
  }
}
</script>

<template>
  <div>
    <InputTextFloating
      name="title"
      :label="label"
      :required="isRequired"
      :value="value"
      :placeholder="placeholder"
      @input="handleInput($listeners.input, $event)"
    />
  </div>
</template>
