<script setup lang="ts">
import { computed, ref } from 'vue';

import ButtonSpinner from '../ButtonSpinner.vue';
import DropdownMenu from '../DropdownMenu.vue';
import UserListSearch from './UserListSearch.vue';

import type { Project } from '../../project';
import {
  formatOfflineRequestStatus,
  getOfflineRequestStatusColor,
  ProjectOfflineRequestStatus,
  ProjectOfflineRequestWithUser,
  SWBackEndMessageType,
} from '../../offline-data';
import { User } from '../../user';
import { getProjectLastUpdated } from '../../utils';

import useApi from '../../composables/useApi';
import { getPushNotificationDevice } from './helpers/user';
import { useSubscribedUsers } from './composables/useSubscribedUserStore';

import useAuth from '../../composables/useAuth';
import Spinner from '../Spinner.vue';
const auth = useAuth();

const props = defineProps<{
  selectedProject: Project;
  offlineRequests: ProjectOfflineRequestWithUser[];
}>();

const emit = defineEmits<{
  (e: 'update:requests', requests: ProjectOfflineRequestWithUser[]): void;
}>();

const api = useApi();

const requestToSwapUser = ref<User | null>(null);
const isChangingId = ref<number | null>(null);

const requestToInvalidate = ref<ProjectOfflineRequestWithUser | null>(null);

const subscribedUsersStore = useSubscribedUsers();

const canChangeOfflineRequest = (status: ProjectOfflineRequestStatus) => {
  return !['is_syncing', 'failed', 'invalidated'].includes(status);
};

const swapUser = async (offlineRequest: ProjectOfflineRequestWithUser) => {
  if (!requestToSwapUser.value) {
    return;
  }

  try {
    const { id } = offlineRequest;

    isChangingId.value = id;

    const user = getProjectUsers.value.find(
      (u) => u.user_id === requestToSwapUser.value!.user_id
    );
    const device = getPushNotificationDevice(user);

    await api.post(`/project/offline/request/${id}/swap-user`, {
      project_id: props.selectedProject.project_id,
      new_user_id: requestToSwapUser.value.user_id,
      device,
    });

    emit(
      'update:requests',
      props.offlineRequests.map((r) =>
        r.id === id
          ? {
              ...offlineRequest,
              user: {
                ...offlineRequest.user,
                ...requestToSwapUser.value,
              },
              status: 'ready_to_sync',
              device,
            }
          : r
      )
    );

    requestToSwapUser.value = null;
  } finally {
    isChangingId.value = null;
  }
};

const invalidateRequest = async () => {
  if (!requestToInvalidate.value) {
    return;
  }

  try {
    const { id } = requestToInvalidate.value;

    isChangingId.value = id;

    await api.post(`/project/offline/request/${id}/invalidate`, {
      project_id: props.selectedProject.project_id,
    });

    emit(
      'update:requests',
      props.offlineRequests.map((r) =>
        r.id === id
          ? { ...requestToInvalidate.value!, status: 'invalidated' }
          : r
      )
    );

    requestToInvalidate.value = null;
  } finally {
    isChangingId.value = null;
  }
};

const retrySync = async (offlineRequest: ProjectOfflineRequestWithUser) => {
  try {
    const { id, project_id } = offlineRequest;

    isChangingId.value = id;

    await api.post(`/project/offline/request/${id}/retry-sync`, {
      project_id,
    });

    emit(
      'update:requests',
      props.offlineRequests.map((r) =>
        r.id === id
          ? {
              ...offlineRequest,
              status: 'ready_to_sync',
              updated_at: new Date().toISOString(),
            }
          : r
      )
    );

    if (
      navigator.serviceWorker.controller &&
      auth.user().company.is_force_local_gather_offline_mode
    ) {
      navigator.serviceWorker.controller.postMessage({
        data: {
          title: 'A request to download offline data has been made',
          data: {
            type: SWBackEndMessageType.OFFLINE_DOWNLOAD,
            project_id,
          },
        },
      });
    }
  } finally {
    isChangingId.value = null;
  }
};

const getProjectUsers = computed(() => {
  return (
    subscribedUsersStore.users.filter(
      (s) => s.push_subscriptions && s.push_subscriptions.length > 0
    ) || []
  );
});

const checkIfRequestStuck = (offlineRequest: ProjectOfflineRequestWithUser) => {
  const oneMinuteAgo = new Date(new Date().getTime() - 60 * 1000);

  return (
    offlineRequest.status === 'ready_to_sync' &&
    new Date(offlineRequest.updated_at) < oneMinuteAgo
  );
};

const hasStuckRequest = computed(() => {
  return props.offlineRequests.some((r) => checkIfRequestStuck(r));
});

const retryStuckRequests = async () => {
  const stuckRequests = props.offlineRequests.filter((r) =>
    checkIfRequestStuck(r)
  );

  for (const request of stuckRequests) {
    await retrySync(request);
  }

  emit(
    'update:requests',
    props.offlineRequests.map((r) =>
      stuckRequests.some((s) => s.id === r.id)
        ? { ...r, updated_at: new Date().toISOString() }
        : r
    )
  );
};

const toggleSwapUser = (
  offlineRequest: ProjectOfflineRequestWithUser,
  user: User
) => {
  if (offlineRequest.user_id === user.user_id) {
    return;
  }

  requestToSwapUser.value = user;
};
</script>

<template>
  <div>
    <div v-if="hasStuckRequest" class="alert alert-danger">
      One of your requests has been sitting in the queue for a while. Please
      ensure the user has installed the application, turned on notifications &
      has internet connection.
      <a href="#" class="text-danger" @click.prevent="retryStuckRequests">
        Retry Syncing
      </a>
    </div>
    <div class="list-group">
      <div
        v-for="offlineRequest in offlineRequests"
        :key="`request-${offlineRequest.id}`"
        class="list-group-item user-list d-flex align-items-center justify-content-between py-3 flex-wrap"
      >
        <div class="d-flex align-items-center w-100">
          <div class="user me-3">
            <div class="user-outline">
              <div class="user-circle" style="width: 52px; height: 52px">
                <div class="user-short bg-dark" style="font-size: 18px">
                  {{ offlineRequest.user.initials }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center w-100 flex-wrap gap-2"
          >
            <div class="d-flex flex-column align-items-start">
              <small
                v-if="offlineRequest.status === 'synced'"
                class="text-uppercase fw-bold text-muted"
              >
                <template v-if="offlineRequest.outdated_apps_count">
                  <span
                    class="text-danger"
                    v-tooltip="
                      'You\'ve updated the app since it was last downloaded.'
                    "
                  >
                    Has outdated offline apps
                  </span>
                  <span class="mx-2">|</span>
                </template>

                <span>
                  {{ offlineRequest.samples_count }}
                  items
                </span>
                <span class="mx-2">|</span>
                <span>
                  {{ offlineRequest.apps_count }}
                  apps
                </span>
              </small>
              <div>
                <span class="fw-medium">
                  {{ offlineRequest.user.name }}
                </span>
                <span class="text-muted"> • </span>
                <small> {{ offlineRequest.user.email }} </small>
                <template v-if="offlineRequest.device">
                  <span class="text-muted"> • </span>
                  <small> {{ offlineRequest.device }} </small>
                </template>
              </div>
              <small class="text-muted">
                Taken offline
                {{ getProjectLastUpdated(offlineRequest.updated_at) }}
              </small>
              <div
                v-if="offlineRequest.status"
                class="badge mt-2"
                :class="getOfflineRequestStatusColor(offlineRequest.status)"
              >
                <Spinner
                  v-if="offlineRequest.status === 'is_syncing'"
                  size="0.6rem"
                  margin="me-1"
                />
                {{ formatOfflineRequestStatus(offlineRequest.status) }}
              </div>
            </div>

            <div class="d-flex flex-wrap gap-2">
              <button
                v-if="
                  (offlineRequest.status === 'failed_sync' ||
                    (offlineRequest.status === 'synced' &&
                      offlineRequest.outdated_apps_count)) &&
                  !isChangingId
                "
                class="btn btn-primary btn-sm text-nowrap"
                @click="retrySync(offlineRequest)"
                v-tooltip="
                  offlineRequest.status === 'failed_sync'
                    ? 'Retry syncing the data'
                    : 'Update data to latest version of apps'
                "
              >
                {{
                  offlineRequest.status === 'failed_sync'
                    ? 'Retry Sync'
                    : 'Update Apps To Latest'
                }}
              </button>

              <DropdownMenu
                v-if="
                  canChangeOfflineRequest(offlineRequest.status) &&
                  (!isChangingId || isChangingId === offlineRequest.id)
                "
                customWidth="350px"
                padding="p-3"
                :toRight="true"
                :autoDetermineDirectionH="true"
              >
                <template v-slot:button="{ toggle }">
                  <ButtonSpinner
                    type="button"
                    class="btn btn-dark btn-sm dropdown-toggle"
                    :is-loading="isChangingId === offlineRequest.id"
                    @click.prevent.native="toggle"
                  >
                    Move data access
                  </ButtonSpinner>
                </template>
                <template v-slot:items="{ close }">
                  <template v-if="!requestToInvalidate && !requestToSwapUser">
                    <ButtonSpinner
                      type="button"
                      class="btn btn-danger w-100"
                      :is-loading="isChangingId === offlineRequest.id"
                      @click.stop.native="requestToInvalidate = offlineRequest"
                    >
                      Invalidate Data Access
                    </ButtonSpinner>

                    <template
                      v-if="
                        getProjectUsers.length > 0 &&
                        !auth.user().company.is_force_local_gather_offline_mode
                      "
                    >
                      <hr />

                      <span class="fw-medium d-block mb-2">
                        Move access to another user
                      </span>

                      <UserListSearch
                        :users="getProjectUsers"
                        :selectedUsers="[offlineRequest.user]"
                        @toggleUser="toggleSwapUser(offlineRequest, $event)"
                      />
                    </template>
                  </template>
                  <template v-if="requestToInvalidate">
                    <span class="d-block fw-medium mb-2">
                      Are you sure you want to invalidate this request?
                    </span>
                    <div class="alert alert-danger">
                      They can still bring their data online at a later point,
                      it will just warn them before doing so. Please inform them
                      if you don't want them to do this.
                    </div>

                    <div class="d-flex w-100 gap-2">
                      <button
                        class="btn btn-light w-100"
                        @click="requestToInvalidate = null"
                      >
                        No
                      </button>
                      <ButtonSpinner
                        type="button"
                        class="btn btn-danger w-100"
                        :is-loading="isChangingId === offlineRequest.id"
                        @click.stop.native="invalidateRequest"
                      >
                        Yes
                      </ButtonSpinner>
                    </div>
                  </template>
                  <template v-if="requestToSwapUser">
                    <span class="d-block fw-medium mb-2">
                      Are you sure you want to swap the user?
                    </span>
                    <div class="alert alert-danger">
                      They can still bring their data online at a later point,
                      it will just warn them before doing so. The new user will
                      receive a download notification.
                    </div>

                    <div class="d-flex w-100 gap-2">
                      <button
                        class="btn btn-light w-100"
                        @click="requestToSwapUser = null"
                      >
                        No
                      </button>
                      <ButtonSpinner
                        type="button"
                        class="btn btn-danger w-100"
                        :is-loading="isChangingId === offlineRequest.id"
                        @click.stop.native="swapUser(offlineRequest)"
                      >
                        Yes
                      </ButtonSpinner>
                    </div>
                  </template>
                </template>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
