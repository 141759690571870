import { Feature, Map } from 'ol';
import FillPattern from 'ol-ext/style/FillPattern';
import { Geometry } from 'ol/geom';
import { getPointResolution } from 'ol/proj';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { StyleLike } from 'ol/style/Style';
import { getMapCenter } from '../common/view';
import { LayerUsageByType } from '../layer/constants';
import { getSampleIcon } from '../layer/sample/utils';
import type { ShapeProperties } from '../layer/shape/types';
import { LayerType } from '../layer/types';
import { formatArea, getArea } from '../measurement/area';
import { getLayoutZoom } from '../measurement/layout';
import addOpacity from './addOpacity';
import { Color } from './color';
import createAreaStyle from './createAreaStyle';
import createArrowStyle from './createArrowStyle';
import createChainageHashMarkStyle from './createChainageHashMarkStyle';
import createHedgeRenderer from './createHedgeRenderer';
import createLineDash from './createLineDash';
import createOutlineStyle from './createOutlineStyle';
import createPolylineArrowHeadStyle from './createPolylineArrowHeadStyle';
import {
  FILL_PATTERN_OPTIONS,
  isFillPatternUsed,
  isNoOverlayUsed,
} from './fill';
import { getLabelStyleOptions, getMeasurementStyleOptions } from './text';
import { FillStyle, OutlineStyle } from './types';

const markerCache = {};

function checkShouldSwapColors(fillStyle: FillStyle) {
  return [
    FillStyle.Circle,
    FillStyle.Cross45Deg,
    FillStyle.Stripe45Deg,
  ].includes(fillStyle);
}

// Geometry layers include Square, Circle, Polygon, Line, Arrow and Boundary.
export default function createShapeStyle(
  map: Map,
  resolveShapeProperties: (feature: Feature<Geometry>) => ShapeProperties
) {
  return function (feature, res) {
    let {
      type,
      usage,
      title,
      color = Color.Black,
      weight = 3, // Stroke thickness
      opacity = 1, // Stroke opacity
      fillStyle = FillStyle.Solid,
      fillOpacity = 0.2,
      outlineStyle = OutlineStyle.Solid,
      areaMeasurement,
      outlineMeasurement,
      multiDirectional,
      icon = 0,
      iconSize = 22,
      shouldShowLabel,
      isConnectorShownAsArrow,
      isConnectorHidden,
      interval = 100,
      shouldReverseHashMarks,
      arrowHeads = [],
    } = resolveShapeProperties(feature);

    const proj = map.getView().getProjection();
    const mpu = proj.getMetersPerUnit()!;
    const layoutZoom = getLayoutZoom(map);

    // TODO foregroundColor could be a gradient of the backgroundColor
    fillOpacity = !isNoOverlayUsed(fillStyle) ? fillOpacity : 0;
    const foregroundColor = !checkShouldSwapColors(fillStyle)
      ? addOpacity(Color.White, fillOpacity)
      : addOpacity(color, fillOpacity);
    const backgroundColor = !checkShouldSwapColors(fillStyle)
      ? addOpacity(color, fillOpacity)
      : addOpacity(Color.White, fillOpacity);
    const strokeColor = addOpacity(
      color,
      type !== LayerType.CALL_OUT ? opacity : 1
    ); // opacity is used to control the opacity of the shape outline
    const stroke = new Stroke({
      color: strokeColor,
      lineCap: outlineStyle === 2 ? 'round' : 'butt',
      lineDash: createLineDash(outlineStyle, weight),
      width: weight,
    });

    let fill;
    if (isFillPatternUsed(fillStyle)) {
      const fillPatternOptions = {
        opacity: 1,
        ratio: 1,
        color: foregroundColor, // foreground color
        offset: 0,
        scale: 1,
        fill: new Fill({ color: backgroundColor }), // background color
        size: 2,
        spacing: 4,
        angle: 0, // in degree
        ...FILL_PATTERN_OPTIONS[fillStyle],
      };
      fill = new FillPattern(fillPatternOptions);
    } else {
      fill = new Fill({
        color: backgroundColor,
      });
    }

    const styles: StyleLike = [];

    if (type === LayerType.POINT) {
      // The way the scaling works is attributed to
      // https://stackoverflow.com/questions/57492200/scaling-the-icon-image-size-to-an-absolute-value
      const {
        src,
        size: [width, height],
      } = getSampleIcon(
        icon,
        color,
        iconSize * layoutZoom,
        iconSize * layoutZoom
      );
      let style = markerCache[src];
      if (!style) {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d')!.drawImage(img, 0, 0, width, height);
          style = markerCache[src] = new Style({
            image: new Icon({
              src: canvas.toDataURL(),
            }),
          });

          styles.push(style);
          feature.changed();
        };
        img.src = src;
      } else {
        styles.push(style);
      }
    } else if (type !== LayerType.HEDGE) {
      styles.push(
        new Style({
          fill,
          stroke,
        })
      );
    }

    if (
      type === LayerType.FEATURE_COLLECTION &&
      usage === LayerUsageByType[LayerType.FEATURE_COLLECTION].CONTOURS &&
      shouldShowLabel
    ) {
      const { Value: value } = feature.getProperties();
      styles.push(
        new Style({
          text: new Text({
            text: String(value),
            ...getMeasurementStyleOptions(layoutZoom),
          }),
        })
      );
    }

    // const interactionManager = map.get(MapPropName.InteractionManager);
    // const isFeatureBeingEdited =
    //   interactionManager.isFeatureBeingEdited(feature);
    const isFeatureBeingEdited = false;
    const hasArea = [
      LayerType.RECTANGLE,
      LayerType.CIRCLE,
      LayerType.POLYGON,
      LayerType.SITE_BOUNDARY,
      LayerType.IMAGE_MATE,
      LayerType.HEDGE,
    ].includes(type);
    const hasLength = [
      LayerType.RECTANGLE,
      LayerType.POLYGON,
      LayerType.POLYLINE,
      LayerType.ARROW,
      LayerType.SITE_BOUNDARY,
      LayerType.IMAGE_MATE,
      LayerType.CHAINAGE,
      LayerType.HEDGE,
    ].includes(type);

    const shouldShowAreaMeasurement =
      (isFeatureBeingEdited && hasArea) || areaMeasurement;
    // If label is shown then the area measurement shows in the label.
    if (!shouldShowLabel && shouldShowAreaMeasurement) {
      styles.push(createAreaStyle(map, feature, !areaMeasurement));
    }

    if ((isFeatureBeingEdited && hasLength) || outlineMeasurement) {
      styles.push(...createOutlineStyle(map, feature, !outlineMeasurement));
    }

    if (
      type === LayerType.ARROW ||
      (type === LayerType.CALL_OUT &&
        !isConnectorHidden &&
        isConnectorShownAsArrow)
    ) {
      const headStroke = new Stroke({
        color: strokeColor,
        width: weight,
      });
      styles.push(
        ...createArrowStyle(map, feature, headStroke, weight, multiDirectional)
      );
    }

    if (type === LayerType.CHAINAGE) {
      const factor = shouldReverseHashMarks ? -1 : 1;
      const largeOffset = 20 * factor;
      const mediumOffset = 15 * factor;
      const smallOffset = 10 * factor;
      const unitPixels =
        interval /
        (mpu *
          getPointResolution(
            map.getView().getProjection(),
            res,
            getMapCenter(map)
          ));

      styles.push(
        createChainageHashMarkStyle(
          Color.Black,
          smallOffset,
          1,
          unitPixels / 10,
          weight,
          res
        ),
        createChainageHashMarkStyle(
          Color.Black,
          mediumOffset,
          1,
          unitPixels / 2,
          weight,
          res
        ),
        createChainageHashMarkStyle(
          Color.Red,
          largeOffset,
          1,
          unitPixels,
          weight,
          res
        )
      );
    }

    if (type === LayerType.HEDGE) {
      const image = map.get('hedgeImage');
      const isEditing = map.get('interactionManager').isEditing();
      styles.push(
        new Style({
          renderer: createHedgeRenderer(map, image, isEditing),
          zIndex: 0,
        })
      );
    }

    // Arrow heads on the polyline
    if (type === LayerType.POLYLINE) {
      const pahStyles = createPolylineArrowHeadStyle(
        map,
        feature,
        res,
        getLayoutZoom(map),
        arrowHeads
      );
      styles.push(...pahStyles);
    }

    if (
      [
        LayerType.POLYGON,
        LayerType.RECTANGLE,
        LayerType.CIRCLE,
        LayerType.POLYLINE,
        LayerType.ARROW,
        LayerType.SITE_BOUNDARY,
        LayerType.HEDGE,
      ].includes(type) &&
      shouldShowLabel
    ) {
      const label = shouldShowAreaMeasurement
        ? [title, formatArea(getArea(map, feature.getGeometry()))].join('\n')
        : title;

      styles.push(
        new Style({
          text: new Text(getLabelStyleOptions(label, color, layoutZoom)),
          zIndex: 1,
        })
      );
    }

    return styles;
  };
}
