import {
  Country,
  STATE_AU_WESTERN_AUSTRALIA,
} from '../../../../../common/address';
import { EsriType } from '../../../../service/esri/types';
import { LayerType } from '../../../../types';
import type { BasemapImageServerApi } from '../../../types';
import { BasemapId } from '../../../types';
import hosts from '../../hosts';

const host = hosts.WA_AERIAL;

const basemapApi: BasemapImageServerApi = {
  id: BasemapId.AU_WA_AERIAL,
  title: 'Western Australia - Aerial',
  subtitle: 'WA Now Image Service',
  type: 'satellite',
  host,
  countries: [Country.Australia],
  states: [STATE_AU_WESTERN_AUSTRALIA],
  layerModel: {
    id: 'western-australia-aerial-folder',
    geojson: {
      properties: {
        title: 'WA Now Imagery',
        type: LayerType.FOLDER,
        url: `https://${host}/arcgis/rest/services/Landgate_Subscription_Imagery/WANow/ImageServer`,
        //@ts-ignore
        token: import.meta.env.VITE_SLIP_TOKEN,
        esri_type: EsriType.ImageServer,
        extent: [
          96.79394282300007, -35.31345375399999, 129.81262262300007,
          -10.40955960399998,
        ],
        visibleExtent: [
          96.79394282300005, -35.31345375399998, 129.81262262300007,
          -10.40955960399998,
        ],
        maxImageSize: { width: 15000, height: 4100 },
        mapTileConfig: null,
        attributions: 'Landgate / SLIP',
        projection: 'EPSG:7844',
        shouldUseCorsProxy: 0,
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [
      {
        id: 'western-australia-aerial-item',
        geojson: {
          properties: {
            title: '',
            type: LayerType.SERVICE,
            index: 'Landgate_Subscription_Imagery/WANow',
            hidden_sub_layers: [],
            renderer: undefined,
          },
        },
        visible: true,
        is_visible_in_basemap_figure: false,
        children: [],
      },
    ],
  },
};

export default basemapApi;
