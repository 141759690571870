import type { LayerModel } from '../../types';
import { LayerType } from '../../types';
import type {
  WfsFolderLayerModel,
  WmsFolderLayerModel,
  WmtsFolderLayerModel,
} from './types';
import { ServiceSubType } from './types';

type Service = { subtype: ServiceSubType };

export function checkIsWfsFolderLayerModel(
  model: LayerModel
): model is WfsFolderLayerModel {
  const { type } = model.geojson.properties;
  return (
    type === LayerType.FOLDER &&
    'service' in model.geojson.properties &&
    (model.geojson.properties.service as Service).subtype === ServiceSubType.WFS
  );
}

export function checkIsWmsFolderLayerModel(
  model: LayerModel
): model is WmsFolderLayerModel {
  const { type } = model.geojson.properties;
  return (
    type === LayerType.FOLDER &&
    'service' in model.geojson.properties &&
    (model.geojson.properties.service as Service).subtype === ServiceSubType.WMS
  );
}

export function checkIsWmtsFolderLayerModel(
  model: LayerModel
): model is WmtsFolderLayerModel {
  const { type } = model.geojson.properties;
  return (
    type === LayerType.FOLDER &&
    'service' in model.geojson.properties &&
    (model.geojson.properties.service as Service).subtype ===
      ServiceSubType.WMTS
  );
}
