import { Map } from 'ol';
import { WMSCapabilities } from 'ol/format';
import { Image as ImageLayer } from 'ol/layer';
import { ImageWMS } from 'ol/source';
import { Code, throwError } from '../../../common/error';
import { mergeExtents } from '../../../common/extent';
import {
  createRequestOptions,
  forceHttps,
  proxify,
  replaceQueryParam,
} from '../../../common/network';
import { getLayoutZoom } from '../../../measurement/layout';
import type { LayerUsage } from '../../constants';
import { LayerType } from '../../types';
import { createLayerProperties, getVisibleExtent } from '../../utils';
import enableLoadingEvents from '../enableLoadingEvents';
import type { WmsFolderLayerModel, WmsItemLayerModel, WmsLayer } from './types';
import { AxisOrientation } from './types';

declare const axios: any;

function correctBBOX(requestUrl) {
  const url = new URL(requestUrl);
  const [minx, miny, maxx, maxy] = url.searchParams.get('BBOX')!.split(',');
  const bbox = [miny, minx, maxy, maxx];
  const bboxParam = encodeURIComponent(bbox.join(','));
  return replaceQueryParam(requestUrl, 'BBOX', bboxParam);
}

function correctSize(requestUrl, scale) {
  const url = new URL(requestUrl);
  const width = parseInt(url.searchParams.get('WIDTH')!);
  const height = parseInt(url.searchParams.get('HEIGHT')!);
  requestUrl = replaceQueryParam(
    requestUrl,
    'WIDTH',
    String(Math.round(width * scale))
  );
  requestUrl = replaceQueryParam(
    requestUrl,
    'HEIGHT',
    String(Math.round(height * scale))
  );
  return requestUrl;
}

export async function getServiceData(serviceUrl, shouldUseCorsProxy) {
  const url = !shouldUseCorsProxy ? serviceUrl : proxify(serviceUrl);
  const { data } = await axios.get(url, createRequestOptions());
  const parser = new WMSCapabilities();
  const wmsCapabilities = parser.read(data);

  const {
    version,
    Service: { Title: title, Abstract: description },
    Capability: {
      Request: {
        GetMap: {
          DCPType: [
            {
              HTTP: {
                Get: { OnlineResource: onlineResource },
              },
            },
          ],
        },
      },
      Layer: wmsLayerGroup,
    },
  } = wmsCapabilities;
  const { Layer: wmsLayers } = wmsLayerGroup;
  const layers: any = [];
  const subLayerIds = wmsLayers.map((wmsLayer) => wmsLayer.name);
  layers.push({
    id: 1,
    name: wmsLayerGroup.Title,
    description: wmsLayerGroup.Abstract,
    extent: wmsLayerGroup.EX_GeographicBoundingBox,
    parentLayerId: -1,
    subLayerIds,
  });

  wmsLayers.forEach((wmsLayer) => {
    const { Name, Title, EX_GeographicBoundingBox, Style } = wmsLayer;

    const legendGraphics: any = [];
    if (Array.isArray(Style)) {
      Style.forEach((item) => {
        const { Title: title } = item;
        const [{ OnlineResource: url, size }] = item.LegendURL; // LegendURL is an array with only one item.
        legendGraphics.push({
          title,
          url: forceHttps(url),
          size,
        });
      });
    }

    layers.push({
      url: forceHttps(onlineResource),
      id: Name,
      name: Title,
      extent: EX_GeographicBoundingBox,
      parentLayerId: 1,
      subLayerIds: null,
      projection: wmsLayer.CRS?.[0] ?? 'EPSG:3857',
      legendGraphics,
    });
  });

  const extents = layers.map((item) => item.extent);
  const fullExtent = mergeExtents(...extents);

  return {
    // TODO Research whether we can get attributions from the capabilities data.
    attributions: '',
    version,
    title,
    description,
    layers,
    fullExtent,
  };
}

export default function createLayer(
  map: Map,
  folderModel: WmsFolderLayerModel,
  itemModel: WmsItemLayerModel,
  layerType: LayerType = LayerType.BASEMAP_SERVICE,
  layerUsage?: LayerUsage
): WmsLayer {
  const { attributions, version, shouldUseCorsProxy, axisOrientation } =
    folderModel.geojson.properties.service;

  const { id, projection = 'EPSG:3857' } = itemModel.geojson.properties;

  const visibleExtent = getVisibleExtent(folderModel, projection);

  const url = (new URL(folderModel.geojson.properties.service.url)).toString();
  const layerSource = new ImageWMS({
    attributions,
    crossOrigin: 'anonymous',
    url,
    params: { VERSION: version, LAYERS: id },
    ratio: 1,
    projection,
  });

  // @ts-ignore
  const getRequestUrl_ = layerSource.getRequestUrl_;
  // @ts-ignore
  layerSource.getRequestUrl_ = function (...args) {
    let requestUrl = getRequestUrl_.call(this, ...args);
    if (shouldUseCorsProxy) {
      requestUrl = proxify(requestUrl);
    }
    if (axisOrientation === AxisOrientation.NEU) {
      requestUrl = correctBBOX(requestUrl);
    }
    requestUrl = correctSize(requestUrl, 1 / getLayoutZoom(map));
    return requestUrl;
  };

  const layer = new ImageLayer({
    source: layerSource,
    extent: visibleExtent,
    properties: createLayerProperties(itemModel.id, layerType, layerUsage),
  }) as WmsLayer;

  layer.getFirstFeature = function () {
    throwError(Code.MethodNotImplemented, 'getFirstFeature');
  };

  layer.checkHasFeature = function (feature) {
    throwError(Code.MethodNotImplemented, 'checkHasFeature');
  };

  layer.toGeoJSON = function () {
    throwError(Code.MethodNotImplemented, 'toGeoJSON');
  };

  layer.getBounds = function (padding = 0) {
    throwError(Code.MethodNotImplemented, 'getBounds');
  };

  layer.refresh = function () { };

  enableLoadingEvents(layer);

  return layer;
}
