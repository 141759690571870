import { Country } from '../../../../common/address';
import { EsriType } from '../../../service/esri/types';
import { LayerType } from '../../../types';
import type { BasemapMapServerApi } from '../../types';
import { BasemapId } from '../../types';
import hosts from '../hosts';

const host = hosts.GB_BRISTOL_AND_SURROUNDING_AREA;

const basemapApi: BasemapMapServerApi = {
  id: BasemapId.GB_BRISTOL_AND_SURROUNDING_AREA,
  title: 'Bristol and the surrounding area',
  subtitle: 'Southwest boundary',
  type: 'street',
  host,
  countries: [Country.UnitedKingdom],
  layerModel: {
    id: 'bristol-and-the-surrounding-area-folder',
    geojson: {
      properties: {
        title: '2020_Q2_GREY_BCC_128dpi',
        type: LayerType.FOLDER,
        url: `https://${host}/server1/rest/services/base/2020_Q2_GREY_BCC_128dpi/MapServer`,
        token: undefined,
        esri_type: EsriType.MapServer,
        extent: [
          -6.6275285724489175, 49.86396892501348, -1.4769876378223192,
          52.111649318969185,
        ],
        visibleExtent: [
          -6.6275285724489175, 49.863968925013495, -1.4769876378223192,
          52.11164931896917,
        ],
        maxImageSize: { width: 4096, height: 4096 },
        mapTileConfig: {
          tileInfo: {
            projection: 'EPSG:27700',
            resolutions: [
              38.10007620015241, 19.050038100076204, 9.525019050038102,
              4.762509525019051, 2.3812547625095255, 1.1906273812547628,
              0.5953136906273814, 0.24804737109474223, 0.14882842265684534,
            ],
            origin: [-5220400, 4470200],
            tileSize: [256, 256],
          },
        },
        attributions:
          "OS data \u00a9 Crown copyright & database rights 2019 Ordnance Survey 100023406. Use of OS data is subject to an <a href='http://www.ordnancesurvey.co.uk/business-and-government/public-sector/mapping-agreements/end-user-licence.html' target='psma_eul'>end user licence</a>",
        projection: 'EPSG:27700',
        shouldUseCorsProxy: true,
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [
      {
        id: 'bristol-and-the-surrounding-area-item',
        geojson: {
          properties: {
            title: '',
            type: LayerType.SERVICE,
            index: 0,
            hidden_sub_layers: [],
            minScale: 0,
            maxScale: 0,
            hasLabels: false,
            renderer: undefined,
          },
        },
        visible: true,
        is_visible_in_basemap_figure: false,
        children: [],
      },
    ],
  },
};

export default basemapApi;
