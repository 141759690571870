<script setup lang="ts">
import { BLACK } from '../../../business-logic/mapping/color';
import ColorChooser from '../ColorChooser.vue';
import FillStyleEditor from './FillStyleEditor.vue';
import GeometryTextStyleEditor from './GeometryTextStyleEditor.vue';
import OutlineStyleEditor from './OutlineStyleEditor.vue';
import { injectContext } from './context';

const { getShapeProperty, setShapeProperty } = injectContext();
</script>

<template>
  <div class="rectangle-style-editor">
    <ColorChooser
      class="mb-2"
      :value="getShapeProperty('color', BLACK)"
      @input="(value) => setShapeProperty('color', value)"
    />

    <FillStyleEditor />

    <OutlineStyleEditor />

    <GeometryTextStyleEditor />
  </div>
</template>
