import { Country } from '../../../../common/address';
import {
  AxisOrientation,
  ServiceSubType,
  WmtsRequestEncoding,
} from '../../../service/ogc-opengis/types';
import { ServiceType } from '../../../service/types';
import { LayerType } from '../../../types';
import type { BasemapWmtsApi } from '../../types';
import { BasemapId } from '../../types';
import hosts from '../hosts';

const host = hosts.GB_OS_MAPS_ROAD;

const basemapApi: BasemapWmtsApi = {
  id: BasemapId.GB_OS_MAPS_ROAD,
  title: 'OS Maps - Road',
  subtitle: '',
  type: 'street',
  host,
  countries: [Country.UnitedKingdom],
  layerModel: {
    id: 'os-maps-road-folder',
    geojson: {
      properties: {
        type: LayerType.FOLDER,
        title: 'OS Maps API',
        service: {
          type: ServiceType.OgcOpenGIS,
          subtype: ServiceSubType.WMTS,
          url: `https://${host}/maps/raster/v1/wmts?key=5bAG78xKo9YRLPTkQmvqzyVpkAdAO2Rc&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities`,
          version: '1.0.0',
          title: 'OS Maps API',
          description: 'OS Maps API',
          extent: [
            -10.8342841886, 49.38802038505334, 7.551552493184295,
            62.2662683043619,
          ],
          visibleExtent: [
            -10.8342841886, 49.38802038505332, 7.551552493184294,
            62.266268304361944,
          ],
          attributions: 'Ordnance Survey',
          shouldUseCorsProxy: false,
          axisOrientation: AxisOrientation.ENU,
          projection: 'EPSG:3857',
        },
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [
      {
        id: 'os-maps-road-item',
        geojson: {
          properties: {
            type: LayerType.SERVICE,
            id: 'Road_3857',
            name: 'Road_3857',
            extent: [
              -10.764179999999964, 49.52842300000003, 1.9134115552,
              61.3311510086,
            ],
            format: 'image/png',
            url: 'https://api.os.uk/maps/raster/v1/wmts?key=5bAG78xKo9YRLPTkQmvqzyVpkAdAO2Rc&',
            matrixSet: 'EPSG:3857',
            matrixIds: [
              'EPSG:3857:7',
              'EPSG:3857:8',
              'EPSG:3857:9',
              'EPSG:3857:10',
              'EPSG:3857:11',
              'EPSG:3857:12',
              'EPSG:3857:13',
              'EPSG:3857:14',
              'EPSG:3857:15',
              'EPSG:3857:16',
              'EPSG:3857:17',
              'EPSG:3857:18',
              'EPSG:3857:19',
              'EPSG:3857:20',
            ],
            resolutions: [
              1222.9924523698928, 611.4962261850814, 305.7481130924087,
              152.87405654633633, 76.43702827303586, 38.21851413651793,
              19.109257068258966, 9.554628534129483, 4.777314267197037,
              2.3886571335985134, 1.1943285666669667, 0.5971642834657753,
              0.2985821416005962, 0.1492910708002979,
            ],
            origins: [
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
              [-20037508.342787, 20037508.342787],
            ],
            sizes: [
              [65, 44],
              [130, 88],
              [260, 175],
              [519, 350],
              [1037, 699],
              [2073, 1398],
              [4145, 2795],
              [8290, 5590],
              [16580, 11180],
              [33159, 22360],
              [66317, 44719],
              [132634, 89437],
              [265267, 178873],
              [530533, 357746],
            ],
            tileSizes: [
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
            ],
            parentLayerId: -1,
            subLayerIds: null,
            style: 'default',
            requestEncoding: WmtsRequestEncoding.KVP,
            projection: 'EPSG:3857',
            title: 'Road_3857',
          },
        },
        visible: true,
        is_visible_in_basemap_figure: false,
        children: [],
      },
    ],
  },
};

export default basemapApi;
