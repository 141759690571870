import { OutlineStyle } from './types';

export default function createLineDash(
  outlineStyle: OutlineStyle,
  weight: number
): number[] | undefined {
  if (outlineStyle === OutlineStyle.Solid) {
    return undefined;
  }

  let dashArray;
  switch (outlineStyle) {
    case OutlineStyle.Dashed:
      dashArray = `${3 * weight},${weight}`;
      break;
    case OutlineStyle.Dotted:
      dashArray = `0,${weight * 1.5}`;
      break;
  }

  if (typeof dashArray === 'string') {
    return dashArray.split(',').map((item) => {
      const neatItem = item.trim();
      return parseFloat(neatItem);
    });
  }

  return undefined;
}
