import { Tile as TileLayer } from 'ol/layer';
import type { ProjectionLike } from 'ol/proj';
import { XYZ } from 'ol/source';
import { Extent } from '../../common/types';
import type { LayerMixin, LayerModel, Properties } from '../types';

export enum ServiceType {
  ESRI = 'esri',
  OgcOpenGIS = 'ogc_opengis',
  Xyz = 'xyz_tiles',
}

export type Service = {
  type: ServiceType;
  url: string;
  title: string;
  description: string;
  extent: Readonly<Extent>;
  visibleExtent: Extent;
  attributions: string;
  shouldUseCorsProxy: boolean | 1 | 0;
  maxZoom?: number;
  projection: ProjectionLike;
};

export type XyzFolderProperties = Properties & {
  service: Service;
};

export type XyzItemProperties = Properties & {
  id: number;
  name: string;
  extent: Extent;
};

export type XyzFolderLayerModel = LayerModel<XyzFolderProperties> & {
  children: XyzItemLayerModel[];
};

export type XyzItemLayerModel = LayerModel<XyzItemProperties>;

export type XyzLayer = TileLayer<XYZ> & LayerMixin;
