<script lang="ts" setup>
import { ref, computed } from 'vue';
import Draggable from 'vuedraggable';
import type { App, ProjectPhase } from '../../gather';
import DropdownMenu from '../DropdownMenu.vue';
import NotifyModal from '../NotifyModal.vue';
import AppCard from './AppCard.vue';
import { useGatherSchemaStore } from '../../store/gather-schema';
import _debounce from 'lodash/debounce';
import { useDialogStore } from '../../store/dialog';
import { KeyValue } from '../../key-value';
import axios from 'axios';
import { useToastStore } from '../../store/toasts';
import { useRouter } from 'vue-router/composables';
import useIsGather from '../../composables/useIsGather';

const props = withDefaults(
  defineProps<{
    apps: App[];
    phases: ProjectPhase[];
    selectedAppId?: number | null;
    isDisabled?: boolean;
    collapsedPhaseIds?: number[];
    canCreateApp?: boolean;
    projectId: number;
  }>(),
  {
    isDisabled: false,
    selectedAppId: null,
    collapsedPhaseIds: () => [],
    canCreateApp: true,
  }
);

type UpdatedPhaseApps = Record<number, App[]>;
type UpdatedPhaseOrdering = { id: number; order: number }[];

const emit = defineEmits<{
  (event: 'selectAppById', id: number): void;
  (event: 'editTab'): void;
  (event: 'removeTab'): void;
  (event: 'reloadTemplate'): void;
  (event: 'updateAppOrdering', data: UpdatedPhaseApps): void;
  (event: 'updateCollapsedPhases', data: number[]): void;
  (event: 'updatePhase', { id, data }: { id: number; data: KeyValue }): void;
  (event: 'updatePhaseOrdering', data: UpdatedPhaseOrdering): void;
}>();

const dialogStore = useDialogStore();
const gatherSchema = useGatherSchemaStore();
const toastStore = useToastStore();
const router = useRouter();
const isGather = useIsGather();

const showDeleteModal = ref(false);
const isDraggingOverGroupId = ref<number | 'outliers' | null>(null);

const orderedPhases = computed({
  get: () => props.phases.sort((a, b) => a.order - b.order),
  set: (value) => {
    emit(
      'updatePhaseOrdering',
      value.map((p, order) => {
        return {
          id: p.id,
          order,
        };
      })
    );
  },
});

const getAppsByPhase = (phaseId: number | null) => {
  return props.apps
    .filter((a) => a.app_phase_group_id === phaseId)
    .sort((a, b) => a.order - b.order);
};

const appsByPhase = computed(() => {
  const appPhases = {};

  orderedPhases.value.forEach((phase) => {
    appPhases[phase.id] = getAppsByPhase(phase.id);
  });

  appPhases['outliers'] = getAppsByPhase(null);

  return appPhases;
});

function deleteApp() {
  showDeleteModal.value = false;
  emit('removeTab');
}

async function createApp() {
  try {
    await axios.post(`/api/template/${props.projectId}/update`, {
      template_tabs: [
        {
          title: 'Untitled App',
          is_locked: false,
          prefix: null,
          drawing_type: 'point',
          drawing_colour: null,
          point_icon: 0,
          drawing_properties: null,
        },
      ],
    });

    emit('reloadTemplate');
  } catch (e: any) {
    toastStore.unexpected(e);
  }
}

const handleDragEnd = (event: any, phaseId: number | 'outliers') => {
  const { newIndex, oldIndex, to } = event;

  const toPhaseId = to.getAttribute('data-phase-id');
  const newPhaseId =
    toPhaseId !== 'outliers' ? parseInt(toPhaseId) : 'outliers';

  const updatedPhaseApps: UpdatedPhaseApps = { ...appsByPhase.value };
  if (newPhaseId === phaseId) {
    const movedItem = updatedPhaseApps[phaseId].splice(oldIndex, 1)[0];
    updatedPhaseApps[phaseId].splice(newIndex, 0, movedItem);
  } else {
    updatedPhaseApps[newPhaseId].splice(
      newIndex,
      0,
      { ...appsByPhase.value }[phaseId][oldIndex]
    );
    updatedPhaseApps[phaseId].splice(oldIndex, 1);
  }

  emit('updateAppOrdering', updatedPhaseApps);

  isDraggingOverGroupId.value = null;
};

const checkMove = (e) => {
  const phaseId = e.to.getAttribute('data-phase-id');
  isDraggingOverGroupId.value =
    phaseId !== 'outliers' ? parseInt(phaseId) : 'outliers';
};

const onTitleChange = _debounce((e, phaseId: number) => {
  emit('updatePhase', {
    id: phaseId,
    data: {
      key: 'title',
      value: e.target.value,
    },
  });
}, 300);

const toggleCollapsePhase = (phaseId: number) => {
  const index = props.collapsedPhaseIds.indexOf(phaseId);
  if (index === -1) {
    emit('updateCollapsedPhases', [...props.collapsedPhaseIds, phaseId]);
  } else {
    emit(
      'updateCollapsedPhases',
      props.collapsedPhaseIds.filter((id) => id !== phaseId)
    );
  }
};

const isPhaseCollapsed = (phaseId: number) => {
  return props.collapsedPhaseIds.includes(phaseId);
};

function toToAllApps() {
  router.push(`/template/${props.projectId}/apps`);
}
</script>

<template>
  <div>
    <Draggable v-model="orderedPhases" draggable=".draggable-phase">
      <div
        v-for="phase in orderedPhases"
        class="drag-area draggable-phase mb-3"
        :class="{
          'dragging-over': isDraggingOverGroupId === phase.id,
          'is-collapsed': isPhaseCollapsed(phase.id),
        }"
        :data-phase-id="phase.id"
      >
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <span class="mb-0 me-3">
              <input
                type="text"
                placeholder="Untitled phase"
                class="border-0 bg-transparent mb-0 fw-medium"
                :value="phase.title"
                @change="onTitleChange($event, phase.id)"
              />
            </span>
          </div>
          <div class="d-flex align-items-center">
            <span
              v-if="isPhaseCollapsed(phase.id)"
              class="badge bg-light text-dark me-2"
            >
              {{ appsByPhase[phase.id].length }} apps inside
            </span>
            <i
              v-if="canCreateApp"
              class="fas fa-trash-alt fa-fw me-2 mb-1 clickable"
              @click="
                dialogStore.confirmDanger(
                  'Are you sure you wish to delete this group?',
                  appsByPhase[phase.id].length > 0
                    ? 'This will move all apps inside the group back to the outliers.'
                    : undefined,
                  async () => {
                    await gatherSchema.deletePhase(phase.id, projectId);
                    emit('reloadTemplate');
                  }
                )
              "
            />

            <div class="form-check form-switch mt-1 me-2">
              <input
                class="form-check-input"
                type="checkbox"
                v-tooltip="'Toggle visibility of this phase'"
                :checked="phase.is_visible"
                @change="
                  emit('updatePhase', {
                    id: phase.id,
                    data: {
                      key: 'is_visible',
                      value: !phase.is_visible,
                    },
                  })
                "
              />
            </div>
            <i
              class="fal clickable"
              :class="{
                'fa-chevron-down': isPhaseCollapsed(phase.id),
                'fa-chevron-up': !isPhaseCollapsed(phase.id),
              }"
              @click="toggleCollapsePhase(phase.id)"
            />
          </div>
        </div>

        <div v-if="!isPhaseCollapsed(phase.id)" class="row mt-2">
          <Draggable
            :value="appsByPhase[phase.id]"
            group="apps"
            class="d-flex flex-wrap gap-3"
            :data-phase-id="phase.id"
            @end="handleDragEnd($event, phase.id)"
            :move="checkMove"
            draggable=".draggable-card"
          >
            <small
              v-if="
                appsByPhase[phase.id].length === 0 &&
                isDraggingOverGroupId !== phase.id
              "
              class="text-muted fw-medium d-flex align-items-center"
            >
              You have not added any apps to this phase. Drag and drop apps
              here.
            </small>

            <AppCard
              v-for="(app, index) of appsByPhase[phase.id]"
              :key="index"
              class="draggable-card"
              :app="app"
              :selected="selectedAppId !== null && app.id === selectedAppId"
              :canDelete="apps.length > 1"
              :isDisabled="isDisabled"
              @editApp="$emit('editTab')"
              @deleteApp="() => (showDeleteModal = true)"
              @click.native="$emit('selectAppById', app.id)"
            />
          </Draggable>
        </div>
      </div>
    </Draggable>

    <div class="drag-area">
      <div class="row">
        <Draggable
          :value="appsByPhase['outliers']"
          class="d-flex flex-wrap gap-3 h-100"
          group="apps"
          data-phase-id="outliers"
          @end="handleDragEnd($event, 'outliers')"
          draggable=".draggable-card"
          :move="checkMove"
        >
          <DropdownMenu
            :slot="
              appsByPhase['outliers'].length === 0 &&
              isDraggingOverGroupId !== 'outliers'
                ? 'header'
                : 'footer'
            "
            class="h-100"
          >
            <template #button="{ toggled, toggle }">
              <button
                v-if="isGather"
                type="button"
                class="btn btn-primary p-4 h-100 d-flex align-items-center justify-content-center"
                :class="{
                  active: toggled,
                  'dropdown-toggle': canCreateApp,
                }"
                @click="canCreateApp ? toggle() : toToAllApps()"
              >
                <h6 v-if="canCreateApp" class="fal fa-plus fa-fw mb-0"></h6>
                <h6 v-else class="fal fa-arrow-right fa-sm fa-fw mb-0"></h6>
              </button>
            </template>
            <template v-slot:items="{ toggled }">
              <RouterLink
                class="dropdown-item p-2 text-primary"
                :to="`/template/${projectId}/apps`"
              >
                View All Apps
              </RouterLink>
              <div
                v-if="canCreateApp"
                class="dropdown-item p-2"
                @click="createApp"
              >
                Create App
              </div>
              <div
                v-if="canCreateApp"
                class="dropdown-item p-2"
                @click="
                  gatherSchema.createPhase(
                    'App Group ' + (orderedPhases.length + 1),
                    projectId
                  )
                "
              >
                Create App Group
              </div>
            </template>
          </DropdownMenu>

          <small
            v-if="
              appsByPhase['outliers'].length === 0 &&
              isDraggingOverGroupId !== 'outliers'
            "
            class="text-muted fw-medium d-flex align-items-center"
          >
            You have not added any apps as outliers. Drag and drop apps here.
          </small>

          <AppCard
            v-for="(app, index) of appsByPhase['outliers']"
            :key="index"
            class="draggable-card"
            :app="app"
            :selected="selectedAppId !== null && app.id === selectedAppId"
            :canDelete="apps.length > 1"
            :isDisabled="isDisabled"
            @editApp="$emit('editTab')"
            @deleteApp="() => (showDeleteModal = true)"
            @click.native="$emit('selectAppById', app.id)"
          />
        </Draggable>
      </div>
    </div>

    <NotifyModal
      v-if="showDeleteModal"
      :show="true"
      @close="() => (showDeleteModal = false)"
      @submit="deleteApp"
      headerMessage="Are you sure you would like to delete this app?"
      :isDelete="true"
      :requireConfirmation="true"
    >
      <p class="alert border-danger text-danger">
        <i class="fas fa-exclamation-triangle fa-lg me-2" />
        This will delete any items collected with this App & project!
      </p>
    </NotifyModal>
  </div>
</template>

<style scoped>
.drag-area {
  border: 2px dashed #f4f4f4;
  border-radius: 0.375rem;
  padding: 12px 16px;
  transition: border-color 0.3s;
}

.dragging-over {
  border-color: #212121;
}

.is-collapsed {
  border: 2px solid #f4f4f4;
}

input.border-0:focus {
  outline: none;
}
</style>
