import { Map } from 'ol';
import { Circle } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { fromCircle } from 'ol/geom/Polygon';
import { getArea as ol_sphere_getArea } from 'ol/sphere';
import { getMapProjection } from '../common/view';
import type { Shape } from '../layer/shape/types';
import type { AbsoluteUnit } from './types';
import { METER } from './unit';

export function getArea(map: Map, shape: Shape) {
  const st = shape.getType();
  if (st === GeometryType.CIRCLE) {
    shape = fromCircle(shape as Circle, 128);
  }

  return ol_sphere_getArea(shape, {
    projection: getMapProjection(map),
  });
}

export function formatArea(
  area: number,
  projectUnit: AbsoluteUnit = METER
): string {
  return METER.format(area, projectUnit, projectUnit, true);
}
