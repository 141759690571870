import { get as getProjection } from 'ol/proj';

export const EPSG_2193 = 'EPSG:2193';
export const EPSG_3851 = 'EPSG:3851';
export const EPSG_4167 = 'EPSG:4167';
export const EPSG_27200 = 'EPSG:27200';
export const EPSG_4764 = 'EPSG:4764';
export const EPSG_2105 = 'EPSG:2105';
export const EPSG_3790 = 'EPSG:3790';
export const EPSG_3788 = 'EPSG:3788';
export const EPSG_3789 = 'EPSG:3789';
export const EPSG_3793 = 'EPSG:3793';
export const EPSG_32704 = 'EPSG:32704';
export const EPSG_32703 = 'EPSG:32703';
export const EPSG_3791 = 'EPSG:3791';
export const EPSG_5479 = 'EPSG:5479';
export const EPSG_32702 = 'EPSG:32702';

// The bbox data comes from https://epsg.io/?format=json&q=[Projection Code, e.g. 2193]
export const projectionConfigs = [
  {
    code: EPSG_2193,
    name: 'NZTM',
    bbox: [-34.1, 166.37, -47.33, 178.63],
    proj4Def:
      '+proj=tmerc +lat_0=0 +lon_0=173 +k=0.9996 +x_0=1600000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_3851,
    name: 'NZCS2000',
    bbox: [-25.88, 160.6, -55.95, -171.2],
    proj4Def:
      '+proj=lcc +lat_1=-37.5 +lat_2=-44.5 +lat_0=-41 +lon_0=173 +x_0=3000000 +y_0=7000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_4167,
    name: 'NZGD2000',
    bbox: [-25.88, 160.6, -55.95, -171.2],
    proj4Def: '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs',
  },
  {
    code: EPSG_27200,
    name: 'NZMG',
    bbox: [-34.1, 166.37, -47.33, 178.63],
    proj4Def:
      '+proj=nzmg +lat_0=-41 +lon_0=173 +x_0=2510000 +y_0=6023150 +ellps=intl +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +units=m +no_defs',
  },
  {
    code: EPSG_4764,
    name: 'RSRGD2000',
    bbox: [-59.99, 144.99, -90, -144.99],
    proj4Def: '+proj=longlat +ellps=GRS80 +no_defs +type=crs',
  },
  {
    code: EPSG_2105,
    name: 'NZGD2000 / Mount Eden 2000',
    bbox: [-34.1, 171.99, -39.01, 176.12],
    proj4Def:
      '+proj=tmerc +lat_0=-36.8797222222222 +lon_0=174.764166666667 +k=0.9999 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  },
  {
    code: EPSG_3790,
    name: 'NZGD2000 / Antipodes Islands TM 2000',
    bbox: [-47.54, 178.4, -49.92, 179.37],
    proj4Def:
      '+proj=tmerc +lat_0=0 +lon_0=179 +k=1 +x_0=3500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  },
  {
    code: EPSG_3788,
    name: 'NZGD2000 / Auckland Islands TM 2000',
    bbox: [-47.8, 165.55, -51.13, 166.93],
    proj4Def:
      '+proj=tmerc +lat_0=0 +lon_0=166 +k=1 +x_0=3500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  },
  {
    code: EPSG_3789,
    name: 'NZGD2000 / Campbell Island TM 2000',
    bbox: [-52.26, 168.65, -52.83, 169.6],
    proj4Def:
      '+proj=tmerc +lat_0=0 +lon_0=169 +k=1 +x_0=3500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  },
  {
    code: EPSG_3793,
    name: 'NZGD2000 / Chatham Islands TM 2000',
    bbox: [-43.3, -177.25, -44.64, -175.54],
    proj4Def:
      '+proj=tmerc +lat_0=0 +lon_0=-176.5 +k=1 +x_0=3500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  },
  {
    code: EPSG_32704,
    name: 'WGS 84 / UTM zone 4S',
    bbox: [0, -162, -80, -156],
    proj4Def:
      '+proj=utm +zone=4 +south +datum=WGS84 +units=m +no_defs +type=crs',
  },
  {
    code: EPSG_32703,
    name: 'WGS 84 / UTM zone 3S',
    bbox: [0, -168, -80, -162],
    proj4Def:
      '+proj=utm +zone=3 +south +datum=WGS84 +units=m +no_defs +type=crs',
  },
  {
    code: EPSG_3791,
    name: 'NZGD2000 / Raoul Island TM 2000',
    bbox: [-29.03, -179.07, -31.56, -177.62],
    proj4Def:
      '+proj=tmerc +lat_0=0 +lon_0=-178 +k=1 +x_0=3500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  },
  {
    code: EPSG_5479,
    name: 'RSRGD2000 / MSLC2000',
    bbox: [-76, 153, -81, 173],
    proj4Def:
      '+proj=lcc +lat_0=-78 +lon_0=163 +lat_1=-76.6666666666667 +lat_2=-79.3333333333333 +x_0=7000000 +y_0=5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  },
  {
    code: EPSG_32702,
    name: 'WGS 84 / UTM zone 2S',
    bbox: [0, -174, -80, -168],
    proj4Def:
      '+proj=utm +zone=2 +south +datum=WGS84 +units=m +no_defs +type=crs',
  },
];

export function afterRegistration() {
  const epsg2193 = getProjection(EPSG_2193);
  epsg2193.setGetPointResolution(function (resolution, point) {
    return resolution;
  });
}
