<script setup lang="ts">
import { computed, ref, unref } from 'vue';
import { BLACK, WHITE } from '../../../business-logic/mapping/color';
import {
  SAMPLE,
  SAMPLE_GROUP,
} from '../../../business-logic/mapping/layer/types';
import { APP_GATHER } from '../../../widgets/marker-picker/constants';
import MarkerPicker from '../../../widgets/marker-picker/index.vue';
import AlertBox from '../../AlertBox.vue';
import AppGroupDropdown from '../AppGroupDropdown.vue';
import ColorChooser from '../ColorChooser.vue';
import Slider from '../../Slider.vue';
import Toggle from '../Toggle.vue';
import { injectContext } from './context';
import TitleBar from '../TitleBar.vue';

defineProps<{
  isNewSampleGroupAvailable?: boolean;
}>();

const {
  getShapeProperty,
  setShapeProperty,
  updateShapeProperties,
  checkIsStylingTypeVisible,
  checkIsRenderableNonSpatialSampleGroup,
  getMarkerPickerAppId,
} = injectContext();

const markerPickerAppId = getMarkerPickerAppId();
const layerType = computed(() =>
  markerPickerAppId === APP_GATHER ? SAMPLE_GROUP : getShapeProperty('type')
);

const isColorDisabled = ref(false);
const colorTitle = computed(() => {
  return unref(layerType) === SAMPLE && isColorDisabled.value
    ? 'The colour of the selected marker is not changeable.'
    : '';
});

function handleMarkerSelect(marker) {
  const icon = getShapeProperty('icon');
  const newIcon = marker.id - 1;
  setShapeProperty('icon', newIcon);

  const iconSize = getShapeProperty('iconSize');
  if (newIcon !== icon || Number.isNaN(parseInt(iconSize, 10))) {
    setShapeProperty('iconSize', marker.default_size);
  }

  isColorDisabled.value = !marker.is_color_changeable;
}

const isHideMarkerLabelIndeterminate = computed(() =>
  getShapeProperty('isHideMarkerLabelIndeterminate', false)
);

function handleHideSampleLabelChange(checked) {
  updateShapeProperties({
    hideMarkerLabel: checked,
    isHideMarkerLabelIndeterminate: false,
  });
}
function handleHideDuplicateLabelChange(checked) {
  updateShapeProperties({
    hideDuplicateLabel: checked,
  });
}
</script>

<template>
  <div>
    <TitleBar class="mb-2"> Icon </TitleBar>

    <div v-if="!checkIsRenderableNonSpatialSampleGroup()" class="mb-2">
      <label class="form-label">
        Marker icon
        <sup class="text-danger">*</sup>
      </label>

      <MarkerPicker
        :appId="markerPickerAppId"
        :selectedMarkerId="getShapeProperty('icon', 0) + 1"
        @markerSelect="handleMarkerSelect($event)"
      />
    </div>

    <template v-if="!checkIsRenderableNonSpatialSampleGroup()">
      <ColorChooser
        v-if="!isColorDisabled"
        class="mb-2"
        label="Marker icon color"
        :value="getShapeProperty('color', BLACK)"
        :title="colorTitle"
        @input="(value) => setShapeProperty('color', value)"
      />
      <AlertBox v-else type="info"
        >The color of the selected marker icon is not changeable.</AlertBox
      >
    </template>

    <Slider
      class="mb-2"
      name="markerIconSize"
      label="Marker icon size"
      :value="getShapeProperty('iconSize', 22)"
      units="pt"
      :min="5"
      :max="180"
      :step="1"
      @input="(value) => setShapeProperty('iconSize', value)"
    />

    <Slider
      class="mb-2"
      name="markerIconRotation"
      label="Marker icon rotation"
      :value="getShapeProperty('iconRotation', 0)"
      units="deg"
      :min="0"
      :max="360"
      :step="1"
      @input="(value) => setShapeProperty('iconRotation', value)"
    />

    <Slider
      name="markerIconOpacity"
      label="Marker icon opacity"
      :value="getShapeProperty('iconOpacity', 1)"
      :formatValue="(value) => Math.round(value * 100).toString()"
      units="%"
      :min="0"
      :max="1"
      :step="0.01"
      @input="(value) => setShapeProperty('iconOpacity', value)"
    />

    <TitleBar class="mb-2"> Label </TitleBar>

    <Toggle
      v-if="checkIsStylingTypeVisible('hideMarkerLabel')"
      name="hideSampleLabel"
      label="Hide sample label"
      :checked="getShapeProperty('hideMarkerLabel', false)"
      :indeterminate="isHideMarkerLabelIndeterminate"
      @change="handleHideSampleLabelChange"
    />

    <Toggle
      v-if="checkIsStylingTypeVisible('hideDuplicateLabel')"
      name="hideDuplicateLabel"
      label="Hide sample duplicate label"
      :checked="getShapeProperty('hideDuplicateLabel', false)"
      @change="handleHideDuplicateLabelChange"
    />

    <Toggle
      v-if="checkIsStylingTypeVisible('showDetatchedConnectors')"
      name="showDetatchedConnectors"
      label="Connect icon to label with line"
      :checked="getShapeProperty('showDetatchedConnectors', false)"
      @change="
        (checked) => setShapeProperty('showDetatchedConnectors', checked)
      "
    />

    <ColorChooser
      v-if="checkIsStylingTypeVisible('markerLabelColor')"
      class="mb-2"
      label="Label colour"
      :value="getShapeProperty('markerLabelColor', BLACK)"
      @input="(value) => setShapeProperty('markerLabelColor', value)"
    />

    <ColorChooser
      v-if="checkIsStylingTypeVisible('markerLabelShadowColor')"
      class="mb-2"
      label="Label shadow colour"
      :value="getShapeProperty('markerLabelShadowColor', WHITE)"
      @input="(value) => setShapeProperty('markerLabelShadowColor', value)"
    />

    <Slider
      v-if="checkIsStylingTypeVisible('labelSize')"
      class="mb-2"
      name="labelSize"
      label="Label font size"
      :value="getShapeProperty('labelSize', 16)"
      units="pt"
      :min="5"
      :max="60"
      :step="1"
      @input="(value) => setShapeProperty('labelSize', value)"
    />

    <Toggle
      v-if="checkIsStylingTypeVisible('markerLabelUnderlined')"
      name="underlineSampleLabel"
      label="Underline sample label"
      :checked="getShapeProperty('markerLabelUnderlined', false)"
      @change="(checked) => setShapeProperty('markerLabelUnderlined', checked)"
    />

    <Toggle
      v-if="checkIsStylingTypeVisible('markerLabelAsteriskAppended')"
      name="appendAsteriskToSampleLabel"
      label="Append an asterisk to sample label"
      :checked="getShapeProperty('markerLabelAsteriskAppended', false)"
      @change="
        (checked) => setShapeProperty('markerLabelAsteriskAppended', checked)
      "
    />

    <AppGroupDropdown
      v-if="
        !checkIsRenderableNonSpatialSampleGroup() &&
        (layerType === SAMPLE_GROUP || isNewSampleGroupAvailable)
      "
      :templateTabId="getShapeProperty('templateTabId', null)"
      @setTemplateTabId="
        (templateTabId) => setShapeProperty('templateTabId', templateTabId)
      "
    />
  </div>
</template>
