import { getUid } from 'ol/util';
import type { LayerUsage } from '../constants';
import { LayerUsageByType } from '../constants';
import { LayerModel, LayerType } from '../types';
import type { CalloutLayer } from './types';
import { CalloutLayerModel } from './types';

export function checkIsCalloutLayerModel(
  model: LayerModel
): model is CalloutLayerModel {
  return model.geojson.properties.type === LayerType.CALL_OUT;
}

export function getUsage(model: CalloutLayerModel): LayerUsage {
  const { usage } = model.geojson.properties;
  if (usage) {
    return usage;
  }

  const { image_data } = model.geojson.properties;
  return image_data.url
    ? LayerUsageByType[LayerType.CALL_OUT].SHOW_IMAGE
    : LayerUsageByType[LayerType.CALL_OUT].SHOW_TEXT;
}

export function getPopupId(layer: CalloutLayer): string {
  return `ol-popup-${getUid(layer)}`;
}
