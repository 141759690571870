export default function downloadByHref(
  href: string,
  downloadName: string = ''
) {
  const a = document.createElement('a');
  a.href = href;
  a.download = downloadName;
  a.rel = 'noopener';
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function saveAs(blob: Blob, fileName: string) {
  const href = window.URL.createObjectURL(blob);
  return downloadByHref(href, fileName);
}

export function blobToBase64(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const base64 = reader.result.split(',')[1];
        const mimeType = blob.type;
        resolve({ base64, mimeType });
      } else {
        reject(new Error('Failed to read blob as base64'));
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
}
