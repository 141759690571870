<template>
  <div v-text="value.reference?.custom_title"></div>
</template>

<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
};
</script>
