import { Feature, Map, getUid } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Geometry, Point, Polygon } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { fromCircle } from 'ol/geom/Polygon';
import { Draw as ol_interaction_Draw } from 'ol/interaction';
import { Vector } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import createClickPulse from '../animation/createClickPulse';
import { toLonLat } from '../common/coordinate';
import { Code, throwError } from '../common/error';
import { toGeoJSON } from '../common/geojson';
import { getStoreApi } from '../common/store-api';
import { Event, FeatureCreatedEventPayload } from '../event/types';
import type { LayerUsage } from '../layer/constants';
import createSampleLayer from '../layer/sample/createSampleLayer';
import getDefaultSampleStyle from '../layer/sample/getDefaultSampleStyle';
import type {
  SupportedLabelPosition,
  Sample,
  SampleGroup,
  SampleLayer,
} from '../layer/sample/types';
import {
  DEFAULT_LABEL_POSITION,
  addSampleFeature,
} from '../layer/sample/utils';
import createSingleShapeLayer from '../layer/shape/createSingleShapeLayer';
import type {
  Shape,
  ShapeLayer,
  SingleShapeLayerModel,
} from '../layer/shape/types';
import type { TempLayerModel } from '../layer/types';
import { LayerType } from '../layer/types';
import { Color } from '../style/color';
import InteractionManager from './InteractionManager';
import createDrawStyle from './createDrawStyle';
import makeId from '@component-library/local-id.mjs';

class Draw extends ol_interaction_Draw {
  shapeName: string;
  constructor(options) {
    super(options);

    this.shapeName = options.shapeName;
  }

  addPoint(coord: Coordinate, isDuplicate: boolean): Feature<Point> {
    // @ts-ignore
    if (this.type_ !== GeometryType.POINT) {
      throwError(
        Code.InvalidOperation,
        'Adding a point is only allowed on sample layers'
      );
    }

    const feature = new Feature(new Point(coord));
    feature.set('isDuplicate', isDuplicate);

    // @ts-ignore
    this.source_.addFeature(feature);

    return feature;
  }

  getShapeName(): string {
    return this.shapeName;
  }
}

export function createBox(map: Map) {
  return function (coordinates, opt_geometry, projection) {
    const topLeft = coordinates[0];
    const bottomRight = coordinates[coordinates.length - 1];
    const topLeftPixel = map.getPixelFromCoordinate(topLeft);
    const bottomRightPixel = map.getPixelFromCoordinate(bottomRight);
    const xDelta = bottomRightPixel[0] - topLeftPixel[0];
    const topRight = map.getCoordinateFromPixel([
      topLeftPixel[0] + xDelta,
      topLeftPixel[1],
    ]);
    const bottomLeft = map.getCoordinateFromPixel([
      bottomRightPixel[0] - xDelta,
      bottomRightPixel[1],
    ]);
    const boxCoordinates = [
      [bottomLeft, bottomRight, topRight, topLeft, bottomLeft],
    ];
    let geometry = opt_geometry;
    if (geometry) {
      geometry.setCoordinates(boxCoordinates);
    } else {
      geometry = new Polygon(boxCoordinates);
    }
    return geometry;
  };
}

function getPointerTipForPolygon(sketchFeatures, drawingName) {
  const lineSketchFeature = sketchFeatures.find(
    (sketchFeature) =>
      sketchFeature.getGeometry().getType() === GeometryType.LINE_STRING
  );
  if (lineSketchFeature.getGeometry().getCoordinates().length < 3) {
    // There are not enough coordinates to create a polygon drawing.
    return `Click to continue drawing ${drawingName}.`;
  } else {
    return `Click the first point or double click it to close the ${drawingName}.`;
  }
}

function getPointerTipForPolyline(sketchFeatures, drawingName) {
  const lineSketchFeature = sketchFeatures.find(
    (sketchFeature) =>
      sketchFeature.getGeometry().getType() === GeometryType.LINE_STRING
  );
  if (lineSketchFeature.getGeometry().getCoordinates().length < 3) {
    // There are not enough coordinates to create a polyline drawing.
    return `Click to continue drawing ${drawingName}.`;
  } else {
    return `Click the last point or double click to finish the ${drawingName}.`;
  }
}

function createTempSampleLayer(
  map: Map,
  icon: number = 0,
  color: string = Color.Black,
  title: string,
  position: Coordinate,
  labelPosition: SupportedLabelPosition,
  iconRotation: number,
  iconOpacity: number,
  iconOpacityOverride: number | null,
  isDuplicate: boolean
): SampleLayer {
  const proj = map.getView().getProjection();
  const storeApi = getStoreApi(map);
  const figure = storeApi.getSelectedFigure()!;
  const defaultSampleStyle = getDefaultSampleStyle();

  const sampleGroup: TempLayerModel<SampleGroup> = {
    id: makeId(),
    geojson: {
      properties: {
        type: LayerType.SAMPLE_GROUP,
        title: '',
        iconSize: defaultSampleStyle.iconSize,
        iconRotation,
        iconOpacity,
        labelSize: defaultSampleStyle.labelSize,
        hideMarkerLabel: false,
        hideDuplicateLabel: false,
        markerLabelColor: Color.Black,
        markerLabelShadowColor: Color.White,
        markerLabelUnderlined: false,
        markerLabelAsteriskAppended: false,
        showDetatchedConnectors: false,
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [],
    marker_identifier: `${icon}_${color}`,
    hidden_sub_folders: [],
    isTemp: true,
  };
  storeApi.addLayerModel(figure, sampleGroup);

  const { longitude, latitude } = toLonLat(position, proj);
  const sample: Sample = {
    id: makeId(),
    longitude,
    latitude,
    custom_title: title,
    lab_title: '',
    icon_size: defaultSampleStyle.iconSize,
    icon_rotation: iconRotation,
    icon_opacity: iconOpacity,
    icon_opacity_override: iconOpacityOverride,
    is_label_hidden: false,
    is_duplicate_label_hidden: false,
    label_color: Color.Black,
    label_shadow_color: Color.White,
    label_size: defaultSampleStyle.labelSize,
    is_label_underlined: false,
    is_label_asterisk_appended: false,
    label_position: labelPosition,
    project_figure_layer_id: sampleGroup.id,
    child_samples: [],
    input_values_for_styling: [],
    start_depth: null,
    end_depth: null,
    input_values_for_linking: [],
    is_non_spatial: false,
    linked_spatial_sample_id: null,
  };
  storeApi.addSample(sample);

  const layer = createSampleLayer(map, sampleGroup);
  addSampleFeature(map, layer, sample, isDuplicate);

  return layer;
}

function createTempSingleShapeLayer(
  map: Map,
  feature: Feature<Geometry>,
  type: LayerType,
  usage: LayerUsage | undefined,
  color: string,
  fillStyle: number,
  outlineStyle: number,
  afterLayerId?: number
): ShapeLayer {
  const storeApi = getStoreApi(map);
  const figure = storeApi.getSelectedFigure()!;

  const layerModel: TempLayerModel<SingleShapeLayerModel> = {
    id: makeId(),
    geojson: {
      ...(toGeoJSON(map, feature! as Feature<Shape>) as GeoJSON.Feature),
      properties: {
        type,
        usage,
        title: '',
        color,
        fillStyle,
        outlineStyle,
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [],
    isTemp: true,
  };

  storeApi.addLayerModel(figure, layerModel, afterLayerId);

  const layer = createSingleShapeLayer(map, layerModel, usage);
  layer.getFirstFeature()!.set('layerUid', getUid(layer));

  return layer;
}

export default function createDraw(
  interactionManager: InteractionManager,
  options: {
    layerType: LayerType;
    layerUsage?: LayerUsage;
    icon?: number;
    color?: string;
    title?: string;
    labelPosition?: SupportedLabelPosition;
    fillStyle?: number;
    outlineStyle?: number;
    activateEdit?: boolean;
    iconRotation?: number;
    iconOpacity?: number;
    iconOpacityOverride?: number | null;
  }
) {
  const map = interactionManager.getMap();
  const {
    layerType,
    layerUsage,
    icon,
    color,
    title = '',
    labelPosition,
    fillStyle,
    outlineStyle,
    activateEdit = true,
    iconRotation = 0,
    iconOpacity = 1,
    iconOpacityOverride = null,
  } = options;

  let type;
  let geometryFunction;
  let pointerTip;
  let shapeName;

  if (layerType === LayerType.RECTANGLE) {
    type = GeometryType.CIRCLE;
    geometryFunction = createBox(map);
    shapeName = 'rectangle';
    pointerTip = `Click and move to draw a ${shapeName}.`;
  } else if (layerType === LayerType.CIRCLE) {
    type = GeometryType.CIRCLE;
    shapeName = 'circle';
    pointerTip = `Click and move to draw a ${shapeName}.`;
  } else if (layerType === LayerType.POLYGON) {
    type = GeometryType.POLYGON;
    shapeName = 'polygon';
    pointerTip = `Click to start drawing a ${shapeName}.`;
  } else if (layerType === LayerType.SITE_BOUNDARY) {
    type = GeometryType.POLYGON;
    shapeName = 'site boundary';
    pointerTip = `Click to start drawing a ${shapeName}.`;
  } else if (layerType === LayerType.POLYLINE) {
    type = GeometryType.LINE_STRING;
    shapeName = 'polyline';
    pointerTip = `Click to start drawing a ${shapeName}.`;
  } else if (layerType === LayerType.ARROW) {
    type = GeometryType.LINE_STRING;
    shapeName = 'arrow';
    pointerTip = `Click to start drawing an ${shapeName}.'`;
  } else if (layerType === LayerType.SAMPLE) {
    type = GeometryType.POINT;
    shapeName = 'marker';
    pointerTip = `Click to place a ${shapeName}.`;
  } else if (layerType === LayerType.HEDGE) {
    type = GeometryType.CIRCLE;
    geometryFunction = createBox(map);
    shapeName = 'hedge';
    pointerTip = `Click and move to draw a ${shapeName}.`;
  } else {
    throwError(Code.LayerTypeNotSupported, layerType);
  }

  type NodeAreaClicked = {
    coords: number[];
    after_layer_id: number;
  };

  let nodeAreaClicked: NodeAreaClicked | null = null;

  const source = new VectorSource({ wrapX: false });
  source.on('addfeature', ({ feature }) => {
    let layer: SampleLayer | ShapeLayer;

    if (layerType === LayerType.SAMPLE) {
      const position = (feature!.getGeometry() as Point).getCoordinates();
      const isDuplicate = (feature!.get('isDuplicate') ?? false) as boolean;
      layer = createTempSampleLayer(
        map,
        icon,
        color,
        title,
        position,
        labelPosition ?? DEFAULT_LABEL_POSITION,
        iconRotation,
        iconOpacity,
        iconOpacityOverride,
        isDuplicate
      );
    } else {
      if (
        layerType === LayerType.CIRCLE &&
        feature!.getGeometry().getType() !== GeometryType.POLYGON
      ) {
        // Convert circle to a regular polygon.
        source.removeFeature(feature!);
        const circle = feature!.getGeometry();
        const polygon = fromCircle(circle, 128);
        feature = new Feature(polygon);
        // The addfeature event of the source will be triggered again.
        source.addFeature(feature);
        return;
      } else {
        layer = createTempSingleShapeLayer(
          map,
          feature as Feature<Geometry>,
          layerType,
          layerUsage,
          color!,
          fillStyle!,
          outlineStyle!,
          nodeAreaClicked ? nodeAreaClicked.after_layer_id : undefined
        );
      }
    }

    interactionManager.endDraw();

    if (layer) {
      const payload: FeatureCreatedEventPayload = {
        layer,
        activateEdit,
      };

      interactionManager.dispatchEvent(new Event('feature-created', payload));
    }
  });

  const draw = new Draw({
    type,
    geometryFunction,
    style: createDrawStyle(map, {
      getPointerTip: () => pointerTip,
    }),
    source,
    shapeName,
  });

  function changeCursorTip() {
    const sketchFeatures = draw.getOverlay().getSource().getFeatures();
    if (sketchFeatures.length === 0) {
      return '';
    }

    if (layerType === LayerType.POLYGON) {
      pointerTip = getPointerTipForPolygon(sketchFeatures, 'polygon');
    } else if (layerType === LayerType.SITE_BOUNDARY) {
      pointerTip = getPointerTipForPolygon(sketchFeatures, 'boundary');
    } else if (layerType === LayerType.POLYLINE) {
      pointerTip = getPointerTipForPolyline(sketchFeatures, 'line');
    } else if (layerType === LayerType.ARROW) {
      pointerTip = getPointerTipForPolyline(sketchFeatures, 'arrow');
    } else {
      pointerTip = 'Release mouse to finish drawing.';
    }
  }

  /**
   * Logic to handle when a start/end node is clicked, it should automatically move the polyline to start to that node.
   */
  function handleNodeClickDetection(
    feature: any,
    layerType: LayerType,
    clickDetectRadius = 16
  ) {
    const originalCoordinates = feature.getGeometry().getCoordinates();

    const pixel = map.getPixelFromCoordinate(originalCoordinates[0]);
    const topLeftPixel = [
      pixel[0] - clickDetectRadius / 2,
      pixel[1] - clickDetectRadius / 2,
    ];

    for (let i = 0; i < clickDetectRadius; i++) {
      if (nodeAreaClicked) {
        break;
      }

      for (let j = 0; j < clickDetectRadius; j++) {
        if (nodeAreaClicked) {
          break;
        }

        const currentPixel = [topLeftPixel[0] + i, topLeftPixel[1] + j];

        map.forEachFeatureAtPixel(
          currentPixel,
          (featureAtPixel: any) => {
            if (featureAtPixel !== feature) {
              const targetGeometry = featureAtPixel.getGeometry();
              const targetCoordinates = targetGeometry.getCoordinates();

              const start: number[] = targetCoordinates[0];
              const end: number[] =
                targetCoordinates[targetCoordinates.length - 1];

              const startPixel = map.getPixelFromCoordinate(start);
              const endPixel = map.getPixelFromCoordinate(end);

              const startDistance = Math.sqrt(
                Math.pow(startPixel[0] - pixel[0], 2) +
                  Math.pow(startPixel[1] - pixel[1], 2)
              );
              const endDistance = Math.sqrt(
                Math.pow(endPixel[0] - pixel[0], 2) +
                  Math.pow(endPixel[1] - pixel[1], 2)
              );

              const threshold = Math.sqrt(2) * clickDetectRadius;
              const isStart = startDistance <= threshold;
              const isEnd = endDistance <= threshold;

              if (isStart || isEnd) {
                const afterLayer =
                  interactionManager.layerManager.findLayerByUid(
                    featureAtPixel.get('layerUid')
                  );

                if (afterLayer) {
                  nodeAreaClicked = {
                    coords: isStart ? start : end,
                    after_layer_id: afterLayer.get('modelId'),
                  };

                  createClickPulse(nodeAreaClicked.coords, map);
                }
              }
            }
          },
          {
            layerFilter: (layer) => {
              if (!(layer instanceof Vector)) {
                return false;
              }
              return layer instanceof Vector && layer.get('type') === layerType;
            },
          }
        );
      }
    }

    return nodeAreaClicked;
  }

  function updatePolylineFromDetectedClick(
    feature: any,
    nodeAreaClicked: NodeAreaClicked
  ) {
    const originalCoordinates = feature.getGeometry().getCoordinates();
    const originalGeometry = feature.getGeometry();

    const updatedCoordinates = [
      nodeAreaClicked.coords,
      ...originalCoordinates.slice(1),
    ];

    originalGeometry.setCoordinates(updatedCoordinates);

    draw.getOverlay().getSource().refresh();
  }

  draw.on('drawstart', (e) => {
    map.on('click', changeCursorTip);

    nodeAreaClicked = null;

    if (layerType === LayerType.POLYLINE) {
      handleNodeClickDetection(e.feature, LayerType.POLYLINE);
    }
  });

  draw.on(['drawend', 'drawabort'], (e) => {
    map.un('click', changeCursorTip);
  });

  draw.on('drawend', (e) => {
    if (nodeAreaClicked !== null) {
      updatePolylineFromDetectedClick(e.feature, nodeAreaClicked);
    }
  });

  return draw;
}
