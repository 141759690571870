import { BasemapId } from '../../../lib/olbm/layer/basemap/types';

export default {
  index: BasemapId.NEARMAP,
  title: 'Nearmap',
  subtitle: 'High-resolution aerial imagery of parts of {COUNTRY}',
  type: 'satellite',
  source:
    'https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.img?apikey={API_KEY}&tertiary=satellite',
  options: {
    attributions: '© Nearmap',
  },
};
