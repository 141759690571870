<template>
  <div>
    <div class="mb-2">
      <label class="form-label w-100">Options</label>
      <input-tag
        :value="options.options"
        :hasInlineDeletion="false"
        :has-import="true"
        :selected-item="selectedOption"
        @input="handleInputTagInput"
        @clickOption="handleClickOption"
        @importClick="handleImportClick"
      >
        <template #extensionContainer>
          <slot
            name="extensionContainer"
            :setSelectedOption="setSelectedOption"
          ></slot>
        </template>
      </input-tag>
      <small class="d-block mt-1 text-muted">
        Press the <b>Enter</b> key after the phrase to add it as an option
      </small>
    </div>

    <!--Option Editor-->
    <div v-if="selectedOption" class="input-group mb-2">
      <input
        type="text"
        class="form-control"
        placeholder="Click an option above"
        v-model.trim="optionEditorContent"
      />
      <button
        v-if="isOptionEditorContentChanged && !isSubmittingOptionChange"
        type="button"
        class="btn btn-outline-secondary"
        @click="handleClickCancelOptionChangeButton"
      >
        <i class="fas fa-times"></i>
      </button>
      <button
        v-if="isOptionEditorContentChanged"
        type="button"
        class="btn btn-outline-primary"
        :disabled="isSubmittingOptionChange"
        @click="handleClickSubmitOptionChangeButton"
      >
        <i v-if="!isSubmittingOptionChange" class="fas fa-check"></i>
        <Spinner small v-else />
      </button>
      <button
        v-if="!isOptionEditorContentChanged"
        type="button"
        class="btn btn-outline-danger"
        @click="handleRemoveSelectedOption"
      >
        <i class="fas fa-trash-alt"></i>
      </button>
    </div>

    <template v-if="selectedOption && tab.drawing_type === 'polyline'">
      <label class="form-label me-2">
        Node Marker
        <a
          v-if="nodeIcons[selectedOption]"
          href="#"
          @click.prevent="onNodeRemove"
        >
          <i class="fal fa-trash-alt" />
        </a>
      </label>

      <MarkerPicker
        class="small-palette mb-2"
        :appId="2"
        :selectedMarkerId="nodeIcons[selectedOption]?.icon_id || null"
        @markerSelect="onNodeSelect"
      />

      <Slider
        v-if="nodeIcons[selectedOption]"
        name="arrowheadsize"
        label="Node Marker Size"
        :value="nodeIcons[selectedOption]?.size || 16"
        units="pt"
        :min="4"
        :max="32"
        :step="1"
        @input="(value) => setNodeIconAttribute('size', value)"
      />

      <ColorChooser
        v-if="nodeIcons[selectedOption]"
        :value="nodeIcons[selectedOption]?.color || '#000000'"
        @input="(value) => setNodeIconAttribute('color', value)"
        class="mb-2"
      />
    </template>

    <div v-if="visibility.optionType" class="mb-2">
      <label class="form-label me-2" :for="optionTypeSelectId">
        Option Type
      </label>
      <InfoButton
        class="ms-2"
        info="This setting determines how the options are handled when they are used in an expression.<br/><br/> WARNING: Selecting the <b>Number</b> option type will remove all existing non-number options."
      />
      <select
        class="form-control"
        :id="optionTypeSelectId"
        v-model.number="option_type"
      >
        <option
          v-for="item in Object.keys(optionTypeOptions)"
          :key="`optionType-${item}`"
          :value="item"
        >
          {{ optionTypeOptions[item].text }}
        </option>
      </select>
    </div>

    <div class="mb-2">
      <label class="form-label me-2"> Display as </label>
      <div class="btn-group btn-group-sm d-block">
        <button
          class="btn btn-dark w-50"
          :class="{
            active: !display_as_input,
          }"
          @click="displayAs('checkbox')"
        >
          Checkbox
        </button>
        <button
          class="btn btn-dark w-50"
          :class="{
            active: display_as_input,
          }"
          @click="displayAs('dropdown')"
        >
          Dropdown
        </button>
      </div>
    </div>

    <div v-if="visibility.multiple" class="form-check mb-2">
      <input
        class="form-check-input"
        type="checkbox"
        :id="hasMultipleCheckboxId"
        v-model="has_multiple"
        @keypress.enter="$emit('blur')"
      />
      <label class="form-check-label" :for="hasMultipleCheckboxId">
        Do you want users to select multiple options?
      </label>
    </div>

    <div v-if="visibility.addOptions" class="form-check mb-2">
      <input
        class="form-check-input"
        type="checkbox"
        :id="isAddEnabledCheckboxId"
        v-model="is_add_enabled"
        @keypress.enter="$emit('blur')"
      />
      <label class="form-check-label" :for="isAddEnabledCheckboxId">
        Do you want users to add options?
      </label>
    </div>

    <div class="form-check mb-2">
      <input
        class="form-check-input"
        type="checkbox"
        :id="shouldClearSelectionOnInvisibleCheckboxId"
        v-model="should_clear_selection_on_invisible"
        @keypress.enter="$emit('blur')"
      />
      <label
        class="form-check-label"
        :for="shouldClearSelectionOnInvisibleCheckboxId"
      >
        Clear selection when it is not visible
      </label>
    </div>

    <NotifyModal
      :show="isSelectingNumberNotifyModalVisible"
      @close="() => (isSelectingNumberNotifyModalVisible = false)"
      @submit="handleSelectNumber"
      :headerMessage="selectingNumberNotice"
      :isDelete="true"
    />

    <slot
      name="importModal"
      :isImportModalVisible="isImportModalVisible"
      :closeImportModal="closeImportModal"
    >
      <ImportExcelColumnModal
        v-if="isImportModalVisible"
        @import="handleImport"
        @close="handleImportModalClose"
      />
    </slot>
  </div>
</template>

<script>
import InputTag from '@component-library/components/InputTag.vue';
import * as cl_bm from '@component-library/business-model';
import * as cl_bl from '@component-library/business-logic';
import ImportExcelColumnModal from './ImportExcelColumnModal.vue';
import InfoButton from '@component-library/components/InfoButton.vue';
import NotifyModal from '../../NotifyModal.vue';
import apis from '../../../apis';
import MarkerPicker from '@component-library/widgets/marker-picker/index.vue';
import Slider from '@component-library/components/Slider.vue';
import ColorChooser from '@component-library/components/mapping/ColorChooser.vue';
import makeId from '@component-library/local-id.mjs';
import Spinner from '@component-library/components/Spinner.vue';

export default {
  components: {
    InputTag,
    InfoButton,
    NotifyModal,
    ImportExcelColumnModal,
    MarkerPicker,
    Slider,
    ColorChooser,
    Spinner,
  },
  props: {
    value: Object,
    options: Object,
    tab: Object,
    visibility: {
      type: Object,
      default: () => ({
        optionType: true,
        multiple: true,
        addOptions: true,
      }),
    },
  },
  data: () => ({
    optionTypeSelectId: makeId(),
    hasMultipleCheckboxId: makeId(),
    isAddEnabledCheckboxId: makeId(),
    shouldClearSelectionOnInvisibleCheckboxId: makeId(),
    optionTypeOptions: cl_bm.dropdown.OPTION_TYPE_OPTIONS,
    isSelectingNumberNotifyModalVisible: false,
    selectedOption: null,
    optionEditorContent: null,
    isSubmittingOptionChange: false,
    isImportModalVisible: false,
  }),
  computed: {
    nonNumberOptions() {
      const { options } = this.options;
      return options.filter((item) => !this.checkIsNumberOption(item));
    },
    selectingNumberNotice() {
      return `Selecting the Number option type will remove all existing non-number options: ${this.nonNumberOptions.join(
        ', '
      )}. Are you sure you would like to proceed?`;
    },
    option_type: {
      get() {
        const { option_type, options } = this.options;
        if (option_type) {
          return option_type;
        }

        return options.length && !this.nonNumberOptions.length
          ? cl_bm.dropdown.OPTION_TYPE_NUMBER
          : cl_bm.dropdown.OPTION_TYPE_TEXT;
      },
      set(value) {
        if (
          value === cl_bm.dropdown.OPTION_TYPE_NUMBER &&
          this.nonNumberOptions.length
        ) {
          this.isSelectingNumberNotifyModalVisible = true;
          return;
        }

        this.$emit('updateOptions', {
          ...this.options,
          option_type: value,
        });
      },
    },
    has_multiple: {
      get() {
        return this.options?.has_multiple || false;
      },
      set(value) {
        const { defaults } = this.options;
        const nextDefaults = defaults.map((item) => {
          return {
            ...item,
            default: value
              ? item.default
                ? [item.default]
                : []
              : item.default[0] ?? null,
          };
        });

        this.$emit('updateOptions', {
          ...this.options,
          has_multiple: value,
          defaults: nextDefaults,
        });
      },
    },
    is_add_enabled: {
      get() {
        return this.options?.is_add_enabled || false;
      },
      set(value) {
        this.$emit('updateOptions', {
          ...this.options,
          is_add_enabled: value,
        });
      },
    },
    should_clear_selection_on_invisible: {
      get() {
        return this.options?.should_clear_selection_on_invisible || false;
      },
      set(value) {
        this.$emit('updateOptions', {
          ...this.options,
          should_clear_selection_on_invisible: value,
        });
      },
    },
    display_as_input: {
      get() {
        return this.options?.display_as_input || false;
      },
      set(value) {
        this.$emit('updateOptions', {
          ...this.options,
          display_as_input: value,
        });
      },
    },
    isOptionEditorContentChanged() {
      return this.selectedOption !== this.optionEditorContent;
    },
    nodeIcons() {
      return this.options?.node_icons || {};
    },
  },
  watch: {
    selectedOption(value) {
      this.optionEditorContent = value;
      this.$emit('selectOption', value);
    },
  },
  methods: {
    checkIsNumberOption(option) {
      return !Number.isNaN(parseFloat(option));
    },
    setSelectedOption(value) {
      this.selectedOption = value;
    },
    openImportModal() {
      this.isImportModalVisible = true;
    },
    closeImportModal() {
      this.isImportModalVisible = false;
    },
    handleInputTagInput(options) {
      const { option_type } = this.options;
      if (option_type === cl_bm.dropdown.OPTION_TYPE_NUMBER) {
        options = options.filter((item) => this.checkIsNumberOption(item));
      }

      this.$emit('updateOptions', {
        ...this.options,
        options,
      });
    },
    handleSelectNumber() {
      const { options } = this.options;
      const nextOptions = options.filter((item) =>
        this.checkIsNumberOption(item)
      );
      this.$emit('updateOptions', {
        ...this.options,
        options: nextOptions,
        option_type: cl_bm.dropdown.OPTION_TYPE_NUMBER,
      });
      this.isSelectingNumberNotifyModalVisible = false;
    },
    handleClickOption(option) {
      this.setSelectedOption(option);
    },
    handleRemoveSelectedOption() {
      const { options } = this.options;
      const nextOptions = options.filter(
        (item) => item !== this.selectedOption
      );
      this.$emit('updateOptions', {
        ...this.options,
        options: nextOptions,
      });
      this.selectedOption = null;
    },
    handleClickCancelOptionChangeButton() {
      this.optionEditorContent = this.selectedOption;
    },
    async handleClickSubmitOptionChangeButton() {
      this.isSubmittingOptionChange = true;
      try {
        // Update the node_icons if the option is used as a node icon.
        await apis.dropdown.updateDropdownOption(
          this.value.id,
          this.selectedOption,
          this.optionEditorContent
        );

        const updatedNodeIcons = { ...this.options.node_icons };
        const previousIconId = updatedNodeIcons[this.selectedOption] || null;
        if (previousIconId !== null) {
          delete updatedNodeIcons[this.selectedOption];
          updatedNodeIcons[this.optionEditorContent] = previousIconId;
          this.$emit('updateOptions', {
            ...this.options,
            node_icons: updatedNodeIcons,
          });
        }

        const oldValueIndex = this.options.options.indexOf(this.selectedOption);
        if (oldValueIndex !== -1) {
          this.options.options[oldValueIndex] = this.optionEditorContent;
        }

        this.selectedOption = this.optionEditorContent;

        // this needs to change, updateOptions can't fire in time & this is terrible
        // this.$nextTick(() => {
        //   // The change may impact other fields in the app so a reload is required.
        //   window.location.reload();
        // });
      } catch (e) {
        this.$toastStore.error(
          'Something went wrong, please try it again later.'
        );
      } finally {
        this.isSubmittingOptionChange = false;
      }
    },
    handleImportClick() {
      this.openImportModal();
    },
    handleImport(options) {
      const { option_type } = this.options;
      if (option_type === cl_bm.dropdown.OPTION_TYPE_NUMBER) {
        options = options.filter((item) => this.checkIsNumberOption(item));
      }

      const { options: existingOptions } = this.options;
      options = options.filter(
        (item) => !cl_bl.dropdown.checkIsOptionDuplicate(existingOptions, item)
      );
      options = [...existingOptions, ...options];

      this.$emit('updateOptions', {
        ...this.options,
        options,
      });

      this.closeImportModal();
    },
    handleImportModalClose() {
      this.closeImportModal();
    },
    displayAs(option) {
      this.display_as_input = option === 'dropdown';
    },
    onNodeRemove() {
      const oldOptions = {
        ...this.options.node_icons,
      };

      delete oldOptions[this.selectedOption];

      this.$emit('updateOptions', {
        ...this.options,
        node_icons: oldOptions,
      });
    },
    onNodeSelect(data) {
      if (!data) {
        return;
      }

      this.$emit('updateOptions', {
        ...this.options,
        node_icons: {
          ...(this.options.node_icons || {}),
          [this.selectedOption]: {
            icon_id: data.id,
            size:
              (this.options.node_icons
                ? this.options.node_icons[this.selectedOption]?.size
                : null) || 16,
            color:
              (this.options.node_icons
                ? this.options.node_icons[this.selectedOption]?.color
                : null) || '#000000',
          },
        },
      });
    },
    setNodeIconAttribute(key, value) {
      this.$emit('updateOptions', {
        ...this.options,
        node_icons: {
          ...(this.options.node_icons || {}),
          [this.selectedOption]: {
            ...(this.options.node_icons[this.selectedOption] || {}),
            [key]: value,
          },
        },
      });
    },
  },
};
</script>
