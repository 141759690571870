export const EPSG_3111 = 'EPSG:3111';
export const EPSG_28349 = 'EPSG:28349';
export const EPSG_28350 = 'EPSG:28350';
export const EPSG_28351 = 'EPSG:28351';
export const EPSG_28352 = 'EPSG:28352';
export const EPSG_28353 = 'EPSG:28353';
export const EPSG_28354 = 'EPSG:28354';
export const EPSG_28355 = 'EPSG:28355';
export const EPSG_28356 = 'EPSG:28356';
export const EPSG_28357 = 'EPSG:28357';
export const EPSG_7849 = 'EPSG:7849';
export const EPSG_7850 = 'EPSG:7850';
export const EPSG_7851 = 'EPSG:7851';
export const EPSG_7852 = 'EPSG:7852';
export const EPSG_7853 = 'EPSG:7853';
export const EPSG_7854 = 'EPSG:7854';
export const EPSG_7855 = 'EPSG:7855';
export const EPSG_7856 = 'EPSG:7856';
export const EPSG_7857 = 'EPSG:7857';
export const EPSG_4283 = 'EPSG:4283';
export const EPSG_7844 = 'EPSG:7844';
export const EPSG_7899 = 'EPSG:7899';
export const EPSG_3308 = 'EPSG:3308';

export const projectionConfigs = [
  {
    code: EPSG_3111,
    name: 'GDA94 / Vicgrid',
    bbox: [-33.98, 140.96, -39.2, 150.04],
    proj4Def:
      '+proj=lcc +lat_1=-36 +lat_2=-38 +lat_0=-37 +lon_0=145 +x_0=2500000 +y_0=2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_28349,
    name: 'GDA94 / MGA zone 49',
    bbox: [-10.72, 108.0, -37.84, 114.01],
    proj4Def:
      '+proj=utm +zone=49 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_28350,
    name: 'GDA94 / MGA zone 50',
    bbox: [-12.06, 114.0, -38.53, 120.01],
    proj4Def:
      '+proj=utm +zone=50 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_28351,
    name: 'GDA94 / MGA zone 51',
    bbox: [-10.46, 120.0, -38.07, 126.01],
    proj4Def:
      '+proj=utm +zone=51 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_28352,
    name: 'GDA94 / MGA zone 52',
    bbox: [-9.1, 125.99, -37.38, 132.0],
    proj4Def:
      '+proj=utm +zone=52 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_28353,
    name: 'GDA94 / MGA zone 53',
    bbox: [-8.88, 132.0, -40.71, 138.01],
    proj4Def:
      '+proj=utm +zone=53 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_28354,
    name: 'GDA94 / MGA zone 54',
    bbox: [-9.08, 138.0, -48.19, 144.01],
    proj4Def:
      '+proj=utm +zone=54 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_28355,
    name: 'GDA94 / MGA zone 55',
    bbox: [-9.23, 144.0, -50.89, 150.01],
    proj4Def:
      '+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_28356,
    name: 'GDA94 / MGA zone 56',
    bbox: [-13.87, 150.0, -58.96, 156.0],
    proj4Def:
      '+proj=utm +zone=56 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_28357,
    name: 'GDA94 / MGA zone 57',
    bbox: [-14.08, 156.0, -60.56, 162.01],
    proj4Def:
      '+proj=utm +zone=57 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: EPSG_7849,
    name: 'GDA2020 / MGA zone 49',
    bbox: [-10.72, 108.0, -37.84, 114.01],
    proj4Def: '+proj=utm +zone=49 +south +ellps=GRS80 +units=m +no_defs',
  },
  {
    code: EPSG_7850,
    name: 'GDA2020 / MGA zone 50',
    bbox: [-12.06, 114.0, -38.53, 120.01],
    proj4Def: '+proj=utm +zone=50 +south +ellps=GRS80 +units=m +no_defs',
  },
  {
    code: EPSG_7851,
    name: 'GDA2020 / MGA zone 51',
    bbox: [-10.46, 120.0, -38.07, 126.01],
    proj4Def: '+proj=utm +zone=51 +south +ellps=GRS80 +units=m +no_defs',
  },
  {
    code: EPSG_7852,
    name: 'GDA2020 / MGA zone 52',
    bbox: [-9.1, 125.99, -37.38, 132.0],
    proj4Def: '+proj=utm +zone=52 +south +ellps=GRS80 +units=m +no_defs',
  },
  {
    code: EPSG_7853,
    name: 'GDA2020 / MGA zone 53',
    bbox: [-8.88, 132.0, -40.71, 138.01],
    proj4Def: '+proj=utm +zone=53 +south +ellps=GRS80 +units=m +no_defs',
  },
  {
    code: EPSG_7854,
    name: 'GDA2020 / MGA zone 54',
    bbox: [-9.08, 138.0, -48.19, 144.01],
    proj4Def: '+proj=utm +zone=54 +south +ellps=GRS80 +units=m +no_defs',
  },
  {
    code: EPSG_7855,
    name: 'GDA2020 / MGA zone 55',
    bbox: [-9.23, 144.0, -50.89, 150.01],
    proj4Def: '+proj=utm +zone=55 +south +ellps=GRS80 +units=m +no_defs',
  },
  {
    code: EPSG_7856,
    name: 'GDA2020 / MGA zone 56',
    bbox: [-13.87, 150.0, -58.96, 156.0],
    proj4Def: '+proj=utm +zone=56 +south +ellps=GRS80 +units=m +no_defs',
  },
  {
    code: EPSG_7857,
    name: 'GDA2020 / MGA zone 57',
    bbox: [-14.08, 156.0, -60.56, 162.01],
    proj4Def: '+proj=utm +zone=57 +south +ellps=GRS80 +units=m +no_defs',
  },
  {
    code: EPSG_4283,
    name: 'GDA94',
    bbox: [-8.47, 93.41, -60.56, 173.35],
    proj4Def: '+proj=longlat +ellps=GRS80 +no_defs +type=crs',
  },
  {
    code: EPSG_7844,
    name: 'GDA2020',
    bbox: [-8.47, 93.41, -60.56, 173.35],
    proj4Def: '+proj=longlat +ellps=GRS80 +no_defs +type=crs',
  },
  {
    code: EPSG_7899,
    name: 'GDA2020 / Vicgrid',
    bbox: [-33.98, 140.96, -39.2, 150.04],
    proj4Def:
      '+proj=lcc +lat_1=-36 +lat_2=-38 +lat_0=-37 +lon_0=145 +x_0=2500000 +y_0=2500000 +ellps=GRS80 +units=m +no_defs',
  },
  {
    code: EPSG_3308,
    name: 'GDA94 / NSW Lambert',
    bbox: [-28.15, 140.99, -37.53, 153.69],
    proj4Def:
      '+proj=lcc +lat_0=-33.25 +lon_0=147 +lat_1=-30.75 +lat_2=-35.75 +x_0=9300000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  },
];
