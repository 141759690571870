import { produce } from 'immer';
import _omit from 'lodash/omit';
import { Map } from 'ol';
import { Code, throwError } from '../../common/error';
import { getStoreApi } from '../../common/store-api';
import type { Id, Pid } from '../../common/types';
import { MapType } from '../../common/types';
import { getMapType } from '../../common/utils';
import {
  checkIsExceedanceStylingUsed,
  getSampleExceedanceColor,
} from '../../evalu8/exceedance';
import {
  BuiltinSampleGroupSubtitle,
  SampleExceedanceColor,
} from '../../evalu8/types';
import { checkShouldHideSampleLabels } from '../../evalu8/utils';
import {
  checkIsFigureStylingRuleOnApp,
  checkIsFigureStylingRuleOnAppFollowed,
  checkIsFigureStylingRuleOnEnviroSamples,
  getFigureStylingRulesOnLayer,
} from '../../figure/styling-rule';
import type { Figure } from '../../figure/types';
import { getLayoutZoom } from '../../measurement/layout';
import { StylingPriority } from '../../style/types';
import { getDefaultStylingPriority } from '../../style/utils';
import getDefaultSampleStyle from './getDefaultSampleStyle';
import type { Sample, SampleGroup, SampleStyle } from './types';
import { getSampleTitle, parseMarkerIdentifier } from './utils';

// Three-level structure.
// {
//   [figureId]: {
//      [sampleGroupId]: {
//        [sampleId]: sampleStyle
//      }
//   }
// }
let sampleStyleCache = {};
let defaultSampleGroup;

export default function getSampleStyle(
  map: Map,
  figure: Figure,
  sample: Sample
): SampleStyle {
  const zoom = getLayoutZoom(map);
  // A sample's sample group is null if it is one used to preview an imported point.
  // The sample_group is legacy because it is not a layer model.
  const { id, custom_title, lab_title, composite_of, duplicate } = sample;
  const defaultSampleStyle = getDefaultSampleStyle({
    id,
    customTitle: custom_title,
    labTitle: lab_title,
    title: getSampleTitle(sample),
    compositeOf: composite_of,
    duplicate,
    width: 140,
    zoom,
  });

  if (checkIsSampleInImportPreview(map, sample)) {
    return defaultSampleStyle;
  }

  const storeApi = getStoreApi(map);
  if (!defaultSampleGroup) {
    defaultSampleGroup = storeApi.getDefaultSampleGroup();
  }

  let result = getSampleStyleFromCache(figure, sample);
  if (result) {
    return {
      ...result,
      zoom,
    };
  }

  // Figure-level style
  const shouldHideSampleLabels = checkShouldHideSampleLabels(figure);
  // SampleGroup-level style
  const sampleGroup = storeApi.getSampleLayerModel(sample) as SampleGroup;
  const { icon, color } = parseMarkerIdentifier(sampleGroup.marker_identifier);
  const {
    iconSize,
    iconRotation,
    iconOpacity,
    hideMarkerLabel,
    hideDuplicateLabel,
    markerLabelColor,
    markerLabelShadowColor,
    markerLabelUnderlined,
    markerLabelAsteriskAppended,
    labelSize,
    stylingPriority,
    default: isDefault,
  } = sampleGroup.geojson.properties;
  const fsrsOnLayer = getFigureStylingRulesOnLayer(map, figure, sampleGroup.id);
  const hasFsrsOnLayer = fsrsOnLayer.length > 0;

  const isExceedanceStylingUsed = checkIsExceedanceStylingUsed(figure, sample);
  const exceedanceColor = isExceedanceStylingUsed
    ? getSampleExceedanceColor(figure, sample, storeApi)
    : undefined;

  const {
    icon_size,
    icon_rotation,
    icon_opacity,
    icon_opacity_override,
    label_color,
    label_shadow_color,
    label_position,
    label_size,
    is_label_hidden,
    is_label_underlined,
    is_label_asterisk_appended,
    styling_priority,
  } = sample;
  result = {
    ...defaultSampleStyle,
    icon: icon ?? defaultSampleStyle.icon,
    color: color ?? defaultSampleStyle.color,
    exceedanceColor,
    subtitle:
      exceedanceColor &&
      [SampleExceedanceColor.Landuse, SampleExceedanceColor.Criteria].includes(
        exceedanceColor
      )
        ? BuiltinSampleGroupSubtitle.ExceedsAdoptedCriteria
        : exceedanceColor === SampleExceedanceColor.NoExceedances
        ? BuiltinSampleGroupSubtitle.NoExceedances
        : BuiltinSampleGroupSubtitle.Default,
    iconSize: icon_size ?? iconSize ?? defaultSampleStyle.iconSize,
    iconRotation:
      icon_rotation ?? iconRotation ?? defaultSampleStyle.iconRotation,
    iconOpacity: icon_opacity ?? iconOpacity ?? defaultSampleStyle.iconOpacity,
    iconOpacityOverride:
      icon_opacity_override ?? defaultSampleStyle.iconOpacityOverride,
    isIconVisible: !composite_of,
    labelColor:
      label_color ?? markerLabelColor ?? defaultSampleStyle.labelColor,
    labelShadowColor:
      label_shadow_color ??
      markerLabelShadowColor ??
      defaultSampleStyle.labelShadowColor,
    labelPosition: label_position,
    labelSize: label_size ?? labelSize ?? defaultSampleStyle.labelSize,
    isLabelHidden:
      shouldHideSampleLabels ||
      ((is_label_hidden || hideMarkerLabel) ??
        defaultSampleStyle.isLabelHidden),
    isDuplicateLabelHidden:
      hideDuplicateLabel ?? defaultSampleStyle.isDuplicateLabelHidden,
    isLabelUnderlined:
      is_label_underlined ??
      markerLabelUnderlined ??
      defaultSampleStyle.isLabelUnderlined,
    isLabelAsteriskAppended:
      is_label_asterisk_appended ??
      markerLabelAsteriskAppended ??
      defaultSampleStyle.isLabelAsteriskAppended,
    isDefault: isDefault ?? false,
    stylingPriority:
      styling_priority ??
      stylingPriority ??
      getDefaultStylingPriority(hasFsrsOnLayer),
  };

  const sampleOnlyExport = storeApi.getSampleOnlyExport();
  if (sampleOnlyExport) {
    result = {
      ...result,
      iconSize: 33,
      labelSize: 24,
    };
  }

  if (result.stylingPriority === StylingPriority.Appbased) {
    const { template_tab_id: appId } = sample;
    const app = appId ? storeApi.findAppById(appId) : undefined;
    if (app) {
      const { point_icon: icon, drawing_colour: color } = app;
      result = {
        ...result,
        icon: icon!,
        color: color!,
      };
    }

    if (exceedanceColor) {
      result = {
        ...result,
        color: exceedanceColor,
      };
    }
  } else if (result.stylingPriority === StylingPriority.Rulebased) {
    if (exceedanceColor) {
      result = {
        ...result,
        color: exceedanceColor,
      };
    }

    // Apply styling rules on the layer.
    const inputValuesForStyling = sample.input_values_for_styling ?? [];
    // The latter styling rule takes precedence.
    for (let i = fsrsOnLayer.length - 1; i >= 0; i--) {
      const fsrOnLayer = fsrsOnLayer[i];
      const {
        icon,
        color,
        iconSize,
        iconRotation = 0,
        iconOpacity = 1,
        isLabelHidden,
        isDuplicateLabelHidden,
        labelColor,
        labelShadowColor,
        labelSize,
        isLabelUnderlined,
        isLabelAsteriskAppended,
      } = fsrOnLayer.style as SampleStyle;
      const resultUpdate = {
        icon,
        color,
        iconSize,
        iconRotation,
        iconOpacity,
        isLabelHidden: shouldHideSampleLabels || isLabelHidden,
        isDuplicateLabelHidden,
        labelColor,
        labelShadowColor,
        labelSize,
        isLabelUnderlined,
        isLabelAsteriskAppended,
      };

      if (
        isExceedanceStylingUsed &&
        checkIsFigureStylingRuleOnEnviroSamples(fsrOnLayer)
      ) {
        const { scenarioIds, chemicalIds } = fsrOnLayer.condition;
        const scenarios = scenarioIds.map(
          (sid) => storeApi.findScenarioById(sid)!
        );
        const exceededCriteriaTypes = storeApi.getExceededCriteriaTypes(
          figure,
          sample,
          scenarios,
          chemicalIds
        );
        if (exceededCriteriaTypes.length) {
          result = {
            ...result,
            ...resultUpdate,
            subtitle: fsrOnLayer.name,
          };
          break;
        }
      }

      if (checkIsFigureStylingRuleOnApp(fsrOnLayer)) {
        const field = storeApi.findFieldById(fsrOnLayer.condition.fieldId);
        if (!field) {
          continue;
        }

        if (
          checkIsFigureStylingRuleOnAppFollowed(
            fsrOnLayer,
            field,
            inputValuesForStyling
          )
        ) {
          result = {
            ...result,
            ...resultUpdate,
            subtitle: fsrOnLayer.name,
          };
          break;
        }
      }
    }
  } else if (result.stylingPriority === StylingPriority.Custom) {
    if (exceedanceColor) {
      result = {
        ...result,
        color: exceedanceColor,
      };
    }
  }

  addSampleStyleToCache(figure, sample, result);
  return result;
}

function getSampleStyleFromCache(figure: Figure, sample: Sample): SampleStyle {
  const sampleGroupId = getSampleGroupId(sample);
  return ((sampleStyleCache[figure.id] ?? {})[sampleGroupId] ?? {})[sample.id];
}

function addSampleStyleToCache(
  figure: Figure,
  sample: Sample,
  sampleStyle: SampleStyle
) {
  const sampleGroupId = getSampleGroupId(sample);
  sampleStyleCache = produce(sampleStyleCache, (draft) => {
    draft[figure.id] = draft[figure.id] ?? {};
    draft[figure.id][sampleGroupId] = draft[figure.id][sampleGroupId] ?? {};
    draft[figure.id][sampleGroupId][sample.id] = sampleStyle;
  });
}

export function deleteSampleStylesFromCacheByFigureId(figureId: Pid) {
  sampleStyleCache = produce(sampleStyleCache, (draft) => {
    draft[figureId] = undefined;
  });
}

export function deleteSampleStylesFromCacheBySampleGroupId(
  figureId: Pid,
  sampleGroupId: Id
) {
  sampleStyleCache = produce(sampleStyleCache, (draft) => {
    if (draft[figureId]) {
      draft[figureId] = _omit(draft[figureId], [sampleGroupId]);
    }
  });
}

export function deleteSampleStyleFromCache(
  map: Map,
  figure: Figure,
  sample: Sample
) {
  if (checkIsSampleInImportPreview(map, sample)) {
    throwError(Code.InvalidArgument, 'sample');
  }

  const sampleGroupId = getSampleGroupId(sample);
  sampleStyleCache = produce(sampleStyleCache, (draft) => {
    if (draft[figure.id] && draft[figure.id][sampleGroupId]) {
      draft[figure.id][sampleGroupId] = _omit(draft[figure.id][sampleGroupId], [
        sample.id,
      ]);
    }
  });
}

function getSampleGroupId(sample: Sample): Id {
  const result =
    sample.sample_group?.id ??
    sample.project_figure_layer_id ??
    defaultSampleGroup?.id;

  if (!result) {
    throwError(Code.NullPointerException, 'sample group id');
  }

  return result;
}

function checkIsSampleInImportPreview(map: Map, sample: Sample) {
  const type = getMapType(map);
  return type === MapType.MAIN && !sample.sample_group;
}
