import { Country } from '../../../../common/address';
import { LayerType } from '../../../types';
import type { GoogleMapsApi } from '../../types';
import { BasemapId } from '../../types';

const basemapApi: GoogleMapsApi = {
  id: BasemapId.GOOGLE_MAPS_SATELLITE,
  title: 'Google Maps - Satellite',
  subtitle: 'Satellite imagery',
  type: 'satellite',
  countries: [Country.UnitedStates],
  layerModel: {
    id: 'google-maps-satellite',
    geojson: {
      properties: {
        type: LayerType.BASEMAP_SERVICE,
        mapType: 'satellite',
        title: 'Google Maps - satellite',
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [],
  },
};

export default basemapApi;
