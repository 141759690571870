import { FillStyle } from './types';

// Use ol-ext's fill patterns
export const FILL_PATTERN_OPTIONS = {
  // horizontal-stripe-pattern
  [FillStyle.HorizontalStripe]: {
    pattern: 'hatch',
    angle: 90,
  },
  // vertical-stripe-pattern
  [FillStyle.VerticalStripe]: {
    pattern: 'hatch',
  },
  // dot-pattern
  [FillStyle.Dot]: {
    pattern: 'dot',
  },
  // circle-pattern
  [FillStyle.Circle]: {
    pattern: 'circle',
    size: 6,
    spacing: 10,
  },
  // cross-45-deg-pattern
  [FillStyle.Cross45Deg]: {
    pattern: 'cross',
    angle: 45,
    spacing: 10,
  },
  // stripe-45-deg-pattern
  [FillStyle.Stripe45Deg]: {
    pattern: 'hatch',
    angle: 45,
    spacing: 10,
  },
};

export function isFillPatternUsed(fillStyle: FillStyle): boolean {
  return Object.keys(FILL_PATTERN_OPTIONS).includes(String(fillStyle));
}

export function isNoOverlayUsed(fillStyle: FillStyle): boolean {
  return fillStyle === FillStyle.Transparent;
}
