<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{ value?: number; isResetAvailable?: boolean }>(),
  {
    value: 1,
    isResetAvailable: true,
  }
);

const emit = defineEmits<{
  (e: 'input', value: number): void;
  (e: 'reset'): void;
}>();

const title = computed<string>(() => `${Math.round(props.value * 100)}%`);

function handleInput(event: Event) {
  emit('input', parseFloat((event.target as HTMLInputElement).value));
}
</script>

<template>
  <div
    class="d-flex flex-column align-items-center bg-primary-subtle p-2 rounded-2"
  >
    <label class="form-label mb-1">Icon Opacity</label>
    <input
      type="range"
      class="form-range mb-3"
      :min="0"
      :max="1"
      :step="0.01"
      :value="value"
      :title="title"
      @input="handleInput"
    />
    <div
      class="d-flex gap-2 align-items-center"
      :style="{ 'font-size': '0.75rem' }"
    >
      <div class="text-center text-muted" style="min-width: 30px">
        {{ title }}
      </div>
      <button
        v-if="isResetAvailable"
        type="button"
        class="btn btn-primary btn-sm"
        @click="emit('reset')"
      >
        Reset
      </button>
    </div>
  </div>
</template>
