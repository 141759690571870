import { BasemapId } from '../../../lib/olbm/layer/basemap/types';

export default {
  index: BasemapId.LINZ,
  title: 'LINZ',
  subtitle: 'Aerial imagery of New Zealand',
  type: 'satellite',
  source:
    'https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.webp?api=d01efztxps0q68x5kzwm2ggg0d0',
  options: {
    attributions: 'LINZ CC BY 4.0 © Imagery Basemap contributors',
    maxNativeZoom: 22,
  },
};
