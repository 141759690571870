import { defineStore } from 'pinia';
import { ref } from 'vue';

export type DialogOptions = {
  confirmButtonText?: string;
  cancelButtonText?: string;
};

export const useDialogStore = defineStore('dialog', () => {
  const showModal = ref(false);
  const title = ref('');
  const message = ref<string | undefined>(undefined);
  const danger = ref(false);
  const options = ref<DialogOptions>({});
  const _acceptCallback = ref<Function | null>(null);
  const _rejectCallback = ref<Function | null>(null);
  const validationString = ref<string | undefined>(undefined);

  function clearState() {
    showModal.value = false;
    title.value = '';
    message.value = undefined;
    options.value = {};
    _acceptCallback.value = null;
    _rejectCallback.value = null;
  }

  function confirm(
    modalTitle: string,
    modalMessage: string | undefined = undefined,
    acceptCallback: Function = () => {},
    rejectCallback = () => {},
    modalOptions: DialogOptions = {}
  ) {
    showModal.value = true;
    title.value = modalTitle;
    message.value = modalMessage;
    danger.value = false;
    options.value = modalOptions;
    _acceptCallback.value = acceptCallback;
    _rejectCallback.value = rejectCallback;
  }

  return {
    showModal,
    title,
    message,
    danger,
    options,
    confirm,
    confirmDanger(
      modalTitle: string,
      modalMessage: string | undefined = undefined,
      acceptCallback: Function = () => {},
      rejectCallback = () => {},
      stringToValidate: string | undefined = undefined
    ) {
      confirm(modalTitle, modalMessage, acceptCallback, rejectCallback);
      danger.value = true;
      validationString.value = stringToValidate;
    },

    accepted() {
      showModal.value = false;
      if (_acceptCallback.value) {
        _acceptCallback.value();
      }

      clearState();
    },

    rejected() {
      showModal.value = false;

      if (_rejectCallback.value) {
        _rejectCallback.value();
      }
      clearState();
    },
    validationString,
  };
});
