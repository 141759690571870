export enum Country {
  NewZealand = 'NZ',
  Australia = 'AU',
  UnitedStates = 'US',
  UnitedKingdom = 'GB',
  Canada = 'CA',
}

export type CountryState = [/* Full name */ string, /* Acronym */ string];

export const STATE_US_CALIFORNIA: CountryState = ['california', 'CA'];
export const STATE_US_NEVADA: CountryState = ['nevada', 'NV'];
export const STATE_US_NEW_YORK: CountryState = ['new york', 'NY'];

export const STATE_AU_NEW_SOUTH_WALES: CountryState = [
  'new south wales',
  'NSW',
];
export const STATE_AU_NORTHERN_TERRITORY: CountryState = [
  'northern territory',
  'NT',
];
export const STATE_AU_QUEENSLAND: CountryState = ['queensland', 'QLD'];
export const STATE_AU_SOUTH_AUSTRALIA: CountryState = ['south australia', 'SA'];
export const STATE_AU_VICTORIA: CountryState = ['victoria', 'VIC'];
export const STATE_AU_WESTERN_AUSTRALIA: CountryState = [
  'western australia',
  'WA',
];
