import { Map } from 'ol';
import { Dpi, Pixel, Size, Unit, UnitShortName } from './types';

export const INCHES_PER_MILLIMETER = 1 / 25.4;
export const INCHES_PER_POINT = 1 / 72;

export function getPixelsPerUnit(unit: Unit, dpi: Dpi): number {
  if (unit.shortName === UnitShortName.Pixel) {
    return 1;
  } else if (unit.shortName === UnitShortName.Millimeter) {
    return INCHES_PER_MILLIMETER * dpi;
  } else if (unit.shortName === UnitShortName.Point) {
    return INCHES_PER_POINT * dpi;
  } else if (unit.shortName === UnitShortName.Inch) {
    return dpi;
  }

  throw `Unit ${unit} is not supported.`;
}

export function getPixelDistance(pixel1: Pixel, pixel2: Pixel): number {
  const [x1, y1] = pixel1;
  const [x2, y2] = pixel2;
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

export function transformMeasure(
  measure: number,
  sourceDpi: Dpi,
  targetDpi: Dpi,
  sourceUnit: Unit,
  targetUnit: Unit
): number {
  if (sourceUnit === targetUnit) {
    return measure;
  }

  const measureInPx = measure * getPixelsPerUnit(sourceUnit, sourceDpi);
  if (targetUnit.shortName === UnitShortName.Pixel) {
    return measureInPx;
  } else if (targetUnit.shortName === UnitShortName.Millimeter) {
    return measureInPx / (INCHES_PER_MILLIMETER * targetDpi);
  } else if (targetUnit.shortName === UnitShortName.Point) {
    return measureInPx / (INCHES_PER_POINT * targetDpi);
  } else if (targetUnit.shortName === UnitShortName.Inch) {
    return measureInPx / targetDpi;
  }

  throw `Target unit ${targetUnit} is not supported.`;
}

export function getRatioOfHeightToWidth(size: Size): number {
  const [width, height] = size;
  return height / width;
}

export function getDpi(map: Map): Dpi {
  return map.get('mediumDpi');
}

export function setDpi(map: Map, value: Dpi) {
  map.set('mediumDpi', value);
}
