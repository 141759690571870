import type { LayerUsage } from '../constants';
import { LayerUsageByType } from '../constants';
import type { LayerModel } from '../types';
import { LayerType } from '../types';
import type {
  BufferLayerModel,
  MultipleShapeLayerModel,
  SingleShapeLayerModel,
} from './types';

export function checkIsSingleShapeLayerModel(
  model: LayerModel
): model is SingleShapeLayerModel {
  if (!model?.geojson?.properties) {
    console.trace('model?.geojson?.properties is undefined: checkIsSingleShapeLayerModel');
  }
  const { type } = model?.geojson?.properties ?? {};

  return [
    LayerType.RECTANGLE,
    LayerType.CIRCLE,
    LayerType.POLYGON,
    LayerType.POLYLINE,
    LayerType.ARROW,
    LayerType.SITE_BOUNDARY,
    LayerType.BUFFER,
    LayerType.HEDGE,
  ].includes(type);
}

export function checkIsBufferLayerModel(
  model: LayerModel
): model is BufferLayerModel {
  const { type } = model.geojson.properties;
  return type === LayerType.BUFFER;
}

export function checkIsMultipleShapeLayerModel(
  model: LayerModel
): model is MultipleShapeLayerModel {
  const { type } = model.geojson.properties;

  return [LayerType.FEATURE_COLLECTION].includes(type);
}

export function checkIsShapeLayerModel(
  model: LayerModel
): model is SingleShapeLayerModel | MultipleShapeLayerModel {
  return (
    checkIsSingleShapeLayerModel(model) || checkIsMultipleShapeLayerModel(model)
  );
}

export function checkIsContours(
  layerType: LayerType,
  layerUsage: LayerUsage
): boolean {
  return (
    layerType === LayerType.FEATURE_COLLECTION &&
    layerUsage === LayerUsageByType.feature_collection.CONTOURS
  );
}
