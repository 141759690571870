import { Map } from 'ol';

const MAP_EVENT = 'postrender';

// Some actions must run after the map is rendered. E.g. if there are
// map.getPixelFromCoordinate calls in the action.
export function postrender<T>(
  map: Map,
  action: () => T | undefined = () => undefined
): () => Promise<T | undefined> {
  return () =>
    new Promise((resolve) => {
      const fn = () => {
        if (map.isRendered()) {
          map.un(MAP_EVENT, fn);
          resolve(action());
        }
      };
      map.on(MAP_EVENT, fn);
      fn();
    });
}
