import { Color } from '../../style/color';
import { TextAlignment, createTextShadow } from '../../style/text';
import type { TextProperties } from './types';

export default function createTextStyle(
  {
    color = Color.Black,
    textSize = 16,
    textWidth = 140,
    textAngle = 0,
    showTextBackground = false,
    textAlignment = TextAlignment.Left,
    textItalicStyle = false,
    textBoldStyle = false,
    textUnderlineStyle = false,
    textBorderColor = Color.White,
  }: TextProperties,
  layoutZoom = 1
) {
  const style: any = {
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color,
    fontSize: `${textSize}px`,
    width: `${textWidth}px`,
    transformOrigin: 'left top',
    transform: `rotate(${-textAngle}deg) scale(${layoutZoom})`,
  };

  switch (textAlignment) {
    case 0:
      style.textAlign = 'left';
      break;
    case 1:
      style.textAlign = 'center';
      break;
  }

  style.fontStyle = textItalicStyle ? 'italic' : 'normal';
  style.fontWeight = textBoldStyle ? 'bold' : 'normal';
  style.textDecoration = textUnderlineStyle ? 'underline' : 'none';
  style.textUnderlinePosition = 'under';

  // use the backslash to remove linebreaks
  style.textShadow = createTextShadow(textBorderColor);

  if (showTextBackground) {
    style.backgroundColor = Color.White;
    style.border = `0.1em solid ${Color.Black}`;
    style.padding = '0.1em';
  } else {
    style.backgroundColor = 'transparent';
    style.border = 'none';
    style.padding = 0;
  }

  return style;
}
