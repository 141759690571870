import { WGS84_WORLD_EXTENT } from '../../../common/extent';
import { ServiceType } from '../../service/types';
import { LayerType } from '../../types';
import type { BasemapXyzApi } from '../types';
import { BasemapId } from '../types';
import hosts from './hosts';

const host = hosts.OPENSTREETMAP;

const basemapApi: BasemapXyzApi = {
  id: BasemapId.OPEN_STREET_MAP,
  title: 'OpenStreetMap',
  subtitle: 'Basic street maps covering world',
  type: 'street',
  host,
  layerModel: {
    id: 'open-street-map-folder',
    geojson: {
      properties: {
        type: LayerType.FOLDER,
        title: 'OpenStreetMap',
        service: {
          type: ServiceType.Xyz,
          url: `https://{a-c}.${host}/{z}/{x}/{y}.png`,
          title: 'OpenStreetMap',
          description: 'Basic street maps covering world',
          extent: WGS84_WORLD_EXTENT,
          visibleExtent: [...WGS84_WORLD_EXTENT],
          attributions: '© OpenStreetMap contributors',
          shouldUseCorsProxy: false,
          maxZoom: 19,
          projection: 'EPSG:3857',
        },
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [],
  },
};

export default basemapApi;
