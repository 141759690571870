<script lang="ts" setup>
import { computed, ref } from 'vue';
import InfoButton from '../InfoButton.vue';
import { App, getAppReferenceError } from '../../gather';
import AppIcon from '../../widgets/AppIcon.vue';

const props = defineProps<{
  app: App;
  selected: boolean;
  canDelete: boolean;
  isDisabled: boolean;
}>();

const emit = defineEmits(['editApp', 'deleteApp']);
const showManageDropdown = ref(false);

const invalidNameError = computed(() => {
  return getAppReferenceError(props.app);
});

const editApp = () => {
  showManageDropdown.value = false;
  emit('editApp');
};

const deleteApp = () => {
  showManageDropdown.value = false;
  emit('deleteApp');
};
</script>

<template>
  <div
    class="bg-light d-flex clickable p-2 px-3 rounded"
    :class="{
      'active border-body': selected,
    }"
    style="border: 1px dashed #d7d7d7; min-height: 47.5px"
  >
    <div class="d-flex align-items-center">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center me-4">
          <InfoButton
            v-if="invalidNameError"
            @click.native.stop
            class="me-2"
            :danger="true"
            backgroundColor="#FF0000"
            :info="invalidNameError"
          />
          <AppIcon v-else :selected="selected" :app="app" />
          <div class="me-2">
            <span
              class="mb-0 fw-medium"
              :class="{ 'text-danger': invalidNameError }"
            >
              {{ app.title }}
            </span>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div
            v-if="selected && !isDisabled"
            class="position-relative"
            v-click-outside="() => (showManageDropdown = false)"
          >
            <button
              class="btn btn-light btn-sm dropdown-toggle"
              @click.stop="() => (showManageDropdown = !showManageDropdown)"
            >
              <i class="fas fa-cog" />
            </button>
            <div
              class="dropdown-menu"
              :class="{
                'd-block': showManageDropdown,
              }"
              style="right: 0; top: calc(100% + 6px)"
            >
              <div class="dropdown-item" @click="editApp">
                <i class="fas fa-pencil-alt fa-fw"></i> Edit
              </div>
              <div
                v-if="canDelete"
                class="dropdown-item"
                @click.stop="deleteApp"
              >
                <i class="fas fa-trash-alt fa-fw"></i> Remove
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.selectable-box {
  position: absolute;
  right: -6px;
  top: -6px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
}

.card.active .selectable-box {
  border-color: #212529;
  background: #212529;
  color: #fff;
}
</style>
