<template>
  <div v-if="sources.length > 0" class="carousel slide h-100 w-100">
    <ol class="carousel-indicators">
      <li
        v-for="(source, index) in sources"
        :key="index"
        :data-slide-to="index"
        @click="setIndex(index)"
        :class="{ active: photoIndex == index }"
      ></li>
    </ol>

    <div class="carousel-inner">
      <div
        class="carousel-item"
        v-for="(source, index) in sources"
        :key="index"
        :class="{ active: photoIndex == index }"
      >
        <div
          class="view d-flex flex-column align-items-center text-center image-background"
        >
          <div class="mx-auto my-auto">
            <img
              class="d-block 1-100"
              :src="getImageFromSource(source)"
              alt="Photo at sample location"
            />
          </div>
        </div>
        <div v-if="descriptionCheck(source)" class="carousel-caption">
          <p class="p-1 rounded-3 caption-background">
            {{ source.description || '' }}
          </p>
        </div>
      </div>
    </div>

    <a
      v-if="sources.length > 1"
      class="carousel-control-prev"
      href
      @click.prevent="previousPhoto"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      v-if="sources.length > 1"
      class="carousel-control-next"
      href
      @click.prevent="nextPhoto"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>
<script>
export default {
  name: 'Carousel',
  props: ['sources'],
  data: () => ({
    photoIndex: 0,
  }),
  methods: {
    descriptionCheck(source) {
      return source?.alt && source?.alt.length > 0;
    },
    nextPhoto() {
      this.photoIndex = (this.photoIndex + 1) % this.sources.length;
    },
    previousPhoto() {
      this.photoIndex = Math.abs(this.photoIndex - 1) % this.sources.length;
    },
    setIndex(index) {
      this.photoIndex = index;
    },
    getImageFromSource(source) {
      if ('src' in source) {
        return source.src;
      }
      return null;
    },
  },
};
</script>
<style scoped>
.carousel {
  min-width: 80vw;
}
.view img {
  width: auto;
  max-width: 100%;
}
.image-background {
  background-color: rgba(0, 0, 0, 0.3);
}
.caption-background {
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
