import { BasemapId } from '../../../../lib/olbm/layer/basemap/types';
import { LayerType } from '../../../../lib/olbm/layer/types';

export default {
  index: BasemapId.GOOGLE_MAPS_SATELLITE,
  title: 'Google Maps - Satellite',
  subtitle: 'Satellite imagery',
  type: 'satellite',
  options: {
    id: 'google-maps-satellite',
    geojson: {
      properties: {
        type: LayerType.BASEMAP_SERVICE,
        mapType: 'satellite',
        title: 'Google Maps - Satellite',
      },
    },
    visible: true,
    children: [],
    attributions: '© Google Maps',
  },
};
