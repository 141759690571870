import { Country, STATE_AU_VICTORIA } from '../../../../../common/address';
import {
  AxisOrientation,
  ServiceSubType,
  WmtsRequestEncoding,
} from '../../../../service/ogc-opengis/types';
import { ServiceType } from '../../../../service/types';
import { LayerType } from '../../../../types';
import type { BasemapWmtsApi } from '../../../types';
import { BasemapId } from '../../../types';
import hosts from '../../hosts';

const host = hosts.VICMAP_BASEMAPS_AERIAL;

const basemapApi: BasemapWmtsApi = {
  id: BasemapId.AU_VIC_AERIAL,
  title: 'Victoria - Aerial',
  subtitle: '',
  type: 'satellite',
  host,
  countries: [Country.Australia],
  states: [STATE_AU_VICTORIA],
  layerModel: {
    id: 'victorial-aerial-folder',
    geojson: {
      properties: {
        type: LayerType.FOLDER,
        title: 'Vicmap Basemaps',
        service: {
          type: ServiceType.OgcOpenGIS,
          subtype: ServiceSubType.WMTS,
          url: `https://${host}/service?service=wmts&request=getCapabilities`,
          version: '1.0.0',
          title: 'Vicmap Basemaps',
          description: 'Vicmap Basemaps',
          extent: [-180, -85.0511287798066, 180, 85.0511287798066],
          visibleExtent: [-180, -85.0511287798066, 180, 85.05112877980662],
          attributions: 'Land Use Victoria',
          shouldUseCorsProxy: 0,
          axisOrientation: AxisOrientation.ENU,
          projection: 'EPSG:3857',
        },
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [
      {
        id: 'victorial-aerial-item',
        geojson: {
          properties: {
            type: LayerType.SERVICE,
            id: 'AERIAL_WM',
            name: 'Vicmap Basemap - Web Mercator - Aerial',
            extent: [-180, -85.0511287798066, 180, 85.0511287798066],
            format: 'image/png',
            url: `https://${host}/service?`,
            matrixSet: 'EPSG:3857',
            matrixIds: [
              '00',
              '01',
              '02',
              '03',
              '04',
              '05',
              '06',
              '07',
              '08',
              '09',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19',
              '20',
            ],
            resolutions: [
              156543.03390624997, 78271.51696402047, 39135.758482010235,
              19567.879241005117, 9783.939620502559, 4891.969810251279,
              2445.9849051256397, 1222.9924525628198, 611.4962262814099,
              305.74811314070496, 152.87405657035248, 76.43702828517624,
              38.21851414258812, 19.10925707129406, 9.55462853564703,
              4.777314267823515, 2.3886571339117575, 1.1943285669558787,
              0.5971642834779394, 0.2985821417389697, 0.14929107086948484,
            ],
            origins: [
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
              [-20037508.342789244, 20037508.342789244],
            ],
            sizes: [
              [1, 1],
              [1, 1],
              [2, 2],
              [4, 4],
              [8, 8],
              [16, 16],
              [32, 32],
              [64, 64],
              [128, 128],
              [256, 256],
              [512, 512],
              [1024, 1024],
              [2048, 2048],
              [4096, 4096],
              [8192, 8192],
              [16384, 16384],
              [32768, 32768],
              [65536, 65536],
              [131072, 131072],
              [262144, 262144],
              [524288, 524288],
            ],
            tileSizes: [
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
              [512, 512],
            ],
            parentLayerId: -1,
            subLayerIds: null,
            style: 'default',
            requestEncoding: WmtsRequestEncoding.KVP,
            projection: 'EPSG:3857',
            title: 'Vicmap Basemap - Web Mercator - Aerial',
          },
        },
        visible: true,
        is_visible_in_basemap_figure: false,
        children: [],
      },
    ],
  },
};

export default basemapApi;
