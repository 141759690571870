export const RECTANGLE = 'rectangle';
export const CIRCLE = 'circle';
export const POLYGON = 'polygon';
export const POLYLINE = 'polyline';
export const POLYLINE_INTERSECTION_INDICATOR =
  'polyline_intersection_indicator';
export const ARROW = 'arrow';
export const SITE_BOUNDARY = 'siteboundary';
export const SITE_BOUNDARY_INDICATOR = 'site_boundary_indicator';
export const TEXT = 'textarea';
export const CALL_OUT = 'call-out';
// The text mate layer is used to edit the text.
export const TEXT_MATE = 'text_mate';
export const IMAGE = 'image';
// The image mate layer is used to edit the image.
export const IMAGE_MATE = 'image_mate';
export const SAMPLE = 'marker';
export const SAMPLE_GROUP = 'sample_group';
export const SAMPLE_GROUP_SUB_FOLDER = 'sub_folder';
export const SAMPLE_POPUP_CONNECTORS = 'sample_popup_connectors';
export const SAMPLE_MARKER_LABEL_CONNECTOR = 'sample_marker_label_connector';
export const HIGHLIGHTED = 'highlighted';
export const FEATURE_COLLECTION = 'feature_collection';
export const FEATURE_COLLECTION_PREVIEW = 'feature_collection_preview';
export const POINT_FEATURE_COLLECTION_PREVIEW =
  'point_feature_collection_preview';
export const NON_POINT_FEATURE_COLLECTION_PREVIEW =
  'non_point_feature_collection_preview';
export const SERVICE = 'service_layer';
export const BASEMAP_SERVICE = 'basemap_service';
export const BASEMAP_IMAGE = 'basemap_image';
export const PARCELS = 'parcels';
export const HOVERED_PARCEL = 'hovered_parcel';
export const COMPOUND_PARCEL = 'compound_parcel';
export const EXTENT_EDITOR = 'extent_editor';
export const BUFFER = 'buffer';
export const MODIFICATION_OVERLAY = 'modification_overlay';
export const FOLDER = 'folder';
export const LEGEND_NOTE = 'legend_note';
export const CHAINAGE = 'chainage';
export const HEDGE = 'hedge';
