<template>
  <div class="map-action-menu-container">
    <div class="map-action-menu d-flex flex-column align-items-center">
      <div
        v-if="
          isTrackingAvailable ||
          (isAllowCollectionOnPoiAvailable && isModifying) ||
          isIosAvailable
        "
        class="d-flex align-items-center mb-2"
      >
        <a
          v-if="isTrackingAvailable"
          href="#"
          class="action-btn history"
          :class="{
            'bg-white text-danger': isTracking,
          }"
          @click.prevent="toggleTracking"
        >
          <i
            class="fal fa-fw fa-location"
            :class="{
              blink: isTracking,
            }"
          />
        </a>

        <div
          v-if="isAllowCollectionOnPoiAvailable && isModifying"
          class="btn-group mb-2"
        >
          <button
            class="btn btn-light btn-lg"
            :class="{
              active: !isPoiEditingOn,
            }"
            @click="clickRootEmitAction('togglePoiEditing', false)"
          >
            Edit Shape
          </button>
          <button
            class="btn btn-light btn-lg"
            :class="{
              active: isPoiEditingOn,
            }"
            @click="clickRootEmitAction('togglePoiEditing', true)"
          >
            Edit Data Points
          </button>
        </div>

        <IconOpacitySlider
          v-if="isIosAvailable"
          :value="iosValue"
          :is-reset-available="isIosResetAvailable"
          @input="handleIosInput"
          @reset="handleIosReset"
        />
      </div>

      <div class="d-flex">
        <!-- Tools for cancel & confirm-->
        <template v-if="isModifying">
          <div class="map-action-btn mx-1" @click="cancelDraw">
            <i class="fal fa-times fa-fw" />
          </div>
          <div class="map-action-btn" @click="confirmOrOpenDraw">
            <i
              v-if="!isTracking || isToolSelected"
              class="fal fa-check fa-fw"
            />
            <i v-else class="fal fa-pencil fa-fw" />
          </div>
        </template>
        <template v-else>
          <!-- Drawing item dropdown -->
          <div class="legend-popup mx-1">
            <div
              v-if="!gatherPermission.isViewOnly()"
              :class="[
                'map-action-btn',
                { 'map-action-btn-disabled': collectionStore.isBusy },
              ]"
              @click="openAddPopup"
            >
              <i
                class="fal fa-fw"
                :class="{
                  'fa-plus': !showAddPopup,
                  'fa-chevron-down': showAddPopup,
                }"
              />
            </div>

            <transition name="dropdown">
              <div
                v-show="showAddPopup"
                class="dropdown-menu box-shadow draw-menu px-2 pb-2"
                :style="drawStyleMenu"
                :class="{
                  'd-flex': showAddPopup,
                }"
              >
                <div
                  class="d-flex flex-column pt-3"
                  :class="{
                    'pe-2 border-end border-light w-50': isOnline,
                    'w-100': !isOnline,
                  }"
                  style="max-height: calc(100vh - 200px)"
                >
                  <span
                    class="fw-medium d-block pb-2"
                    style="padding-left: 10px"
                  >
                    {{ selectedPhase?.title || 'Apps' }}
                    <i
                      v-if="
                        gatherSchema.phases?.length >= 1 || isSelectingPhase
                      "
                      class="fas fa-exchange-alt ms-2 clickable"
                      @click="isSelectingPhase = !isSelectingPhase"
                    />
                  </span>
                  <template v-if="isSelectingPhase">
                    <div
                      v-for="phase of gatherSchema.phases.filter(
                        (p) => p.is_visible
                      )"
                      class="dropdown-item overflow-hidden"
                      style="text-overflow: ellipsis; border-radius: 0px"
                      :class="{
                        'bg-primary text-white':
                          selectedPhaseId && phase.id === selectedPhaseId,
                      }"
                      @click="
                        () => {
                          selectedPhaseId = phase.id;
                          isSelectingPhase = false;
                        }
                      "
                    >
                      <small class="text-muted">{{ phase.title }}</small>
                    </div>
                    <div
                      class="dropdown-item overflow-hidden"
                      style="text-overflow: ellipsis; border-radius: 0px"
                      @click="
                        () => {
                          selectedPhaseId = null;
                          isSelectingPhase = false;
                        }
                      "
                    >
                      <small class="text-muted">Show All Apps</small>
                    </div>
                  </template>
                  <template v-else-if="appMenu.length > 0">
                    <div
                      v-for="(item, key) of appMenu"
                      :key="key"
                      :class="[
                        'dropdown-item overflow-hidden',
                        {
                          disabled: !figureStore.checkIsAppEnabled(
                            item.tabId,
                            templateTabs
                          ),
                        },
                      ]"
                      :title="item.label"
                      style="text-overflow: ellipsis"
                      @click="
                        figureStore.checkIsAppEnabled(
                          item.tabId,
                          templateTabs
                        ) && item.click()
                      "
                    >
                      <i
                        v-if="!item.icon.startsWith('/')"
                        class="fal fa-fw"
                        :class="item.icon"
                      />
                      <img v-else :src="item.icon" width="18" height="18" />
                      <small class="text-muted">{{ item.label }}</small>
                    </div>
                  </template>
                  <div
                    v-else
                    class="text-muted small px-2 pb-2 d-flex flex-column h-100"
                  >
                    <p class="mt-2 flex-grow-1">
                      No apps configured for this project
                    </p>
                    <a :href="`/#/template/${project?.project_id}/apps`">
                      Go to App Editor
                    </a>
                  </div>
                </div>
                <div
                  v-if="isOnline"
                  class="d-flex flex-column w-50 pt-3 ps-2 position-sticky top-0"
                >
                  <span
                    class="fw-medium d-block pb-2"
                    style="padding-left: 10px"
                  >
                    Tools
                  </span>
                  <!-- menu items for the plain area and plain line-->
                  <div
                    v-for="(item, key) of toolMenu"
                    :key="key"
                    class="dropdown-item overflow-hidden"
                    style="text-overflow: ellipsis"
                    @click="item.click"
                  >
                    <i
                      v-if="!item.icon.startsWith('/')"
                      class="fal fa-fw"
                      :class="item.icon"
                    />
                    <img v-else :src="item.icon" width="18" height="18" />
                    <small class="text-muted">{{ item.label }}</small>
                  </div>
                </div>
              </div>
            </transition>
          </div>

          <!-- Item manager modal -->
          <div
            :class="[
              'map-action-btn mx-1',
              { 'map-action-btn-disabled': collectionStore.isBusy },
            ]"
            @click="openManagersModal"
          >
            <i class="fal fa-fw fa-database" />
          </div>

          <!-- Information Dropdown -->
          <div class="legend-popup mx-1">
            <div
              :class="[
                'map-action-btn',
                { 'map-action-btn-disabled': collectionStore.isBusy },
              ]"
              @click="openInfoPopup"
            >
              <i
                class="fal fa-fw"
                :class="{
                  'fa-info': !showInfoPopup,
                  'fa-chevron-down': showInfoPopup,
                }"
              />
            </div>

            <transition v-if="showInfoPopup" name="dropdown">
              <div
                v-if="!isBasemapMenuVisible"
                class="dropdown-menu box-shadow action-menu p-2"
                :style="actionMenuStyle"
              >
                <div
                  class="dropdown-item text-center"
                  v-for="(item, key) of actionMenu"
                  :key="key"
                  @click="item.click"
                >
                  <i class="fal fa-fw" :class="item.icon" />
                  <small class="text-muted">{{ item.label }}</small>
                </div>
              </div>
              <div
                v-else
                class="dropdown-menu box-shadow action-menu basemap-menu p-2"
                :style="actionMenuStyle"
              >
                <div
                  class="dropdown-item d-flex align-items-center"
                  @click="handleBasemapMenuItemClick('back')"
                >
                  <i class="fas fa-arrow-left"></i>
                </div>
                <div
                  v-for="bmItem of basemapMenu"
                  class="dropdown-item d-flex align-items-center gap-2"
                  :key="bmItem.id"
                  @click="handleBasemapMenuItemClick(bmItem.id)"
                >
                  <BasemapTypeIcon
                    v-if="
                      bmItem.type === 'street' || bmItem.type === 'satellite'
                    "
                    class="flex-shrink-0"
                    :type="bmItem.type"
                    :selected="bmItem.id === selectedBasemapId"
                  />
                  <div class="flex-grow-1 text-start text-wrap">
                    <div class="fw-bold">{{ bmItem.title }}</div>
                    <div class="text-muted">{{ bmItem.subtitle }}</div>
                    <BasemapWarning
                      v-if="checkIsBasemapWarningVisible(bmItem.id)"
                    />
                  </div>
                </div>
              </div>
            </transition>
          </div>

          <!-- Layer management dropdown -->
          <div
            v-if="isOnline"
            :class="[
              'map-action-btn mx-1',
              { 'map-action-btn-disabled': collectionStore.isBusy },
            ]"
            aria-controls="layerStylingOffcanvas"
            @click="handleLayerStylingOffcanvasOpen"
          >
            <i class="fas fa-palette"></i>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { StackableModalType } from '@/js/types/modal-stack';
import {
  checkIfAppReachedLimit,
  checkIsEmbedded,
  checkIsPhaseVisible,
  checkIsPoiApp,
} from '@component-library/business-logic/app';
import BasemapTypeIcon from '@component-library/components/mapping/BasemapTypeIcon.vue';
import BasemapWarning from '@component-library/components/mapping/BasemapWarning.vue';
import useLegacyRootBus from '@component-library/composables/useLegacyRootBus';
import useLegacyStore from '@component-library/composables/useLegacyStore';
import { FEATURES, hasAccess } from '@component-library/feature-manager';
import { App, Item } from '@component-library/gather';
import { useCollectionStore } from '@component-library/store/collection';
import { useGatherSchemaStore } from '@component-library/store/gather-schema';
import { useToastStore } from '@component-library/store/toasts';
import { checkIsBasemapWarningVisible } from '@maps/lib/olbm/layer/basemap/utils';
import { LayerType } from '@maps/lib/olbm/layer/types';
import { computed, inject, onMounted, ref, Ref, watch } from 'vue';
import useFigureStore from '../../../../stores/figure';
import IconOpacitySlider from './IconOpacitySlider.vue';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';

const props = defineProps<{
  selectedBasemapId?: number;
  isModifying: boolean;
  drawingType?: string;
  templateTabs: App[];
  samples: Item[];
  isTrackingAvailable: boolean;
  isAllowCollectionOnPoiAvailable: boolean;
  isPoiEditingOn: boolean;
  isIosAvailable: boolean;
  iosValue: number;
  isIosResetAvailable: boolean;
}>();

const isMapMounted = inject<Ref<boolean>>('isMapMounted');
if (!isMapMounted) {
  throw new Error('isMapMounted is not provided');
}
const figureStore = useFigureStore();
const gatherSchema = useGatherSchemaStore();
const collectionStore = useCollectionStore();
const toastStore = useToastStore();

const gatherPermission = useViewRestriction(AVAILABLE_PERMISSIONS.GATHER);

const emit = defineEmits<{
  (event: 'showProjectInfoModal'): void;
  (event: 'showAddressLookupModal'): void;
  (event: 'showOfflineManagerModal'): void;
  (event: 'scale-toggle'): void;
  (event: 'basemap-change', id: number): void;
}>();

watch(
  () => collectionStore.isBusy,
  (newValue) => {
    if (!newValue) {
      return;
    }

    showAddPopup.value = false;
    showInfoPopup.value = false;
  }
);

const isToolSelected = ref(false),
  showInfoPopup = ref(false),
  showAddPopup = ref(false),
  hasLocation = ref(false),
  hedgeIconSrc = ref('/styled-map-icon/tool-icons/hedge'),
  isBasemapMenuVisible = ref(false),
  isSelectingPhase = ref(false),
  selectedPhaseId = ref<number | null>(null);

const legacyRootBus = useLegacyRootBus();
const legacyStore = useLegacyStore();
const persistence = legacyStore.getters.get_persistence;
const project = computed(() => legacyStore.state.project);
const isOnline = computed(() => legacyStore.state.isOnline);
const isScaleVisible = computed(() => legacyStore.state.isScaleVisible);

const isTracking = computed({
  get() {
    return persistence.isTracking;
  },
  set(value) {
    legacyStore.dispatch('updatePersistence', {
      isTracking: value,
    });
  },
});

const drawingItems = computed(() => {
  const drawingItems = {
    [LayerType.POINT]: {
      title: 'Point',
      icon: 'fa-map-marker',
      method: 'startDrawMarker',
    },
    [LayerType.POLYGON]: {
      title: 'Polygon',
      icon: 'fa-hexagon',
      method: 'startDrawPoly',
    },
    [LayerType.POLYLINE]: {
      title: 'Line',
      icon: 'fa-wave-triangle',
      method: 'startDrawPoly',
    },
    [LayerType.ARROW]: {
      title: 'Arrow',
      icon: 'fa-arrow-right',
      method: 'startDrawPoly',
    },
    [LayerType.CIRCLE]: {
      title: 'Circle',
      icon: 'fa-circle',
      method: 'startDrawPoly',
    },
    [LayerType.RECTANGLE]: {
      title: 'Rectangle',
      icon: 'fa-rectangle-wide',
      method: 'startDrawPoly',
    },
  };

  if (hasAccess(FEATURES.MAPS_HEDGE)) {
    drawingItems[LayerType.HEDGE] = {
      title: 'Hedge',
      icon: hedgeIconSrc.value,
      method: 'startDrawPoly',
    };
  }

  return drawingItems;
});

const appMenu = computed(() => {
  const menuItems: any[] = [];

  const _drawingItems = drawingItems.value;
  _drawingItems['non-spatial'] = {
    title: 'Non-spatial',
    icon: 'fa-chart-bar',
    method: 'startNonSpatial',
  };

  const addMenuItem = (type, label, tabId) => {
    const drawingItem = _drawingItems[type];
    // The drawing item could not be ready. For example the Hedge is controlled
    // by a permission. If the permission is not ready the Hedge is not available.
    if (!drawingItem) {
      return;
    }
    menuItems.push({
      icon: drawingItem.icon,
      label,
      tabId,
      click: () => {
        isToolSelected.value = false;
        let param = {
          tabId,
          drawingType: type,
        } as { tabId: number; drawingType: string; isPlacedAtCenter?: boolean };
        if (type === LayerType.POINT) {
          param = {
            ...param,
            isPlacedAtCenter: true,
          };
        }
        clickRootEmitAction(drawingItem.method, param);
      },
    });
  };

  const templateTabs = props.templateTabs
    .filter(
      (t) =>
        !t.is_read_only &&
        !checkIfAppReachedLimit(t) &&
        !checkIsEmbedded(t, props.templateTabs) &&
        (selectedPhaseId.value !== null
          ? t.app_phase_group_id === selectedPhaseId.value
          : checkIsPhaseVisible(t, gatherSchema.phases))
    )
    .sort((a, b) => a.order - b.order);

  if (!templateTabs) {
    return [];
  }

  const hasAnyType = templateTabs.some(
    (t) =>
      t.drawing_type === 'any' &&
      figureStore.checkIsAppEnabled(t.id, props.templateTabs)
  );

  if (hasAnyType) {
    Object.keys(_drawingItems).forEach((type) => {
      const { title: label } = _drawingItems[type];
      addMenuItem(type, label, null);
    });
    return menuItems;
  }

  const isPreviousAppComplete = (tab: App) => {
    const previousShareableAppId = tab.previous_app_requirement;

    const previousApp = props.templateTabs.find(
      (t) =>
        t.parent_shareables &&
        t.parent_shareables.findIndex(
          (shareable) => shareable.share_group === previousShareableAppId
        ) !== -1
    );

    return previousApp
      ? props.samples.findIndex((s) => s.template_tab_id === previousApp.id) !==
          -1
      : false;
  };

  for (const tab of templateTabs.filter((t) => t.drawing_type !== 'any')) {
    if (
      tab.previous_app_requirement
        ? isPreviousAppComplete(tab)
        : !checkIsPoiApp(templateTabs, tab.id)
    ) {
      const { drawing_type: type, title: label, id: tabId } = tab;
      addMenuItem(type, label, tabId);
    }
  }

  return menuItems;
});

const toolMenu = computed(() => {
  const menuItems: any[] = [];

  const addMenuItem = (type) => {
    const drawingItem = drawingItems.value[type];
    if (!drawingItem) {
      throw new Error(`Drawing item ${type} is not available`);
    }

    menuItems.push({
      icon: drawingItem.icon,
      label: drawingItem.title,
      click: () => {
        isToolSelected.value = true;
        clickRootEmitAction('startDrawPoly', {
          tabId: null,
          drawingType: type,
          isPlainLayer: true,
        });
      },
    });
  };

  const types = [
    LayerType.POLYGON,
    LayerType.RECTANGLE,
    LayerType.CIRCLE,
    LayerType.POLYLINE,
    LayerType.ARROW,
  ];

  if (hasAccess(FEATURES.MAPS_HEDGE)) {
    types.push(LayerType.HEDGE);
  }

  types.forEach((type) => {
    addMenuItem(type);
  });

  return menuItems;
});

const actionMenu = computed(() => {
  let menuItems: any[] = [];

  if (hasLocation.value) {
    menuItems.push({
      icon: 'fa-location',
      label: 'Go to My Location',
      click: () => {
        clickRootEmitAction('gotoMyLocation');
      },
    });
  }

  menuItems = [
    ...menuItems,
    ...[
      {
        icon: 'fa-home-alt',
        label: 'Go to Project Address',
        click: () => {
          clickRootEmitAction('backToAddress');
        },
      },
      {
        icon: 'fa-info',
        label: 'Project Information',
        click: () => {
          clickNormalEmitAction('showProjectInfoModal');
        },
      },
      {
        icon: 'fa-horizontal-rule',
        label: `${isScaleVisible.value ? 'Hide' : 'Show'} Scale Line`,
        click: () => {
          clickNormalEmitAction('scale-toggle');
        },
      },
    ],
  ];

  if (isOnline.value) {
    menuItems = [
      ...menuItems,
      ...[
        {
          icon: 'fa-map-marked',
          label: 'Find Address',
          click: () => {
            clickNormalEmitAction('showAddressLookupModal');
          },
        },
        {
          icon: 'fa-map',
          label: 'Change Basemap',
          click: () => {
            isBasemapMenuVisible.value = true;
          },
        },
      ],
    ];

    if (props.templateTabs.length) {
      menuItems.push({
        icon: 'fa-wifi-slash',
        label: 'Offline Manager',
        click: () => {
          clickNormalEmitAction('showOfflineManagerModal');
        },
      });
    }
  }

  menuItems.push({
    icon: 'fa-layer-group',
    label: 'Layer Manager',
    click: () => {
      openManagersModal();
    },
  });

  return menuItems;
});

const basemapMenu = computed(() => {
  const apis = [...figureStore.getAvailableBasemapApis()];
  apis.sort((api1, api2) => api1.id - api2.id);
  return apis;
});

const actionMenuStyle = computed(() => {
  return {
    top: 'auto',
    bottom: 'calc(60px + 1rem)',
    overflow: 'auto',
  };
});

const drawStyleMenu = computed(() => {
  if (!isOnline.value) {
    return actionMenuStyle.value;
  }

  return {
    ...actionMenuStyle.value,
    width: '300px',
    left: '-17.5px',
  };
});

const selectedPhase = computed(() => {
  return selectedPhaseId.value
    ? gatherSchema.phases.find((p) => p.id === selectedPhaseId.value)
    : null;
});

function confirmOrOpenDraw() {
  if (!isTracking.value || isToolSelected.value) {
    confirmDraw();
  } else {
    openDraw();
  }
}

function confirmDraw() {
  clickRootEmitAction('confirmDraw');
}

function openDraw() {
  clickRootEmitAction('openDraw');
}
function cancelDraw() {
  clickRootEmitAction('cancelDraw');
}
function openAddPopup() {
  if (collectionStore.isBusy) {
    return;
  }
  showInfoPopup.value = false;
  showAddPopup.value = !showAddPopup.value;
}
function openInfoPopup() {
  if (collectionStore.isBusy) {
    return;
  }
  showAddPopup.value = false;
  showInfoPopup.value = !showInfoPopup.value;
}
function openManagersModal() {
  if (collectionStore.isBusy) {
    return;
  }

  showInfoPopup.value = false;
  showAddPopup.value = false;
  legacyStore.dispatch('pushToModalStack', {
    type: StackableModalType.ManagersModal,
  });
}
function clickNormalEmitAction(
  method:
    | 'showProjectInfoModal'
    | 'scale-toggle'
    | 'showAddressLookupModal'
    | 'showOfflineManagerModal'
) {
  showInfoPopup.value = false;
  showAddPopup.value = false;

  emit(method as any);
}
function clickRootEmitAction(method, options: any | undefined = undefined) {
  showInfoPopup.value = false;
  showAddPopup.value = false;

  legacyRootBus.$emit(method, options);
}
function toggleTracking() {
  if (isTracking.value) {
    legacyRootBus.$emit('stopTracking');
  } else {
    legacyRootBus.$emit('openTrackingActivityModal');
  }
}
function handleLayerStylingOffcanvasOpen() {
  if (!isMapMounted?.value) {
    toastStore.warning(
      'The map is being loaded, please wait util the loading completes.'
    );
    return;
  }
  if (collectionStore.isBusy) {
    return;
  }
  clickRootEmitAction('showLayerStylingOffcanvas');
}
function handleBasemapMenuItemClick(id) {
  isBasemapMenuVisible.value = false;

  if (id === 'back') {
    return;
  }

  showInfoPopup.value = false;
  emit('basemap-change', id);
}

function handleIosInput(value: number) {
  clickRootEmitAction('changeIconOpacity', value);
}

function handleIosReset() {
  clickRootEmitAction('changeIconOpacity', null);
}

onMounted(() => {
  legacyRootBus.$on('hasLocation', (state) => {
    hasLocation.value = state;
  });
  legacyRootBus.$on('openAddPopup', openAddPopup);
  isTracking.value = false;
});
</script>

<style lang="scss" scoped>
.map-action-menu-container {
  position: absolute;
  left: calc(50% - 275px / 2);
  bottom: 1rem;
  z-index: 1000;
  pointer-events: none;

  .map-action-menu {
    width: 275px;
    display: flex;
    align-items: center;
  }

  .map-action-menu * {
    pointer-events: auto;
  }

  .basemap-menu {
    width: 240px;
  }
}

.dropdown-item {
  padding: 10px 10px;
  text-align: left !important;
  border-radius: 6px;
  flex-shrink: 0;

  &.disabled {
    opacity: 0.5;
  }
}

.action-btn {
  flex: 1;
  align-items: center;
  text-align: center;
  display: block;
  padding: 1em 1.2em;
  color: #212529;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  background: #ffffff;
  border-radius: 0.2em;
  border-bottom: 1px solid #ccc;
  margin-right: 0.5em;
}

.action-btn:hover {
  background-color: #f4f4f4;
}

.action-btn.history {
  flex: 0;
  padding: 8px 11px;
}

.action-btn.disabled {
  opacity: 0.6;
}

.blink {
  animation: fadeinout 2s steps(5, start) infinite;
  -webkit-animation: fadeinout 1s steps(5, start) infinite;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.dropdown-leave-to,
.dropdown-leave-active {
  display: flex !important;
}

.map-action-btn-disabled {
  color: var(--text-color-disabled);
  cursor: not-allowed;
}
</style>
