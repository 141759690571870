<template>
  <div>
    <div class="py-3 text-center" v-if="loading">
      <Spinner small />
    </div>
    <template v-else>
      <template v-for="category in selectedCategories">
        <div
          class="bg-dark text-white d-flex justify-content-between align-items-center p-3 clickable mb-2"
          @click.prevent="toggleCategoryCollapsed(category)"
        >
          <div class="d-flex align-items-center">
            <h6 class="mb-0">{{ category.name }}</h6>
            <span
              class="badge rounded-pill ms-1"
              :class="
                getHighestPostRiskScore(category) != null
                  ? getHazardStyleByValue(getHighestPostRiskScore(category))
                  : 'bg-light'
              "
              v-if="getHighestPostRiskScore(category) != null"
            >
              {{ getHighestPostRiskScore(category) }}
            </span>
          </div>
          <h6
            class="fas mb-0"
            :class="{
              'fa-chevron-down': category.collapsed,
              'fa-chevron-up': !category.collapsed,
            }"
          />
        </div>
        <div
          v-if="!category.collapsed"
          :key="`section-safety-${category.name}-hazards`"
        >
          <div
            v-for="n in sectionCount"
            :key="n"
            class="position-relative mb-2"
            :class="{
              'mt-2': n != 1,
            }"
            :id="`section-${section.id}-${n}`"
          >
            <template v-if="sectionIsFromCategory(category, n)">
              <div
                class="d-flex justify-content-between align-items-center mb-2 p-3 clickable"
                :class="
                  getPostRiskScore(n) != null
                    ? getHazardStyleByValue(getPostRiskScore(n))
                    : 'bg-light'
                "
                @click="toggleHazardCollapsed(n)"
              >
                <div>
                  <b>{{ getPrimaryFieldTitle(n) }}</b>
                  <span
                    class="badge rounded-pill ms-1 bg-light text-dark"
                    v-if="getPostRiskScore(n) != null"
                  >
                    {{ getPostRiskScore(n) }}
                  </span>
                </div>
                <div class="d-flex align-items-center">
                  <h6
                    class="fas clickable mb-0 ms-2"
                    :class="{
                      'fa-chevron-down': hazardIsCollapsed(n),
                      'fa-chevron-up': !hazardIsCollapsed(n),
                    }"
                    @click.stop="toggleHazardCollapsed(n)"
                  />
                </div>
              </div>
              <template v-if="!hazardIsCollapsed(n)">
                <FormInput
                  v-for="(field, fieldIndex) of shownSectionFields"
                  :search="search"
                  :id="field.id"
                  :key="field.id"
                  :field="field"
                  :inputValue="getInputValue(field, n - 1)"
                  :lastValue="n > 1 ? getInputValue(field, n - 2) : null"
                  :inputValues="inputValues"
                  :fields="shownSectionFields"
                  :allFields="allFields"
                  :sectionCount="sectionCount"
                  :sectionIndex="n - 1"
                  :templateTabId="section.template_tab_id"
                  :repeatable="!!section.is_repeatable"
                  :class="{
                    'mb-2': fieldIndex != shownSectionFields.length - 1,
                  }"
                  :css-field-scroll-margin-top="cssFieldScrollMarginTop"
                  @input="updateFormInput(n)"
                />
              </template>
            </template>
          </div>
          <a
            class="btn btn-outline-primary w-100 mb-2"
            @click="addCustomHazard(category)"
          >
            <i class="fas fa-plus" />
            Add Hazard
          </a>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import striptags from 'striptags';
import FormInput from './FormInput.vue';
import Category from './classes/Category.js';
import Hazard from './classes/Hazard.js';
import CategoryDropdown from './fields/CategoryDropdown.vue';
import { checkIsConditionMet } from '../fields';
import Spinner from '../components/Spinner.vue';

export default {
  name: 'SafetySection',
  components: { FormInput, CategoryDropdown, Spinner },
  watch: {
    search(updated) {
      if (!!updated) {
        this.openedRepeatedSections = Array.from(
          { length: this.sectionCount },
          (_, i) => i + 1
        );
      }
    },
    inputValues: {
      handler: function () {
        this.checkValidationStatus();
      },
      deep: true,
    },
  },
  props: {
    section: Object,
    search: {
      required: false,
    },
    inputValues: Array,
    templateTab: Object,
    allFields: Array,
    publicForm: {
      required: false,
      default: false,
      type: Boolean,
    },
    shouldEmit: {
      required: false,
      default: true,
      type: Boolean,
    },
    cssFieldScrollMarginTop: {
      type: String,
      default: '0px',
    },
  },
  data: () => ({
    loading: false,
    sectionCount: 0,
    sectionToDelete: null,
    openedRepeatedSections: [],
    fields: [],

    categories: [],
    selectedCategories: [],
  }),
  computed: {
    categoryList() {
      const categories = [...this.categories];
      return categories.filter((category) => {
        return !this.inputValues.find(
          (inputValue) => inputValue.value == category.name
        );
      });
    },
    shownSectionFields() {
      const templateFields = this.section.template_fields.filter(
        (f) => f.label != 'Category'
      );

      if (!this.publicForm || this.section.is_public_form) {
        return templateFields;
      }

      return templateFields.filter((f) => f.options.is_public_form);
    },
  },
  methods: {
    addCustomHazard({ id }) {
      const categoryName = this.categories.find((c) => c.id == id)?.name;

      this.addHazard(
        {
          category_name: categoryName,
        },
        true
      );

      this.toggleHazardCollapsed(this.sectionCount);
    },
    async addHazard(hazard, isCustom = false) {
      const prevSection = this.sectionCount;
      this.sectionCount++;

      await this.$nextTick();
      const length = this.section.template_fields.length;
      const values = [
        hazard.category_name,
        hazard.name,
        hazard.pre,
        hazard.control_measure,
        null,
        null,
        hazard.post,
        hazard.responsibility,
      ];

      for (let fieldIndex = 0; fieldIndex < length; fieldIndex++) {
        const aField = this.section.template_fields[fieldIndex];
        let options = { disabled: false, is_health_safety: true };
        if (
          ['Hazard', 'Pre-Risk Score', 'Post-Risk Score'].includes(
            aField.label
          ) &&
          !isCustom
        ) {
          options.disabled = true;
        }

        const inputValue = this.getInputValue(aField, prevSection);

        // this stops existing input values been overriden
        if (!inputValue.id) {
          this.$root.$emit('updateInputValue', {
            inputValue: { ...inputValue, value: values[fieldIndex], options },
            field: inputValue.template_field_id,
            sectionIndex: prevSection,
            templateTabId: this.section.template_tab_id,
          });
        }
      }
    },
    checkValidationStatus() {
      const requiredFieldIds = this.section.template_fields
        .filter(
          (f) => f.is_required && checkIsConditionMet(f, this.inputValues)
        )
        .map((f) => f.id);

      const isValid =
        this.inputValues.filter(
          (v) =>
            !v.value &&
            !v.value2 &&
            v.template_section_index >= 0 &&
            requiredFieldIds.includes(v.template_field_id)
        ).length == 0;

      this.$set(this.section, 'validated', isValid);

      return isValid;
    },
    checkSectionValidation() {
      const requiredFieldIds = this.section.template_fields
        .filter(
          (f) => f.is_required && checkIsConditionMet(f, this.inputValues)
        )
        .map((f) => f.id);

      this.selectedCategories.forEach((category) => {
        for (let n = 0; n < this.sectionCount; n++) {
          if (
            this.sectionIsFromCategory(category, n + 1) &&
            this.hazardIsCollapsed(n + 1)
          ) {
            const hasRequiredInputValues =
              this.inputValues.filter(
                (v) =>
                  !v.value &&
                  !v.value2 &&
                  v.template_section_index == n &&
                  requiredFieldIds.includes(v.template_field_id)
              ).length > 0;

            if (hasRequiredInputValues) {
              this.openedRepeatedSections.push(n + 1);
            }
          }
        }
      });
    },
    hazardIsCollapsed(index) {
      return this.openedRepeatedSections.indexOf(index) === -1;
    },
    getPrimaryFieldTitle(index) {
      return this.getInputValueByField(this.getFieldByLabel('Hazard'), index);
    },
    getHighestPostRiskScore(category) {
      const field = this.getFieldByLabel('Post-Risk Score');
      const postRiskScores = this.inputValues
        .filter(
          (iV) =>
            iV.template_field_id == field.id &&
            this.sectionIsFromCategory(
              category,
              iV.template_section_index + 1
            ) &&
            iV.value
        )
        .map((iV) => iV.value);

      return postRiskScores.length > 0 ? Math.max(...postRiskScores) : null;
    },
    getPostRiskScore(index) {
      return this.getInputValueByField(
        this.getFieldByLabel('Post-Risk Score'),
        index
      );
    },
    sectionIsFromCategory({ name }, index) {
      const field = this.getFieldByLabel('Category');
      const categoryInputValue = this.getInputValueByField(field, index);
      return categoryInputValue == name;
    },
    getFieldByLabel(label) {
      return this.section.template_fields.find(
        (f) =>
          f.id == this.section.template_fields.find((f) => f.label == label).id
      );
    },
    updateSectionPostRiskScore(index) {
      const hazard = new Hazard({});

      const postRiskScore = hazard.calculateScore(
        this.getInputValueByField(this.getFieldByLabel('Consequence'), index),
        this.getInputValueByField(this.getFieldByLabel('Likelihood'), index)
      );

      let inputValue = this.getInputValue(
        this.getFieldByLabel('Post-Risk Score'),
        index - 1
      );

      if (postRiskScore) {
        inputValue.value = postRiskScore;
      }
    },
    updateFormInput(n) {
      this.updateSectionPostRiskScore(n);
      this.$emit('input');
    },
    getInputValueByField(field, index) {
      const inputValue = this.getInputValue(field, index - 1);
      const v =
        inputValue &&
        (typeof inputValue.value === 'string' ||
          Number.isInteger(inputValue.value))
          ? inputValue.value
          : inputValue.value2;
      return v ? striptags(v + '') : null;
    },
    toggleCategoryCollapsed(category) {
      this.$set(category, 'collapsed', !category.collapsed);
    },
    toggleHazardCollapsed(index) {
      this.$emit('clearSearch', true);
      let rIndex = this.openedRepeatedSections.indexOf(index);
      if (rIndex !== -1) {
        this.openedRepeatedSections.splice(rIndex, 1);
        return;
      }

      this.openedRepeatedSections.push(index);
    },
    getInputValue(field, sectionIndex = 0) {
      if (sectionIndex < 0) {
        throw new Error('Cannot get negative sectionIndex');
      }

      const iv = this.inputValues.find(
        (iv) =>
          iv.template_field_id == field.id &&
          iv.template_section_index == sectionIndex
      );

      if (iv) {
        return iv;
      }

      const placeholder = {
        template_field_id: field.id,
        template_section_index: sectionIndex,
        template_section_id: field.template_section_id,
        value: null,
        value2: null,
        options: null,
        sample_id: this.sample ? this.sample.id : null,
      };

      if (this.shouldEmit) {
        this.$root.$emit('updateInputValue', {
          inputValue: placeholder,
          field,
          sectionIndex,
          templateTabId: this.section.template_tab_id,
          isDefaultInputValue: true,
        });
      }

      return placeholder;
    },
    getHazardStyleByValue(value) {
      const hazard = new Hazard({});
      return hazard.calculateStyling(value);
    },
    addExistingHazards() {
      for (let iv of this.inputValues) {
        if (iv.template_section_index > this.sectionCount - 1) {
          this.sectionCount = iv.template_section_index + 1;
        }
      }
    },
    getCategoriesFromInputValues(categoryInputValues) {
      const categoryField = this.getFieldByLabel('Category');
      const hazardField = this.getFieldByLabel('Hazard');

      return categoryInputValues
        .map((iV) => iV.value)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((category_name) => {
          const hazards = this.inputValues.filter(
            (iV) =>
              iV.template_field_id == hazardField.id &&
              this.inputValues.find(
                (catIv) =>
                  catIv.template_field_id == categoryField.id &&
                  catIv.value == category_name &&
                  catIv.template_section_index == iV.template_section_index
              )
          );

          return new Category({
            name: category_name,
            hazards: hazards.map((h) => {
              return {
                name: h.value,
              };
            }),
          });
        });
    },
  },
  beforeDestroy() {
    this.$root.$off('checkSectionValidation');
  },
  async mounted() {
    this.$root.$on('checkSectionValidation', () => {
      this.checkSectionValidation();
    });

    this.checkValidationStatus();

    try {
      this.loading = true;

      const data = await Category.get();
      this.categories = [...data.filter((aCat) => aCat.hazards.length)];

      const categoryField = this.getFieldByLabel('Category');
      const hazardField = this.getFieldByLabel('Hazard');

      const hsCategories = this.templateTab.hs_categories;

      // if deleted from selected categories
      const deletedHsCategories = this.getCategoriesFromInputValues(
        this.inputValues.filter(
          (iV) =>
            iV.template_field_id == categoryField.id &&
            !hsCategories.map((c) => c.category_name).includes(iV.value)
        )
      );
      this.categories = [...this.categories, ...deletedHsCategories];

      // if deleted from account categories
      const deletedAccountCategories = this.getCategoriesFromInputValues(
        this.inputValues.filter(
          (iV) =>
            iV.template_field_id == categoryField.id &&
            !this.categories.map((c) => c.name).includes(iV.value)
        )
      );
      this.categories = [...this.categories, ...deletedAccountCategories];

      // if exists in selected categories, but not in categories
      hsCategories
        .filter(
          (c) => !this.categories.map((c) => c.name).includes(c.category_name)
        )
        .forEach(({ category_name, hazards }) => {
          this.categories.push(
            new Category({
              name: category_name,
              hazards: hazards.map((hazard) => {
                return {
                  name: hazard,
                };
              }),
            })
          );
        });

      this.selectedCategories = [
        ...hsCategories,
        ...deletedHsCategories.map((c) => {
          return {
            category_name: c.name,
            hazards: c.hazards.map((h) => h.name),
          };
        }),
      ]
        .sort((a, b) => a.category_name - b.category_name)
        .map(({ category_name, hazards }) => {
          const category = this.categories.find((c) => c.name == category_name);
          return {
            ...category,
            hazards: hazards
              .map((hazard_name) => {
                return category.hazards.find((h) => h.name == hazard_name);
              })
              .filter((h) => h),
            collapsed: false,
          };
        });

      this.addExistingHazards();

      // only add hazards that don't already exist in existing data.
      this.selectedCategories.forEach((category) => {
        category.hazards
          .filter((hazard) => {
            return (
              this.inputValues.findIndex(
                (iV) =>
                  iV.template_field_id == hazardField.id &&
                  iV.value == hazard.name &&
                  this.inputValues.find(
                    (catIv) =>
                      catIv.template_field_id == categoryField.id &&
                      catIv.value == category.name &&
                      catIv.template_section_index == iV.template_section_index
                  )
              ) == -1
            );
          })
          .forEach((hazard) => {
            this.addHazard({ ...hazard });
          });
      });
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  },
};
</script>
