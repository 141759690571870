import { BasemapId } from '../../../lib/olbm/layer/basemap/types';

export default {
  index: BasemapId.MAP_BOX,
  title: 'MapBox',
  subtitle: 'Low quality aerial imagery covering world',
  type: 'satellite',
  source:
    'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=pk.eyJ1IjoiZW50dWl0aXZlIiwiYSI6ImNqeXl5YjZhNzAwOGEzaHFod3ptOGJ2enMifQ.onSyhLh_3bbSVv6u-6pqyQ',
  options: {
    attributions: '© Mapbox',
    maxNativeZoom: 19,
  },
};
