export const EPSG_4326 = 'EPSG:4326';
export const EPSG_3857 = 'EPSG:3857';
export const projectionConfigs = [
  {
    code: EPSG_4326,
    name: 'WGS84',
  },
  {
    code: EPSG_3857,
    name: 'Web Mercator',
  },
];
