import { Map } from 'ol';
import { Tile as TileLayer } from 'ol/layer';
import { patchTileGrid } from '../../../measurement/scale';
import { EPSG_3857 } from '../../../projection/common';
import { createLayerProperties } from '../../utils';
import GoogleSource from './GoogleSource';
import type { GoogleMapsLayerModel } from './types';

export type Options = {};

export default function createGoogleMapsLayer(
  map: Map,
  layerModel: GoogleMapsLayerModel
) {
  const source = new GoogleSource({
    key: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
    scale: 'scaleFactor2x',
    highDpi: true,
    mapType: layerModel.geojson.properties.mapType,
  });
  source.on('change', () => {
    if (source.getState() === 'ready') {
      patchTileGrid(map, source.getTileGrid(), EPSG_3857);
    }
  });

  return new TileLayer({
    source,
    properties: createLayerProperties(
      layerModel.id,
      layerModel.geojson.properties.type
    ),
  });
}
