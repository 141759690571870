<template>
  <div v-tooltip="computedHtml" v-html="computedHtml" />
</template>

<script>
import { fieldHasTolerance, inputValuePasses } from '../../utils/tolerance';
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  computed: {
    computedHtml() {
      let html = '<div class="d-flex align-items-center">';
      if (this.field.options.prefix) {
        html += `<div class="fw-bold me-1" style="overflow: unset;">${this.field.options.prefix}</div>`;
      }
      html += `<div class="text-center">${this.value.value}</div>`;
      if (this.field.options.unit) {
        html += `<div class="fw-bold ms-1" style="overflow: unset;">${this.field.options.unit}</div>`;
      }

      if (this.hasTolerance) {
        let textColorClass = this.passesTolerance
          ? 'text-success'
          : 'text-warning';
        let iconClass = this.passesTolerance
          ? 'fa-check-circle'
          : 'fa-times-circle';
        html += `<div class="${textColorClass}"><i class="fas fa-fw ${iconClass}"></i></div>`;
      }
      return html + '</div>';
    },
    hasTolerance() {
      return fieldHasTolerance(this.field);
    },
    passesTolerance() {
      if (!this.hasTolerance) {
        return false;
      }
      return inputValuePasses(this.field, this.value, this.values);
    },
  },
};
</script>
