import { Stroke, Fill } from 'ol/style';

export default function changeTransformStyle(transform) {
  const primaryColor = [51, 102, 162];
  transform.setDefaultStyle({
    pointStroke: new Stroke({ color: [...primaryColor, 1], width: 1 }),
    stroke: new Stroke({
      color: [...primaryColor, 1],
      width: 1,
      lineDash: [4, 4],
    }),
    fill: new Fill({ color: [...primaryColor, 0.01] }),
  });
}
