import { LineString } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { TextAlignment } from '../../style/text';
import { FillStyle, OutlineStyle } from '../../style/types';
import type { ShapeProperties } from '../shape/types';
import type { LayerMixin, LayerModel, LayerType } from '../types';

export enum ConnectedTargetType {
  Sample = 'marker', // Use marker for the compatibility with the LayerType.SAMPLE
  Coordinate = 'coordinate',
}

type ConnectedTarget = {
  type: ConnectedTargetType;
  // Sample id
  id?: number;
};

export type CalloutProperties = Omit<ShapeProperties, 'type'> & {
  type: LayerType.CALL_OUT;
  title: string;
  outlineStyle: OutlineStyle;
  fillStyle: FillStyle;
  fillOpacity: number;
  opacity: number;
  color: string;
  positioning: string;
  isBuiltin: boolean;
  connectedTarget?: ConnectedTarget;
  weight: number;
  text: string;
  textColor: string;
  textOutlineColor: string;
  fontSize: number;
  alignment: TextAlignment;
  isItalic: boolean;
  isBold: boolean;
  isUnderlined: boolean;
  backgroundColor: string;
  width: number;
  height: number;
  image_data: { url?: string; isFromGather: boolean };
  mapData?: object;
  isTextUsedAsCaption: boolean;
};

export type CalloutLayerModel = LayerModel<
  CalloutProperties,
  GeoJSON.Feature<GeoJSON.Geometry, CalloutProperties>
>;

export type CalloutLayer = VectorLayer<VectorSource<LineString>> &
  LayerMixin<LineString>;
