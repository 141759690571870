<template>
  <div>
    <div v-if="field.options.type == 'image' || !field.options.type">
      <span
        v-if="hasImageDirection"
        class="badge bg-primary w-auto ms-2"
        title="Image direction"
      >
        {{ imageDirection }}
      </span>
      <a href="#" class="fw-medium" @click.prevent="viewImage">
        View
        {{
          Array.isArray(imageUrl) && imageUrl.length > 1
            ? 'Images'
            : values.length === 1 && values[0].name
            ? values[0].name
            : 'Image'
        }}
      </a>
    </div>
    <a
      v-if="field.options.type == 'audio'"
      href="#"
      class="fw-medium"
      @click.prevent="viewMedia"
    >
      Listen
    </a>
    <a
      v-if="field.options.type == 'video'"
      href="#"
      class="fw-medium"
      @click.prevent="viewMedia"
    >
      Watch
    </a>
  </div>
</template>

<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  computed: {
    imageUrl() {
      const { has_multiple } = this.field.options;
      let { value: url } = this.value;
      if (Array.isArray(url) && url.length && !has_multiple) {
        url = url.slice(0, 1);
      }
      return url;
    },
    hasImageDirection() {
      const { has_multiple, track_direction } = this.field.options;
      return !has_multiple && track_direction;
    },
    imageDirection() {
      return this.hasImageDirection
        ? this.value.options?.direction ?? 'N'
        : null;
    },
  },
  methods: {
    viewImage() {
      this.$emit('viewImage', this.imageUrl);
    },
    viewMedia() {
      this.$emit('viewMedia', {
        src: this.value.value,
        type: this.field.options.type,
      });
    },
  },
};
</script>
