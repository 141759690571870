<script lang="ts" setup>
import { computed } from 'vue';
import moment from 'moment';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import AdminAppEditor from './AdminAppEditor.vue';
import ChildAppList from './ChildAppList.vue';
import {
  getAppIcon,
  getAppShareGroupTitle,
  getAppShareGroupDescription,
  getAppCreator,
} from '@component-library/business-logic/app';
import { isCompanyManager } from '@component-library/company';
import useAuth from '@component-library/composables/useAuth';
import { App } from '@component-library/gather';
import AlertBox from '@component-library/components/AlertBox.vue';

const auth = useAuth();
const props = defineProps<{
  app: App;
  selectedApps: App[];
  canCreateApp: boolean;
  importing: boolean;
}>();

const hasImportedApp = computed(() => {
  let id = props.app.id;
  if (props.app.share_group && props.app.template_tab) {
    id = props.app.template_tab.id;
  }

  return (
    props.selectedApps.findIndex(
      (selectedApp) => selectedApp.id == id || selectedApp.cloned_from_id == id
    ) != -1
  );
});

const canEditApp = computed(() => {
  if (props.app.custom_template_id) {
    return false;
  }

  const user = auth.user();
  if (user.role === 'admin') {
    return true;
  }
  if (!user.company) {
    return false;
  }
  return isCompanyManager(user);
});

const emit = defineEmits(['importApp', 'getApps']);

function importApp() {
  emit('importApp', props.app.share_group);
}
</script>

<template>
  <div>
    <h6>{{ getAppShareGroupTitle(app) }}</h6>

    <img
      v-if="getAppIcon(app)"
      :src="getAppIcon(app)"
      class="mb-3"
      style="height: 64px; width: 64px"
    />

    <div v-if="getAppShareGroupDescription(app)">
      <b class="d-block">Description</b>
      <p>
        {{ getAppShareGroupDescription(app) }}
      </p>
    </div>
    <div v-if="getAppCreator(app)">
      <b>Designed By:</b>
      <span class="ms-1"> {{ getAppCreator(app) }}</span>
    </div>
    <div>
      <b>Last Update:</b>
      <span class="ms-1">{{
        moment.utc(app.updated_at).local().format('DD-MM-YYYY hh:mm A')
      }}</span>
    </div>

    <ChildAppList title="Apps to import:" :app="app" />

    <ButtonSpinner
      class="btn btn-primary w-100 p-3 fw-bold mt-3"
      :is-loading="importing"
      v-if="canCreateApp"
      @click.native.prevent="importApp"
    >
      <i class="fas fa-plus fa-fw" /> Import into project
    </ButtonSpinner>
    <AlertBox v-else type="warning" class="mt-3">
      You do not have permission to import apps.
    </AlertBox>
    <ButtonSpinner
      v-if="hasImportedApp"
      class="btn w-100 p-3 fw-bold mt-3 btn-outline-danger disabled"
    >
      <i class="fas fa-exclamation-triangle fa-fw" /> This app is already in
      your project.
    </ButtonSpinner>

    <AdminAppEditor v-if="canEditApp" :app="app" @getApps="emit('getApps')" />
  </div>
</template>
