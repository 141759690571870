<template>
  <div class="position-relative" v-click-outside="hideResults">
    <form class="input-group" @submit.prevent>
      <span class="input-group-text" v-if="prepend"
        ><i class="fas fa-search"></i
      ></span>

      <input
        type="text"
        class="form-control"
        :placeholder="placeholder"
        v-model.trim="query"
        @keyup="keyMonitor($event)"
        :class="{ 'is-invalid': error != null }"
        :disabled="disableSearch || disabled"
        autocomplete="off"
      />

      <button
        class="btn btn-outline-danger"
        data-cy="remove-projectAddress"
        type="button"
        @click="removeSelection"
        v-if="disableSearch && !isLoading"
      >
        <i class="fas fa-times"></i>
      </button>
      <div
        class="invalid-feedback"
        data-cy="project-address-required"
        v-if="error != null"
      >
        {{ error[0] }}
      </div>
    </form>

    <template v-if="results.length || isLoading">
      <div class="dropdown-menu autocomplete-menu show w-100">
        <template v-if="isLoading">
          <div class="dropdown-item text-center">
            <Spinner small />
          </div>
        </template>
        <template v-else>
          <div
            class="dropdown-item"
            v-for="(result, rIndex) in results"
            @click="selectResult(result, true)"
            :key="rIndex"
            data-cy="dropdown-items"
            :class="{
              'bg-danger text-white': result['style'] == 'danger',
            }"
          >
            {{ getDisplayValue(result, displayIndex) }}
            <template v-if="subIndex">
              <br />
              <small
                :class="{
                  'text-white': result['style'] == 'danger',
                  'text-secondary': !result['style'],
                }"
              >
                {{ getDisplayValue(result, subIndex) | strippedContent }}
              </small>
            </template>
          </div>
        </template>
      </div>
    </template>
    <template
      v-else-if="!isLoading && dataLoaded && query && results.length == 0"
    >
      <div class="dropdown-menu autocomplete-menu show w-100">
        <div class="dropdown-item">No results found.</div>
      </div>
    </template>
  </div>
</template>

<script>
import Spinner from '@component-library/components/Spinner.vue';
import _debounce from 'lodash/debounce';

export default {
  name: 'Autocomplete',
  components: { Spinner },
  props: [
    'queryUrl',
    'placeholder',
    'previousValue',
    'disabled',
    'container',
    'displayIndex',
    'subIndex',
    'error',
    'dontDisable',
    'prepend',
    'clearOnClick',
    'selectOnExit',
  ],
  data: () => ({
    query: null,
    disableSearch: false,
    isLoading: false,
    results: [],
    dataLoaded: false,
  }),
  filters: {
    strippedContent(string) {
      return string.replace(/<\/?[^>]+>/gi, ' ');
    },
  },
  watch: {
    previousValue(newVal) {
      if (newVal != null && !this.dontDisable) {
        this.disableSearch = true;
      }

      if (!newVal && !this.dontDisable) {
        this.disableSearch = false;
      }

      this.query = newVal;

      this.$emit('changeValue', {
        value: newVal,
      });
    },
  },
  methods: {
    hideResults() {
      this.dataLoaded = false;

      if (this.results.length == 0) {
        return;
      }

      if (this.selectOnExit) {
        this.selectResult(this.results[0], false);
      }

      this.results = [];
    },
    getDisplayValue(item, index) {
      return item[index];
    },
    autoComplete: _debounce(function () {
      this.$emit('changeValue', {
        value: this.query,
      });

      if (this.query != null && this.query.length > 1) {
        axios
          .get(this.queryUrl, {
            params: {
              q: this.query,
            },
          })
          .then((response) => {
            this.isLoading = false;
            let data = response.data;

            if (this.container != null) {
              data = data[this.container];
            }

            this.results = data;
            this.dataLoaded = true;
          });
        return;
      }

      this.results = [];
      this.dataLoaded = false;
      this.isLoading = false;
    }, 800),
    selectResult(result, hideResults) {
      if (!this.dontDisable) {
        this.disableSearch = true;
      }

      this.$emit('changeValue', {
        value: this.getDisplayValue(result, this.displayIndex),
        data: result,
      });

      this.query = this.getDisplayValue(result, this.displayIndex);

      if (this.clearOnClick) this.query = '';

      if (hideResults) {
        this.hideResults();
      }
    },
    removeSelection() {
      this.disableSearch = false;

      this.$emit('changeValue', {
        value: null,
      });

      this.query = null;
    },
    keyMonitor(event) {
      this.dataLoaded = false;

      if (event.key === 'Enter' && this.results.length > 0) {
        this.selectResult(this.results[0], true);
      } else {
        this.isLoading = true;

        this.autoComplete(event);
      }
    },
  },
  mounted() {
    if (!this.previousValue) {
      return;
    }

    this.query = this.previousValue;

    this.$emit('changeValue', {
      value: this.previousValue,
    });

    this.disableSearch = true;
  },
};
</script>
<style scoped>
.auto-complete-loader {
  position: absolute;
  right: 10px;
  top: 0px;
  bottom: 0px;
  z-index: 9999;
}
</style>
