import { Feature, Map } from 'ol';
import { Style, Text } from 'ol/style';
import type { Shape } from '../layer/shape/types';
import { formatArea, getArea } from '../measurement/area';
import { getLayoutZoom } from '../measurement/layout';
import { getProjectUnit } from '../measurement/utils';
import { getMeasurementStyleOptions } from './text';

export default function createAreaStyle(
  map: Map,
  feature: Feature<Shape>,
  isTemporary: boolean
) {
  const shape = feature.getGeometry()!;
  return new Style({
    text: new Text({
      text: formatArea(getArea(map, shape), getProjectUnit(map)),
      overflow: true,
      ...getMeasurementStyleOptions(getLayoutZoom(map), isTemporary),
    }),
    zIndex: 1,
  });
}
