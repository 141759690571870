import type { Country, CountryState } from '../../common/address';
import type {
  ImageServerFolderLayerModel,
  MapServerFolderLayerModel,
  VectorTileServerFolderLayerModel,
} from '../service/esri/types';
import type { GoogleMapsLayerModel } from '../service/google/types';
import type { WmtsFolderLayerModel } from '../service/ogc-opengis/types';
import type { XyzFolderLayerModel } from '../service/types';
import type { LayerModel } from '../types';

export enum BasemapId {
  OPEN_STREET_MAP = 0,
  NEARMAP = 1,
  LINZ = 2,
  MAP_BOX = 3,
  GOOGLE_MAPS_ROADMAP = 4,
  AU_VIC_CARTOGRAPHIC = 5,
  AU_VIC_OVERLAY = 6,
  AU_VIC_AERIAL = 7,
  AU_NSW_AERIAL = 8,
  AU_QLD_AERIAL = 9,
  AU_SA_AERIAL = 10,
  AU_WA_AERIAL = 11,
  METROMAP = 12,
  GB_BRISTOL_AND_SURROUNDING_AREA = 13,
  GB_OS_MAPS_ROAD = 14,
  CA_TOPOGRAPHIC = 15,
  GB_OS_VECTOR = 16,
  GOOGLE_MAPS_SATELLITE = 17,
}

export type AbstractBasemapApi<T extends LayerModel> = {
  id: BasemapId;
  title: string;
  subtitle: string;
  type: string;
  host?: string;
  countries?: Country[];
  states?: CountryState[];
  layerModel: T;
};

export type GoogleMapsApi = AbstractBasemapApi<GoogleMapsLayerModel>;

export type BasemapMapServerApi = AbstractBasemapApi<MapServerFolderLayerModel>;

export type BasemapImageServerApi =
  AbstractBasemapApi<ImageServerFolderLayerModel>;

export type BasemapVectorTileServerApi =
  AbstractBasemapApi<VectorTileServerFolderLayerModel>;

export type BasemapXyzApi = AbstractBasemapApi<XyzFolderLayerModel>;

export type BasemapWmtsApi = AbstractBasemapApi<WmtsFolderLayerModel>;

export type BasemapApi =
  | GoogleMapsApi
  | BasemapMapServerApi
  | BasemapImageServerApi
  | BasemapVectorTileServerApi
  | BasemapXyzApi
  | BasemapWmtsApi;

export type PreprocessBasemapApi = (basemapApi: BasemapApi) => BasemapApi;

export enum BasemapStatus {
  Available = 1,
  IntegrationNotReady,
  IntegrationDisabled,
  AccessForbidden,
}
