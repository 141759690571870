import { Company } from './company';
import { ProjectMemberPermission } from './company-role-profile';
import type { User } from './user';

type UUID = string;

export type ProjectIDOrUUID = number | UUID;

/**
 * ISO 3166-1 alpha-2 country code
 */
export type Country2CharCode = 'NZ' | 'GB' | 'US' | 'AU' | 'CA';

export interface Address {
  full_address: string;
  latitude: string;
  longitude: string;
  google_place_id: null | string;

  address_street: string;
  address_locality: string;
  address_city: string;
  address_state: string;
  address_country: string;
  address_postcode: string;
}

export interface Project {
  project_id: number;
  uuid: UUID;
  project_name: string;
  project_number: string;
  project_client: string;
  project_address: string;
  storage_usage: number;
  project_user?: Partial<User>;
  available_job_numbers_count: number;
  dashboard_view: DashboardView;

  users?: User[];

  archived: boolean;
  is_confidential: boolean;
  is_confirmed?: boolean;

  tab_logs_project_number?: string | null;
  google_place_id: string | null;
  address_street: string | null;
  address_locality: string | null;
  address_city: string | null;
  address_state: string | null;
  address_country: Country2CharCode | null;
  address_postcode: string | null;

  timezone: string;

  user_id: number | null;
  company_id: number | null;
  company?:
  | Company
  | {
    id: number;
    company_name: string;
    enad_ref: string;
  }
  | null;
  project_type: ProjectType;
  latitude: number | null;
  longitude: number | null;
  location?: Location;
  measurement_type: string;
  lab_access_token?: null | string;

  favourite_count?: number;
  session_role_permissions?: ProjectMemberPermission[];
  workflow_id: number;
  importing_workflow_at?: string;

  has_chemical_misalignment: boolean;
  has_templates?: boolean;
  scenario_set_id: number;

  map_color_palette?: string[] | null;
  updated_at: string;
}

export interface ProjectWithUsers extends Project {
  users?: User[];
}

export enum DashboardView {
  ENVIRO_VIEW,
  GATHER_VIEW,
  INSIGHTS_VIEW,
}

export enum ProjectType {
  ENVIRO,
  STANDARD,
}

export interface Location {
  country: Country;
  state: null | string;
}

export type Country = 'US' | 'NZ' | 'AU' | 'GB' | 'CA';

export function getCountry(project: Project): Country {
  return project.location?.country ?? 'NZ';
}

export const PROJECT_FILTERS = [
  'my_projects',
  'company_projects',
  'my_favourites',
  'needs_attention',
  'unassigned', // Feature flag: PROJECT_FILTER_ASSIGNMENTS
  'assigned', // Feature flag: PROJECT_FILTER_ASSIGNMENTS
] as const;

export type ProjectFilterKey = (typeof PROJECT_FILTERS)[number];
