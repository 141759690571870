import { Feature, Map } from 'ol';
import { LineString } from 'ol/geom';
import { Stroke, Style } from 'ol/style';
import ArrowHead from '../layer/shape/ArrowHead';
import { isFirstSegment, isLastSegment } from '../layer/shape/polyline';

export default function createArrowStyle(
  map: Map,
  feature: Feature<LineString>,
  headStroke: Stroke,
  weight: number,
  multiDirectional = false
) {
  const arrowStyle: Style[] = [];
  const polyline = feature.getGeometry()!;
  polyline.forEachSegment(function (start, end) {
    if (isLastSegment(polyline, { end })) {
      const arrowHead = new ArrowHead(map, start, end, polyline, weight);
      const [arrowHeadLine1, arrowHeadLine2] = arrowHead.getLines(map);
      arrowStyle.push(
        new Style({
          geometry: arrowHeadLine1,
          stroke: headStroke,
        })
      );
      arrowStyle.push(
        new Style({
          geometry: arrowHeadLine2,
          stroke: headStroke,
        })
      );
    }

    if (multiDirectional && isFirstSegment(polyline, { start })) {
      const arrowHead = new ArrowHead(map, end, start, polyline, weight);
      const [arrowHeadLine1, arrowHeadLine2] = arrowHead.getLines(map);

      arrowStyle.push(
        new Style({
          geometry: arrowHeadLine1,
          stroke: headStroke,
        })
      );
      arrowStyle.push(
        new Style({
          geometry: arrowHeadLine2,
          stroke: headStroke,
        })
      );
    }
  });

  return arrowStyle;
}
