import { computed, ref, watch } from 'vue';
import EventBus from '../EventBus';
import axios from 'axios';
import downloadByHref from '../utils/download';
import { File, Directory } from '../file-utils';
import { useHistoryModalStore } from './files-history-modal';
import { useToastStore } from '../store/toasts';
import { useRouter } from 'vue-router/composables';
import { defineStore } from 'pinia';
import { Paginated } from '../utils';
import ContextMenu from '../components/ContextMenu.vue';
import { useDialogStore } from './dialog';

let toastStore: ReturnType<typeof useToastStore>;

function toast() {
  if (!toastStore) {
    toastStore = useToastStore();
  }
  return toastStore;
}

export const useFilesStore = defineStore('files', () => {
  const dialogStore = useDialogStore();
  const router = useRouter();

  const workingDirectory = ref<string>('');
  const workingDirectoryFiles = ref<Paginated<File> | null>(null);
  const cachedFiles = ref<Map<string, File>>(new Map());
  const showHiddenFiles = ref<boolean>(false);
  const isLoadingFiles = ref<boolean>(false);
  const browserDialogShow = ref(false);
  const browserDialogExtension = ref<string | null>(null);
  const browserDialogMode = ref<
    'upload' | 'create' | 'move' | 'select' | 'finalize'
  >('upload');
  const browserDialogNewFileName = ref<string>();
  const browserDialogFile = ref<File | null>(null);
  const browserDialogDirectory = ref<Directory | null>(null);
  const directoryActionDialogShow = ref(false);
  const directoryActionDialogAction = ref<'download' | 'delete'>('download');
  const directoryActionDialogDirectory = ref<Directory | null>(null);
  const contextOptions = ref<(ContextMenuItem | ContextMenuDivider)[]>([]);
  const contextMenu = ref<typeof ContextMenu | null>(null);
  type FileStoreUploadMode = 'file' | 'folder';
  const fileStoreUploadMode = ref<FileStoreUploadMode>('file');

  function openFileContextMenu(file, event: Event) {
    if (contextMenu.value) {
      contextOptions.value = getFileContextMenu(file);
      contextMenu.value.open(event, file);
    }
  }

  function openDirectoryContextMenu(directory: Directory, event: Event) {
    if (contextMenu.value) {
      contextOptions.value = getDirectoryContextMenu(directory);
      contextMenu.value.open(event, directory);
    }
  }

  EventBus.on('fileDeleted', refresh);
  EventBus.on('fileUpdated', refresh);
  watch(workingDirectory, refresh);

  function setWorkingDirectory(newDirectory: string): void {
    workingDirectory.value = trimSlashes(newDirectory);
    const url = new URL(window.location.href);
    url.searchParams.set('path', workingDirectory.value);
    window.history.pushState({}, '', url.toString());
  }
  function setFileStoreAccessMode(mode: FileStoreUploadMode): void {
    fileStoreUploadMode.value = mode;
  }

  function getWorkingDirectoryCrumbs(): string[] {
    return getCrumbs(workingDirectory.value);
  }

  function getCrumbs(directoryFullPath: string): string[] {
    return trimSlashes(directoryFullPath).split('/');
  }

  function goUp(levels = 1): void {
    let split = workingDirectory.value.split('/');
    if (split.length == 1) {
      setWorkingDirectory('');
      return;
    }
    setWorkingDirectory(split.slice(0, split.length - levels).join('/'));

    if (levels > 1) {
      goUp(levels - 1);
    }
  }

  async function openFile(file: File, newTab = false): Promise<void> {
    if (isVirus(file)) {
      toast().error('Cannot open this file as it may contain a virus');
      return;
    }
    if (!isFileOpenable(file)) {
      return downloadFile(file);
    }

    if (newTab) {
      window.open('/file/' + file.id, '_blank');
      return;
    }

    router.push('/file/' + file.id);
  }

  function openDirectory(directory: Directory, newTab = false): void {
    if (newTab) {
      window.open('/files?path=' + directory.path, '_blank');
      return;
    }
    setWorkingDirectory(directory.path);
  }

  function createEmptyFolder() {
    browserDialogExtension.value = null;
    browserDialogMode.value = 'create';
    browserDialogShow.value = true;
    browserDialogFile.value = null;
    browserDialogNewFileName.value = undefined;
    browserDialogDirectory.value = {
      display_name: '',
      path: workingDirectory.value,
    };
  }

  async function downloadDirectory(directory: Directory) {
    directoryActionDialogShow.value = true;
    directoryActionDialogAction.value = 'download';
    directoryActionDialogDirectory.value = directory;
  }

  async function deleteDirectory(directory: Directory) {
    directoryActionDialogShow.value = true;
    directoryActionDialogAction.value = 'delete';
    directoryActionDialogDirectory.value = directory;
  }

  async function renameOrMove(file: Directory | File) {
    browserDialogShow.value = true;
    const nameParts = file.display_name.split('.');
    browserDialogExtension.value =
      nameParts.length > 1 ? nameParts.pop() ?? null : null;
    browserDialogMode.value = 'move';
    browserDialogNewFileName.value = undefined;
    browserDialogFile.value = 'id' in file ? file : null;
    browserDialogDirectory.value = !browserDialogFile.value ? file : null;
  }

  async function finalize(file: Directory | File) {
    browserDialogShow.value = true;
    const nameParts = file.display_name.split('.');
    browserDialogMode.value = 'finalize';
    browserDialogNewFileName.value =
      nameParts.slice(0, nameParts.length - 1) + ' (Final)';
    browserDialogExtension.value = 'pdf';
    browserDialogFile.value = 'id' in file ? file : null;
    browserDialogDirectory.value = !browserDialogFile.value ? file : null;
  }

  function openUploadModal() {
    browserDialogExtension.value = null;
    browserDialogMode.value = 'upload';
    browserDialogNewFileName.value = undefined;
    browserDialogShow.value = true;
  }

  function createNewFile(extension) {
    browserDialogExtension.value = extension;
    browserDialogMode.value = 'create';
    browserDialogNewFileName.value = undefined;
    browserDialogShow.value = true;
  }

  function closeDialog() {
    browserDialogShow.value = false;
    browserDialogNewFileName.value = undefined;
    browserDialogExtension.value = null;
    directoryActionDialogShow.value = false;
  }

  const noFiles = computed(() => {
    return (
      !workingDirectoryFiles.value?.data?.length &&
      (workingDirectory.value === '' ||
        workingDirectory.value === '/' ||
        workingDirectory.value === null)
    );
  });

  async function loadFiles(page = 1, search = '') {
    isLoadingFiles.value = true;
    const response = await axios.get('/files', {
      params: { page, search, dir: workingDirectory.value },
    });

    if (!workingDirectoryFiles.value) {
      workingDirectoryFiles.value = response.data.files;
    } else {
      workingDirectoryFiles.value.current_page =
        response.data.files.current_page;
      workingDirectoryFiles.value.data = [
        ...workingDirectoryFiles.value.data.filter(
          (f) => !response.data.files.data.some((f2) => f2.id === f.id)
        ),
        ...response.data.files.data,
      ];
    }

    if (response.data.files.last_page > response.data.files.current_page) {
      return await loadFiles(response.data.files.current_page + 1, search);
    }
    isLoadingFiles.value = false;
  }

  function refresh() {
    workingDirectoryFiles.value = null;
    loadFiles(1);
  }

  async function loadDirectoryStats(directory: Directory) {
    const response = await axios.get('/file/directory-stats', {
      params: { path: directory.path },
    });
    return response.data;
  }

  async function zipDirectoryAction(
    directory: Directory,
    flatten: boolean = false
  ) {
    const response = await axios.post('/file/directory-zip', {
      path: directory.path,
      flatten,
    });
    EventBus.$emit('openNotificationDropdown');
    return response.data;
  }

  async function deleteDirectoryAction(directory: Directory) {
    const response = await axios.delete('/file/directory-delete', {
      params: { path: directory.path },
    });
    EventBus.$emit('fileDeleted');
    return response.data;
  }

  function addFile(file: File) {
    if (!workingDirectoryFiles.value) {
      throw new Error('Files not loaded yet');
    }
    workingDirectoryFiles.value.data.push(file);
  }

  function updateFile(file: File) {
    if (!workingDirectoryFiles.value) {
      throw new Error('Files not loaded yet');
    }
    const index = workingDirectoryFiles.value.data.findIndex(
      (f) => f.id === file.id
    );
    if (index !== -1) {
      workingDirectoryFiles.value.data.splice(index, 1);
    }
    workingDirectoryFiles.value.data.push(file);
  }

  function copyFileLink(file: File) {
    const projectUrl = `${window.location.origin}/open-project/${file.project_id}`;
    const url =
      projectUrl + '?redirect=' + encodeURIComponent('/file/' + file.id);
    navigator.clipboard.writeText(url);
    toast().success('Copied file link to clipboard');
  }

  function copyDirectoryLink(directory: Directory) {
    const projectUrl = `${window.location.origin}/open-project/${directory.project_id}`;
    const url =
      projectUrl +
      '?redirect=' +
      encodeURIComponent('/files?path=' + directory.path);
    navigator.clipboard.writeText(url);
    toast().success('Copied folder link to clipboard');
  }

  async function downloadFile(file: File): Promise<void> {
    if (isVirus(file)) {
      toast().error('Cannot download this file as it may contain a virus');
      return;
    }

    const fileInfo = await getFileInfo(file);
    if (!fileInfo?.temporary_url) {
      toast().error('This file is not available for download');
      return;
    }
    downloadByHref(fileInfo.temporary_url, fileInfo.display_name);
  }

  function canFinalize(file: File): boolean {
    const extension = getExtension(file.display_name);
    return (
      wordDocumentExtensions.includes(('.' + extension) as any) &&
      canRename(file)
    );
  }

  function canRename(file: File) {
    return !file.is_final;
  }

  function getFileContextMenu(
    file: File
  ): (ContextMenuItem | ContextMenuDivider)[] {
    const contextMenu: (ContextMenuItem | ContextMenuDivider)[] = [
      {
        label: 'Open',
        click: (file: File) => openFile(file),
        canUse: (file: File) => isFileOpenable(file),
      },
      {
        label: 'Open in new window',
        click: (file: File) => openFile(file, true),
        canUse: (file: File) => isFileOpenable(file),
      },
      {
        label: 'Copy Share Link',
        click: (file: File) => copyFileLink(file),
        canUse: (file: File) => isFileOpenable(file),
      },
      {
        label: 'Download',
        click: (file: File) => downloadFile(file),
        canUse: (file: File) => canDownload(file),
      },
      {
        label: 'Finalize Report',
        click: (file: Directory) => finalize(file),
        canUse: (file: File) => canFinalize(file),
      },
      {
        label: 'Scan for Viruses',
        canUse: (file: File) => canRescan(file),
        click: (file: File) => scanFile(file),
      },
      {
        label: 'Version History',
        click: openHistoryModal,
        canUse: canSeeHistory,
      },
    ];
    if (canRename(file)) {
      contextMenu.push({
        label: 'Rename or Move',
        click: (file: File) => renameOrMove(file),
        canUse: canRename,
      });
    }
    contextMenu.push({ type: 'divider' });
    contextMenu.push({
      label: 'Delete',
      danger: true,
      click: (file: File) => {
        dialogStore.confirmDanger(
          'Delete File',
          'Are you sure you want to delete ' + file.display_name + '?',
          () => deleteFile(file)
        );
      },
    });

    return contextMenu;
  }

  function getDirectoryContextMenu(
    directory: Directory
  ): (ContextMenuItem | ContextMenuDivider)[] {
    const contextMenu: (ContextMenuItem | ContextMenuDivider)[] = [
      {
        label: 'Open',
        click: (file: Directory) => openDirectory(file),
      },
      {
        label: 'Open in new window',
        click: (file: Directory) => openDirectory(file, true),
      },
      {
        label: 'Copy Share Link',
        click: (file: Directory) => copyDirectoryLink(file),
      },
      {
        label: 'Download as Zip',
        click: (file: Directory) => downloadDirectory(file),
      },
    ];
    if (!doesDirectoryContainNonReNameableFile(directory)) {
      contextMenu.push({
        label: 'Rename or Move',
        click: (file: Directory) => renameOrMove(file),
      });
    }
    contextMenu.push({ type: 'divider' });
    contextMenu.push({
      label: 'Delete',
      danger: true,
      click: (file: File) => deleteDirectory(file),
    });
    return contextMenu;
  }

  function toggleHiddenFiles(): void {
    showHiddenFiles.value = !showHiddenFiles.value;
  }

  function doesDirectoryContainNonReNameableFile(
    directory: Directory
  ): boolean {
    return (
      workingDirectoryFiles.value?.data.some((file) => {
        return file.path.startsWith(directory.path) && !canRename(file);
      }) ?? false
    );
  }

  function getCurrentFilesFromAllFiles(files: File[]): File[] {
    return files.filter((f: File) => {
      if (
        (!showHiddenFiles.value && isHiddenFile(f)) ||
        f.display_name === 'README.md'
      ) {
        return false;
      }
      return trimSlashes(f.path || '') === workingDirectory.value;
    });
  }

  function getCurrentDirectoriesFromFiles(files: File[]): Directory[] {
    const dirs: Directory[] = [];
    files.filter((f: File) => {
      if (f.path === null) {
        return false;
      }
      if (!showHiddenFiles.value && isHiddenFile(f)) {
        return false;
      }
      if (
        f.path !== workingDirectory.value &&
        f.path.startsWith(workingDirectory.value)
      ) {
        const dirName = trimSlashes(
          f.path.replace(workingDirectory.value, '')
        ).split('/')[0];
        if (dirName.length === 0) {
          return;
        }
        if (dirs.some((d) => d.display_name === dirName)) {
          return;
        }
        dirs.push({
          project_id: f.project_id,
          display_name: dirName,
          path: trimSlashes(workingDirectory.value + '/' + dirName),
        });
      }
    });

    return dirs;
  }

  return {
    // Refs
    workingDirectory,
    workingDirectoryFiles,
    showHiddenFiles,
    isLoadingFiles,
    contextOptions,
    contextMenu,
    browserDialogShow,
    browserDialogMode,
    browserDialogNewFileName,
    browserDialogFile,
    browserDialogDirectory,
    browserDialogExtension,
    directoryActionDialogShow,
    directoryActionDialogAction,
    directoryActionDialogDirectory,
    fileStoreUploadMode,

    // Computed
    noFiles,

    // Methods
    openFileContextMenu,
    openDirectory,
    openDirectoryContextMenu,
    openUploadModal,
    addFile,
    updateFile,
    createNewFile,
    createEmptyFolder,
    closeDialog,
    loadDirectoryStats,
    zipDirectoryAction,
    deleteDirectoryAction,
    loadFiles,
    refresh,
    renameOrMove,
    setWorkingDirectory,
    getWorkingDirectoryCrumbs,
    getCrumbs,
    goUp,
    openFile,
    downloadFile,
    getFileContextMenu,
    getDirectoryContextMenu,
    toggleHiddenFiles,
    getCurrentFilesFromAllFiles,
    getCurrentDirectoriesFromFiles,
    setFileStoreAccessMode,
  };
});

export function hasHiddenFiles(files: File[]): boolean {
  return files.some((file) => file.display_name.endsWith('.json'));
}

type FileOrId = (Partial<File> & { id: string }) | string;

export async function getFileInfo(file: FileOrId): Promise<File | undefined> {
  const fileId = typeof file === 'string' ? file : file.id;
  const response = await axios.get('/file/' + fileId);

  return response.data.file;
}

export async function loadJsonFile(file: FileOrId): Promise<any> {
  const fileId = typeof file === 'string' ? file : file.id;
  const response = await axios.get('/file/' + fileId + '/json');
  return response.data;
}

export async function scanFile(file: File): Promise<any> {
  const response = await axios.post('/file/' + file.id + '/scan');

  EventBus.emit('fileUpdated', response.data.file);
  return response;
}

export async function deleteFile(file: File): Promise<any> {
  let response;
  try {
    response = await axios.delete('/file/' + file.id);
  } catch (e: any) {
    if (e.response?.status === 422) {
      toast().error(e.response.data.message ?? 'Unable to delete file');
      return;
    }
    toast().unexpected();
    return;
  }

  EventBus.emit('fileDeleted', file.id);
  return response;
}

export function openHistoryModal(file: File) {
  const historyModalStore = useHistoryModalStore();
  historyModalStore.openHistoryModal(file);
}

function canRescan(file: File): boolean {
  if (file.virus_status === VIRUS_STATUS_UNABLE) {
    return true;
  }

  return false;
}

function canDownload(file: File): boolean {
  return !isVirus(file);
}

function canSeeHistory(file: File) {
  return file.version > 1;
}

function isVirus(file: File): boolean {
  return file.virus_status === VIRUS_STATUS_FAILED;
}

export const copyFile = notImplemented;
export const moveFile = notImplemented;

export interface ContextMenuItem {
  label: string;
  danger?: boolean;
  disabled?: (file: File) => boolean;
  click: (file: File) => void;
  canUse?: (file: File) => boolean;
}

export interface ContextMenuDivider {
  type?: 'divider';
}

function notImplemented(file: File): void {
  alert('Not yet implemented');
}

export const chevronIconHtml =
  ' <i class="fal fa-chevron-right mx-2 fa-fw"></i> ';

export function formatPath(path: string, trailingChevron = false): string {
  if (!path) {
    return '';
  }
  return (
    path.replace(/\/$/, '').split('/').join(chevronIconHtml) +
    (trailingChevron ? chevronIconHtml : ' ')
  );
}

export function formatFileSize(fileOrMBs: File | number) {
  if (typeof fileOrMBs === 'number') {
    return formatBytes(fileOrMBs * 1024 * 1024);
  }

  return formatBytes(fileOrMBs.size_mb * 1024 * 1024);
}

export const getUploadIconFromType = (type: string) => {
  switch (type) {
    case 'xlsx':
    case 'xls':
    case 'csv':
      return 'fa-file-excel';
    case 'png':
    case 'jpg':
    case 'jpeg':
      return 'fa-file-image';
    case 'zip':
      return 'fa-file-archive';
    case 'pdf':
    case 'application/pdf':
      return 'fa-file-pdf';
  }

  return 'fa-file';
};

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function trimSlashes(str: string): string {
  return str.replace(/^\/|\/$/g, '');
}

export function getExtension(fileName: string): string {
  const fileParts = fileName.split('.');
  const extension = fileParts[fileParts.length - 1];
  return extension.toLowerCase();
}

export function filterBySearch(
  searchQuery: string | null
): (a: File | Directory) => boolean {
  if (!searchQuery || searchQuery.trim() === '') {
    return () => true;
  }
  return (a: File | Directory) => {
    return (
      a.display_name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
    );
  };
}

export const VIRUS_STATUS_PENDING = 0; // Not yet scanned
export const VIRUS_STATUS_PASSED = 1; // No known virus found
export const VIRUS_STATUS_FAILED = 2; // Contains a potential virus
export const VIRUS_STATUS_UNABLE = 3; // AntiVirus service was not available
export const VIRUS_STATUS_SKIP = 4; // System files, trusted, no need to scan

export function getFileVirusDisplayStatus(file: File): string {
  return (
    [
      'Pending',
      'Passed',
      'Contains potential virus!',
      'Not scanned',
      'Skipped',
    ][file.virus_status] || 'Unknown'
  );
}
export function getFileVirusIcon(file: File): string | null {
  return (
    [
      'fa-spinner fa-spin text-warning', // pending
      'fa-check text-success', // passed
      'fa-exclamation-triangle text-danger', // failed, may contain virus
      'fa-exclamation-triangle text-warning', // unable
      null, // skipped
    ][file.virus_status] || null
  );
}

export function getFileIcon(file: File): string {
  const extension = getExtension(file.display_name);

  return (
    {
      xlsx: 'fad fa-file-excel',
      xls: 'fad fa-file-excel',
      csv: 'fad fa-file-csv',
      docx: 'fad fa-file-word',
      doc: 'fad fa-file-word',
      txt: 'fal fa-file-alt',
      log: 'fal fa-file-alt',
      xml: 'fal fa-file-alt',
      svg: 'fad fa-file-image',
      png: 'fad fa-file-image',
      jpg: 'fad fa-file-image',
      jpeg: 'fad fa-file-image',
      bmp: 'fad fa-file-image',
      webp: 'fad fa-file-image',
      gif: 'fad fa-file-image',
      tif: 'fad fa-file-image',
      tiff: 'fad fa-file-image',
      ovr: 'fad fa-file-image',
      pdf: 'fad fa-file-pdf',
      json: 'fal fa-file-code',
      geojson: 'fad fa-globe',
      shp: 'fad fa-globe',
      shx: 'fad fa-globe',
      dbf: 'fad fa-globe',
      gml: 'fad fa-globe',
      kml: 'fad fa-globe',
      kmz: 'fad fa-globe',
      gpx: 'fad fa-globe',
      vct: 'fad fa-globe',
      vdc: 'fad fa-globe',
      map: 'fad fa-globe',
      osm: 'fad fa-globe',
      dlg: 'fad fa-globe',
      gdb: 'fad fa-globe',
      mdb: 'fad fa-globe',
      gpkg: 'fad fa-globe',
      mbtiles: 'fad fa-globe',
      qgs: 'fad fa-globe',
      qgz: 'fad fa-globe',
      aprx: 'fad fa-globe',
      '3dd': 'fad fa-globe',
      sxd: 'fad fa-globe',
      qml: 'fad fa-globe',
      mpk: 'fad fa-globe',
      dae: 'fal fa-cube',
      obj: 'fal fa-cube',
      fbx: 'fal fa-cube',
      glb: 'fal fa-cube',
      '3ds': 'fal fa-cube',
      gltf: 'fal fa-cube',
      usdz: 'fal fa-cube',
      blend: 'fal fa-cube',
      c4d: 'fal fa-cube',
      mesh: 'fal fa-cube',
      zip: 'fad fa-file-archive',
      mp4: 'fad fa-file-video',
      ogv: 'fad fa-file-video',
      webm: 'fad fa-file-video',
      mkv: 'fad fa-file-video',
    }[extension] || 'fal fa-file'
  );
}

export function getFileIconColor(file: File): string {
  const extension = getExtension(file.display_name);

  return (
    {
      xlsx: 'text-success',
      xls: 'text-success',
      csv: 'text-success',
      docx: 'text-info',
      doc: 'text-info',
      txt: 'text-info',
      png: 'text-info',
      svg: 'text-info',
      jpg: 'text-warning',
      jpeg: 'text-warning',
      tif: 'text-warning',
      tiff: 'text-warning',
      bmp: 'text-info',
      webp: 'text-info',
      pdf: 'text-danger',
      geojson: 'text-warning',
      shp: 'text-warning',
      shx: 'text-warning',
      dbf: 'text-warning',
      gml: 'text-warning',
      kml: 'text-warning',
      kmz: 'text-warning',
      gpx: 'text-warning',
      vct: 'text-warning',
      vdc: 'text-warning',
      map: 'text-warning',
      osm: 'text-warning',
      dlg: 'text-warning',
      gdb: 'text-warning',
      mdb: 'text-warning',
      gpkg: 'text-warning',
      mbtiles: 'text-warning',
      qgs: 'text-warning',
      qgz: 'text-warning',
      aprx: 'text-warning',
      '3dd': 'text-warning',
      sxd: 'text-warning',
      qml: 'text-warning',
      mpk: 'text-warning',
      dae: 'text-success',
      obj: 'text-success',
      fbx: 'text-success',
      glb: 'text-success',
      '3ds': 'text-success',
      gltf: 'text-success',
      usdz: 'text-success',
      blend: 'text-success',
      c4d: 'text-success',
      mesh: 'text-success',
      zip: 'text-warning',
      mp4: 'text-danger',
      ogv: 'text-danger',
      webv: 'text-danger',
      mkv: 'text-danger',
    }[extension] || ''
  );
}

export function isHiddenFile(file: File): boolean {
  return (
    file.display_name?.endsWith('.json') || file.s3_file?.endsWith('.json')
  );
}

export function isFileOpenable(file: File): boolean {
  const extension = getExtension(file.display_name);
  return (
    onlyOfficeSupportedExtensions.includes(('.' + extension) as any) ||
    imagePreviewSupportedExtensions.includes(('.' + extension) as any) ||
    videoPreviewSupportedExtensions.includes(('.' + extension) as any)
  );
}

export function isFileOnlyOfficeSupported(file: File): boolean {
  const extension = getExtension(file.display_name);
  return onlyOfficeSupportedExtensions.includes(('.' + extension) as any);
}

export function isFileImagePreviewSupported(file: File): boolean {
  const extension = getExtension(file.display_name);
  return imagePreviewSupportedExtensions.includes(('.' + extension) as any);
}

export function isFileVideoPlayerSupported(file: File): boolean {
  const extension = getExtension(file.display_name);
  return videoPreviewSupportedExtensions.includes(('.' + extension) as any);
}

export const wordDocumentExtensions = ['.doc', '.docx', '.odt'] as const;

export const onlyOfficeSupportedExtensions = [
  '.csv',
  '.djvu',
  '.doc',
  '.docm',
  '.docx',
  '.docxf',
  '.dot',
  '.dotm',
  '.dotx',
  '.epub',
  '.fb2',
  '.fodp',
  '.fods',
  '.fodt',
  '.htm',
  '.html',
  '.mht',
  '.odp',
  '.ods',
  '.odt',
  '.oform',
  '.otp',
  '.ots',
  '.ott',
  '.oxps',
  '.pdf',
  '.pot',
  '.potm',
  '.potx',
  '.pps',
  '.ppsm',
  '.ppsx',
  '.ppt',
  '.pptm',
  '.pptx',
  '.rtf',
  '.txt',
  '.xls',
  '.xlsb',
  '.xlsm',
  '.xlsx',
  '.xlt',
  '.xltm',
  '.xltx',
  '.xml',
  '.xps',
] as const;

export const imagePreviewSupportedExtensions = [
  '.png',
  '.jpg',
  '.jpeg',
  '.webp',
  '.bmp',
  '.gif',
  '.svg',
] as const;

export const videoPreviewSupportedExtensions = [
  '.mp4',
  '.webm',
  '.ogg',
  '.ogv',
  '.mkv',
] as const;
