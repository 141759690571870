import { AccessType, PERMISSION_ID } from '../company-role-profile';
import { Project } from '../project';
import { useProjectStore } from '../store/project';

export default function useViewRestriction(
  type?: PERMISSION_ID,
  project: Project | null = null
) {
  // this is set to null if user has full access, as no permissions were set.
  const getAllPermissions = () => {
    const currentProject = project || useProjectStore().project;
    return currentProject?.session_role_permissions ?? null;
  };

  const getPermissionByType = (byType: PERMISSION_ID) => {
    const permissions = getAllPermissions();

    if (!permissions) {
      return null;
    }

    return permissions.find((p) => p.feature_key === byType) || null;
  };

  // this is generally set on a route, if they don't have permission it blocks them.
  const hasPermissionToAccess = (overriddenType: PERMISSION_ID | null = null) => {
    if (getAllPermissions() === null) {
      return true;
    }

    return getPermissionByType(overriddenType || type!) !== null;
  };

  function hasWriteAccess(overriddenType: PERMISSION_ID | null = null) {
    if (getAllPermissions() === null) {
      return true;
    }

    const permission = getPermissionByType(overriddenType || type!);
    return !permission || permission.access_type === AccessType.WRITE;
  }


  // this allows us to control using v-if/v-show whether to show a component based on a permission
  // used for building a custom read only interface.
  const isViewOnly = (overriddenType: PERMISSION_ID | null = null) => {
    if (getAllPermissions() === null) {
      return false;
    }

    const permission = getPermissionByType(overriddenType ?? type!);
    return !permission || permission.access_type === AccessType.READ;
  };

  return {
    hasPermissionToAccess,
    isViewOnly,
    hasWriteAccess,
  };
}
