import { defineStore } from 'pinia';
import axios from 'axios';
import { ref, computed } from 'vue';
import { File, FileVersion } from '../file-utils';
import downloadByHref from '../utils/download';
import { useToastStore } from './toasts';
import { hasAccess, FEATURES } from '../feature-manager';

export const useHistoryModalStore = defineStore('history-modal', () => {
    const toastStore = useToastStore();

    const showModal = ref(false);
    const selectedFile = ref<File | null>(null);
    const versions = ref<FileVersion[]>([]);
    const isLoading = ref(false);

    const hasFeatureAccess = computed(() => {
        return hasAccess(FEATURES.FILE_BROWSER_VERSIONS);
    });
    const hasUnlimitedVersions = computed(() => {
        return hasAccess(FEATURES.FILE_BROWSER_UNLIMITED_VERSIONS);
    });

    function openHistoryModal(file: File) {
        selectedFile.value = file;
        showModal.value = true;
        loadVersions();
    }

    function closeModal() {
        showModal.value = false;
    }

    async function loadVersions() {
        if (!hasFeatureAccess.value) {
            return;
        }
        versions.value = [];
        isLoading.value = true;

        if (!selectedFile.value) {
            throw new Error('No file selected');
        }

        const response = await axios.get(`/file/${selectedFile.value.id}/versions`);
        versions.value = response.data.versions ?? [];
    }

    function downloadFileVersion(fileVersion: FileVersion) {
        if (!fileVersion?.temporary_url) {
            toastStore.error('The file could not be found');
            return;
        }
        downloadByHref(fileVersion.temporary_url, fileVersion.display_name);
    }

    return {
        showModal,
        openHistoryModal,
        closeModal,
        downloadFileVersion,
        file: selectedFile,

        hasFeatureAccess,
        hasUnlimitedVersions,
        versions: computed(() => versions.value),
    }
});