export enum Code {
  Generic = 1,
  MethodNotImplemented,
  RequestExtentFailed,
  StyleVectorTileServerLayerFailed,
  GeometryTypeNotSupported,
  InvalidLayerModel,
  SampleLayerModelNotBeSampleGroup,
  SampleLayerModelNotFound,
  LayerTypeNotSupported,
  InteractionSessionAlreadyExisted,
  NoInteractionSession,
  InteractionSessionRequired,
  DrawAlreadyExisted,
  LayerBeingEdited,
  LayerModelAlreadyInStore,
  LayerModelNotInStore,
  SampleAlreadyInStore,
  SampleNotInStore,
  InvalidOperation,
  LayerModelNotFound,
  IntegrationNotReady,
  IntegrationDisabled,
  AccessForbidden,
  InvalidArgument,
  NullPointerException,
}

type Descriptor = {
  message: string;
};

const DESCRIPTOR_BY_CODE: Record<Code, Descriptor> = {
  [Code.Generic]: {
    message: 'Something went wrong, please try it again later.',
  },
  [Code.MethodNotImplemented]: {
    message: 'The method $1 is not implemented.',
  },
  [Code.RequestExtentFailed]: {
    message: 'Failed to request the extent from $1.',
  },
  [Code.StyleVectorTileServerLayerFailed]: {
    message: 'Failed to style the vector tile sever layer $1.',
  },
  [Code.GeometryTypeNotSupported]: {
    message: 'The geometry type $1 is not supported.',
  },
  [Code.InvalidLayerModel]: {
    message: 'The layer model with id $1 is invalid.',
  },
  [Code.SampleLayerModelNotBeSampleGroup]: {
    message: "The sample with id $1's layer model is not a sample group.",
  },
  [Code.SampleLayerModelNotFound]: {
    message: "The sample with id $1's layer model is not found.",
  },
  [Code.LayerTypeNotSupported]: {
    message: 'The layer type $1 is not supported.',
  },
  [Code.InteractionSessionAlreadyExisted]: {
    message: 'An interaction session has already existed.',
  },
  [Code.NoInteractionSession]: {
    message: 'There is no interaction session.',
  },
  [Code.InteractionSessionRequired]: {
    message: 'An interaction session is required.',
  },
  [Code.DrawAlreadyExisted]: {
    message: 'A draw has already existed.',
  },
  [Code.LayerBeingEdited]: {
    message: 'The layer with id $1 is being edited.',
  },
  [Code.LayerModelAlreadyInStore]: {
    message: 'The layer model with id $1 is already in the store.',
  },
  [Code.LayerModelNotInStore]: {
    message: 'The layer model with id $1 is not in the store.',
  },
  [Code.SampleAlreadyInStore]: {
    message: 'The sample with id $1 is already in the store.',
  },
  [Code.SampleNotInStore]: {
    message: 'The sample with id $1 is not in the store.',
  },
  [Code.InvalidOperation]: {
    message: 'Invalid operation: $1',
  },
  [Code.LayerModelNotFound]: {
    message: 'The layer model with id $1 is not found.',
  },
  [Code.IntegrationNotReady]: {
    message: 'The $1 integration is not ready.',
  },
  [Code.IntegrationDisabled]: {
    message:
      'Please contact the company Account Manager to enable the $1 integration.',
  },
  [Code.AccessForbidden]: {
    message:
      'Please contact the company Account Manager to give you permission to access $1.',
  },
  [Code.InvalidArgument]: {
    message: `The argument is invalid: $1.`,
  },
  [Code.NullPointerException]: {
    message: 'Something is null: $1.',
  },
};

export function getMessage(code: Code, ...params): string {
  const { message } =
    DESCRIPTOR_BY_CODE[code] ?? DESCRIPTOR_BY_CODE[Code.Generic];
  return message.replaceAll(/\$(\d+)/g, (match, number) => params[number - 1]);
}

export class OlbmError extends Error {
  code: Code;

  constructor(code: Code, ...params) {
    super(getMessage(code, ...params));
    this.code = code;
  }
}

export function createError(code: Code, ...params): OlbmError {
  return new OlbmError(code, ...params);
}

export function throwError(code: Code, ...params): never {
  throw createError(code, ...params);
}

export function parseError(error: Error): string {
  return error instanceof OlbmError ? error.message : getMessage(Code.Generic);
}
