import WMTSRequestEncoding from 'ol/source/WMTSRequestEncoding';
import { BasemapId } from '../../../../../lib/olbm/layer/basemap/types';

export default {
  index: BasemapId.AU_VIC_AERIAL,
  title: 'Victoria - Aerial',
  subtitle: '',
  type: 'satellite',
  options: {
    attributions: 'Land Use Victoria',
    version: '1.0.0',
    id: 'AERIAL_WM',
    format: 'image/png',
    url: 'https://base.maps.vic.gov.au/service',
    matrixSet: 'EPSG:3857',
    matrixIds: [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
    ],
    resolutions: [
      156543.03390625003, 78271.51696402048, 39135.75848201024,
      19567.87924100512, 9783.93962050256, 4891.96981025128, 2445.98490512564,
      1222.99245256282, 611.49622628141, 305.748113140705, 152.8740565703525,
      76.43702828517625, 38.21851414258813, 19.109257071294063,
      9.554628535647032, 4.777314267823516, 2.388657133911758,
      1.194328566955879, 0.5971642834779395, 0.29858214173896974,
      0.14929107086948487,
    ],
    origins: [
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
      [-20037508.342789244, 20037508.342789244],
    ],
    sizes: [
      [1, 1],
      [1, 1],
      [2, 2],
      [4, 4],
      [8, 8],
      [16, 16],
      [32, 32],
      [64, 64],
      [128, 128],
      [256, 256],
      [512, 512],
      [1024, 1024],
      [2048, 2048],
      [4096, 4096],
      [8192, 8192],
      [16384, 16384],
      [32768, 32768],
      [65536, 65536],
      [131072, 131072],
      [262144, 262144],
      [524288, 524288],
    ],
    tileSizes: [
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
      [512, 512],
    ],
    style: 'default',
    requestEncoding: WMTSRequestEncoding.KVP,
    projection: 'EPSG:3857',
  },
};
