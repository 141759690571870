import type { Figure } from '../figure/types';

export function checkIsSpecificChemicalPlan(figure: Figure): boolean {
  const { scenarios, chemicals } = figure;
  return !!(scenarios && chemicals);
}

export function checkShouldHideSampleLabels(figure: Figure): boolean {
  return figure.hide_sample_labels;
}
