import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import useAuth from '../composables/useAuth';
import useCompanyManagement from '../composables/useCompanyManagement';

export const useSubscriptionStore = defineStore('subscription', () => {
  const showTiersModal = ref(false);
  const featuresInPreview = ref({});
  const toggleTiersModal = () => {
    showTiersModal.value = !showTiersModal.value;
  };
  const auth = useAuth();
  const { companyData } = useCompanyManagement(auth.user(), false);

  const userTier = computed(() => {
    return Object.keys(
      companyData.value?.tiers ? companyData.value?.tiers : {}
    )[companyData.value?.company?.subscription_tier ?? 0];
  });

  const addToFeatureInPreview = (feature: string) => {
    featuresInPreview.value = {
      ...featuresInPreview.value,
      [feature]: true,
    };
  };

  const removeFromFeatureInPreview = (feature: string) => {
    featuresInPreview.value = {
      ...featuresInPreview.value,
      [feature]: false,
    };
  };

  const hasFeatureInPreview = (feature: string) => {
    return featuresInPreview.value[feature];
  };

  return {
    showTiersModal,
    toggleTiersModal,
    userTier,
    companyData,
    featuresInPreview,
    addToFeatureInPreview,
    removeFromFeatureInPreview,
    hasFeatureInPreview,
  };
});
