import { Fill, Stroke } from 'ol/style';
import type { Options } from '../common/types';
import addOpacity from './addOpacity';
import { Color } from './color';

export enum TextAlignment {
  Left = 0,
  Center = 1,
}

export function getMeasurementStyleOptions(
  layoutZoom: number,
  isTemporary = false
): Options {
  return {
    font: getFont(),
    fill: new Fill({
      color: isTemporary ? Color.White : Color.Black,
    }),
    stroke: new Stroke({
      color: isTemporary ? addOpacity(Color.Black, 0.4) : Color.White,
      width: 4,
    }),
    scale: layoutZoom,
  };
}

export function getLabelStyleOptions(
  label: string,
  color: string,
  layoutZoom: number
): Options {
  return {
    text: label,
    font: getFont(),
    fill: new Fill({
      color,
    }),
    stroke: new Stroke({
      color: Color.White,
      width: 4,
    }),
    backgroundFill: new Fill({
      color: addOpacity(Color.White, 0.9),
    }),
    padding: [2, 0, 0, 2],
    justify: 'center',
    scale: layoutZoom,
    overflow: true,
  };
}

export function getFont(fontSize = 12) {
  return `${fontSize}px "Helvetica Neue", Arial, Helvetica, sans-serif`;
}

export function createTextShadow(color) {
  return `
    2px 0 0 ${color},
    -2px 0 0 ${color},
    0 2px 0 ${color},
    0 -2px 0 ${color},
    1px 1px ${color},
    -1px -1px 0 ${color},
    1px -1px 0 ${color},
    -1px 1px 0 ${color}
  `;
}
