import { LayerType } from './types';

export type LayerUsage = number;

export const LayerUsageByType: Record<
  | LayerType.RECTANGLE
  | LayerType.IMAGE
  | LayerType.FEATURE_COLLECTION
  | LayerType.CALL_OUT,
  Record<string, LayerUsage>
> = {
  [LayerType.RECTANGLE]: {
    HEATMAP_BOUNDARY: 1,
    CACHING_BOUNDARY: 7,
  },
  [LayerType.IMAGE]: {
    HEATMAP: 2,
    FIGURE_IMAGE: 6,
  },
  [LayerType.FEATURE_COLLECTION]: {
    CONTOURS: 3,
    BASEMAP_POLYLINES: 4,
    BASEMAP_POLYGONS: 5,
    CACHING_BOUNDARIES: 8,
  },
  [LayerType.CALL_OUT]: {
    SHOW_TEXT: 1,
    SHOW_IMAGE: 2,
    SHOW_MAP: 3,
  },
};
