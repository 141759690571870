export enum Dpi {
  Screen = 96,
  PrinterPaper = 300,
  // Refer to https://gis.stackexchange.com/questions/29671/mathematics-behind-converting-scale-to-resolution
  // to understand the fomula used to calculate the resolution.
  WMTS = 25.4 / 0.28,
}

export type LegacySize = { width: number; height: number };
export type LegacyPixel = { x: number; y: number };

export type Size = [/*width*/ number, /*height*/ number];
export type Pixel = [/* x */ number, /* y */ number];

export enum UnitShortName {
  Kilometer = 'km',
  Meter = 'm',
  Centimeter = 'cm',
  Millimeter = 'mm',
  Mile = 'mi',
  Yard = 'yd',
  Foot = 'ft',
  Inch = 'in',
  Pixel = 'px',
  Point = 'pt',
}
export class Unit {
  shortName: UnitShortName;
  longName: string;
  pluralName: string;

  constructor(shortName: UnitShortName, longName: string, pluralName: string) {
    this.shortName = shortName;
    this.longName = longName;
    this.pluralName = pluralName;
  }
}

export class AbsoluteUnit extends Unit {
  static SQUARE_SYMBOL = '\u00B2';

  meters: number;
  nextUnit: AbsoluteUnit | undefined;

  constructor(
    shortName: UnitShortName,
    longName: string,
    pluralName: string,
    meters: number,
    nextUnit: AbsoluteUnit | undefined
  ) {
    super(shortName, longName, pluralName);

    this.meters = meters;
    this.nextUnit = nextUnit;
  }
  getTippingPoint(squared = false): number | undefined {
    if (!this.nextUnit) {
      return undefined;
    }

    const value = this.nextUnit.meters / this.meters;
    return squared ? Math.pow(value, 2) : value;
  }
  // The value is in meters.
  format(
    value: number,
    projectUnit: AbsoluteUnit,
    targetUnit: AbsoluteUnit,
    squared = false
  ): string {
    const valueInTargetUnit = squared
      ? Math.pow(Math.sqrt(value) / targetUnit.meters, 2)
      : value / targetUnit.meters;
    const tippingPoint = targetUnit.getTippingPoint(squared);
    const suffix = squared ? AbsoluteUnit.SQUARE_SYMBOL : '';

    if (!tippingPoint || valueInTargetUnit < tippingPoint) {
      let decimalPlaces;
      if (targetUnit.shortName === UnitShortName.Meter) {
        decimalPlaces = 1;
      } else {
        decimalPlaces = Math.round(
          Math.log10(targetUnit.meters / projectUnit.meters)
        );
        if (decimalPlaces < 0) {
          decimalPlaces = 0;
        }
      }

      return `${valueInTargetUnit.toFixed(decimalPlaces)} ${
        targetUnit.shortName
      }${suffix}`;
    }

    return this.format(value, projectUnit, targetUnit.nextUnit!, squared);
  }
}
