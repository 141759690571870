import { Map } from 'ol';
import { getLength as ol_sphere_getLength } from 'ol/sphere';
import { getMapProjection } from '../common/view';
import type { Shape } from '../layer/shape/types';
import { METER } from './unit';
import type { AbsoluteUnit } from './types';

export function getLength(map: Map, shape: Shape, percentage = 1) {
  return (
    ol_sphere_getLength(shape, {
      projection: getMapProjection(map),
    }) * percentage
  );
}

export function formatLength(
  length: number,
  projectUnit: AbsoluteUnit = METER
): string {
  return METER.format(length, projectUnit, projectUnit);
}
