import { Map } from 'ol';
import { getUnit } from '../adapter/project';
import { getStoreApi } from '../common/store-api';
import { AbsoluteUnit } from './types';

export function getProjectUnit(map: Map): AbsoluteUnit {
  const storeApi = getStoreApi(map);
  const project = storeApi.getProject();
  return getUnit(project);
}

// convert radians to degrees
export function radToDeg(rad: number): number {
  return (rad * 360) / (Math.PI * 2);
}

export function degToRad(deg: number): number {
  return (deg * Math.PI * 2) / 360;
}
