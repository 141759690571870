<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import * as constants from './constants';
import MarkerPalette from './MarkerPalette.vue';
import { useToastStore } from '../../store/toasts';
import useAuth from '../../composables/useAuth';
import axios from 'axios';

const props = defineProps({
  appId: {
    type: Number,
    default: constants.APP_DATANEST,
  },
  selectedMarkerId: Number,
});
const emit = defineEmits(['markerSelect']);

const auth = useAuth();
const toastStore = useToastStore();
const { id: companyId } = auth.user().company;

const currentMarkerGroup = ref(constants.MARKER_GROUP_BUILTIN);
const onTabClick = (markerGroup) => {
  currentMarkerGroup.value = markerGroup;
};

const builtinMarkers = ref<Marker[]>([]);
const customMarkers = ref<Marker[]>([]);
const favoriteMarkers = ref<Marker[]>([]);

// The request url is prefixed automatically in Datanest.
const requestUrlPrefix = computed(() =>
  props.appId === constants.APP_GATHER ? '/api' : ''
);

interface Marker {
  id: number;
}

const loadMarkers = async (isCustom) => {
  const { data: markers } = await axios.get(
    `${requestUrlPrefix.value}/markers?company_id=${companyId}&is_custom=${isCustom}&is_svg_excluded=true`
  );
  return markers as Marker[];
};

const loadAndUpdateBuiltinMarkers = async () => {
  builtinMarkers.value = await loadMarkers(false);
};

const loadAndUpdateCustomMarkers = async () => {
  customMarkers.value = await loadMarkers(true);
};

const loadAndUpdateFavoriteMarkers = async () => {
  const { data: markers } = await axios.get(
    `${requestUrlPrefix.value}/markers/favorite?is_svg_excluded=true`
  );
  favoriteMarkers.value = markers;
};

const checkIsMarkerInGroup = (markers, markerId) => {
  return !!markers.find((item) => item.id === markerId);
};

const selectTab = () => {
  if (checkIsMarkerInGroup(favoriteMarkers.value, props.selectedMarkerId)) {
    currentMarkerGroup.value = constants.MARKER_GROUP_FAVORITE;
  } else if (
    checkIsMarkerInGroup(customMarkers.value, props.selectedMarkerId)
  ) {
    currentMarkerGroup.value = constants.MARKER_GROUP_CUSTOM;
  } else if (
    checkIsMarkerInGroup(builtinMarkers.value, props.selectedMarkerId)
  ) {
    currentMarkerGroup.value = constants.MARKER_GROUP_BUILTIN;
  }
};

const findMarker = (id) => {
  const allMarkers = [...builtinMarkers.value, ...customMarkers.value];
  return allMarkers.find((item) => item.id === id);
};

const onMarkerSelect = (id) => {
  emit('markerSelect', findMarker(id));
};

const onLike = async (id) => {
  try {
    await axios.post(`${requestUrlPrefix.value}/markers/favorite/${id}`);
    await loadAndUpdateFavoriteMarkers();
    toastStore.success(
      'The marker has been added to the favorite group successfully.'
    );
  } catch (e) {
    toastStore.unexpected();
    throw e;
  }
};

const onUnlike = async (id) => {
  try {
    await axios.delete(`${requestUrlPrefix.value}/markers/favorite/${id}`);
    await loadAndUpdateFavoriteMarkers();
    toastStore.success(
      'The marker has been removed from the favorite group successfully.'
    );
    selectTab();
  } catch (e) {
    toastStore.unexpected();
    throw e;
  }
};

onMounted(async () => {
  await loadAndUpdateBuiltinMarkers();
  await loadAndUpdateCustomMarkers();
  await loadAndUpdateFavoriteMarkers();

  selectTab();
  emit('markerSelect', findMarker(props.selectedMarkerId));
});
</script>

<template>
  <div class="marker-picker">
    <div class="tabs">
      <ul class="nav nav-underline mb-2">
        <li class="nav-item">
          <a
            class="nav-link px-2"
            :class="{
              active: currentMarkerGroup === constants.MARKER_GROUP_BUILTIN,
            }"
            href="#"
            @click.prevent="onTabClick(constants.MARKER_GROUP_BUILTIN)"
          >
            Default
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link px-2"
            :class="{
              active: currentMarkerGroup === constants.MARKER_GROUP_CUSTOM,
            }"
            href="#"
            @click.prevent="onTabClick(constants.MARKER_GROUP_CUSTOM)"
          >
            Custom
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link px-2"
            :class="{
              active: currentMarkerGroup === constants.MARKER_GROUP_FAVORITE,
            }"
            href="#"
            @click.prevent="onTabClick(constants.MARKER_GROUP_FAVORITE)"
          >
            Favourite
          </a>
        </li>
      </ul>
    </div>
    <MarkerPalette
      v-if="currentMarkerGroup === constants.MARKER_GROUP_BUILTIN"
      :appId="appId"
      :group="currentMarkerGroup"
      :markers="builtinMarkers"
      :selectedMarkerId="props.selectedMarkerId"
      @markerSelect="onMarkerSelect"
      @like="onLike"
      @unlike="onUnlike"
    />
    <MarkerPalette
      v-if="currentMarkerGroup === constants.MARKER_GROUP_CUSTOM"
      :appId="appId"
      :group="currentMarkerGroup"
      :markers="customMarkers"
      :selectedMarkerId="props.selectedMarkerId"
      @markerSelect="onMarkerSelect"
      @like="onLike"
      @unlike="onUnlike"
    />
    <MarkerPalette
      v-if="currentMarkerGroup === constants.MARKER_GROUP_FAVORITE"
      :appId="appId"
      :group="currentMarkerGroup"
      :markers="favoriteMarkers"
      :selectedMarkerId="props.selectedMarkerId"
      @markerSelect="onMarkerSelect"
      @like="onLike"
      @unlike="onUnlike"
    />
  </div>
</template>
