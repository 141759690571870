import { BasemapId } from '../../../../lib/olbm/layer/basemap/types';

const fullExtentInEpsg4326 = [
  -6.6275285724489175, 49.86396892501348, -1.4769876378223192,
  52.111649318969185,
];

export default {
  index: BasemapId.GB_BRISTOL_AND_SURROUNDING_AREA,
  title: 'Bristol and the surrounding area',
  subtitle: 'Southwest boundary',
  type: 'street',
  options: {
    attributions: `OS data © Crown copyright & database rights 2019 Ordnance Survey 100023406. Use of OS data is subject to an <a href='http://www.ordnancesurvey.co.uk/business-and-government/public-sector/mapping-agreements/end-user-licence.html' target='psma_eul'>end user licence</a>`,
    url: 'https://maps2.bristol.gov.uk/server1/rest/services/base/2020_Q2_GREY_BCC_128dpi/MapServer',
    token: null,
    extent: fullExtentInEpsg4326,
    visibleExtent: fullExtentInEpsg4326,
    maxImageSize: {
      width: 4096,
      height: 4096,
    },
    mapTileConfig: {
      tileInfo: {
        projection: 'EPSG:27700',
        resolutions: [
          38.10007620015241, 19.050038100076204, 9.525019050038102,
          4.762509525019051, 2.3812547625095255, 1.1906273812547628,
          0.5953136906273814, 0.24804737109474223, 0.14882842265684534,
        ],
        origin: [-5220400, 4470200],
        tileSize: [256, 256],
      },
    },
    layers: [0],
    hasLabels: false,
    type: 'service_layer',
    isDynamicMapLayer: true,
    shouldUseCorsProxy: true,
  },
};
