<template>
  <modal :show="show" @close="close" :hideHeader="true">
    <template v-slot:header> Address Lookup </template>
    <div class="modal-body text-center" style="overflow-y: unset">
      <h1 class="fal fa-location"></h1>
      <h6 class="mb-3">Navigate to a specific address</h6>
      <auto-complete
        :queryUrl="`/api/address/autocomplete?${additionalQueryParams}`"
        @changeValue="setAddress"
        placeholder="Enter an address"
        container="predictions"
        displayIndex="description"
      />
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary w-100" @click="close">
        Close
      </button>
    </div>
  </modal>
</template>
<script>
import { mapState } from 'vuex';

import Modal from '@/js/components/Modal.vue';
import AutoComplete from '@/js/components/Autocomplete.vue';

export default {
  name: 'AddressLookupModal',
  props: ['show', 'map'],
  data: () => ({}),
  components: { Modal, AutoComplete },
  computed: {
    ...mapState({
      country: (state) => state.project.location.country,
    }),
    additionalQueryParams() {
      return 'components=country:' + this.country;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    setAddress(data) {
      if (!data || !data.data || !data.value) {
        return;
      }

      const address = data.value;

      axios
        .get(
          '/api/address/geocode?q=' +
            address +
            '&componentRestrictions=country:' +
            this.country
        )
        .then((response) => {
          if (response.data.results.length > 0) {
            const { lat, lng } = response.data.results[0].geometry.location;

            this.map.animateView({ longitude: lng, latitude: lat }, 20);

            this.close();
          }
        })
        .finally(() => {
          this.isLoadingLatLng = false;
        });
    },
  },
};
</script>
