import type { Project } from '../figure/types';
import { AbsoluteUnit } from '../measurement/types';
import { CENTIMETER, FEET, INCH, METER, MILLIMETER } from '../measurement/unit';

export function getUnit(project: Project): AbsoluteUnit {
  const { measurement_type } = project;
  switch (measurement_type) {
    case 'metre':
      return METER;
    case 'mm':
      return MILLIMETER;
    case 'cm':
      return CENTIMETER;
    case 'feet':
      return FEET;
    case 'inch':
      return INCH;
  }
}

export function getImageSrc(project: Project, value: string): string {
  const { project_id: projectId } = project;
  if (value.startsWith('file:')) {
    return (
      '/api/file/' +
      value.replace('file:', '') +
      '/data?project_id=' +
      projectId
    );
  }

  return value.startsWith('data:image')
    ? value
    : `/api/images/figure/${value}?project_id=${projectId}`;
}

export function getGatherImageSrc(project: Project, fileName: string): string {
  const { project_id: projectId } = project;

  if (fileName.startsWith('file:')) {
    return (
      '/api/file/' +
      fileName.replace('file:', '') +
      '/data?project_id=' +
      projectId
    );
  }

  // Using redirect causes CORS security issue.
  return `/api/images/value/${projectId}/${fileName}?redirect=false`;
}
