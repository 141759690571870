import { Country } from '../../../common/address';
import { WGS84_WORLD_EXTENT } from '../../../common/extent';
import { ServiceType } from '../../service/types';
import { LayerType } from '../../types';
import type { BasemapXyzApi } from '../types';
import { BasemapId } from '../types';
import hosts from './hosts';

const host = hosts.LINZ;

const basemapApi: BasemapXyzApi = {
  id: BasemapId.LINZ,
  title: 'LINZ',
  subtitle: 'Aerial imagery of New Zealand',
  type: 'satellite',
  host,
  countries: [Country.NewZealand],
  layerModel: {
    id: 'linz-folder',
    geojson: {
      properties: {
        type: LayerType.FOLDER,
        title: 'LINZ',
        service: {
          type: ServiceType.Xyz,
          url: `https://${host}/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.webp?api=d01efztxps0q68x5kzwm2ggg0d0`,
          title: 'LINZ',
          description: 'Aerial imagery of New Zealand',
          extent: WGS84_WORLD_EXTENT,
          visibleExtent: [...WGS84_WORLD_EXTENT],
          attributions: 'LINZ CC BY 4.0 © Imagery Basemap contributors',
          shouldUseCorsProxy: false,
          maxZoom: 22,
          projection: 'EPSG:3857',
        },
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [],
  },
};

export default basemapApi;
