<template>
  <a href="#" class="fw-medium" @click.prevent="viewImage"> View Image </a>
</template>

<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  methods: {
    viewImage() {
      this.$emit('viewImage', this.value.value);
    },
  },
};
</script>
