<template>
  <Modal
    :show="show"
    @close="close"
    @submit="submit"
    :isLoading="isLoading"
    :notClosable="notClosable"
  >
    <template v-slot:header>
      {{ headerMessage }}
    </template>
    <div
      v-if="bodyMessage != null || requireConfirmation || hasSlot"
      class="modal-body"
      :class="{
        'text-center': fullWidthButtons,
      }"
    >
      <slot />

      <div v-if="bodyMessage != null" v-html="bodyMessage" />

      <div
        v-if="requireConfirmation"
        class="mb-0"
        :class="{
          'mt-3': bodyMessage,
        }"
      >
        <label class="form-label" for="confirmText">
          Type <b>I Accept</b> To Confirm
        </label>
        <input
          class="form-control"
          placeholder="I accept"
          v-model="confirmText"
        />
      </div>
    </div>
    <div
      class="modal-footer"
      :class="{
        'd-flex flex-column': fullWidthButtons,
      }"
    >
      <button
        :class="[
          getCancelButtonClass,
          {
            'btn btn-lg w-100 btn btn-flat fw-medium mt-1': fullWidthButtons,
          },
        ]"
        @click="close"
      >
        {{ yesOrNoOption ? 'No' : 'Cancel' }}
      </button>

      <ButtonSpinner
        v-if="!hideSubmit"
        type="submit"
        class="btn"
        :class="[
          isDelete || isDanger ? 'btn-danger' : 'btn-primary',
          {
            'btn-lg w-100 fw-medium': fullWidthButtons,
          },
        ]"
        :is-loading="isSubmitting"
        @click.native.prevent="submit"
        :disabled="isDisabled"
      >
        {{ isDelete ? 'Delete' : yesOrNoOption ? 'Yes' : 'Ok' }}
      </ButtonSpinner>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/js/components/Modal.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';

/**
 * @deprecated Use Dialog Store instead
 */
export default {
  name: 'NotifyModal',
  props: [
    'show',
    'headerMessage',
    'bodyMessage',
    'isDelete',
    'isDanger',
    'hideSubmit',
    'yesOrNoOption',
    'isLoading',
    'isSubmitting',
    'notClosable',
    'requireConfirmation',
    'fullWidthButtons',
  ],
  data: () => ({
    confirmText: '',
  }),
  components: {
    Modal,
    ButtonSpinner,
  },
  computed: {
    isDisabled() {
      if (!this.requireConfirmation) {
        return false;
      }

      return this.confirmText.toLowerCase().trim() != 'i accept';
    },
    hasSlot() {
      return !!this.$slots.default;
    },
    getCancelButtonClass() {
      if (this.fullWidthButtons) {
        return '';
      }
      return 'btn btn-outline-secondary';
    },
  },
  methods: {
    close: function () {
      this.$emit('close');
    },
    submit: function () {
      this.$emit('submit');
    },
  },
  mounted() {
    console.warn(
      'Deprecated: NotifyModal is deprecated. Use Dialog Store instead'
    );
  },
};
</script>

<style scoped>
.btn-flat {
  order: 2;
}

.text-center .form-control {
  text-align: center;
}
</style>
