import {
  getBottomLeft,
  getBottomRight,
  getCenter,
  getTopLeft,
  getTopRight,
} from 'ol/extent';
import { Polygon } from 'ol/geom';
import { getPixelDistance } from '../../measurement/medium';

export function createRectangleFromExtent(extent, rotation = 0) {
  const bottomLeft = getBottomLeft(extent);
  const bottomRight = getBottomRight(extent);
  const topRight = getTopRight(extent);
  const topLeft = getTopLeft(extent);

  const rectangle = new Polygon([
    [bottomLeft, bottomRight, topRight, topLeft, bottomLeft],
  ]);
  if (rotation) {
    rectangle.rotate(rotation, getCenter(extent));
  }

  return rectangle;
}

/**
 * The sides of a rectangle are numbered as the following illustration:
 *
 * --------2--------
 * |               |
 * 3               1
 * |               |
 * --------0--------
 *
 * Each side is represented as a pair of coordinates.
 * @param {ol/Map} map
 * @param {ol/geom/Polygon-Rectangle} rectangle
 * @returns
 */
export function getRectangleSides(rectangle) {
  const coords = rectangle.getLinearRing(0).getCoordinates();
  const sides = {};
  let sideId = 0;

  for (let i = 0; i < coords.length - 1; i++) {
    sides[sideId++] = {
      startCoordinate: coords[i],
      endCoordinate: coords[i + 1],
    };
  }

  return sides;
}

export function getRectangleSidePixelDistance(map, side) {
  const { startCoordinate, endCoordinate } = side;
  const startPixel = map.getPixelFromCoordinate(startCoordinate);
  const endPixel = map.getPixelFromCoordinate(endCoordinate);

  return getPixelDistance(startPixel, endPixel);
}

export function calculateRectangleScale(
  map,
  rectBeforeScaling,
  rectAfterScaling
) {
  const sidesBeforeScaling = getRectangleSides(rectBeforeScaling);
  const sidesAfterScaling = getRectangleSides(rectAfterScaling);

  const pdOfSide0BeforeScaling = getRectangleSidePixelDistance(
    map,
    sidesBeforeScaling[0]
  );
  const pdOfSide1BeforeScaling = getRectangleSidePixelDistance(
    map,
    sidesBeforeScaling[1]
  );
  const pdOfSide0AfterScaling = getRectangleSidePixelDistance(
    map,
    sidesAfterScaling[0]
  );
  const pdOfSide1AfterScaling = getRectangleSidePixelDistance(
    map,
    sidesAfterScaling[1]
  );

  return [
    pdOfSide0AfterScaling / pdOfSide0BeforeScaling,
    pdOfSide1AfterScaling / pdOfSide1BeforeScaling,
  ];
}

/** General case solution for a rectangle
 *
 *  Given coordinages of [x1, y1, x2, y2, x3, y3, x4, y4]
 *  where the corners are:
 *            top left    : x1, y1
 *            top right   : x2, y2
 *            bottom right: x3, y3
 *            bottom left : x4, y4
 *
 *  The centre is the average top left and bottom right coords:
 *  center: (x1 + x3) / 2 and (y1 + y3) / 2
 *
 *  Clockwise rotation: Math.atan((x1 - x4)/(y1 - y4)) with
 *  adjustment for the quadrant the angle is in.
 *
 *  Note that if using page coordinates, y is +ve down the page which
 *  is the reverse of the mathematic sense so y page coordinages
 *  should be multiplied by -1 before being given to the function.
 *  (e.g. a page y of 400 should be -400).
 *
 * @see https://stackoverflow.com/a/13003782/938822
 */
function getRotation(coords) {
  // Get center as average of top left and bottom right
  var center = [(coords[0] + coords[4]) / 2, (coords[1] + coords[5]) / 2];

  // Get differences top left minus bottom left
  var diffs = [coords[0] - coords[6], coords[1] - coords[7]];

  // Get rotation in degrees
  var rotation = (Math.atan(diffs[0] / diffs[1]) * 180) / Math.PI;

  // Adjust for 2nd & 3rd quadrants, i.e. diff y is -ve.
  if (diffs[1] < 0) {
    rotation += 180;

    // Adjust for 4th quadrant
    // i.e. diff x is -ve, diff y is +ve
  } else if (diffs[0] < 0) {
    rotation += 360;
  }
  // return array of [[centerX, centerY], rotation];
  return [center, rotation];
}

export function calculateRectangleRotation(map, rectangle) {
  const [bottomLeft, bottomRight, topRight, topLeft] = rectangle
    .getLinearRing(0)
    .getCoordinates();

  // The coordinate can be undefined if the map scale is too small.
  if (
    [bottomLeft, bottomRight, topRight, topLeft].some(
      (coordinate) => !coordinate
    )
  ) {
    return 0;
  }

  const center = getCenter(rectangle.getExtent());
  const blPixel = map.getPixelFromCoordinate(bottomLeft);
  const brPixel = map.getPixelFromCoordinate(bottomRight);
  const trPixel = map.getPixelFromCoordinate(topRight);
  const tlPixel = map.getPixelFromCoordinate(topLeft);
  const cPixel = map.getPixelFromCoordinate(center);
  const x1 = tlPixel[0] - cPixel[0];
  const y1 = cPixel[1] - tlPixel[1];
  const x2 = trPixel[0] - cPixel[0];
  const y2 = cPixel[1] - trPixel[1];
  const x3 = brPixel[0] - cPixel[0];
  const y3 = cPixel[1] - brPixel[1];
  const x4 = blPixel[0] - cPixel[0];
  const y4 = cPixel[1] - blPixel[1];
  const coords = [x1, y1, x2, y2, x3, y3, x4, y4];
  const [, rotation] = getRotation(coords);

  return ((rotation as number) / 180) * Math.PI;
}
