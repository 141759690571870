import { BasemapId } from '../../../lib/olbm/layer/basemap/types';

export default {
  index: BasemapId.METROMAP,
  title: 'Metromap',
  subtitle: 'High-quality and accurate aerial imagery',
  type: 'satellite',
  source:
    'https://api-v3.metromap.com.au/tiles/xyz/Australia_latest/{z}/{y}/{x}.png?key={API_KEY}',
  options: {
    attributions: 'Metromap by Aerometrex',
  },
};
