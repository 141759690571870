<script setup lang="ts">
import { BLACK } from '../../../business-logic/mapping/color';
import ColorChooser from '../ColorChooser.vue';
import Toggle from '../Toggle.vue';
import GeometryTextStyleEditor from './GeometryTextStyleEditor.vue';
import OutlineStyleEditor from './OutlineStyleEditor.vue';
import { injectContext } from './context';

const { getShapeProperty, setShapeProperty } = injectContext();
</script>

<template>
  <div class="rectangle-style-editor">
    <ColorChooser
      class="mb-2"
      :value="getShapeProperty('color', BLACK)"
      @input="(value) => setShapeProperty('color', value)"
    />

    <OutlineStyleEditor
      class="mb-2"
      patternLabel="Pattern"
      thicknessLabel="Thickness"
      opacityLabel="Opacity"
    />

    <Toggle
      name="showAsBidirectionalArrow"
      label="Show as a bidirectional arrow"
      :checked="getShapeProperty('multiDirectional', false)"
      @change="(checked) => setShapeProperty('multiDirectional', checked)"
    />

    <GeometryTextStyleEditor :hasArea="false" />
  </div>
</template>
