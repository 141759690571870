import { Country } from '../../../common/address';
import { WGS84_WORLD_EXTENT } from '../../../common/extent';
import { ServiceType } from '../../service/types';
import { LayerType } from '../../types';
import type { BasemapXyzApi } from '../types';
import { BasemapId } from '../types';
import hosts from './hosts';

const host = hosts.METROMAP;

const basemapApi: BasemapXyzApi = {
  id: BasemapId.METROMAP,
  title: 'Metromap',
  subtitle: 'High-quality and accurate aerial imagery',
  type: 'satellite',
  host,
  countries: [Country.Australia],
  layerModel: {
    id: 'metromap-folder',
    geojson: {
      properties: {
        type: LayerType.FOLDER,
        title: 'Metromap',
        service: {
          type: ServiceType.Xyz,
          url: `https://${host}/tiles/xyz/Australia_latest/{z}/{y}/{x}.png?key={API_KEY}`,
          title: 'Metromap',
          description: 'High-quality and accurate aerial imagery',
          extent: WGS84_WORLD_EXTENT,
          visibleExtent: [...WGS84_WORLD_EXTENT],
          attributions: 'Metromap by Aerometrex',
          shouldUseCorsProxy: false,
          maxZoom: 19,
          projection: 'EPSG:3857',
        },
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [],
  },
};

export default basemapApi;
