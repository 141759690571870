import { BasemapId } from '../../../lib/olbm/layer/basemap/types';

export default {
  index: BasemapId.OPEN_STREET_MAP,
  title: 'OpenStreetMap',
  subtitle: 'Basic street maps covering world',
  type: 'street',
  source: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  options: {
    attributions: '© OpenStreetMap contributors',
    maxNativeZoom: 19,
  },
};
