export const EPSG_27700 = 'EPSG:27700';

export const projectionConfigs = [
  {
    code: EPSG_27700,
    name: 'OSGB36 / British National Grid',
    bbox: [61.01, -9, 49.75, 2.01],
    proj4Def:
      '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs',
  },
];
