<template>
  <div v-html="value.value"></div>
</template>

<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
};
</script>
