import { Map } from 'ol';
import { Coordinate, equals as ol_coordinate_equals } from 'ol/coordinate';
import { Geometry, LineString, Polygon } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import type { ProjectionLike } from 'ol/proj';
import {
  equivalent,
  get as getProjection,
  fromLonLat as ol_proj_fromLonLat,
  toLonLat as ol_proj_toLonLat,
} from 'ol/proj';
import { getLayoutZoom } from '../measurement/layout';
import type { LegacyLatLng, LonLat } from './types';

export const HIT_TOLERANCE = 1e1;

export function fromLonLat(lonLat: LonLat, proj: ProjectionLike): Coordinate {
  const { longitude, latitude } = lonLat;
  return ol_proj_fromLonLat([longitude, latitude], proj);
}

export function toLonLat(
  coord: Coordinate,
  proj: ProjectionLike,
  precision = 7
): LonLat {
  const factor = Math.pow(10, precision);
  let [longitude, latitude] = ol_proj_toLonLat(coord, proj);
  longitude = Math.round(longitude * factor) / factor;
  latitude = Math.round(latitude * factor) / factor;
  return { longitude, latitude };
}

export function lonLatToLegacyLatLng(lonLat: LonLat): LegacyLatLng {
  const { longitude, latitude } = lonLat;
  return {
    lat: latitude,
    lng: longitude,
  };
}

export function legacyLatLngToLonLat(value: LegacyLatLng): LonLat {
  const { lat, lng } = value;
  return {
    longitude: lng,
    latitude: lat,
  };
}

export function getCoordinateFromGeoJSONPointFeature(
  gFeature: GeoJSON.Feature<GeoJSON.Point>,
  proj: ProjectionLike
): Coordinate {
  const [longitude, latitude] = gFeature.geometry.coordinates;
  return fromLonLat({ longitude, latitude }, proj);
}

export function transformCoordinate(
  coordinate: Coordinate,
  sourceProjection: ProjectionLike,
  targetProjection: ProjectionLike
): Coordinate {
  sourceProjection = getProjection(sourceProjection);
  targetProjection = getProjection(targetProjection);
  if (equivalent(sourceProjection, targetProjection)) {
    return coordinate;
  }

  const lonLat = toLonLat(coordinate, sourceProjection);
  return fromLonLat(lonLat, targetProjection);
}

export function checkIsValidLatLng(value): value is LegacyLatLng {
  let { lat, lng } = value;
  lat = parseFloat(lat);
  lng = parseFloat(lng);
  return lng > -180 && lng < 180 && lat > -90 && lat < 90;
}

export function normalizeLongitude(longitude) {
  let result = longitude;
  while (result < -180) {
    result += 360;
  }
  while (result > 180) {
    result -= 360;
  }
  return result;
}

export function normalizeLatitude(latitude) {
  let result = latitude;
  while (result < -90) {
    result += 180;
  }
  while (result > 90) {
    result -= 180;
  }
  return result;
}

/**
 * Check whether the coordinate is one of the vertext coordinates of a geometry.
 */
export function checkIsVertexCoordinate(
  map: Map,
  geom: Geometry,
  coord: Coordinate,
  tolerance = HIT_TOLERANCE
) {
  const gt = geom.getType();
  let coords: Coordinate[] = [];
  if (gt === GeometryType.POLYGON) {
    coords = (geom as Polygon).getLinearRing(0).getCoordinates();
  } else if (gt === GeometryType.LINE_STRING) {
    coords = (geom as LineString).getCoordinates();
  }

  return coords.some((item) => {
    return checkEqualityOfTwoCoordinatesLeniently(map, item, coord, tolerance);
  });
}

export function checkEqualityOfTwoCoordinatesLeniently(
  map: Map,
  coordinate1: Coordinate,
  coordinate2: Coordinate,
  tolerance: number = 2
): boolean {
  const pixel1 = map.getPixelFromCoordinate(coordinate1);
  const pixel2 = map.getPixelFromCoordinate(coordinate2);
  const offsetX = Math.abs(pixel1[0] - pixel2[0]);
  const offsetY = Math.abs(pixel1[1] - pixel2[1]);
  const scaledTolerance = tolerance * getLayoutZoom(map);
  return offsetX <= scaledTolerance && offsetY <= scaledTolerance;
}

export function findCoordinateIndex(coordinates, coordinate) {
  return coordinates.findIndex((item) =>
    ol_coordinate_equals(item, coordinate)
  );
}

export function checkIsFirstCoordinate(coordinates, coordinate) {
  return ol_coordinate_equals(coordinate, coordinates[0]);
}

export function checkIsLastCoordinate(coordinates, coordinate) {
  return ol_coordinate_equals(coordinate, coordinates[coordinates.length - 1]);
}

export function checkIsEndCoord(coordinates, coordinate) {
  return (
    checkIsFirstCoordinate(coordinates, coordinate) ||
    checkIsLastCoordinate(coordinates, coordinate)
  );
}
