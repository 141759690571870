import chatwoot from './chat-providers/chatwoot';
import freshchat from './chat-providers/freshchat';
import { hasAccess, FEATURES } from './feature-manager';
import { User } from './user';

const defaultChatProvider: keyof typeof providers = 'chatwoot';
const providers = {
  chatwoot,
  freshchat,
};
let provider: ChatProvider | null = null;

export interface ChatProvider {
  hasLiveChat(): boolean;
  loadScript: () => void;
  setContext: (
    user: User,
    projectId: string | undefined,
    project_name: string | undefined,
    project_number: string | undefined
  ) => void;
  open: (
    user: User,
    projectId: string | undefined,
    projectName: string | undefined,
    projectNumber: string | undefined
  ) => void;
}

function transformProvider(provider): ChatProvider {
  return {
    ...provider,
    hasLiveChat: function () {
      if (
        !import.meta.env.VITE_SHOW_LIVECHAT ||
        import.meta.env.VITE_SHOW_LIVECHAT !== 'true'
      ) {
        return false;
      }
      if (!hasAccess(FEATURES.LIVE_CHAT)) {
        console.log('Company does not have live chat access.');
        return false;
      }

      return provider.hasLiveChat();
    },
  };
}

export default function useChatProvider() {
  if (provider) {
    return provider;
  }
  const providerKey = (
    import.meta.env.VITE_CHAT_PROVIDER || defaultChatProvider
  ).toLowerCase();
  const adapter = providers[providerKey];

  if (adapter) {
    console.log('Using chat provider', providerKey);
    provider = transformProvider(adapter);
    return provider;
  }

  console.log('Using default chat provider', defaultChatProvider);
  provider = transformProvider(providers[defaultChatProvider]);

  return provider;
}
