import {
  Country,
  STATE_AU_NEW_SOUTH_WALES,
} from '../../../../../common/address';
import { EsriType } from '../../../../service/esri/types';
import { LayerType } from '../../../../types';
import type { BasemapMapServerApi } from '../../../types';
import { BasemapId } from '../../../types';
import hosts from '../../hosts';

const host = hosts.NSW_AERIAL;

const basemapApi: BasemapMapServerApi = {
  id: BasemapId.AU_NSW_AERIAL,
  title: 'New South Wales - Aerial',
  subtitle: '',
  type: 'satellite',
  host,
  countries: [Country.Australia],
  states: [STATE_AU_NEW_SOUTH_WALES],
  layerModel: {
    id: 'new-south-wales-aerial-folder',
    geojson: {
      properties: {
        title: 'LPI_Imagery_Best',
        type: LayerType.FOLDER,
        url: `https://${host}/arcgis/rest/services/sixmaps/LPI_Imagery_Best/MapServer`,
        token: undefined,
        esri_type: EsriType.MapServer,
        extent: [
          140.85958619429368, -37.537567934258014, 159.28994762857334,
          -28.085047888600215,
        ],
        visibleExtent: [
          140.85958619429368, -37.53756793425801, 159.28994762857334,
          -28.085047888600215,
        ],
        maxImageSize: { width: 4096, height: 4096 },
        mapTileConfig: {
          tileInfo: {
            projection: 'EPSG:3857',
            resolutions: [
              156543.033928, 78271.5169639999, 39135.7584820001,
              19567.8792409999, 9783.93962049996, 4891.96981024998,
              2445.98490512499, 1222.99245256249, 611.49622628138,
              305.748113140558, 152.874056570411, 76.4370282850732,
              38.2185141425366, 19.1092570712683, 9.55462853563415,
              4.77731426794937, 2.38865713397468, 1.19432856685505,
              0.597164283559817, 0.298582141647617, 0.14929107082380833,
              0.07464553541190416, 0.03732276770595208, 0.01866138385297604,
            ],
            origin: [-20037508.342787, 20037508.342787],
            tileSize: [256, 256],
          },
        },
        attributions:
          '\u00a9 Department of Finance, Services & Innovation 2018',
        projection: 'EPSG:3857',
        shouldUseCorsProxy: false,
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [
      {
        id: 'new-south-wales-aerial-item',
        geojson: {
          properties: {
            title: '',
            type: LayerType.SERVICE,
            index: 3,
            hidden_sub_layers: [],
            minScale: 0,
            maxScale: 0,
            hasLabels: false,
            renderer: undefined,
          },
        },
        visible: true,
        is_visible_in_basemap_figure: false,
        children: [],
      },
    ],
  },
};

export default basemapApi;
