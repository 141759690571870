import { Country } from '../../../common/address';
import { WGS84_WORLD_EXTENT } from '../../../common/extent';
import { ServiceType } from '../../service/types';
import { LayerType } from '../../types';
import type { BasemapXyzApi } from '../types';
import { BasemapId } from '../types';
import hosts from './hosts';

const host = hosts.NEARMAP;

const basemapApi: BasemapXyzApi = {
  id: BasemapId.NEARMAP,
  title: 'Nearmap',
  subtitle: 'High-resolution aerial imagery of parts of {COUNTRY}',
  type: 'satellite',
  host,
  countries: [Country.NewZealand, Country.Australia],
  layerModel: {
    id: 'nearmap-folder',
    geojson: {
      properties: {
        type: LayerType.FOLDER,
        title: 'Nearmap',
        service: {
          type: ServiceType.Xyz,
          url: `https://${host}/tiles/v3/Vert/{z}/{x}/{y}.img?apikey={API_KEY}&tertiary=satellite`,
          title: 'Nearmap',
          description: 'High-resolution aerial imagery of parts of {COUNTRY}',
          extent: WGS84_WORLD_EXTENT,
          visibleExtent: [...WGS84_WORLD_EXTENT],
          attributions: '© Nearmap',
          shouldUseCorsProxy: false,
          maxZoom: 19,
          projection: 'EPSG:3857',
        },
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [],
  },
};

export default basemapApi;
