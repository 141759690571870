import { StylingPriority } from './types';

export const CHANGE_TO_APPBASED_STYLING = 'Change to app-based styling';
export const CHANGE_TO_RULEBASED_STYLING = 'Change to rule-based styling';
export const CHANGE_TO_CUSTOM_STYLING = 'Change to custom styling';

export function getDefaultStylingPriority(
  hasFsrsOnLayer: boolean
): StylingPriority {
  return hasFsrsOnLayer ? StylingPriority.Rulebased : StylingPriority.Custom;
}

export function getStylingName(stylingPriority: StylingPriority): string {
  switch (stylingPriority) {
    case StylingPriority.Appbased:
      return 'App-based styling';
    case StylingPriority.Rulebased:
      return 'Rule-based styling';
    case StylingPriority.Custom:
      return 'Custom styling';
  }
}
