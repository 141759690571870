import { BasemapId } from '../../../../../lib/olbm/layer/basemap/types';

const fullExtentInEpsg4326 = [96.793921, -35.258506, 129.323767, -10.409562];

export default {
  index: BasemapId.AU_WA_AERIAL,
  title: 'Western Australia - Aerial',
  subtitle: 'WA Now Image Service',
  type: 'satellite',
  options: {
    attributions: 'Landgate / SLIP',
    url: 'https://token.slip.wa.gov.au/arcgis/rest/services/Landgate_Subscription_Imagery/WANow/ImageServer',
    token: import.meta.env.VITE_SLIP_TOKEN,
    extent: fullExtentInEpsg4326,
    visibleExtent: fullExtentInEpsg4326,
    maxImageSize: {
      width: 15000,
      height: 4100,
    },
    projection: 'EPSG:4283',
    isImageMapLayer: true,
    type: 'service_layer',
  },
};
