import {
  Country,
  STATE_AU_SOUTH_AUSTRALIA,
} from '../../../../../common/address';
import {
  AxisOrientation,
  ServiceSubType,
  WmtsRequestEncoding,
} from '../../../../service/ogc-opengis/types';
import { ServiceType } from '../../../../service/types';
import { LayerType } from '../../../../types';
import type { BasemapWmtsApi } from '../../../types';
import { BasemapId } from '../../../types';
import hosts from '../../hosts';

const host = hosts.SA_AERIAL;

const basemapApi: BasemapWmtsApi = {
  id: BasemapId.AU_SA_AERIAL,
  title: 'South Australia - Aerial',
  subtitle: '',
  type: 'satellite',
  host,
  countries: [Country.Australia],
  states: [STATE_AU_SOUTH_AUSTRALIA],
  layerModel: {
    id: 'south-australia-aerial-folder',
    geojson: {
      properties: {
        type: LayerType.FOLDER,
        title: 'Location SA public mosaic image services',
        service: {
          type: ServiceType.OgcOpenGIS,
          subtype: ServiceSubType.WMTS,
          url: `https://${host}/mapproxy/wmts/1.0.0/WMTSCapabilities.xml`,
          version: '1.0.0',
          title: 'Location SA public mosaic image services',
          description: 'Location SA public mosaic image services',
          extent: [-180, -85.0511287798, 180, 85.0511287798],
          visibleExtent: [-180, -85.0511287798, 180, 85.0511287798],
          attributions: 'Aerial photo: Government for South Australia',
          shouldUseCorsProxy: 0,
          axisOrientation: AxisOrientation.ENU,
          projection: 'EPSG:3857',
        },
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [
      {
        id: 'south-australia-aerial-item',
        geojson: {
          properties: {
            type: LayerType.SERVICE,
            id: 'SAGovMosaic',
            name: 'SA Imagery and ArcGIS Online mosaic',
            extent: [-180, -85.0511287798, 180, 85.0511287798],
            format: 'image/png',
            url: `https://${host}/mapproxy/wmts/SAGovMosaic/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png`,
            matrixSet: 'webmercator_22',
            matrixIds: [
              '00',
              '01',
              '02',
              '03',
              '04',
              '05',
              '06',
              '07',
              '08',
              '09',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19',
              '20',
              '21',
            ],
            resolutions: [
              156543.03392811998, 78271.51696391999, 39135.758481959994,
              19567.879241008, 9783.939620504, 4891.969810252, 2445.984905126,
              1222.9924525615997, 611.4962262807999, 305.74811314039994,
              152.87405657047998, 76.43702828523999, 38.21851414248,
              19.109257071295996, 9.554628535647998, 4.777314267823999,
              2.3886571339119995, 1.1943285669559998, 0.5971642834779999,
              0.2985821417376, 0.14929107086935997, 0.07464553543467999,
            ],
            origins: [
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
              [-20037508.3428, 20037508.3428],
            ],
            sizes: [
              [1, 1],
              [2, 2],
              [4, 4],
              [8, 8],
              [16, 16],
              [32, 32],
              [64, 64],
              [128, 128],
              [256, 256],
              [512, 512],
              [1024, 1024],
              [2048, 2048],
              [4096, 4096],
              [8192, 8192],
              [16384, 16384],
              [32768, 32768],
              [65536, 65536],
              [131072, 131072],
              [262144, 262144],
              [524288, 524288],
              [1048576, 1048576],
              [2097152, 2097152],
            ],
            tileSizes: [
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
              [256, 256],
            ],
            parentLayerId: -1,
            subLayerIds: null,
            style: 'default',
            requestEncoding: WmtsRequestEncoding.REST,
            projection: 'EPSG:3857',
            title: 'SA Imagery and ArcGIS Online mosaic',
          },
        },
        visible: true,
        is_visible_in_basemap_figure: false,
        children: [],
      },
    ],
  },
};

export default basemapApi;
