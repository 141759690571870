export default function getFillPatternProperties(style) {
  switch (style) {
    // Transparent
    case 1:
      return {
        fillOpacity: 0,
        opacity: 1,
      };
    // Horizontal stripe
    case 2:
      return {
        fillOpacity: 0.3,
        opacity: 1,
      };
    // Vertical stripe
    case 3:
      return {
        fillOpacity: 0.3,
        opacity: 1,
      };
    // Dot
    case 4:
      return {
        fillOpacity: 0.3,
        opacity: 1,
      };
    // Circle
    case 5:
      return {
        fillOpacity: 1,
        opacity: 1,
      };
    // Cross 45 Degree
    case 6:
      return {
        fillOpacity: 1,
        opacity: 1,
      };
    // Stripe 45 Degree
    case 7:
      return {
        fillOpacity: 1,
        opacity: 1,
      };
  }

  return {
    //overlay
    fillOpacity: 0.3,
    opacity: 1,
  };
}
