<script lang="ts" setup>
import { useSubscriptionStore } from '../store/subscription';
import AlertBox from './AlertBox.vue';

defineProps<{
  featureName: string;
}>();
const subscriptionStore = useSubscriptionStore();
</script>

<template>
  <AlertBox v-if="subscriptionStore.userTier" type="warning">
    <div class="d-flex flex-row align-items-center">
      <span>
        The {{ featureName }} feature is not available in your subscription
        tier. Please upgrade to access this feature.
        <a href="#" @click.prevent="subscriptionStore.toggleTiersModal">
          Compare Plans
        </a>
      </span>
    </div>
  </AlertBox>
</template>
