import booleanContains from '@turf/boolean-contains';
import { lineString } from '@turf/helpers';
import lineIntersect from '@turf/line-intersect';
import { Feature } from 'ol';
import Transform from 'ol-ext/interaction/Transform';
import 'ol-ext/render/Cspline';
import Collection from 'ol/Collection';
import * as olCoordinate from 'ol/coordinate';
import {
  altKeyOnly,
  always,
  doubleClick,
  singleClick,
} from 'ol/events/condition';
import { boundingExtent, buffer as bufferExtent } from 'ol/extent';
import { LineString, Point } from 'ol/geom';
import { Modify } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import RBush from 'ol/structs/RBush';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { getUid } from 'ol/util';
import {
  checkIsEndCoord,
  checkIsFirstCoordinate,
  checkIsLastCoordinate,
  findCoordinateIndex,
  fromLonLat,
  legacyLatLngToLonLat,
} from '../common/coordinate';
import { toGeoJSON } from '../common/geojson';
import { getStoreApi } from '../common/store-api';
import { normalizePolyline } from '../layer/shape/polyline';
import type { SingleShapeLayerModel } from '../layer/shape/types';
import { LayerType } from '../layer/types';
import { getMaxZIndex, getModel, getModelId } from '../layer/utils';
import { getPixelDistance } from '../measurement/medium';
import type { Pixel } from '../measurement/types';
import changeTransformStyle from '../style/changeTransformStyle';
import InteractionManager from './InteractionManager';
import createDrawStyle from './createDrawStyle';

/**
 * Concetps:
 * 1. Main Modify
 * A Modify object which is used to convert segements of a LineString to a Cardinal Spline.
 * The cspline function of ol-ext is used here.
 *
 * 2. Curve Modify
 * A Modify object which is used to edit the curve. A curve can be changed by dragging points on it.
 *
 * 3. Mark
 * The index of a coordinate in a coordinate array.
 *
 * 4. Control Point
 * A point which is fed to the cspline function.
 *
 * 5. Curve Spec
 * An object which records the marks of control points on the curve. It is read from and wrote to
 * the geojson of the layer.
 *
 * 6. Segment
 * A segment is a line that can't be divided further. It is represented by an array with two items
 * which are the start coordinate and end coordinate.
 *
 * 7. Control Point Layer
 * A vector layer that draws the control points of all curves.
 *
 * 8. Curve RBush
 * A RBush used to store segments of curves.
 *
 * 9. Vertex
 * The coordinate of the editing vertex of the main modify.
 *
 * 10. Modification Point
 * A point on a curve that can be dragged around to edit the curve.
 *
 */

const CURVES_CHANGE = 'curvesChange';

type PolylineIntersectionIndicatorLayer = VectorLayer<VectorSource<Point>> & {
  options: { type: LayerType };
  intersection: any;
  getIntersection();
  show(intersection: any): void;
  hide(): void;
};

function checkIsAutoConnectEnabled(): boolean {
  return true;
}

// @ts-ignore
class MainModify extends Modify {
  interactionManager: InteractionManager;
  layerType: LayerType;
  controlPointLayer: VectorLayer<VectorSource<Point>>;
  curves: any[];
  curveRBush: RBush<any>;
  polyCoordsBeforeTransform: any[];
  polylineIntersectionIndicatorLayer: PolylineIntersectionIndicatorLayer;

  constructor(
    interactionManager: InteractionManager,
    layerType: LayerType,
    features: Collection<Feature<any>>,
    polylineIntersectionIndicatorLayer: PolylineIntersectionIndicatorLayer
  ) {
    super({
      features,
      style: createDrawStyle(interactionManager.getMap(), {
        getPointerTip: () => {
          if (this.checkIsVertexOnCurve()) {
            // The pointer tip of CurveModify supersedes.
            return '';
          }

          const pointerTips = ['Drag to modify'];
          if (this.checkIsNewCurveAvailable()) {
            pointerTips.push('or single click + Alt to create a curve');
          }
          if (this.checkCanVertexBeDeleted()) {
            pointerTips.push('or double click to delete this vertex');
          }

          return `${pointerTips.join(', ')}.`;
        },
      }),
      deleteCondition: doubleClick,
    });

    this.interactionManager = interactionManager;
    this.layerType = layerType;
    this.controlPointLayer = new VectorLayer({
      source: new VectorSource(),
      style: (cpFeature) =>
        new Style({
          image: new Circle({
            radius: 6,
            fill: new Fill({
              color: 'rgba(255, 255, 255, 1)',
            }),
            stroke: new Stroke({
              color: this.getControlPointStrokeColor(cpFeature),
              width: 1.5,
            }),
          }),
          zIndex: Infinity,
        }),
    });
    this.curves = [];
    this.curveRBush = new RBush();
    this.polyCoordsBeforeTransform = [];
    this.polylineIntersectionIndicatorLayer =
      polylineIntersectionIndicatorLayer;
    this.on('modifyend', (evt) => {
      if (!checkIsAutoConnectEnabled()) {
        return;
      }

      const { coordinate: coord } = evt.mapBrowserEvent;
      const intersection =
        this.polylineIntersectionIndicatorLayer.getIntersection();
      if (intersection) {
        // @ts-ignore
        const feature = this.features_.item(0);
        const geom = feature.getGeometry();
        const coords = feature.getGeometry().getCoordinates();
        if (checkIsFirstCoordinate(coords, coord)) {
          geom.setCoordinates([intersection.coordinate, ...coords.slice(1)]);
        } else if (checkIsLastCoordinate(coords, coord)) {
          geom.setCoordinates([
            ...coords.slice(0, coords.length - 1),
            intersection.coordinate,
          ]);
        }

        this.polylineIntersectionIndicatorLayer.hide();
      }
    });
  }
  handlePolylineIntersection_(coord) {
    const map = this.getMap();
    const proj = map.getView().getProjection();
    // @ts-ignore
    const feature = this.features_.item(0);
    const gFeature = toGeoJSON(map, feature);

    const coords = feature.getGeometry().getCoordinates();
    const isEndCoord = checkIsEndCoord(coords, coord);
    if (isEndCoord) {
      const polylineLayers =
        this.interactionManager.layerManager.getPolylineLayersInViewport();
      const intersections: any[] = [];
      polylineLayers.forEach((pl) => {
        const polylineFeature = pl.getFirstFeature();
        const gPolylineFeature = toGeoJSON(map, polylineFeature);
        const gIntersectionFeatureCollection = lineIntersect(
          // @ts-ignore
          gFeature,
          gPolylineFeature
        );
        gIntersectionFeatureCollection.features.forEach(
          (gIntersectionFeature) => {
            const [lng, lat] = gIntersectionFeature.geometry.coordinates;
            let intersectionCoord = fromLonLat(
              legacyLatLngToLonLat({ lat, lng }),
              proj
            );

            // Snap to the first or last end coordinate if it should.
            const polylineGeom = polylineFeature.getGeometry();
            const firstCoord = polylineGeom.getFirstCoordinate();
            const lastCoord = polylineGeom.getLastCoordinate();
            const intersectionPixel = map.getPixelFromCoordinate(
              intersectionCoord
            ) as Pixel;
            const firstPixel = map.getPixelFromCoordinate(firstCoord) as Pixel;
            const lastPixel = map.getPixelFromCoordinate(lastCoord) as Pixel;
            if (getPixelDistance(intersectionPixel, firstPixel) < 1) {
              intersectionCoord = firstCoord;
            } else if (getPixelDistance(intersectionPixel, lastPixel) < 1) {
              intersectionCoord = lastCoord;
            }

            intersections.push({
              intersectedLineId: pl.databaseLayerId,
              coordinate: intersectionCoord,
            });
          }
        );
      });

      intersections.sort((intersection1, intersection2) => {
        const pixel = map.getPixelFromCoordinate(coord) as Pixel;
        const pixel1 = map.getPixelFromCoordinate(
          intersection1.coordinate
        ) as Pixel;
        const pixel2 = map.getPixelFromCoordinate(
          intersection2.coordinate
        ) as Pixel;
        return (
          getPixelDistance(pixel, pixel1) - getPixelDistance(pixel, pixel2)
        );
      });

      if (intersections.length > 0) {
        this.polylineIntersectionIndicatorLayer.show(intersections[0]);
      } else {
        this.polylineIntersectionIndicatorLayer.hide();
      }
    }
  }
  handleDragEvent(evt) {
    super.handleDragEvent(evt);

    if (this.layerType === LayerType.POLYGON || !checkIsAutoConnectEnabled()) {
      return;
    }

    this.handlePolylineIntersection_(this.getVertex());
  }
  onBeforeTransform() {
    this.polyCoordsBeforeTransform = this.getPolyCoordinates();
  }
  onDuringTransform() {
    const curves = [...this.curves];

    this.removeCurves(curves);

    const polyCoords = this.getPolyCoordinates();
    for (const item of curves) {
      // There are no coordinate addition and deletion during transform.
      item.controlPoints = this.getControlPointCoords(item).map(
        (item2, index, array) => {
          let cpMark = findCoordinateIndex(
            this.polyCoordsBeforeTransform,
            item2
          );
          if (index === array.length - 1) {
            cpMark = this.correctEndMark(cpMark);
          }
          return { coord: polyCoords[cpMark] };
        }
      );
      this.addCurve(item, polyCoords);
    }
    this.polyCoordsBeforeTransform = polyCoords;
  }
  onAfterTransform() {
    this.polyCoordsBeforeTransform = [];
  }
  writeCurves() {
    const polyCoords = this.getPolyCoordinates();
    return this.curves.map((item) => ({
      controlPointMarks: this.getControlPointCoords(item).map(
        (item2, index, array) => {
          let cpMark = findCoordinateIndex(polyCoords, item2);
          if (index === array.length - 1) {
            cpMark = this.correctEndMark(cpMark);
          }
          return cpMark;
        }
      ),
    }));
  }
  readCurves(curveSpecs) {
    this.removeCurves(this.curves);

    const polyCoords = this.getPolyCoordinates();
    for (const item of curveSpecs) {
      const curve = {
        controlPoints: item.controlPointMarks.map((item2) => ({
          coord: polyCoords[item2],
        })),
      };
      this.addCurve(curve, polyCoords);
    }
  }
  setMap(map) {
    this.controlPointLayer.setMap(map);
    super.setMap(map);
  }
  getVertex() {
    // @ts-ignore
    return this.vertexFeature_?.getGeometry().getCoordinates();
  }
  checkIsVertexOnCurve() {
    // @ts-ignore
    if (!this.vertexFeature_) {
      return false;
    }

    const vertex = this.getVertex();
    const containingCurves = this.getContainingCurves(vertex);

    return !!containingCurves.length;
  }
  checkCanVertexBeDeleted() {
    const polyCoords = this.getPolyCoordinates();
    const polyGeom = this.getPolyGeometry();
    const minVertexCount = polyGeom.getType() === 'LineString' ? 2 : 4;
    // @ts-ignore
    return this.snappedToVertex_ && polyCoords.length > minVertexCount;
  }
  checkIsNewCurveAvailable() {
    // @ts-ignore
    if (!this.vertexFeature_ || !this.snappedToVertex_) {
      return false;
    }

    if (this.checkIsVertexOnCurve()) {
      return false;
    }

    const vertex = this.getVertex();
    const polyCoords = this.getPolyCoordinates();
    const vertexMark = findCoordinateIndex(polyCoords, vertex);

    return vertexMark > 0 && vertexMark < polyCoords.length - 1;
  }
  handleEvent(evt) {
    if (!evt.originalEvent) {
      return true;
    }

    const checkIsCreateCurveCondition = (evt) =>
      altKeyOnly(evt) && singleClick(evt);
    if (this.checkIsNewCurveAvailable() && checkIsCreateCurveCondition(evt)) {
      const vertex = this.getVertex();
      this.createCurve(vertex);
      this.mergeCurves();
      return false;
    }

    return super.handleEvent(evt);
  }
  // A callback called by OpenLayers.
  writeLineStringGeometry_(feature, geometry) {
    const coords = geometry.getCoordinates();
    for (let i = 0, ii = coords.length - 1; i < ii; i++) {
      const segment = coords.slice(i, i + 2);

      // Segments on a curve are excluded so that no editing vertex appears on a curve.
      const containingCurves = this.getContainingCurves(segment);
      if (containingCurves.length) {
        continue;
      }

      /** @type {SegmentData} */
      const segmentData = {
        feature: feature,
        geometry: geometry,
        index: i,
        segment: segment,
      };

      // @ts-ignore
      this.rBush_.insert(boundingExtent(segment), segmentData);
    }
  }
  getPolyFeature() {
    // @ts-ignore
    return this.features_.item(0);
  }
  getPolyGeometry() {
    return this.getPolyFeature().getGeometry();
  }
  getPolyCoordinates() {
    const polyGeom = this.getPolyGeometry();
    const type = polyGeom.getType();
    const coords = polyGeom.getCoordinates();
    if (type === 'Polygon') {
      return coords[0];
    } else if (type === 'LineString') {
      return coords;
    }
    return [];
  }
  updatePolyCoords(value) {
    const polyGeom = this.getPolyGeometry();
    const type = polyGeom.getType();
    if (type === 'Polygon') {
      polyGeom.setCoordinates([value]);
    } else if (type === 'LineString') {
      polyGeom.setCoordinates(value);
    }
  }
  getControlPointStrokeColor(cpFeature) {
    const polyCoords = this.getPolyCoordinates();
    const cpCoord = cpFeature.getGeometry().getCoordinates();
    const cpMark = findCoordinateIndex(polyCoords, cpCoord);
    return (cpMark === 0 || cpMark === polyCoords.length - 1) &&
      this.getPolyGeometry().getType() === 'Polygon'
      ? 'rgba(49, 28, 23, 1)' // Eclipse colour
      : 'rgba(255, 0, 0, 1)';
  }
  getControlPointCoords(curve) {
    return curve.controlPoints.map((item) => item.coord);
  }
  getNewControlPointIndex(mp) {
    const {
      coord,
      closestValue: { curveId, segment },
      isControlPoint,
    } = mp;
    const curve = this.findCurveById(curveId);
    const cpCoords = this.getControlPointCoords(curve);

    if (isControlPoint) {
      for (let i = 0, ii = cpCoords.length; i < ii; i++) {
        if (olCoordinate.equals(coord, cpCoords[i])) {
          return i;
        }
      }
    } else {
      const polyCoords = this.getPolyCoordinates();
      for (let i = 0, ii = cpCoords.length; i < ii; i++) {
        let cpMark = findCoordinateIndex(polyCoords, cpCoords[i]);
        if (i === ii - 1) {
          cpMark = this.correctEndMark(cpMark);
        }
        const segmentStartMark = findCoordinateIndex(polyCoords, segment[0]);
        if (cpMark < segmentStartMark) {
          continue;
        } else {
          return i;
        }
      }
    }
  }
  getControlPointCount(curve) {
    return curve?.controlPoints.length ?? 0;
  }
  getClosestControPointInfo(curve, coord) {
    const map = this.getMap();
    const pixel = map.getPixelFromCoordinate(coord);
    const pixelDistances = {};
    const sortByDistance = (a, b) => {
      const pixel1 = map.getPixelFromCoordinate(
        a.getGeometry().getCoordinates()
      );
      const pixel2 = map.getPixelFromCoordinate(
        b.getGeometry().getCoordinates()
      );
      const pixelDistance1 =
        pixelDistances[getUid(a)] ??
        (pixelDistances[getUid(a)] = olCoordinate.distance(pixel, pixel1));
      const pixelDistance2 =
        pixelDistances[getUid(b)] ??
        (pixelDistances[getUid(b)] = olCoordinate.distance(pixel, pixel2));
      return pixelDistance1 - pixelDistance2;
    };
    const polyCoords = this.getPolyCoordinates();
    const closestCpFeature = this.controlPointLayer
      .getSource()
      .getFeatures()
      .filter((feature) => feature.get('curveId') === getUid(curve))
      .sort(sortByDistance)[0];
    const closestCpCoord = closestCpFeature.getGeometry()!.getCoordinates();
    let closestCpMark = findCoordinateIndex(polyCoords, closestCpCoord);
    if (olCoordinate.equals(closestCpCoord, this.getCurveEnd(curve))) {
      closestCpMark = this.correctEndMark(closestCpMark);
    }

    let closestSegmentStartMark = closestCpMark;
    const end = this.getCurveEnd(curve);
    const endMark = this.correctEndMark(findCoordinateIndex(polyCoords, end));
    if (closestCpMark === endMark) {
      closestSegmentStartMark = closestCpMark - 1;
    }

    return {
      feature: closestCpFeature,
      value: {
        curveId: getUid(curve),
        segment: [
          polyCoords[closestSegmentStartMark],
          polyCoords[closestSegmentStartMark + 1],
        ],
      },
      pixelDistance: pixelDistances[getUid(closestCpFeature)],
    };
  }
  findCurveById(id) {
    return this.curves.find((item) => getUid(item) === id);
  }
  getCurveStart(curve) {
    const coords = this.getControlPointCoords(curve);
    return coords[0];
  }
  getCurveEnd(curve) {
    const coords = this.getControlPointCoords(curve);
    return coords[coords.length - 1];
  }
  correctEndMark(endMark) {
    return endMark === 0 ? this.getPolyCoordinates().length - 1 : endMark;
  }
  createCurve(coord) {
    const polyCoords = this.getPolyCoordinates();
    const mark = findCoordinateIndex(polyCoords, coord);

    if (mark < 1 || mark > polyCoords.length - 1) {
      throw `Can not create a curve at the coordinate with mark: ${mark}`;
    }

    let left: any = [polyCoords[mark - 1], polyCoords[mark]]; // left is a segment in the beginning.
    let right: any = [polyCoords[mark], polyCoords[mark + 1]]; // right is a segmen in the beginning.

    const leftContainingCurves = this.getContainingCurves(left);
    if (leftContainingCurves.length) {
      left = leftContainingCurves[0];
    }
    const rightContainingCurves = this.getContainingCurves(right);
    if (rightContainingCurves.length) {
      right = rightContainingCurves[0];
    }

    const leftStart = left.controlPoints ? this.getCurveStart(left) : left[0];
    const leftStartMark = findCoordinateIndex(polyCoords, leftStart);
    const leftEnd = left.controlPoints ? this.getCurveEnd(left) : left[1];
    const leftEndMark = this.correctEndMark(
      findCoordinateIndex(polyCoords, leftEnd)
    );
    const rightStart = right.controlPoints
      ? this.getCurveStart(right)
      : right[0];
    const rightStartMark = findCoordinateIndex(polyCoords, rightStart);
    const rightEnd = right.controlPoints ? this.getCurveEnd(right) : right[1];
    const rightEndMark = this.correctEndMark(
      findCoordinateIndex(polyCoords, rightEnd)
    );

    const cpCoords = [
      ...(left.controlPoints?.map((item) => item.coord) ?? left),
      ...(right.controlPoints?.map((item) => item.coord) ?? right).slice(1),
    ];
    const curve = {
      controlPoints: cpCoords.map((item) => ({ coord: item })),
    };
    // @ts-ignore
    const curveGeom = new LineString(cpCoords).cspline();
    normalizePolyline(curveGeom);

    const staleCurveIds: any = [];
    if (left.controlPoints) {
      staleCurveIds.push(getUid(left));
    }
    if (right.controlPoints) {
      staleCurveIds.push(getUid(right));
    }
    if (staleCurveIds.length) {
      this.removeCurves(staleCurveIds.map((item) => this.findCurveById(item)));
    }

    const newPolyCoords = [...polyCoords];
    newPolyCoords.splice(
      leftStartMark,
      leftEndMark -
        leftStartMark +
        1 /* number of coordinates in the left */ +
        rightEndMark -
        rightStartMark +
        1 /* number of coordinates in the right */ -
        1 /* the overlap between the left and the right */,
      ...curveGeom.getCoordinates()
    );
    this.addCurve(curve, newPolyCoords);
  }
  // A new curve brings the change of poly coordinates.
  addCurve(curve, newPolyCoords) {
    this.curves.push(curve);

    // Update the poly coordinates.
    this.updatePolyCoords(newPolyCoords);

    // Update the curves RBush.
    const curveId = getUid(curve);
    const start = this.getCurveStart(curve);
    const end = this.getCurveEnd(curve);
    const startMark = findCoordinateIndex(newPolyCoords, start);
    const endMark = this.correctEndMark(
      findCoordinateIndex(newPolyCoords, end)
    );
    const curveCoords = newPolyCoords.slice(startMark, endMark + 1);
    for (let i = 0, ii = curveCoords.length - 1; i < ii; i++) {
      const segment = curveCoords.slice(i, i + 2);
      const value = {
        curveId,
        segment,
      };
      this.curveRBush.insert(boundingExtent(segment), value);
    }

    // Update the curve control point layer.
    const cpSource = this.controlPointLayer.getSource();
    for (const item of this.getControlPointCoords(curve)) {
      const cpFeature = new Feature(new Point(item));
      cpFeature.set('curveId', curveId);
      cpSource.addFeature(cpFeature);
    }

    // @ts-ignore
    this.dispatchEvent({ type: CURVES_CHANGE });
  }
  removeCurves(curves) {
    if (!curves.length) {
      return;
    }

    for (const item of curves) {
      // Remove the stale curve control point features.
      const cpSource = this.controlPointLayer.getSource();
      const cpFeatures = cpSource.getFeatures();
      const staleCpFeatures = cpFeatures.filter(
        (item2) => item2.get('curveId') === getUid(item)
      );
      for (const item2 of staleCpFeatures) {
        cpSource.removeFeature(item2);
      }

      // Remove the stale values in the curve RBush.
      const staleValues: any[] = [];
      this.curveRBush.forEach((value) => {
        if (value.curveId === getUid(item)) {
          staleValues.push(value);
        }
      });
      for (const item2 of staleValues) {
        this.curveRBush.remove(item2);
      }

      // Remove the curve.
      const index = this.curves.indexOf(item);
      this.curves.splice(index, 1);
    }

    // @ts-ignore
    this.dispatchEvent({ type: CURVES_CHANGE });
  }
  getContainingCurves(coordOrSegment) {
    const isCoord = typeof coordOrSegment[0] === 'number';

    if (!isCoord) {
      const polyCoords = this.getPolyCoordinates();
      const turfSegment = lineString(coordOrSegment);
      // The retuned curve array has one item at most.
      return this.curves.filter((item) => {
        const start = this.getCurveStart(item);
        const end = this.getCurveEnd(item);
        const startMark = findCoordinateIndex(polyCoords, start);
        const endMark = this.correctEndMark(
          findCoordinateIndex(polyCoords, end)
        );
        // Marks can not be found because this.curves has not
        // been synced with the poly coordinates. E.g. after the
        // poly is been transformed.
        if (startMark === -1 || endMark === -1) {
          return false;
        }
        const turfCurve = lineString(polyCoords.slice(startMark, endMark + 1));

        return booleanContains(turfCurve, turfSegment);
      });
    }

    const values = this.getCurveRBushValues(coordOrSegment);
    if (values.length) {
      const curveIds = values.reduce((accu, item) => {
        const { curveId } = item;
        if (!accu.includes(curveId)) {
          accu.push(curveId);
        }
        return accu;
      }, []);
      return curveIds.map((item) => this.findCurveById(item));
    }

    return [];
  }
  getCurveRBushValues(coord) {
    const buffer =
      // @ts-ignore
      this.getMap().getView().getResolution() * this.pixelTolerance_;
    const extent = bufferExtent(boundingExtent([coord]), buffer);
    return this.curveRBush.getInExtent(extent);
  }
  updateCurve(curve, cpCountBeforeUpdate, newCpIndex, newCpCoord, replace) {
    const polyCoords = this.getPolyCoordinates();
    const start = this.getCurveStart(curve);
    const end = this.getCurveEnd(curve);
    const startMark = findCoordinateIndex(polyCoords, start);
    const endMark = this.correctEndMark(findCoordinateIndex(polyCoords, end));
    const cpCoords = this.getControlPointCoords(curve);
    const newCpCoords = [...cpCoords];
    newCpCoords.splice(
      newCpIndex,
      this.getControlPointCount(curve) -
        cpCountBeforeUpdate +
        (replace ? 1 : 0),
      newCpCoord
    );
    // @ts-ignore
    const newCurveGeom = new LineString(newCpCoords).cspline();
    normalizePolyline(newCurveGeom);
    const newCurveCoords = newCurveGeom.getCoordinates();

    // If the newCpCoord is too close to the coordinate of the start or end control point,
    // the coordinates returned from the cspline function could not include these endpoint
    // coordinates. Skip updating in this case.
    if (
      findCoordinateIndex(newCurveCoords, newCpCoords[0]) === -1 ||
      findCoordinateIndex(
        newCurveCoords,
        newCpCoords[newCpCoords.length - 1]
      ) === -1
    ) {
      return;
    }

    const newCurve = {
      ol_uid: getUid(curve),
      controlPoints: newCpCoords.map((item) => ({ coord: item })),
    };

    this.removeCurves([curve]);
    const newPolyCoords = [...polyCoords];
    newPolyCoords.splice(startMark, endMark - startMark + 1, ...newCurveCoords);
    this.addCurve(newCurve, newPolyCoords);
  }
  mergeCurves() {
    const polyCoords = this.getPolyCoordinates();
    const curves = [...this.curves];
    curves.sort((a, b) => {
      const start1 = this.getCurveStart(a);
      const startMark1 = findCoordinateIndex(polyCoords, start1);
      const start2 = this.getCurveStart(b);
      const startMark2 = findCoordinateIndex(polyCoords, start2);

      return startMark1 - startMark2;
    });

    let jointMark = -1;
    for (let i = 0; i < curves.length; i++) {
      const end = this.getCurveEnd(curves[i]);
      const endMark = this.correctEndMark(findCoordinateIndex(polyCoords, end));
      for (let j = i + 1; j < curves.length; j++) {
        const start = this.getCurveStart(curves[j]);
        const startMark = findCoordinateIndex(polyCoords, start);
        if (endMark === startMark) {
          jointMark = endMark;
          break;
        }
      }
      if (jointMark !== -1) {
        break;
      }
    }

    if (jointMark !== -1) {
      this.createCurve(polyCoords[jointMark]);
      this.mergeCurves();
    }
  }
}

// @ts-ignore
class CurveModify extends Modify {
  mp: any;
  mainModify: MainModify;
  handleModificationPointChange: Function | null;

  constructor(interactionManager: InteractionManager, mainModify) {
    super({
      source: new VectorSource(),
      style: createDrawStyle(interactionManager.getMap(), {
        getSnappedToVertex: (map, featureBeingModified, pointerCoordinate) => {
          return this.mp?.isControlPoint;
        },
        getPointerTip: () => {
          const pointerTips = ['Drag to modify'];
          if (this.checkCanModificationPointBeDeleted()) {
            pointerTips.push('or double click to delete this vertex');
          }

          return `${pointerTips.join(', ')}.`;
        },
      }),
      deleteCondition: doubleClick,
    });
    this.mainModify = mainModify;
    this.mp = null;
    this.handleModificationPointChange = null;
    this.on('modifyend', () => {
      if (!checkIsAutoConnectEnabled()) {
        return;
      }

      const intersection =
        this.mainModify.polylineIntersectionIndicatorLayer.getIntersection();
      if (intersection) {
        // When intersection exists the modification point definitely exist.
        // @ts-ignore
        const mpFeature = this.source_.getFeatures()[0];
        mpFeature.getGeometry().setCoordinates(intersection.coordinate);

        this.mainModify.polylineIntersectionIndicatorLayer.hide();
      }
    });
  }
  checkCanModificationPointBeDeleted() {
    if (!this.mp?.isControlPoint) {
      return false;
    }

    const {
      coord,
      closestValue: { curveId },
    } = this.mp;
    const curve = this.mainModify.findCurveById(curveId);
    const cpCoords = this.mainModify.getControlPointCoords(curve);
    const mpMark = findCoordinateIndex(cpCoords, coord);
    return mpMark > 0 && mpMark < cpCoords.length - 1;
  }
  getModificationPoint(coord) {
    const values = this.mainModify.getCurveRBushValues(coord);

    if (!values.length) {
      return null;
    }

    const sortByDistance = (a, b) => {
      return (
        olCoordinate.squaredDistanceToSegment(coord, a.segment) -
        olCoordinate.squaredDistanceToSegment(coord, b.segment)
      );
    };
    const closestValue = values.sort(sortByDistance)[0];

    const { curveId } = closestValue;
    const curve = this.mainModify.findCurveById(curveId);
    const closestCpInfo = this.mainModify.getClosestControPointInfo(
      curve,
      coord
    );
    // @ts-ignore
    if (closestCpInfo.pixelDistance <= this.pixelTolerance_) {
      return {
        coord: closestCpInfo.feature.getGeometry()!.getCoordinates(),
        closestValue: closestCpInfo.value,
        isControlPoint: true,
      };
    }

    const map = this.getMap();
    const pixel = map.getPixelFromCoordinate(coord);
    const closestSegment = closestValue.segment;
    let closestCoord = olCoordinate.closestOnSegment(coord, closestSegment);
    const closestPixel = map.getPixelFromCoordinate(closestCoord);
    let dist = olCoordinate.distance(pixel, closestPixel);
    // @ts-ignore
    if (dist <= this.pixelTolerance_) {
      const pixel1 = map.getPixelFromCoordinate(closestSegment[0]);
      const pixel2 = map.getPixelFromCoordinate(closestSegment[1]);
      const squaredDist1 = olCoordinate.squaredDistance(closestPixel, pixel1);
      const squaredDist2 = olCoordinate.squaredDistance(closestPixel, pixel2);
      dist = Math.sqrt(Math.min(squaredDist1, squaredDist2));
      // @ts-ignore
      if (dist <= this.pixelTolerance_) {
        closestCoord =
          squaredDist1 > squaredDist2 ? closestSegment[1] : closestSegment[0];
      }
      return {
        coord: closestCoord,
        closestValue,
        isControlPoint: false,
      };
    }

    return null;
  }
  // A callback called by OpenLayers.
  // Makes the CurveModify responsible for only modification points.
  // It's not called if handlingDownUpSequence is true
  handlePointerMove_(evt) {
    const { map, pixel, coordinate } = evt;
    const coord = coordinate ?? map.getCoordinateFromPixel(pixel);
    this.mp = this.getModificationPoint(coord);

    if (this.mp) {
      const {
        coord,
        closestValue: { curveId },
        isControlPoint,
      } = this.mp;
      // @ts-ignore
      let mpFeature = this.source_.getFeatures()[0];
      if (!mpFeature) {
        mpFeature = new Feature(new Point(coord));
        mpFeature.set('curveId', curveId);
        // @ts-ignore
        this.source_.addFeature(mpFeature);
      } else {
        mpFeature.getGeometry().setCoordinates(coord);
        mpFeature.un('change', this.handleModificationPointChange);
      }

      const curve = this.mainModify.findCurveById(curveId);
      const cpCountBeforeUpdate = this.mainModify.getControlPointCount(curve);
      const newCpIndex = this.mainModify.getNewControlPointIndex(this.mp);
      const polyCoords = this.mainModify.getPolyCoordinates();
      const isFirstCoord =
        newCpIndex === 0 && olCoordinate.equals(coord, polyCoords[0]);
      const curveEndsAtFirstCoord = isFirstCoord
        ? this.mainModify.curves.find((item) =>
            olCoordinate.equals(
              item.controlPoints[item.controlPoints.length - 1].coord,
              coord
            )
          )
        : null;
      const curveEndsAtFirstCoordId = curveEndsAtFirstCoord
        ? getUid(curveEndsAtFirstCoord)
        : null;
      const isLastCoord =
        newCpIndex === curve.controlPoints.length - 1 &&
        olCoordinate.equals(coord, polyCoords[polyCoords.length - 1]);
      const curveStartsAtLastCoord = isLastCoord
        ? this.mainModify.curves.find((item) =>
            olCoordinate.equals(item.controlPoints[0].coord, coord)
          )
        : null;
      const curveStartsAtLastCoordId = curveStartsAtLastCoord
        ? getUid(curveStartsAtLastCoord)
        : null;
      this.handleModificationPointChange = () => {
        if (this.handlingDownUpSequence) {
          const newMpCoord = mpFeature.getGeometry().getCoordinates();
          this.mainModify.updateCurve(
            // Always use the latest curve
            this.mainModify.findCurveById(curveId),
            cpCountBeforeUpdate,
            newCpIndex,
            newMpCoord,
            isControlPoint
          );
          this.mainModify.mergeCurves();

          if (
            this.mainModify.layerType === LayerType.POLYLINE &&
            checkIsAutoConnectEnabled()
          ) {
            this.mainModify.handlePolylineIntersection_(newMpCoord);
          }

          const polyGeom = this.mainModify.getPolyGeometry();
          const type = polyGeom.getType();
          if (!isControlPoint || type === 'LineString') {
            return;
          }

          // Handle the case that the control point is the one at ends.
          if (isFirstCoord) {
            if (curveEndsAtFirstCoordId) {
              const curveEndsAtFirstCoord = this.mainModify.findCurveById(
                curveEndsAtFirstCoordId
              );
              this.mainModify.updateCurve(
                curveEndsAtFirstCoord,
                curveEndsAtFirstCoord.controlPoints.length,
                curveEndsAtFirstCoord.controlPoints.length - 1,
                newMpCoord,
                true
              );
            } else {
              const newPolyCoords = [...this.mainModify.getPolyCoordinates()];
              newPolyCoords.splice(newPolyCoords.length - 1, 1, newMpCoord);
              this.mainModify.updatePolyCoords(newPolyCoords);
            }
          }

          if (isLastCoord) {
            if (curveStartsAtLastCoordId) {
              const curveStartsAtLastCoord = this.mainModify.findCurveById(
                curveStartsAtLastCoordId
              );
              this.mainModify.updateCurve(
                curveStartsAtLastCoord,
                curveStartsAtLastCoord.controlPoints.length,
                0,
                newMpCoord,
                true
              );
            } else {
              const newPolyCoords = [...this.mainModify.getPolyCoordinates()];
              newPolyCoords.splice(0, 1, newMpCoord);
              this.mainModify.updatePolyCoords(newPolyCoords);
            }
          }
        }
      };
      mpFeature.on('change', this.handleModificationPointChange);
    } else {
      // @ts-ignore
      this.source_.clear();
    }

    // @ts-ignore
    super.handlePointerMove_(evt);
  }
  // A callback called by OpenLayers.
  // This function is used to remove the control points.
  removeVertex_() {
    // @ts-ignore
    const { feature, geometry } = this.dragSegments_[0][0];
    const curveId = feature.get('curveId');
    const vertex = geometry.getCoordinates();

    const curve = this.mainModify.findCurveById(curveId);
    const cpCoords = this.mainModify.getControlPointCoords(curve);
    const vertexMark = findCoordinateIndex(cpCoords, vertex);
    const leftCpCoords = vertexMark > 1 ? cpCoords.slice(0, vertexMark) : null;
    const middleCpCoords =
      vertexMark >= 1 && vertexMark <= cpCoords.length - 2
        ? cpCoords.slice(vertexMark - 1, vertexMark + 2)
        : null;
    const rightCpCoords =
      vertexMark < cpCoords.length - 2
        ? cpCoords.slice(vertexMark + 1, cpCoords.length)
        : null;

    if (middleCpCoords === null) {
      // No curve to delete.
      return false;
    }

    this.mainModify.removeCurves([curve]);

    const polyCoords = this.mainModify.getPolyCoordinates();
    const newPolyCoords = [...polyCoords];
    const updatePolyCoords = (coords) => {
      const startMark = findCoordinateIndex(newPolyCoords, coords[0]);
      const endMark = this.mainModify.correctEndMark(
        findCoordinateIndex(newPolyCoords, coords[coords.length - 1])
      );
      newPolyCoords.splice(startMark, endMark - startMark + 1, ...coords);
    };
    if (leftCpCoords) {
      if (leftCpCoords.length === 2) {
        updatePolyCoords(leftCpCoords);
      } else {
        const curve = {
          controlPoints: leftCpCoords.map((item) => ({ coord: item })),
        };
        this.mainModify.addCurve(curve, newPolyCoords);
      }
    }
    updatePolyCoords(middleCpCoords);
    if (rightCpCoords) {
      if (rightCpCoords.length === 2) {
        updatePolyCoords(rightCpCoords);
      } else {
        const curve = {
          controlPoints: rightCpCoords.map((item) => ({ coord: item })),
        };
        this.mainModify.addCurve(curve, newPolyCoords);
      }
    }

    this.mainModify.updatePolyCoords(newPolyCoords);

    return false;
  }
}

export default function createPolyEdit(
  interactionManager: InteractionManager,
  layerType: LayerType
) {
  const map = interactionManager.getMap();
  const storeApi = getStoreApi(map);

  const edit: any = [];

  // The indicator layer is used to show auxiliary info, e.g. when dragging the vertex on a polyline,
  // the available intersection with another polyline is shown in this layer.

  const polylineIntersectionIndicatorLayer = new VectorLayer({
    source: new VectorSource(),
  }) as PolylineIntersectionIndicatorLayer;
  polylineIntersectionIndicatorLayer.options = {
    type: LayerType.POLYLINE_INTERSECTION_INDICATOR,
  };
  polylineIntersectionIndicatorLayer.setZIndex(getMaxZIndex());
  polylineIntersectionIndicatorLayer.show = function (intersection) {
    this.getSource().clear();

    this.intersection = intersection;
    const feature = new Feature(new Point(intersection.coordinate));
    this.getSource().addFeature(feature);
  };
  polylineIntersectionIndicatorLayer.hide = function () {
    this.getSource().clear();
    this.intersection = undefined;
  };
  polylineIntersectionIndicatorLayer.getIntersection = function () {
    return this.intersection;
  };

  // The transform interaction is from ol-ext. It is used to do square resizing, rotation and moving.
  // For usage of transform, see https://viglino.github.io/ol-ext/examples/interaction/map.interaction.transform.html
  const transform = new Transform({
    hitTolerance: 2,
    translateFeature: false,
    scale: true,
    rotate: true,
    keepAspectRatio: always,
    translate: false,
    stretch: false,
    translateBBox: true,
    enableRotatedTransform: true,
    // Don't use the Select interaction so that the shape can keep being selected
    selection: false,
    condition: (event, features) => {
      // Do nothing if the Modify is working.
      return !edit.checkIsWorking();
    },
  });

  transform.on(['rotatestart', 'translatestart', 'scalestart'], () => {
    mainModify.onBeforeTransform();
  });
  transform.on(['rotating', 'translating', 'scaling'], () => {
    mainModify.onDuringTransform();
  });
  transform.on(['rotateend', 'translateend', 'scaleend'], () => {
    mainModify.onAfterTransform();
  });

  const features = transform.getFeatures();
  edit.push(transform);

  const mainModify = new MainModify(
    interactionManager,
    layerType,
    features,
    polylineIntersectionIndicatorLayer
  );
  edit.push(mainModify);

  const curveModify = new CurveModify(interactionManager, mainModify);
  edit.push(curveModify);

  edit.selectFeature = function (feature) {
    const layer = interactionManager.layerManager.findLayerByFeature(feature);
    const layerModel = getModel(storeApi, layer)! as SingleShapeLayerModel;
    // @ts-ignore
    mainModify.on(CURVES_CHANGE, () => {
      const curveSpecs = mainModify.writeCurves();
      const { properties } = layerModel.geojson;
      layerModel.geojson.properties = {
        ...properties,
        curveSpecs,
      };
    });

    transform.select(feature, true);

    // curveSpecsData has been renamed to curveSpecs.
    const curveSpecsData = layerModel.geojson.properties.curveSpecsData ?? [];
    let curveSpecs = curveSpecsData.length
      ? curveSpecsData.map((item) => ({
          controlPointMarks: item.routeMarks,
        }))
      : layerModel.geojson.properties.curveSpecs ?? [];
    if (curveSpecs.length > 0) {
      mainModify.readCurves(curveSpecs);
    }
  };

  edit.getFeature = function () {
    return transform.getFeatures().item(0);
  };

  edit.activate = function (map) {
    this.forEach((interaction) => {
      map.addInteraction(interaction);
    });
    changeTransformStyle(transform);
    interactionManager.layerManager.addLayer(
      polylineIntersectionIndicatorLayer
    );
  };

  edit.destroy = function (map) {
    this.forEach((interaction) => {
      map.removeInteraction(interaction);
    });
    interactionManager.layerManager.removeLayer(
      polylineIntersectionIndicatorLayer
    );
  };

  edit.checkIsWorking = function () {
    const mainModifyWorking = !!mainModify
      .getOverlay()
      .getSource()
      .getFeatures().length;
    const curveModifyWorking = !!curveModify
      .getOverlay()
      .getSource()
      .getFeatures().length;
    return mainModifyWorking || curveModifyWorking;
  };

  edit.getState = function () {
    const { layerManager } = interactionManager;
    const feature = this.getFeature();
    const layer = layerManager.findLayerByFeature(feature);

    return {
      layerModelId: getModelId(layer),
    };
  };

  return edit;
}
