<template>
  <div v-html="displayValue"></div>
</template>

<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  computed: {
    displayValue() {
      const { value } = this.value;
      return value;
    },
  },
};
</script>
