<script setup lang="ts">
import TitleBar from '../TitleBar.vue';
import Toggle from '../Toggle.vue';
import { injectContext } from './context';

withDefaults(
  defineProps<{
    hasArea?: boolean;
    hasLength?: boolean;
  }>(),
  {
    hasArea: true,
    hasLength: true,
  }
);

const { getShapeProperty, setShapeProperty } = injectContext();
</script>

<template>
  <div>
    <TitleBar class="mb-2"> Geometry </TitleBar>

    <Toggle
      name="showLabel"
      label="Show label"
      :checked="getShapeProperty('shouldShowLabel', false)"
      @change="(checked) => setShapeProperty('shouldShowLabel', checked)"
    />

    <Toggle
      v-if="hasArea"
      name="showAreaMeasurement"
      label="Show area measurement"
      :checked="getShapeProperty('areaMeasurement', false)"
      @change="(checked) => setShapeProperty('areaMeasurement', checked)"
    />

    <Toggle
      v-if="hasLength"
      name="showLengthMeasurement"
      label="Show length measurement"
      :checked="getShapeProperty('outlineMeasurement', false)"
      @change="(checked) => setShapeProperty('outlineMeasurement', checked)"
    />
  </div>
</template>
