import { Geometry } from 'ol/geom';
import {
  Image as ImageLayer,
  Tile as TileLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import { ImageWMS, Vector as VectorSource, WMTS } from 'ol/source';
import { Extent } from '../../../common/types';
import type { ShapeProperties } from '../../shape/types';
import type { LayerMixin, LayerModel, Properties } from '../../types';
import type { Service } from '../types';

export enum ServiceSubType {
  WFS = 'ogc_opengis_wfs',
  WMS = 'ogc_opengis_wms',
  WMTS = 'ogc_opengis_wmts',
}

export enum AxisOrientation {
  ENU = 'enu',
  NEU = 'neu',
}

export type OgcOpenGisService<T extends ServiceSubType> = Service & {
  subtype: T;
  version: string;
  axisOrientation: AxisOrientation;
};

// WFS
export type WfsService = OgcOpenGisService<ServiceSubType.WFS>;

export type WfsRenderer = {
  type: ServiceSubType.WFS;
  properties: ShapeProperties & {
    isForServiceLayer: true;
  };
};

export type WfsFolderProperties = Properties & {
  service: WfsService;
};

export type WfsItemProperties = Properties & {
  id: string;
  name: string;
  crs: string;
  extent: Extent;
  outputFormat: string;
  parentLayerId: number;
  subLayerIds: null;
  renderer: WfsRenderer | undefined;
};

export type WfsFolderLayerModel = LayerModel<WfsFolderProperties> & {
  children: WfsItemLayerModel[];
};

export type WfsItemLayerModel = LayerModel<WfsItemProperties>;

export type WfsLayer = VectorLayer<VectorSource<Geometry>> & LayerMixin;

// WMS
export type WmsService = OgcOpenGisService<ServiceSubType.WMS>;

export type WmsFolderProperties = Properties & {
  service: WmsService;
};

export type LegendGraphic = {
  title: string;
  url: string;
  size: [number, number];
};

export type WmsItemProperties = Properties & {
  url: string;
  id: string;
  name: string;
  extent: Extent;
  parentLayerId: number;
  subLayerIds: null;
  projection: string;
  legendGraphics: LegendGraphic[];
};

export type WmsFolderLayerModel = LayerModel<WmsFolderProperties> & {
  children: WmsItemLayerModel[];
};

export type WmsItemLayerModel = LayerModel<WmsItemProperties>;

export type WmsLayer = ImageLayer<ImageWMS> & LayerMixin;

// WMTS
export enum WmtsRequestEncoding {
  REST = 'REST',
  KVP = 'KVP',
}

export type WmtsService = OgcOpenGisService<ServiceSubType.WMTS>;

export type WmtsFolderProperties = Properties & {
  service: WmtsService;
};

export type WmtsItemProperties = Properties & {
  id: string;
  name: string;
  extent: Extent;
  format: string;
  url: string;
  matrixSet: string;
  matrixIds: string[];
  resolutions: number[];
  origins: [number, number][];
  sizes: [number, number][];
  tileSizes: [number, number][];
  parentLayerId: -1;
  subLayerIds: null;
  style: string;
  requestEncoding: WmtsRequestEncoding;
  projection: string;
};

export type WmtsFolderLayerModel = LayerModel<WmtsFolderProperties> & {
  children: WmtsItemLayerModel[];
};

export type WmtsItemLayerModel = LayerModel<WmtsItemProperties>;

export type WmtsLayer = TileLayer<WMTS> & LayerMixin;

export type OgcOpenGisLayer = WfsLayer | WmsLayer | WmtsLayer;
