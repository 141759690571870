import { Map } from 'ol';
import {
  calculateRectangleRotation,
  getRectangleSidePixelDistance,
  getRectangleSides,
} from '../layer/shape/rectangle';
import addOpacity from './addOpacity';
import { Color } from './color';

const HEDGE_IMAGE_WIDTH = 2243;
const HEDGE_IMAGE_HEIGHT = 1013;
const HEDGE_IMAGE_ASPECT_RATIO = HEDGE_IMAGE_WIDTH / HEDGE_IMAGE_HEIGHT;

const createHedgeRenderer =
  (map: Map, image: HTMLImageElement, isEditing: boolean = false) =>
  (pixels, state) => {
    const { context, geometry: rectangle } = state;
    // Calculate position and rotation for the image
    const [corners] = pixels;
    const [bottomLeftCorner, , topRightCorner] = corners as any;

    // The coordinate can be undefined if the map scale is too small.
    if (!bottomLeftCorner || !topRightCorner) {
      return;
    }

    context.save();

    const rotation = calculateRectangleRotation(map, rectangle);
    const imageCenterX = (bottomLeftCorner[0] + topRightCorner[0]) / 2;
    const imageCenterY = (bottomLeftCorner[1] + topRightCorner[1]) / 2;

    // Draw the rotated image on the canvas
    const { 0: side0, 1: side1 } = getRectangleSides(rectangle) as any;
    const width =
      getRectangleSidePixelDistance(map, side0) * window.devicePixelRatio;
    const height =
      getRectangleSidePixelDistance(map, side1) * window.devicePixelRatio;
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    context.translate(imageCenterX, imageCenterY);
    context.rotate(rotation);

    let imageWidth = width;
    let imageHeight = imageWidth / HEDGE_IMAGE_ASPECT_RATIO;
    if (imageHeight > height) {
      imageHeight = height;
      imageWidth = height * HEDGE_IMAGE_ASPECT_RATIO;
    }
    const scale = imageWidth / HEDGE_IMAGE_WIDTH;
    const halfImageWidth = imageWidth / 2;
    const halfImageHeight = imageHeight / 2;

    // Draw background
    if (isEditing) {
      context.fillStyle = addOpacity(Color.Black, 0.2);
      context.fillRect(-halfWidth, -halfHeight, width, height);
    }

    // Hedge image at the center
    context.drawImage(
      image,
      -halfImageWidth,
      -halfImageHeight,
      imageWidth,
      imageHeight
    );

    if (imageWidth < width) {
      // Hedge images at the left side
      for (
        let leftStartX = -halfImageWidth;
        leftStartX > -halfWidth;
        leftStartX = leftStartX - imageWidth
      ) {
        const sx =
          leftStartX + halfWidth >= imageWidth
            ? 0
            : imageWidth - leftStartX - halfWidth;
        const sy = 0;
        const sWidth =
          leftStartX + halfWidth >= imageWidth
            ? imageWidth
            : leftStartX + halfWidth;
        const sHeight = imageHeight;
        const dx =
          leftStartX + halfWidth >= imageWidth
            ? leftStartX - imageWidth
            : -halfWidth;
        const dy = -halfImageHeight;
        const dWidth = sWidth;
        const dHeight = sHeight;
        context.drawImage(
          image,
          sx / scale,
          sy / scale,
          sWidth / scale,
          sHeight / scale,
          dx,
          dy,
          dWidth,
          dHeight
        );
      }

      // Hedge images at the right side
      for (
        let rightStartX = halfImageWidth;
        rightStartX < halfWidth;
        rightStartX = rightStartX + imageWidth
      ) {
        const sx = 0;
        const sy = 0;
        const sWidth =
          halfWidth - rightStartX >= imageWidth
            ? imageWidth
            : halfWidth - rightStartX;
        const sHeight = imageHeight;
        const dx = rightStartX;
        const dy = -halfImageHeight;
        const dWidth = sWidth;
        const dHeight = sHeight;
        context.drawImage(
          image,
          sx / scale,
          sy / scale,
          sWidth / scale,
          sHeight / scale,
          dx,
          dy,
          dWidth,
          dHeight
        );
      }
    }

    // Draw outline
    if (isEditing) {
      context.lineWidth = 2;
      context.setLineDash([20, 3, 3, 3, 3, 3, 3, 3]);
      context.strokeStyle = Color.Black;
      context.strokeRect(-halfWidth, -halfHeight, width, height);
    }

    context.rotate(-rotation);
    context.translate(-imageCenterX, -imageCenterY);
    context.restore();
  };

export default createHedgeRenderer;
