<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import useAuth from '@component-library/composables/useAuth';
import {
  getDefaultStructures,
  structureToPreview,
} from '@component-library/upload-structure';
import { CompanyUploadTitleStructure } from '@component-library/company';
import { GatherFieldOptions } from '@component-library/gather';

import InputCheckbox from '@component-library/components/InputCheckbox.vue';

const auth = useAuth();

const props = defineProps<{
  options: GatherFieldOptions;
}>();

const emit = defineEmits(['updateOptions']);

const selectedFormatId = ref<string | null>(null);

const formats = computed<CompanyUploadTitleStructure[]>(() => {
  const companyStructures = auth.user().company.upload_title_structures;
  return companyStructures && companyStructures.length > 0
    ? companyStructures
    : getDefaultStructures();
});

const allowInputTitle = computed({
  get: () => props.options.rpd_allow_custom_title || false,
  set: (value: boolean) => {
    emit('updateOptions', {
      ...props.options,
      rpd_allow_custom_title: value,
    });
  },
});

const usePresetFormat = computed({
  get: () => props.options.use_preset_format || false,
  set: (value: boolean) => {
    emit('updateOptions', {
      ...props.options,
      use_preset_format: value,
    });
  },
});

const updateFormat = () => {
  emit('updateOptions', {
    ...props.options,
    selected_lab_title_format_id: selectedFormatId.value,
  });
};

onMounted(() => {
  if (props.options.selected_lab_title_format_id) {
    selectedFormatId.value = props.options.selected_lab_title_format_id;
    return;
  }

  if (formats.value.length > 0) {
    selectedFormatId.value = formats.value[0].id;
  }
});
</script>

<template>
  <div>
    <InputCheckbox
      v-model="usePresetFormat"
      label="Would you like to use a preset format?"
    />

    <template v-if="usePresetFormat">
      <div class="form-group mb-2">
        <label class="form-label" for="labFormat"> Lab Format </label>
        <select
          class="form-select"
          v-model="selectedFormatId"
          @change="updateFormat"
        >
          <option v-for="format in formats" :value="format.id">
            {{ structureToPreview(format.structure) }}
          </option>
        </select>
      </div>

      <InputCheckbox
        v-model="allowInputTitle"
        label="Would you like to allow an inputted item title?"
      />
    </template>
  </div>
</template>
