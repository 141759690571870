import { Map } from 'ol';
import { getUid } from 'ol/util';
import { Code, throwError } from './error';
import { withLoadManager } from './LoadManager';
import { MapType } from './types';

export const createWaitForImage = (
  image: HTMLImageElement,
  fallbackUrl: string
): (() => Promise<undefined>) =>
  withLoadManager(
    () =>
      new Promise((resolve) => {
        image.onload = () => resolve(undefined);
        image.onerror = () => {
          image.src = fallbackUrl;
        };
      })
  );

export { getUid };

export function convertTextToLines(
  ctx: CanvasRenderingContext2D,
  text: string,
  maxWidth: number
): string[] {
  const words = text.split(' ');
  const lines: string[] = [];
  let line = '';
  words.forEach(function (word) {
    const testLine = `${line}${word} `;
    const { width: testWidth } = ctx.measureText(testLine);
    if (testWidth > maxWidth) {
      lines.push(line);
      line = `${word} `;
    } else {
      line = testLine;
    }
  });
  lines.push(line);
  return lines;
}

export function wrapText(text: string, font: string, maxWidth: number): string {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error(`Can't get the context.`);
  }

  ctx.font = font;

  const lines = convertTextToLines(ctx, text, maxWidth);

  return lines.join('\n');
}

export function appendAsterisk(text: string): string {
  return `${text} *`;
}

export type RotationListeners = {
  onRotateStart: () => void;
  onRotate: (angle: number) => void;
  onRotateEnd: () => void;
};

type PointerEvent = MouseEvent | TouchEvent;

export function enableRotation(
  el: HTMLElement,
  initialAngle: number = 0,
  listeners?: RotationListeners
) {
  const parentEl = el.parentElement;
  if (!parentEl) {
    throw new Error('The element does not have a parent element.');
  }

  rotate(initialAngle);
  parentEl.removeChild(el);

  const containerEl = document.createElement('div');
  containerEl.style.position = 'relative';
  containerEl.style.padding = '20px';
  containerEl.appendChild(el);
  parentEl.appendChild(containerEl);

  const handleEl = document.createElement('div');
  handleEl.title = 'Rotate the icon';
  handleEl.style.position = 'absolute';
  handleEl.style.right = '0px';
  handleEl.style.bottom = '0px';
  handleEl.style.width = '20px';
  handleEl.style.height = '20px';
  handleEl.style.backgroundColor = 'white';
  handleEl.style.border = '1px solid black';
  handleEl.style.borderRadius = '50%';
  handleEl.style.cursor = 'pointer';
  containerEl.appendChild(handleEl);

  let isRotateAdded = false;

  function checkIsTouchEvent(event: PointerEvent): event is TouchEvent {
    return 'touches' in event;
  }

  // Function to calculate angle from pointer position
  function getAngle(event: PointerEvent) {
    const rect = el.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;

    const isTouch = checkIsTouchEvent(event);
    const deltaX =
      (isTouch ? event.touches[0].clientX : event.clientX) - centerX;
    const deltaY =
      (isTouch ? event.touches[0].clientY : event.clientY) - centerY;
    return (Math.atan2(deltaY, deltaX) * 180) / Math.PI;
  }

  function rotate(angle: number) {
    el.style.transform = `rotate(${angle}deg)`;
  }

  function getMoveEventName(event: PointerEvent) {
    return checkIsTouchEvent(event) ? 'touchmove' : 'mousemove';
  }

  function handlePointerMove(event: PointerEvent) {
    const angle = getAngle(event);
    rotate(angle);

    if (listeners) {
      listeners.onRotate(angle);
    }
  }

  function handlePointerDown(event: PointerEvent) {
    if (!isRotateAdded) {
      if (listeners) {
        listeners.onRotateStart();
      }
      window.addEventListener(getMoveEventName(event), handlePointerMove);
      isRotateAdded = true;
    }
  }

  function handlePointerUp(event: PointerEvent) {
    if (isRotateAdded) {
      if (listeners) {
        listeners.onRotateEnd();
      }
      window.removeEventListener(getMoveEventName(event), handlePointerMove);
      isRotateAdded = false;
    }
  }

  // Event listeners for mouse events
  handleEl.addEventListener('mousedown', handlePointerDown);
  window.addEventListener('mouseup', handlePointerUp);

  // Event listeners for touch events
  handleEl.addEventListener('touchstart', handlePointerDown);
  window.addEventListener('touchend', handlePointerUp);
}

export function getMapType(map: Map): MapType {
  const result =
    map.get('type') ??
    ('getType' in map && typeof map.getType === 'function'
      ? map.getType()
      : undefined);

  if (!result) {
    throwError(Code.NullPointerException, `map type`);
  }

  return result;
}

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export function getRemainingDays(validDays: number, startDate: Date): number {
  return Math.round(
    (validDays * DAY_IN_MILLISECONDS - (Date.now() - startDate.getTime())) /
      DAY_IN_MILLISECONDS
  );
}
