import { asArray as colorAsArray, asString as colorAsString } from 'ol/color';
import { Color } from './color';

// Opacity is implemented through the alpha value in a color
export default function addOpacity(
  color: Color | string,
  opacity: number
): string {
  const colorArray = colorAsArray(color);
  colorArray[3] = typeof opacity === 'number' ? opacity : parseFloat(opacity); // change the alpha of the color
  return colorAsString(colorArray);
}
