export enum OutlineStyle {
  Solid = 0,
  Dashed = 1,
  Dotted = 2,
}

export enum FillStyle {
  Solid = 0,
  Transparent = 1,
  HorizontalStripe = 2,
  VerticalStripe = 3,
  Dot = 4,
  Circle = 5,
  Cross45Deg = 6,
  Stripe45Deg = 7,
}

export enum StylingPriority {
  Appbased = 1, // App-based styling
  Rulebased = 2, // Rule-based styling
  Custom = 3, // Custom styling
}
