import { Map } from 'ol';
import { Coordinate, distance } from 'ol/coordinate';
import { LineString } from 'ol/geom';
import { getDiagonalLineDisplayLength } from '../../common/extent';
import { getLineFormula } from './formulae';

export default class ArrowHead {
  arrowStart: Coordinate;
  arrowEnd: Coordinate;
  lineLength: number;
  lineRotationRadian: number;

  /**
   * Encapsulate information of an arrow head
   * @param {ol/Map}
   * @param {ol/coordinate-Coordinate} arrowStart The start coordinate of an arrow
   * @param {ol/coordinate-Coordinate} arrowEnd The end coordinate of an arrow
   * @param {ol.geom.LineString} lineGeometry The line geometry to which the arrow head belongs
   * @param {Number} weight The width of the line
   */
  constructor(
    map: Map,
    arrowStart: Coordinate,
    arrowEnd: Coordinate,
    lineGeometry: LineString,
    weight: number
  ) {
    this.arrowStart = arrowStart;
    this.arrowEnd = arrowEnd;

    const lineExtent = lineGeometry.getExtent();
    const diagonalLineDisplayLength = getDiagonalLineDisplayLength(
      map,
      lineExtent
    );
    this.lineLength = Math.min(6 * weight, diagonalLineDisplayLength / 5);
    this.lineRotationRadian = Math.PI / 6;
  }

  /**
   * An arrow head has two lines. They start at the end of the arrow.
   * The two lines of the arrow head are formed by the following steps:
   * 1. Find the point on the shaft (Shaft Intersection) according to the length of the arrow head line
   * 2. Create a line that starts at the end of the arrow and ends at the shaft intersection
   * 3. Rotate the line at the start in a positive angle to form the first line of the arrow head
   * 4. Create the same line as the step 2
   * 5. Rotate the line at the start in a negative angle to form the second line of the arrow head
   *  -------------------------------------
   *  -         Arrow End                 -
   *  -            /|\                    - Arrow Head
   *  -           / | \                   -
   *  -   Line 1 /  |  \ Line 2           -
   *  --------------.Shaft Intersection----
   *                |
   *                |
   *                |
   *                |
   *            Arrow Start
   */
  getLines(map) {
    let shaftIntersection: Coordinate | null = null;
    const arrowFormula = getLineFormula(map, this.arrowStart, this.arrowEnd);
    if (arrowFormula.hasOwnProperty('k')) {
      const {
        startPixel,
        endPixel: [x2, y2],
      } = arrowFormula;
      const a = Math.pow(arrowFormula['k'], 2) + 1;
      const b =
        2 * arrowFormula['b'] * arrowFormula['k'] -
        2 * arrowFormula['k'] * y2 -
        2 * x2;
      const c =
        Math.pow(y2, 2) +
        Math.pow(x2, 2) -
        2 * y2 * arrowFormula['b'] +
        Math.pow(arrowFormula['b'], 2) -
        Math.pow(this.lineLength, 2);
      const delta = Math.pow(b, 2) - 4 * a * c;
      if (delta < 0) {
        throw new Error(`Can not create lines, delta = ${delta}`);
      }
      // Two shaft intersections are found. The one that is closer to the start coordinate is chosen.
      const xOfShaftIntersection1 = (-b + Math.sqrt(delta)) / (2 * a);
      const yOfShaftIntersection1 =
        arrowFormula['k'] * xOfShaftIntersection1 + arrowFormula['b'];
      const shaftIntersectionPixel1 = [
        xOfShaftIntersection1,
        yOfShaftIntersection1,
      ];
      const xOfShaftIntersection2 = (-b - Math.sqrt(delta)) / (2 * a);
      const yOfShaftIntersection2 =
        arrowFormula['k'] * xOfShaftIntersection2 + arrowFormula['b'];
      const shaftIntersectionPixel2 = [
        xOfShaftIntersection2,
        yOfShaftIntersection2,
      ];
      const dist1 = distance(shaftIntersectionPixel1, startPixel);
      const dist2 = distance(shaftIntersectionPixel2, startPixel);
      shaftIntersection =
        dist1 < dist2
          ? map.getCoordinateFromPixel(shaftIntersectionPixel1)
          : map.getCoordinateFromPixel(shaftIntersectionPixel2);
    } else {
      const {
        startPixel: [, y1],
        endPixel: [, y2],
      } = arrowFormula;
      const yDelta = y2 >= y1 ? -this.lineLength : this.lineLength;
      const shaftIntersectionPixel = [arrowFormula['b'], y2 + yDelta];
      shaftIntersection = map.getCoordinateFromPixel(shaftIntersectionPixel);
    }

    const line1 = new LineString([this.arrowEnd, shaftIntersection!]);
    line1.rotate(this.lineRotationRadian, this.arrowEnd);
    const line2 = new LineString([this.arrowEnd, shaftIntersection!]);
    line2.rotate(-this.lineRotationRadian, this.arrowEnd);

    return [line1, line2];
  }
}
