import { Country } from '../../../../common/address';
import { EsriType } from '../../../service/esri/types';
import { LayerType } from '../../../types';
import type { BasemapVectorTileServerApi } from '../../types';
import { BasemapId } from '../../types';
import hosts from '../hosts';

const host = hosts.CA_TOPOGRAPHIC;

const basemapApi: BasemapVectorTileServerApi = {
  id: BasemapId.CA_TOPOGRAPHIC,
  title: 'Canada Topographic',
  subtitle:
    'The Canada topographic map includes boundaries, cities, water features, parks, landmarks, transportation, buildings, parcels and address points',
  type: 'street',
  host,
  countries: [Country.Canada],
  layerModel: {
    id: 'canada-topograhic-folder',
    geojson: {
      properties: {
        title: 'services',
        type: LayerType.FOLDER,
        url: `https://${host}/tiles/B6yKvIZqzuOr0jBR/arcgis/rest/services/Canada_Topographic/VectorTileServer`,
        token: undefined,
        esri_type: EsriType.VectorTileServer,
        extent: [
          -173.57302763219946, 37.09241084670485, -18.967756401777738,
          84.16605376642326,
        ],
        visibleExtent: [
          -173.57302763219946, 37.09241084670485, -18.967756401777738,
          84.16605376642326,
        ],
        maxImageSize: undefined,
        mapTileConfig: undefined,
        attributions: 'Esri Canada',
        projection: 'EPSG:3857',
        shouldUseCorsProxy: false,
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [
      {
        id: 'canada-topographic-item',
        geojson: {
          properties: {
            title: 'services',
            type: LayerType.SERVICE,
            index: 'Canada_Topographic',
            hidden_sub_layers: [],
            defaultStyles: 'resources/styles',
            projection: 'EPSG:3857',
            resolutions: [
              78271.516964, 39135.75848199995, 19567.87924100005,
              9783.93962049995, 4891.96981024998, 2445.98490512499,
              1222.992452562495, 611.496226281245, 305.74811314069,
              152.874056570279, 76.4370282852055, 38.2185141425366,
              19.1092570712683, 9.55462853563415, 4.777314267817075,
              2.388657133974685, 1.19432856698734, 0.597164283427525,
              0.2985821417799085, 0.1492910708238085, 0.07464553541190416,
              0.03732276770595208, 0.01866138385297604, 0.00933069192648802,
            ],
            origin: [-20037508.342787, 20037508.342787],
            tileSize: [512, 512],
            maxLOD: 16,
          },
        },
        visible: true,
        is_visible_in_basemap_figure: false,
        children: [],
      },
    ],
  },
};

export default basemapApi;
