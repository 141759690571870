import { Control } from 'ol/control';

export type Options = {
  layoutZoom: number;
};

export default class GoogleLogoControl extends Control {
  constructor(options: Options) {
    const { layoutZoom } = options;

    const element = document.createElement('img');
    element.style.pointerEvents = 'none';
    element.style.position = 'absolute';
    element.src = '/images/google_on_white.png';
    super({
      element: element,
    });

    this.addEventListener('change:layoutZoom', () => {
      const layoutZoom = this.getLayoutZoom();
      element.style.bottom = `${5 * layoutZoom}px`;
      element.style.left = `${5 * layoutZoom}px`;
      element.width = 59 * layoutZoom;
      element.height = 18 * layoutZoom;
    });
    this.setLayoutZoom(layoutZoom);
  }
  getLayoutZoom(): number {
    return this.get('layoutZoom') ?? 1;
  }
  setLayoutZoom(value: number) {
    this.set('layoutZoom', value);
  }
}
