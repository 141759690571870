import { BasemapId } from '../../../../lib/olbm/layer/basemap/types';

const URL = 'https://api.os.uk/maps/vector/ngd/ota/v1/collections/ngd-base';
const API_KEY = 'f8GHIAvb7oxcsHacGbza1AmypHomt9l0';
const PROJECTION = '3857';
const TILE_MATRICES = [
  {
    id: '0',
    scaleDenominator: 279541132,
    cellSize: 78271.51696,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 1,
    matrixWidth: 1,
  },
  {
    id: '1',
    scaleDenominator: 139770566,
    cellSize: 39135.75848,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 2,
    matrixWidth: 2,
  },
  {
    id: '2',
    scaleDenominator: 69885283,
    cellSize: 19567.87924,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 4,
    matrixWidth: 4,
  },
  {
    id: '3',
    scaleDenominator: 34942641.5,
    cellSize: 9783.939621,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 8,
    matrixWidth: 8,
  },
  {
    id: '4',
    scaleDenominator: 17471320.75,
    cellSize: 4891.96981,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 16,
    matrixWidth: 16,
  },
  {
    id: '5',
    scaleDenominator: 8735660.375,
    cellSize: 2445.984905,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 32,
    matrixWidth: 32,
  },
  {
    id: '6',
    scaleDenominator: 4367830.1877243574709,
    cellSize: 1222.9924525628201,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 64,
    matrixWidth: 64,
  },
  {
    id: '7',
    scaleDenominator: 2183915.0938621787354,
    cellSize: 611.49622628141,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 128,
    matrixWidth: 128,
  },
  {
    id: '8',
    scaleDenominator: 1091957.5469310893677,
    cellSize: 305.748113140705,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 256,
    matrixWidth: 256,
  },
  {
    id: '9',
    scaleDenominator: 545978.7734655446839,
    cellSize: 152.8740565703525,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 512,
    matrixWidth: 512,
  },
  {
    id: '10',
    scaleDenominator: 272989.3867327723419,
    cellSize: 76.4370282851763,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 1024,
    matrixWidth: 1024,
  },
  {
    id: '11',
    scaleDenominator: 136494.693366386171,
    cellSize: 38.2185141425881,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 2048,
    matrixWidth: 2048,
  },
  {
    id: '12',
    scaleDenominator: 68247.3466831930855,
    cellSize: 19.1092570712941,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 4096,
    matrixWidth: 4096,
  },
  {
    id: '13',
    scaleDenominator: 34123.6733415965427,
    cellSize: 9.554628535647,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 8192,
    matrixWidth: 8192,
  },
  {
    id: '14',
    scaleDenominator: 17061.8366707982714,
    cellSize: 4.7773142678235,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 16384,
    matrixWidth: 16384,
  },
  {
    id: '15',
    scaleDenominator: 8530.9183353991357,
    cellSize: 2.3886571339118,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 32768,
    matrixWidth: 32768,
  },
  {
    id: '16',
    scaleDenominator: 4265.4591676995678,
    cellSize: 1.1943285669559,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 65536,
    matrixWidth: 65536,
  },
  {
    id: '17',
    scaleDenominator: 2132.7295838497839,
    cellSize: 0.5971642834779,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 131072,
    matrixWidth: 131072,
  },
  {
    id: '18',
    scaleDenominator: 1066.364791924892,
    cellSize: 0.298582141739,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 262144,
    matrixWidth: 262144,
  },
  {
    id: '19',
    scaleDenominator: 533.182395962446,
    cellSize: 0.1492910708695,
    cornerOfOrigin: 'topLeft',
    pointOfOrigin: [-20037508.3427892439067, 20037508.3427892439067],
    tileWidth: 512,
    tileHeight: 512,
    matrixHeight: 524288,
    matrixWidth: 524288,
  },
];

export default {
  index: BasemapId.GB_OS_VECTOR,
  title: 'OS Maps - Vector',
  subtitle: '',
  type: 'street',
  options: {
    attributions: '© Ordnance Survey',
    url: `${URL}/tiles/${PROJECTION}/{z}/{y}/{x}?key=${API_KEY}`,
    styleUrl: `${URL}/styles/${PROJECTION}?key=${API_KEY}`,
    projection: `EPSG:${PROJECTION}`,
    visibleExtent: [-10.76418, 49.528423, 1.9134116, 61.331151],
    resolutions: TILE_MATRICES.map(({ cellSize }) => cellSize),
    origin: TILE_MATRICES[0].pointOfOrigin,
    tileSize: [TILE_MATRICES[0].tileHeight, TILE_MATRICES[0].tileWidth],
    maxLOD: 19,
  },
};
