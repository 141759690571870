<script setup>
import Spinner from '@component-library/components/Spinner.vue';
import _debounce from 'lodash/debounce';
import { ref } from 'vue';

const emit = defineEmits('getEntries');
const props = defineProps({
  loading: Boolean,
  nonSpatialTabs: Array,
});

const entryQuery = ref(null);
const templateTabId = ref(null);

const searchEntries = _debounce(function () {
  getEntries();
}, 400);

const getEntries = () => {
  emit('getEntries', {
    query: entryQuery.value,
    tab_id: templateTabId.value,
  });
};
</script>

<template>
  <div class="input-group mb-3">
    <span class="input-group-text">
      <Spinner small v-if="loading" />
      <i class="fas fa-search" v-else></i>
    </span>
    <input
      type="text"
      placeholder="Search items..."
      class="form-control"
      v-model="entryQuery"
      @input="searchEntries"
    />
    <select class="form-control" v-model="templateTabId" @change="getEntries">
      <option :value="null">All apps</option>
      <option v-if="nonSpatialTabs.length === 0" disabled>No apps found</option>
      <option v-for="tab of nonSpatialTabs" :key="tab.id" :value="tab.id">
        {{ tab.title }}
      </option>
    </select>
  </div>
</template>
