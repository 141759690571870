import { Map } from 'ol';

export function getLayoutZoom(map: Map): number {
  return map.get('layoutZoom');
}

export function setLayoutZoom(map: Map, value: number) {
  map.set('layoutZoom', value);
}

enum LayoutZoomUsage {
  read = 1,
  write,
}

type ApplyLayoutZoomFeedResult = number | number[];
type ApplyLayoutZoomFeed<T extends ApplyLayoutZoomFeedResult> = (
  ...params: any[]
) => T;
// Can be used to do rounding, ceiling, etc.
type ApplyLayoutZoomEnhancer<T extends ApplyLayoutZoomFeedResult> = (
  value: T
) => T;

const DEFAULT_APPLY_LAYOUT_ZOOM_ENHANCER = (value) => value;

function applyLayoutZoom<T extends ApplyLayoutZoomFeedResult>(
  map: Map,
  fn: ApplyLayoutZoomFeed<T>,
  usage: LayoutZoomUsage,
  enhancer: ApplyLayoutZoomEnhancer<T>
): ApplyLayoutZoomFeed<T> {
  return (...params: Parameters<ApplyLayoutZoomFeed<T>>): T => {
    const zoom = getLayoutZoom(map);
    let factor;
    switch (usage) {
      case LayoutZoomUsage.read:
        factor = zoom;
        break;
      case LayoutZoomUsage.write:
        factor = 1 / zoom;
        break;
    }

    const result = fn(...params);
    if (Array.isArray(result)) {
      return enhancer(result.map((value) => value * factor) as T);
    }

    return enhancer(((result as number) * factor) as T);
  };
}

export function applyLayoutZoomToRead<T extends ApplyLayoutZoomFeedResult>(
  map: Map,
  fn: ApplyLayoutZoomFeed<T>,
  enhancer: ApplyLayoutZoomEnhancer<T> = DEFAULT_APPLY_LAYOUT_ZOOM_ENHANCER
): ApplyLayoutZoomFeed<T> {
  return applyLayoutZoom<T>(map, fn, LayoutZoomUsage.read, enhancer);
}

export function applyLayoutZoomToWrite<T extends ApplyLayoutZoomFeedResult>(
  map: Map,
  fn: ApplyLayoutZoomFeed<T>,
  enhancer: ApplyLayoutZoomEnhancer<T> = DEFAULT_APPLY_LAYOUT_ZOOM_ENHANCER
): ApplyLayoutZoomFeed<T> {
  return applyLayoutZoom<T>(map, fn, LayoutZoomUsage.write, enhancer);
}
