import { defineStore } from 'pinia';
import axios from 'axios';
import { useToastStore } from '../store/toasts';
import { ref } from 'vue';
import useIsGather from '../composables/useIsGather';
import useAuth from '../composables/useAuth';

export interface Task {
  key: string;
  title: string;
  hint: string;
}

// Note: Task endpoints are not implemented in Gather's Laravel API.

export const useTaskStore = defineStore('task', () => {
  const completedTasks = ref<string[]>([]);
  const tasks = ref<Task[]>([]);
  const loadingTasks = ref(true);
  const toast = useToastStore();
  const isGather = useIsGather();
  const auth = useAuth();

  async function completeTask(taskKey: string) {
    if (
      isGather ||
      completedTasks.value.indexOf(taskKey) != -1 ||
      !auth.check()
    ) {
      return;
    }

    try {
      await axios.post('/company/task/complete', {
        task_key: taskKey,
      });

      completedTasks.value.push(taskKey);
    } catch (e) {
      toast.error('Failed to complete task');
      throw e;
    }
  }

  async function loadTasks() {
    if (isGather || !auth.check()) {
      return;
    }

    loadingTasks.value = true;

    try {
      const response = await axios.get('/company/task/get');
      tasks.value = response.data.tasks;
      completedTasks.value = response.data.completed_task_keys;
    } catch (e) {
      throw e;
    } finally {
      loadingTasks.value = false;
    }
  }

  return {
    completeTask,
    loadTasks,
    loadingTasks,
    tasks,
    completedTasks,
  };
});
