import { BasemapId } from '../../../../lib/olbm/layer/basemap/types';
import { LayerType } from '../../../../lib/olbm/layer/types';

export default {
  index: BasemapId.GOOGLE_MAPS_ROADMAP,
  title: 'Google Maps - Roadmap',
  subtitle: 'The standard Google Maps painted map tiles',
  type: 'street',
  options: {
    id: 'google-maps-roadmap',
    geojson: {
      properties: {
        type: LayerType.BASEMAP_SERVICE,
        mapType: 'roadmap',
        title: 'Google Maps - Roadmap',
      },
    },
    visible: true,
    children: [],
    attributions: '© Google Maps',
  },
};
