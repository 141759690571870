import { Country } from '../../../../common/address';
import { LayerType } from '../../../types';
import type { GoogleMapsApi } from '../../types';
import { BasemapId } from '../../types';

const basemapApi: GoogleMapsApi = {
  id: BasemapId.GOOGLE_MAPS_ROADMAP,
  title: 'Google Maps - Roadmap',
  subtitle: 'The standard Google Maps painted map tiles',
  type: 'street',
  countries: [Country.UnitedStates],
  layerModel: {
    id: 'google-maps-roadmap',
    geojson: {
      properties: {
        type: LayerType.BASEMAP_SERVICE,
        mapType: 'roadmap',
        title: 'Google Maps - Roadmap',
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [],
  },
};

export default basemapApi;
