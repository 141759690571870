import { defineStore } from 'pinia';

import { computed, ref } from 'vue';
import { App, Section, GatherField, ProjectPhase } from '../gather';
import useApi from '../composables/useApi';
import { useToastStore } from './toasts';

type FieldWithSectionAndApp = GatherField & { section: Section & { app: App } };

export const useGatherSchemaStore = defineStore('gather-schema', () => {
  const api = useApi();
  const toast = useToastStore();
  const loadingCounter = ref<number>(0);
  const apps = ref<App[]>([]);
  const referenceFields = ref<FieldWithSectionAndApp[]>([]);
  const appFieldMap = ref<Map<number, GatherField[]>>(new Map());

  const phases = ref<ProjectPhase[]>([]);
  const collapsedPhaseIds = ref<number[]>([]);

  async function getApps(project_id: number) {
    if (apps.value.filter((a) => a.project_id === project_id).length > 0) {
      return;
    }

    loadingCounter.value++;

    try {
      const { data } = await api.get(`/gather/tabs?project_id=${project_id}`);

      apps.value = data.tabs;
    } catch (e: any) {
      toast.unexpected(e);
      throw e;
    } finally {
      loadingCounter.value--;
    }
  }

  async function getAppFields(
    project_id: number,
    template_tab_id: number
  ): Promise<GatherField[]> {
    loadingCounter.value++;

    const existing = appFieldMap.value.get(template_tab_id);
    if (existing) {
      return existing;
    }

    try {
      const response = await api.get(
        `/app/${template_tab_id}/fields?project_id=${project_id}`
      );
      appFieldMap.value.set(template_tab_id, response.data.fields);
      return response.data.fields;
    } catch (e: any) {
      toast.unexpected(e);
      throw e;
    } finally {
      loadingCounter.value--;
    }
  }

  async function loadProjectReferenceFields(projectId: number) {
    try {
      const { data } = await api.get(
        `/gather/schema/project-references/${projectId}?project_id=${projectId}`
      );

      referenceFields.value = data.reference_fields;
    } catch (e: any) {
      toast.unexpected(e);
    }

    return referenceFields.value;
  }

  function isAppReadOnly(id: number) {
    const app = apps.value.find((a) => a.id === id);
    return app?.is_read_only || false;
  }

  async function getPhases(projectId: number): Promise<ProjectPhase[]> {
    try {
      const { data } = await api.get(`/gather/phase`, {
        params: {
          project_id: projectId,
        },
      });

      phases.value = data.phases;

      collapsedPhaseIds.value = phases.value
        .filter((p) => !p.is_visible)
        .map((p) => p.id);
    } catch (e: any) {
      toast.unexpected(e);
    }

    return phases.value;
  }

  async function createPhase(title: string, projectId: number) {
    try {
      const { data } = await api.post(`/gather/phase`, {
        project_id: projectId,
        title,
      });

      phases.value.push(data.phase);
    } catch (e: any) {
      toast.unexpected(e);
    }
  }

  type UpdatePhasePayload = {
    title?: string;
    is_visible?: boolean;
    order?: number;
  };

  async function updatePhase(
    phaseId: number,
    payload: UpdatePhasePayload,
    projectId: number
  ) {
    try {
      await api.put(`/gather/phase/${phaseId}`, {
        project_id: projectId,
        ...payload,
      });

      const phase = phases.value.find((p) => p.id === phaseId)!;

      Object.keys(payload).forEach((key) => {
        phase[key] = payload[key];
      });
    } catch (e: any) {
      toast.unexpected(e);
    }
  }

  async function deletePhase(phaseId: number, projectId: number) {
    try {
      await api.delete(`/gather/phase/${phaseId}`, {
        params: {
          project_id: projectId,
        },
      });

      phases.value = phases.value.filter((p) => p.id !== phaseId);
    } catch (e: any) {
      toast.unexpected(e);
    }
  }

  async function updatePhaseOrdering(phaseOrdering: any, projectId: number) {
    for (const phase of phaseOrdering) {
      const phaseToUpdate = phases.value.find((p) => p.id === phase.id);
      if (phaseToUpdate) {
        phaseToUpdate.order = phase.order;
      }
    }

    await api.post(`/gather/phase/ordering`, {
      project_id: projectId,
      phase_ordering: phaseOrdering,
    });
  }

  function toggleCollapsePhase(phaseId: number) {
    const index = collapsedPhaseIds.value.indexOf(phaseId);
    if (index === -1) {
      collapsedPhaseIds.value.push(phaseId);
    } else {
      collapsedPhaseIds.value.splice(index, 1);
    }
  }

  function isPhaseCollapsed(phaseId: number) {
    return collapsedPhaseIds.value.includes(phaseId);
  }

  return {
    getApps,
    getAppFields,
    loadProjectReferenceFields,
    isAppReadOnly,
    isLoadingApps: computed(() => loadingCounter.value > 0),
    apps,
    appFieldMap,
    referenceFields,

    getPhases,
    createPhase,
    updatePhase,
    deletePhase,
    updatePhaseOrdering,
    phases,
    collapsedPhaseIds,
    toggleCollapsePhase,
    isPhaseCollapsed,
  };
});
