import type { LayerModel } from '../types';
import { LayerType } from '../types';
import type { Service, XyzFolderLayerModel } from './types';
import { ServiceType } from './types';

export function checkIsXyzFolderLayerModel(
  model: LayerModel
): model is XyzFolderLayerModel {
  const { type } = model.geojson.properties;
  return (
    type === LayerType.FOLDER &&
    'service' in model.geojson.properties &&
    (model.geojson.properties.service as Service).type === ServiceType.Xyz
  );
}
