<script setup lang="ts">
import makeId from '../local-id.mjs';
import InfoButton from './InfoButton.vue';

withDefaults(
  defineProps<{
    name: string;
    label: string;
    value: number;
    formatValue?: (value: number) => string;
    units?: string;
    min: number;
    max: number;
    step: number;
    info?: string;
  }>(),
  {
    formatValue: (value: number): string => {
      return String(value);
    },
    units: '',
  }
);

const id = makeId();

function handleInput(onInput, target: EventTarget | null) {
  if (typeof onInput === 'function') {
    onInput(parseFloat((target as HTMLInputElement).value));
  }
}
</script>

<template>
  <div>
    <label class="d-flex form-label align-items-center" :for="id">
      {{ label }} ({{ `${formatValue(value)}${units}` }})
      <sup class="text-danger">*</sup>
        <InfoButton  v-if="info" class="ms-1" :info="info" />
    </label>
    <input
      type="range"
      class="form-range"
      :id="id"
      :min="min"
      :max="max"
      :step="step"
      :value="value"
      @input="handleInput($listeners.input, $event.target)"
    />
  </div>
</template>
