// 16 main colours.
export const AQUA = '#00FFFF';
export const BLACK = '#000000';
export const BLUE = '#0000FF';
export const FUCHSIA = '#CA2C92';
export const GRAY = '#808080';
export const GREEN = '#00FF00';
export const LIME = '#BFFF00';
export const MAROON = '#800000';
export const NAVY = '#000080';
export const OLIVE = '#808000';
export const PURPLE = '#A020F0';
export const RED = '#FF0000';
export const SILVER = '#C0C0C0';
export const TEAL = '#008080';
export const WHITE = '#FFFFFF';
export const YELLOW = '#FFFF00';

const graphColors = [
  BLACK,
  RED,
  GREEN,
  BLUE,
  AQUA,
  FUCHSIA,
  PURPLE,
  MAROON,
  NAVY,
  OLIVE,
  TEAL,
  GRAY,
];

// Datanest colors
export const PRIMARY = '#3366a2';
export const BORDER = '#dbdbdb';

// Feature colors
export const FEATURE = '#2980b9';
export const SITE_BOUNDARY = '#e84b3c';

export const SWATCHES = Object.freeze([
  Object.freeze(['#1FBC9C', '#1CA085', '#2ECC70', '#27AF60', '#3398DB']),
  Object.freeze(['#2980B9', '#A463BF', '#8E43AD', '#3D556E', BLACK]),
  Object.freeze(['#F2C511', '#F39C19', '#E84B3C', '#C0382B', '#DDE6E8']),
]);

export function getSwatchTriggerStyle(color) {
  return color?.toLowerCase() === WHITE.toLowerCase()
    ? { border: `1px solid ${BORDER}` }
    : null;
}

const graphMap = new Map<string, string[]>();
const graphDefaultColors = new Map<string, string[]>();

export function resetGraphColors(graphKey: string, defaultColors?: string[]) {
  if (defaultColors) {
    graphMap.set(graphKey, defaultColors);
    graphDefaultColors.set(graphKey, defaultColors);
  } else {
    graphMap.delete(graphKey)
  }
}

export function nextGraphColor(key: string) {
  const nextColors = graphMap.get(key) || [...(graphDefaultColors.get(key) || graphColors)];
  if (nextColors.length === 0) {
    console.warn('Ran out of colors for graph', key);
    nextColors.push(...graphColors);
  }
  const color = nextColors.shift();
  graphMap.set(key, nextColors);
  return color;
}

export function getDefaultGraphColors() {
  return [...graphColors];
}
