import { BasemapId } from '../../../../../lib/olbm/layer/basemap/types';

const fullExtentInEpsg4326 = [
  136.3119551569189, -30.720117605897357, 163.73585302127165,
  -7.9586473305608365,
];

export default {
  index: BasemapId.AU_QLD_AERIAL,
  title: 'Queensland - Aerial',
  subtitle: '',
  type: 'satellite',
  options: {
    attributions:
      'Includes material © State of Queensland (Department of Resources); © Planet Labs Netherlands B.V. reproduced under licence from Planet and Geoplex, all rights reserved, 2024.',
    url: 'https://spatial-img.information.qld.gov.au/arcgis/rest/services/Basemaps/LatestStateProgram_AllUsers/ImageServer',
    token: null,
    extent: fullExtentInEpsg4326,
    visibleExtent: fullExtentInEpsg4326,
    maxImageSize: {
      width: 7680,
      height: 4100,
    },
    mapTileConfig: {
      tileInfo: {
        projection: 'EPSG:3857',
        resolutions: [
          156543.03392800014, 78271.51696399994, 39135.75848200009,
          19567.87924099992, 9783.93962049996, 4891.96981024998,
          2445.98490512499, 1222.992452562495, 611.4962262813797,
          305.74811314055756, 152.87405657041106, 76.43702828507324,
          38.21851414253662, 19.10925707126831, 9.554628535634155,
          4.77731426794937, 2.388657133974685, 1.1943285668550503,
          0.5971642835598172, 0.29858214164761665,
        ],
        origin: [-20037508.342787, 20037508.342787],
        tileSize: [256, 256],
      },
    },
    projection: 'EPSG:3857',
    isImageMapLayer: true,
    type: 'service_layer',
  },
};
