// The string type means the object identified by the id is a new one created in the browser and has not been saved.
export type Id = number | string;
// Persistent id.
export type Pid = Exclude<Id, string>;

export type Options = Record<string, any>;

export type LonLat = { longitude: number; latitude: number };

export type LegacyLatLng = { lat: number; lng: number };

export type LegacyBounds =
  | {
      _southWest: LegacyLatLng;
      _northEast: LegacyLatLng;
    }
  | {
      _southEast: LegacyLatLng;
      _northWest: LegacyLatLng;
    };

export type Extent = number[];

export enum MapType {
  MAIN = 1,
  MINI,
  PARCEL_EDITOR,
  ADDRESS_CHECKER,
  PROJECT_OVERVIEW,
  CALL_OUT,
  GATHER_MAIN,
}
