<script setup lang="ts">
import { computed } from 'vue';
import getFillPatternProperties from '../../../business-logic/mapping/helpers/getFillPatternProperties';
import * as bm from '../../../business-model';
import FillPatternChooser from '../../FillPatternChooser.vue';
import Slider from '../../Slider.vue';
import { injectContext } from './context';
import TitleBar from '../TitleBar.vue';

const { getShapeProperty, setShapeProperty, updateShapeProperties } =
  injectContext();

const selectedFillPattern = computed(() => {
  return parseInt(getShapeProperty('fillStyle', 0), 10) + 1;
});

const fillPatterns = computed(() => {
  return [
    bm.common.FILL_PATTERN_SOLID,
    bm.common.FILL_PATTERN_TRANSPARENT,
    bm.common.FILL_PATTERN_HORIZONTAL_STRIPE,
    bm.common.FILL_PATTERN_VERTICAL_STRIPE,
    bm.common.FILL_PATTERN_DOT,
    bm.common.FILL_PATTERN_CIRCLE,
    bm.common.FILL_PATTERN_CROSS_45_DEG,
    bm.common.FILL_PATTERN_STRIPE_45_DEG,
  ];
});

function handleFillStyleSelected(value) {
  const newShapeProperties: any = {
    fillStyle: value,
    ...getFillPatternProperties(value),
  };
  updateShapeProperties(newShapeProperties);
}
</script>

<template>
  <div>
    <TitleBar class="mb-2"> Fill </TitleBar>

    <div class="mb-2">
      <FillPatternChooser
        :fill-patterns="fillPatterns"
        :selectedFillPattern="selectedFillPattern"
        @change="(value) => handleFillStyleSelected(value - 1)"
      />
    </div>

    <Slider
      v-if="
        ![bm.common.FILL_PATTERN_TRANSPARENT - 1].includes(
          getShapeProperty('fillStyle', 0)
        )
      "
      name="fillOpacity"
      label="Fill opacity"
      :value="getShapeProperty('fillOpacity', 0.2)"
      :formatValue="(value) => Math.round(value * 100).toString()"
      units="%"
      :min="0"
      :max="1"
      :step="0.1"
      @input="(value) => setShapeProperty('fillOpacity', value)"
    />
  </div>
</template>
