<template>
  <div v-html="displayValue" :title="getTooltipValue"></div>
</template>

<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  computed: {
    getTooltipValue() {
      const { has_multiple } = this.field.options;
      let { value } = this.value;
      try {
        value = JSON.parse(value);
      } catch (e) {}
      return !has_multiple ? value : value.join('<br/>');
    },
    displayValue() {
      const { has_multiple } = this.field.options;

      let { value } = this.value;
      try {
        value = JSON.parse(value);
      } catch (e) {}
      value = !has_multiple ? value : value.join(', ');

      if (value.length > 100) {
        value = value.substr(0, 100) + '...';
      }
      return value;
    },
  },
};
</script>
