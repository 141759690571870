import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Projection } from 'ol/proj';
import type { Extent } from 'ol/extent';
import { SimpleGeometry } from 'ol/geom';

export const DEFAULT_ZOOM = 20;

export function getMapProjection(map: Map): Projection {
  return map.getView().getProjection();
}

export function getMapResolution(map: Map): number {
  return map.getView().getResolution()!;
}

export function getMapCenter(map: Map): Coordinate {
  return map.getView().getCenter()!;
}

export type FitTarget = SimpleGeometry | Extent;
