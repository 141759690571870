import { Feature, Map, getUid } from 'ol';
import BaseObject from 'ol/Object';
import { Coordinate } from 'ol/coordinate';
import { Geometry, LineString, Point, Polygon } from 'ol/geom';
import { XYZ } from 'ol/source';
import LoadManager from '../common/LoadManager';
import { getStoreApi } from '../common/store-api';
import type { Id } from '../common/types';
import { waitFor } from '@component-library/utils/wait-for';
import { findOverlayById } from '../overlay/util';
import {
  checkIsBasemapLayer,
  checkIsBasempLayerSplitAvailable,
  getBasemapLayerTypes,
} from './basemap/utils';
import createCalloutLayer from './call-out/createCalloutLayer';
import type { CalloutLayerModel } from './call-out/types';
import { ConnectedTargetType } from './call-out/types';
import {
  checkIsCalloutLayerModel,
  getPopupId as getCalloutPopupId,
} from './call-out/utils';
import createImageLayer from './image/createImageLayer';
import { checkIsImageLayerModel } from './image/utils';
import createSampleLayer from './sample/createSampleLayer';
import type { Sample, SampleLayer } from './sample/types';
import {
  addSampleFeature,
  checkIsSampleGroup,
  removeSampleFeature,
  updateSampleFeature,
} from './sample/utils';
import createXyzLayer from './service/createXyzLayer';
import createFeatureServerLayer from './service/esri/createFeatureServerLayer';
import createMapImageServerLayer from './service/esri/createMapImageServerLayer';
import createVectorTileServerLayer from './service/esri/createVectorTileServerLayer';
import type {
  FeatureServerLayer,
  VectorTileServerLayer,
} from './service/esri/types';
import { EsriType } from './service/esri/types';
import {
  checkIsFeatureServerFolderLayerModel,
  checkIsImageServerFolderLayerModel,
  checkIsMapServerFolderLayerModel,
  checkIsVectorTileServerFolderLayerModel,
} from './service/esri/utils';
import type {
  WfsLayer,
  WmsLayer,
  WmtsLayer,
} from './service/ogc-opengis/types';
import {
  checkIsWfsFolderLayerModel,
  checkIsWmsFolderLayerModel,
  checkIsWmtsFolderLayerModel,
} from './service/ogc-opengis/utils';
import createWfsLayer from './service/ogc-opengis/wfs';
import createWmsLayer from './service/ogc-opengis/wms';
import createWmtsLayer from './service/ogc-opengis/wmts';
import { checkIsXyzFolderLayerModel } from './service/utils';
import createBufferLayer from './shape/createBufferLayer';
import createMultipleShapeLayer from './shape/createMultipleShapeLayer';
import createSingleShapeLayer from './shape/createSingleShapeLayer';
import { BufferLayerModel, BufferType } from './shape/types';
import {
  checkIsBufferLayerModel,
  checkIsMultipleShapeLayerModel,
  checkIsSingleShapeLayerModel,
} from './shape/utils';
import createTextLayer from './text/createTextLayer';
import { checkIsTextLayerModel } from './text/utils';
import type { Layer, LayerModel } from './types';
import { LayerType } from './types';
import { getLayerCenter, getModel, getModelId, getType } from './utils';

const MAXIMUM_NUMBER_OF_LAYERS = 1e4;

export default class LayerManager extends BaseObject {
  map: Map;

  constructor(map: Map) {
    super();
    this.map = map;
  }

  checkIsEditableLayer(layer: Layer) {
    return [
      LayerType.RECTANGLE,
      LayerType.CIRCLE,
      LayerType.POLYGON,
      LayerType.POLYLINE,
      LayerType.ARROW,
      LayerType.SITE_BOUNDARY,
      LayerType.TEXT,
      LayerType.CALL_OUT,
      LayerType.IMAGE,
      LayerType.SAMPLE,
      LayerType.CHAINAGE,
      LayerType.HEDGE,
    ].includes(getType(layer));
  }
  getStoreApi() {
    return getStoreApi(this.map);
  }
  getIsBasemapLayerSplit(): boolean {
    return false;
  }
  setIsBasemapLayerSplit(value: boolean) { }
  // Methods related to layer management
  getAllLayers(): Layer[] {
    return this.map.getLayers().getArray() as Layer[];
  }
  findLayersByType(type: LayerType): Layer[] {
    const layers = this.getAllLayers();
    return layers.filter((layer) => getType(layer) === type);
  }
  findLayersByTypes(types: LayerType[]): Layer[] {
    return types.reduce((accu, type) => {
      return [...accu, ...this.findLayersByType(type)];
    }, [] as Layer[]);
  }
  findLayerByModelId(modelId: Id): Layer | undefined {
    const layers = this.getAllLayers();
    return layers.find((layer) => getModelId(layer) === modelId);
  }
  findPreviousLayerId(uid: Id): number | null {
    const layers = this.getAllLayers();
    const layerIndex = layers.findIndex((layer) => getModelId(layer) === uid);
    if (layerIndex === -1 || layerIndex === 0) {
      return null;
    }
    return layers[layerIndex - 1].get('modelId') || null;
  }
  findLayerByUid(uid: string): Layer | undefined {
    const layers = this.getAllLayers();
    return layers.find((layer) => getUid(layer) === uid);
  }
  findLayerByFeature(feature: Feature<Geometry>): Layer {
    const layers = this.getAllLayers();
    const layerUid = feature.get('layerUid');
    return layerUid
      ? this.findLayerByUid(layerUid)
      : layers.find((layer) => {
        const isCandidateLayer = [
          LayerType.RECTANGLE,
          LayerType.CIRCLE,
          LayerType.POLYGON,
          LayerType.POLYLINE,
          LayerType.ARROW,
          LayerType.SITE_BOUNDARY,
          LayerType.TEXT,
          LayerType.CALL_OUT,
          LayerType.IMAGE,
          LayerType.SAMPLE,
          LayerType.FEATURE_COLLECTION,
          LayerType.SERVICE,
          LayerType.SAMPLE_POPUP_CONNECTORS,
          LayerType.BUFFER,
        ].includes(layer.get('type') as LayerType);
        return isCandidateLayer && layer.checkHasFeature(feature);
      });
  }
  findSampleLayerBySampleId(sampleId: Id): SampleLayer | undefined {
    const storeApi = this.getStoreApi();
    const sample = storeApi.findSampleById(sampleId);

    if (!sample) {
      return undefined;
    }

    const layerModel = storeApi.getSampleLayerModel(sample)!;
    return this.findLayerByModelId(layerModel.id)! as SampleLayer;
  }
  removeLayersByType(type) {
    const layers = this.findLayersByType(type);
    for (const layer of layers) {
      this.removeLayer(layer);
    }
  }
  removeLayersByTypes(types) {
    for (const type of types) {
      this.removeLayersByType(type);
    }
  }
  removeLayersByModelIds(modelIds: Id[]) {
    for (const id of modelIds) {
      const layer = this.findLayerByModelId(id);
      if (layer) {
        this.removeLayer(layer);
      }
    }
  }
  getBasemapLayers() {
    const types = getBasemapLayerTypes();
    return this.findLayersByTypes(types);
  }
  clearBasemapLayers() {
    const types = getBasemapLayerTypes();
    this.removeLayersByTypes(types);
  }
  getPolylineLayersInViewport() {
    const extent = this.map.getView().calculateExtent();
    const polylines = this.findLayersByType(LayerType.POLYLINE);
    return polylines.filter((polyline) =>
      polyline.getFirstFeature().getGeometry().intersectsExtent(extent)
    );
  }
  async createLayers(
    model: LayerModel,
    params?: Record<string, any>
  ): Promise<Layer[]> {
    const loadManager = LoadManager.getInstance();

    if (checkIsSingleShapeLayerModel(model)) {
      if (checkIsBufferLayerModel(model)) {
        return [createBufferLayer(this.map, model, this)];
      }

      return [createSingleShapeLayer(this.map, model)];
    } else if (checkIsMultipleShapeLayerModel(model)) {
      return [createMultipleShapeLayer(this.map, model)];
    } else if (checkIsImageLayerModel(model)) {
      return [await createImageLayer(this.map, model)];
    } else if (checkIsFeatureServerFolderLayerModel(model)) {
      const result: FeatureServerLayer[] = [];
      for (let i = 0; i < model.children.length; i++) {
        const item = model.children[i];
        const layer = createFeatureServerLayer(
          this.map,
          model,
          item,
          LayerType.SERVICE
        );
        loadManager.addLoadListenersToServiceLayer(layer);
        result.push(layer);
      }
      return result;
    } else if (
      checkIsMapServerFolderLayerModel(model) ||
      checkIsImageServerFolderLayerModel(model)
    ) {
      const layer = createMapImageServerLayer(
        this.map,
        model,
        LayerType.SERVICE
      );
      loadManager.addLoadListenersToServiceLayer(layer);
      return [layer];
    } else if (checkIsVectorTileServerFolderLayerModel(model)) {
      const result: VectorTileServerLayer[] = [];
      for (let i = 0; i < model.children.length; i++) {
        const item = model.children[i];
        const layer = createVectorTileServerLayer(
          this.map,
          model,
          item,
          undefined,
          LayerType.SERVICE
        );
        loadManager.addLoadListenersToServiceLayer(layer);
        result.push(layer);
      }
      return result;
    } else if (checkIsXyzFolderLayerModel(model)) {
      const layer = createXyzLayer(this.map, model, XYZ, LayerType.SERVICE);
      loadManager.addLoadListenersToServiceLayer(layer);
      return [layer];
    } else if (checkIsWfsFolderLayerModel(model)) {
      const result: WfsLayer[] = [];
      for (let i = 0; i < model.children.length; i++) {
        const item = model.children[i];
        const layer = createWfsLayer(this.map, model, item, LayerType.SERVICE);
        loadManager.addLoadListenersToServiceLayer(layer);
        result.push(layer);
      }
      return result;
    } else if (checkIsWmsFolderLayerModel(model)) {
      const result: WmsLayer[] = [];
      for (let i = 0; i < model.children.length; i++) {
        const item = model.children[i];
        if (item.visible) {
          const layer = createWmsLayer(
            this.map,
            model,
            item,
            LayerType.SERVICE
          );
          loadManager.addLoadListenersToServiceLayer(layer);
          result.push(layer);
        }
      }
      return result;
    } else if (checkIsWmtsFolderLayerModel(model)) {
      const result: WmtsLayer[] = [];
      for (let i = 0; i < model.children.length; i++) {
        const item = model.children[i];
        if (item.visible) {
          const layer = createWmtsLayer(
            this.map,
            model,
            item,
            LayerType.SERVICE
          );
          loadManager.addLoadListenersToServiceLayer(layer);
          result.push(layer);
        }
      }
      return result;
    } else if (checkIsSampleGroup(model)) {
      return [createSampleLayer(this.map, model)];
    } else if (checkIsTextLayerModel(model)) {
      return [createTextLayer(this.map, model)];
    } else if (checkIsCalloutLayerModel(model)) {
      return [createCalloutLayer(this.map, model)];
    }

    throw `The layer model with id ${model.id} is invalid.`;
  }
  addLayer(layer) {
    const type = getType(layer);

    if (checkIsBasemapLayer(layer)) {
      const count = this.getBasemapLayers().length;
      if (!count) {
        this.map.getLayers().insertAt(0, layer);
      } else if (count === 1) {
        const lowestLayer = this.map.getLayers().item(0) as Layer;
        const lowestLayerType = getType(lowestLayer);
        if (
          lowestLayerType === LayerType.BASEMAP_IMAGE ||
          lowestLayerType !== type ||
          getModelId(lowestLayer) !== getModelId(layer)
        ) {
          this.clearBasemapLayers();
          this.map.getLayers().insertAt(0, layer);
        } else if (checkIsBasempLayerSplitAvailable(layer)) {
          if (this.getIsBasemapLayerSplit()) {
            this.map.getLayers().insertAt(1, layer);
          } else {
            this.clearBasemapLayers();
            this.map.getLayers().insertAt(0, layer);
          }
        }
      } else if (count === 2) {
        this.clearBasemapLayers();
        this.map.getLayers().insertAt(0, layer);
      } else {
        throw `The count of basemap layers should not exceed 2.`;
      }
    } else {
      this.map.addLayer(layer);
    }
  }
  removeLayer(layer) {
    this.map.removeLayer(layer);
  }
  clear() {
    const types = [
      LayerType.RECTANGLE,
      LayerType.CIRCLE,
      LayerType.POLYGON,
      LayerType.POLYLINE,
      LayerType.ARROW,
      LayerType.SITE_BOUNDARY,
      LayerType.TEXT,
      LayerType.CALL_OUT,
      LayerType.IMAGE,
      LayerType.SERVICE,
      LayerType.FEATURE_COLLECTION,
      LayerType.BUFFER,
      LayerType.CHAINAGE,
      LayerType.SAMPLE,
    ];

    this.removeLayersByTypes(types);
  }
  getLayerZIndex(layer: Layer): number {
    return getType(layer) === LayerType.CALL_OUT
      ? MAXIMUM_NUMBER_OF_LAYERS
      : layer.getZIndex() ?? 0;
  }
  getLayerCenter(layer: Layer): Coordinate {
    return getLayerCenter(this.map, layer);
  }
  addSampleFeature(
    layer: SampleLayer,
    sample: Sample,
    isDuplicate: false
  ): Feature<Point> {
    return addSampleFeature(this.map, layer, sample, isDuplicate);
  }
  updateSampleFeature(layer: SampleLayer, sample: Sample): void {
    updateSampleFeature(this.map, layer, sample);
  }
  removeSampleFeature(layer: SampleLayer, sampleId: Id) {
    removeSampleFeature(layer, sampleId);
  }
  findSampleFeatureById(
    id: Id
  ): Feature<Point> | Feature<Polygon> | Feature<LineString> | undefined {
    const storeApi = this.getStoreApi();
    const sample = storeApi.findSampleById(id);
    if (!sample) {
      throw `The sample was not found: sample id is ${id}`;
    }
    const layerModel = storeApi.getSampleLayerModel(sample);
    if (!layerModel) {
      throw `The sample layer model was not found: sample id is ${id}.`;
    }
    const layer = this.findLayerByModelId(layerModel.id);
    return layer?.getSource().getFeatureById(id);
  }
  async showFeature(feature: Feature<Geometry>) {
    feature.set('isHidden', false);
    return waitFor(() => !this.map.getView().getAnimating());
  }
  async hideFeature(feature: Feature<Geometry>) {
    feature.set('isHidden', true);
    return waitFor(() => !this.map.getView().getAnimating());
  }
  calculateZIndexOfBufferLayer(
    boundLayerModelIds: number[],
    bufferType: BufferType
  ) {
    const boundLayers = boundLayerModelIds
      .map((id) => this.findLayerByModelId(id))
      .filter((layer) => !!layer);
    const zs = boundLayers.map((boundLayer) => boundLayer.getZIndex());
    return bufferType === BufferType.Inverse
      ? Math.max(...zs) + 1
      : Math.min(...zs) - 1;
  }
  sort() {
    const getLayers = (model: LayerModel): Layer[] => {
      const layers: Layer[] = [];
      // Although the ESRI MapServer service layer has children, it
      // is handled as a single layer.
      if (
        (!checkIsMapServerFolderLayerModel(model) ||
          model.geojson.properties.esri_type !== EsriType.MapServer) &&
        model.geojson.properties.type !== LayerType.SAMPLE_GROUP &&
        model.children &&
        model.children.length > 0
      ) {
        model.children.forEach((child) => {
          const childLayers = getLayers(child);
          layers.push(...childLayers);
        });
      } else {
        let layer;
        if (
          !checkIsCalloutLayerModel(model) ||
          !model.geojson.properties.isBuiltin
        ) {
          // Skip the built-in call-out delegate.
          layer = this.findLayerByModelId(model.id);
        }
        // The layer is null when the corresponding layer is invisible.
        if (layer) {
          layers.push(layer);
        }
      }
      return layers;
    };

    const layers: Layer[] = [];
    const storeApi = this.getStoreApi();
    storeApi.getLayerModels().forEach((model) => {
      layers.push(...getLayers(model));
    });
    // The z index of layers starts with 1.
    const topmostZIndex = layers.length;
    layers.forEach((layer, index) => {
      // Why is index multiplied by 2?
      // That's because gaps are needed to arrange call-out connectors and buffers.
      const zIndex = (topmostZIndex - index) * 2;
      const type = getType(layer);
      if (type === LayerType.CALL_OUT) {
        const model = getModel(storeApi, layer)! as CalloutLayerModel;
        const { connectedTarget } = model.geojson.properties;
        if (
          !connectedTarget ||
          connectedTarget.type === ConnectedTargetType.Coordinate
        ) {
          layer.setZIndex(zIndex);
        } else if (connectedTarget.type === ConnectedTargetType.Sample) {
          const { id: sampleId } = connectedTarget;
          const sampleLayer = this.findSampleLayerBySampleId(sampleId!)!;
          const sampleLayerIndex = layers.indexOf(sampleLayer);
          layer.setZIndex((topmostZIndex - sampleLayerIndex) * 2 - 1);
        }
        const popupId = getCalloutPopupId(layer);
        const popup = findOverlayById(this.map, popupId);
        if (popup) {
          // Check popup because the layer could be invisible
          popup.getElement()!.style.zIndex = String(zIndex);
        }
      } else {
        layer.setZIndex(zIndex);
      }
    });

    layers
      .filter((layer) => getType(layer) === LayerType.BUFFER)
      .forEach((layer) => {
        const model = getModel(storeApi, layer)! as BufferLayerModel;
        const { boundLayerIds, bufferType } = model.geojson.properties;
        const z = this.calculateZIndexOfBufferLayer(boundLayerIds, bufferType);
        layer.setZIndex(z);
      });
  }
}
