import { Map } from 'ol';
import { toSize } from 'ol/size';
import { DEFAULT_TILE_SIZE } from 'ol/tilegrid/common';
import { LayerModel, LayerType } from '../../types';
import {
  EsriType,
  FeatureServerFolderLayerModel,
  GetVectorTileServerUrls,
  ImageServerFolderLayerModel,
  MapServerFolderLayerModel,
  MapServerItemLayerModel,
  VectorTileServerFolderLayerModel,
} from './types';

export function checkIsFeatureServerFolderLayerModel(
  model: LayerModel
): model is FeatureServerFolderLayerModel {
  const { type } = model.geojson.properties;
  return (
    type === LayerType.FOLDER &&
    'esri_type' in model.geojson.properties &&
    model.geojson.properties.esri_type === EsriType.FeatureServer
  );
}

export function checkIsMapServerFolderLayerModel(
  model: LayerModel
): model is MapServerFolderLayerModel {
  const { type } = model.geojson.properties;
  return (
    type === LayerType.FOLDER &&
    'esri_type' in model.geojson.properties &&
    model.geojson.properties.esri_type === EsriType.MapServer
  );
}

export function checkIsImageServerFolderLayerModel(
  model: LayerModel
): model is ImageServerFolderLayerModel {
  const { type } = model.geojson.properties;
  return (
    type === LayerType.FOLDER &&
    'esri_type' in model.geojson.properties &&
    model.geojson.properties.esri_type === EsriType.ImageServer
  );
}

export function checkIsVectorTileServerFolderLayerModel(
  model: LayerModel
): model is VectorTileServerFolderLayerModel {
  const { type } = model.geojson.properties;
  return (
    type === LayerType.FOLDER &&
    'esri_type' in model.geojson.properties &&
    model.geojson.properties.esri_type === EsriType.VectorTileServer
  );
}

export function getVisibleMapServerItemLayerModels(
  folderModel: MapServerFolderLayerModel
): MapServerItemLayerModel[] {
  return (folderModel.children as MapServerItemLayerModel[]).filter(
    (item) => item.visible
  );
}

export function getMapImageServerLayerInfo(
  map: Map,
  folderModel: MapServerFolderLayerModel | ImageServerFolderLayerModel
): { isTileServiceForced: boolean; tileSize: number[] | undefined } {
  if (checkIsImageServerFolderLayerModel(folderModel)) {
    return {
      isTileServiceForced: false,
      tileSize: undefined,
    };
  }

  const hasLabels = !!getVisibleMapServerItemLayerModels(folderModel).find(
    (item) => item.geojson.properties.hasLabels
  );
  const { maxImageSize } = folderModel.geojson.properties;

  const mapSize = map.getSize()!;
  const ratio = 1.5;
  const imageSize = [mapSize[0] * ratio, mapSize[1] * ratio];

  const isMaxImageSizeExceeded =
    maxImageSize &&
    (imageSize[0] > maxImageSize.width || imageSize[1] > maxImageSize.height);
  const isTileServiceForced = !hasLabels || isMaxImageSizeExceeded;

  const tileSize = isMaxImageSizeExceeded
    ? toSize(Math.min(maxImageSize.width, maxImageSize.height))
    : toSize(DEFAULT_TILE_SIZE);

  return {
    isTileServiceForced,
    tileSize,
  };
}

export const getVectorTileServerUrlsDefault: GetVectorTileServerUrls = (
  folderModel,
  itemModel
) => {
  const { url } = folderModel.geojson.properties;
  const { defaultStyles } = itemModel.geojson.properties;
  return {
    tileUrl: `${url}/tile/{z}/{y}/{x}.pbf`,
    styleUrl: `${url}/${defaultStyles}`,
  };
};
