import { ChatProvider } from '../chat-service';
import { User } from '../user';

function loadScript() {
  if (hasLiveChat()) {
    return;
  }

  const scriptTag = document.createElement('script');
  scriptTag.onload = chatwootLoaded;
  scriptTag.defer = true;
  scriptTag.async = true;
  scriptTag.src = 'https://chat.datanest.earth/packs/js/sdk.js';
  document.body.appendChild(scriptTag);
}

declare global {
  interface Window {
    chatwootSettings: any;
    chatwootSDK: any;
    $chatwoot: any;
  }
}

function chatwootLoaded() {
  window.chatwootSettings = {
    hideMessageBubble: true,
  };
  window.chatwootSDK.run({
    websiteToken:
      import.meta.env.VITE_CHATWOOT_WEBSITE_TOKEN ?? '9mZxsbXhLiaDzxNFoUi3nghC',
    baseUrl:
      'https://' +
      (import.meta.env.VITE_CHATWOOT_HOST ?? 'chat.datanest.earth'),
  });
}

function setContext(
  user: User,
  projectId: string | undefined,
  projectName: string | undefined,
  projectNumber: string | undefined
) {
  window.$chatwoot.setUser(user.user_id, {
    name: user.name,
    email: user.email,
    company_name: user.company?.company_name,
  });

  window.$chatwoot.setCustomAttributes({
    datanest_company_id: user.company?.id,
    company_name: user.company?.company_name,
    datanest_user_id: user.user_id,
    datanest_project_id: projectId,
    datanest_uri: window.location.pathname + window.location.search,
    project_url: projectId
      ? window.location.origin + '/open-project/' + projectId
      : null,
    project_name: projectName,
    project_number: projectNumber,
  });
}

function open(
  user: User,
  projectId: string | undefined,
  projectName: string | undefined,
  projectNumber: string | undefined
) {
  setContext(user, projectId, projectName, projectNumber);
  window.$chatwoot.toggle('open');
}

function hasLiveChat() {
  if (!import.meta.env.VITE_SHOW_LIVECHAT) {
    return false;
  }
  return window.chatwootSDK !== undefined;
}

export default {
  loadScript,
  setContext,
  open,
  hasLiveChat,
} as ChatProvider;
