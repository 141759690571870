import type { Pid } from '../common/types';
import { FillStyle, OutlineStyle } from '../types';

export type App = {
  id: Pid;
  sections: Section[];
  point_icon?: number;
  drawing_colour?: string;
  drawing_properties?: { fillStyle: FillStyle; outlineStyle: OutlineStyle };
};

export type Section = { id: Pid; template_fields: Field[] };

export type Field = { id: Pid };

type InputValueValue = string | null | boolean | number | string[];

export type InputValue = {
  template_tab_id: Pid;
  template_section_id: Pid;
  template_field_id: Pid;
  template_section_index: number;
  value: InputValueValue;
  value2: InputValueValue;
};

export enum DrawingType {
  Any = 'any',
  Point = 'point',
  Polygon = 'polygon',
  Polyline = 'polyline',
  NonSpatial = 'non-spatial',
}

export type CollectionType =
  | DrawingType.Point
  | DrawingType.Polygon
  | DrawingType.Polyline;

export enum Operator {
  EqualTo = '==',
  NotEqualTo = '!=',
  GreaterThan = '>',
  GreaterThanOrEqualTo = '>=',
  LessThan = '<',
  LessThanOrEqualTo = '<=',
}
