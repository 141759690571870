import { Feature, Map } from 'ol';
import { GeoJSON as GeoJSONDriver } from 'ol/format';
import { Geometry } from 'ol/geom';
import { getMapProjection } from './view';

function createGeoJSONDriver(map: Map): GeoJSONDriver {
  const featureProjection = getMapProjection(map);
  return new GeoJSONDriver({ featureProjection });
}

// Convert object to GeoJSON data.
export function toGeoJSON(
  map: Map,
  object: Feature<Geometry> | Feature<Geometry>[]
): GeoJSON.Feature | GeoJSON.FeatureCollection {
  const driver = createGeoJSONDriver(map);

  return object instanceof Feature
    ? driver.writeFeatureObject(object)
    : driver.writeFeaturesObject(object);
}

// Convert data in GeoJSON format to an array of features.
export function fromGeoJSON(map: Map, object: any): Feature<Geometry>[] {
  const driver = createGeoJSONDriver(map);
  return driver.readFeatures(object);
}
