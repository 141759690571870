import { Map } from 'ol';
import Popup from 'ol-ext/overlay/Popup';
import { LineString } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { getGatherImageSrc, getImageSrc } from '../../adapter/project';
import { extentToBounds } from '../../common/extent';
import { fromGeoJSON, toGeoJSON } from '../../common/geojson';
import { getStoreApi } from '../../common/store-api';
import { offsetNoAnchor } from '../../overlay/popup';
import { findOverlayById } from '../../overlay/util';
import addOpacity from '../../style/addOpacity';
import createShapeStyle from '../../style/createShapeStyle';
import { TextAlignment, createTextShadow } from '../../style/text';
import { LayerUsageByType } from '../constants';
import { LayerType } from '../types';
import { createLayerProperties } from '../utils';
import type { CalloutLayer, CalloutLayerModel } from './types';
import { getPopupId, getUsage } from './utils';

export default function createCalloutLayer(
  map: Map,
  model: CalloutLayerModel
): CalloutLayer {
  const [connector] = fromGeoJSON(map, model.geojson);
  const layer = new VectorLayer<VectorSource<LineString>>({
    source: new VectorSource({ features: [connector] }),
    properties: createLayerProperties(
      model.id,
      model.geojson.properties.type,
      model.geojson.properties.usage
    ),
    style: createShapeStyle(map, (feature) => {
      return model.geojson.properties;
    }),
  }) as CalloutLayer;

  layer.setVisible(model.geojson.properties.isConnectorHidden ?? true);

  const layers = map.getLayers();
  const handleAdd = ({ element }) => {
    if (element === layer) {
      const popupId = getPopupId(layer);
      const popup = new Popup({
        id: popupId,
        closeBox: false,
        stopEvent: false,
        positioning: model.geojson.properties.positioning,
        anchor: false,
      });
      map.addOverlay(popup);
      const position = (
        connector.getGeometry() as LineString
      ).getCoordinates()[0];
      popup.show(position);
      updatePopup(map, model, popup);

      layers.un('add', handleAdd);
    }
  };
  layers.on('add', handleAdd);
  const handleRemove = ({ element }) => {
    if (element === layer) {
      const popupId = getPopupId(layer);
      const popup = findOverlayById(map, popupId)!;
      map.removeOverlay(popup);

      layers.un('remove', handleRemove);
    }
  };
  layers.on('remove', handleRemove);

  layer.getFirstFeature = function () {
    const [firstFeature] = this.getSource().getFeatures();
    return firstFeature;
  };

  layer.checkHasFeature = function (feature) {
    return this.getSource().hasFeature(feature);
  };

  layer.toGeoJSON = function () {
    const feature = this.getFirstFeature()!.clone();
    return toGeoJSON(map, feature);
  };

  layer.getBounds = function (padding) {
    const extent = this.getSource().getExtent();
    return extentToBounds(extent, map.getView().getProjection(), padding);
  };

  layer.refresh = function () {};

  return layer;
}

function updatePopup(map: Map, model: CalloutLayerModel, popup: Popup) {
  const usage = getUsage(model);
  const {
    text,
    width,
    color,
    backgroundColor,
    opacity,
    fontSize,
    weight,
    image_data,
  } = model.geojson.properties;

  offsetNoAnchor(popup);

  const popupElement = popup.getElement();
  popupElement.style.width = `${width}px`;
  popupElement.style.height = 'auto';
  popupElement.style.minHeight =
    (usage === LayerUsageByType[LayerType.CALL_OUT].SHOW_TEXT &&
      text.trim() === '') ||
    (usage === LayerUsageByType[LayerType.CALL_OUT].SHOW_IMAGE &&
      !image_data.url)
      ? '70px'
      : 0;
  popupElement.style.borderColor = color;
  popupElement.style.borderWidth = `${weight}px`;
  popupElement.style.borderRadius = `5px`;
  popupElement.style.fontSize = `${fontSize}px`;
  popupElement.style.overflow = 'hidden';
  popupElement.style.backgroundColor = addOpacity(backgroundColor, opacity);

  const { content } = popup;
  content.style.width = '100%';
  content.style.height = '100%';
  content.style.padding = '0';
  content.appendChild(createContent(map, model));
}

function createContent(map: Map, model: CalloutLayerModel): HTMLDivElement {
  const usage = getUsage(model);
  const { text, image_data, opacity, isTextUsedAsCaption } =
    model.geojson.properties;
  const container = document.createElement('div');
  const textStyle = getTextStyle(model);

  if (
    usage === LayerUsageByType[LayerType.CALL_OUT].SHOW_IMAGE &&
    image_data.url
  ) {
    const imageNode = document.createElement('img');
    const { url, isFromGather } = image_data;
    const storeApi = getStoreApi(map);
    const project = storeApi.getProject();
    imageNode.src = !isFromGather
      ? getImageSrc(project, url)
      : getGatherImageSrc(project, url);
    imageNode.style.width = '100%';
    imageNode.style.opacity = String(opacity);
    imageNode.draggable = false;
    container.appendChild(imageNode);

    if (isTextUsedAsCaption) {
      imageNode.style.display = 'block';
      const captionNode = document.createElement('div');
      captionNode.innerHTML = text;
      applyStyleToTextNode(textStyle, captionNode);
      container.appendChild(captionNode);
    }
  } else if (usage === LayerUsageByType[LayerType.CALL_OUT].SHOW_TEXT) {
    container.innerHTML = text;
    container.style.width = '100%';
    container.style.height = '100%';
    applyStyleToTextNode(textStyle, container);
  }

  return container;
}

function getTextStyle(model: CalloutLayerModel) {
  const {
    textColor,
    textOutlineColor,
    alignment,
    isItalic,
    isBold,
    isUnderlined,
  } = model.geojson.properties;

  return {
    color: textColor,
    textShadow: createTextShadow(textOutlineColor),
    textAlign: alignment === TextAlignment.Center ? 'center' : 'left',
    fontStyle: isItalic ? 'italic' : 'normal',
    fontWeight: isBold ? 'bold' : 'normal',
    textDecoration: isUnderlined ? 'underline' : 'none',
    textUnderlinePosition: 'under',
    padding: '0.2em',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  };
}

function applyStyleToTextNode(style, textNode) {
  Object.keys(style).forEach((item) => {
    textNode.style[item] = style[item];
  });
}
