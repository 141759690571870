<script setup lang="ts">
import { Popover } from 'bootstrap';
import { onMounted, ref, watch, computed } from 'vue';
import ContextMenu from './ContextMenu.vue';
import type { ContextMenuModel } from './ContextMenuTypes';

const props = withDefaults(
  defineProps<{
    action: Function;
    isDisabled: boolean;
    reason?: string;
    container?: string;
    trigger?: string;
    title?: string;
    contextMenuModel?: ContextMenuModel;
    adaptContextMenu?: (root: HTMLDivElement) => void;
  }>(),
  {
    container: 'body',
    trigger: 'hover focus',
  }
);

const button = ref<HTMLDivElement | null>(null);
const contextMenu = ref<typeof ContextMenu | null>(null);
const isPopoverAvailable = computed<boolean>(() => {
  const { isDisabled, reason } = props;
  return isDisabled && !!reason?.trim() && !!button.value;
});

function createPopover(
  element: HTMLDivElement,
  reason: string,
  container?: string,
  trigger?: string
) {
  new Popover(element, {
    html: true,
    content: reason.trim(),
    container,
    trigger,
  });
}

function getPopover() {
  return button.value ? Popover.getInstance(button.value) : null;
}

async function handleClick(event) {
  if (props.isDisabled) {
    return;
  }

  if (props.contextMenuModel && props.contextMenuModel?.length > 0) {
    await contextMenu.value!.open(event);
    if (props.adaptContextMenu) {
      props.adaptContextMenu(contextMenu.value!.getMenu());
    }
  } else {
    props.action();
  }
}

watch(isPopoverAvailable, (value) => {
  if (value) {
    const { reason, container, trigger } = props;
    createPopover(button.value!, reason!, container, trigger);
  } else {
    const popover = getPopover();
    popover.dispose();
  }
});

watch(
  () => props.reason,
  (value) => {
    if (isPopoverAvailable.value) {
      const popover = getPopover()!;
      popover.setContent({
        '.popover-body': value,
      });
    }
  }
);

onMounted(() => {
  if (isPopoverAvailable.value) {
    const { reason, container, trigger } = props;
    createPopover(button.value!, reason!, container, trigger);
  }
});
</script>

<template>
  <div class="position-relative">
    <div
      ref="button"
      class="position-relative"
      :style="{ cursor: isDisabled ? 'not-allowed' : 'pointer' }"
      :title="title"
      @click.stop="handleClick"
    >
      <slot></slot>
      <div
        v-if="isDisabled"
        class="position-absolute"
        :style="{ top: 0, left: 0, color: 'red' }"
      >
        <i class="fas fa-ban"></i>
      </div>
    </div>

    <ContextMenu
      v-if="contextMenuModel && contextMenuModel?.length > 0"
      ref="contextMenu"
      :options="contextMenuModel"
    />
  </div>
</template>
