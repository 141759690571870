export enum IntegrationId {
  NEARMAP = 4,
  METROMAP = 5,
}

// The undefined apiKey means the integration is not ready.
// The null apiKey means the integration is disabled.
export type Integration = {
  id: IntegrationId;
  apiKey?: string | null;
};

export const INTEGRATION_NAMES: Record<IntegrationId, string> = {
  [IntegrationId.NEARMAP]: 'Nearmap',
  [IntegrationId.METROMAP]: 'Metromap',
};

export function findIntegrationById(
  integrations: Integration[],
  id: IntegrationId
): Integration | undefined {
  return integrations.find((integration) => integration.id === id);
}
