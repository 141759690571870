<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import Modal from './Modal.vue';
import Carousel from './Carousel.vue';
import Spinner from './Spinner.vue';

const props = defineProps<{
  imageUrl: string | string[];
}>();

const emit = defineEmits(['close']);
const isLoading = ref(false);
const display = ref('');
const isCarousel = computed(() => Array.isArray(props.imageUrl));

function close() {
  isLoading.value = false;
  emit('close');
}

onMounted(() => {
  if (!Array.isArray(props.imageUrl)) {
    display.value = props.imageUrl;
    isLoading.value = true;
  }
});
</script>

<template>
  <Modal @close="close" half class="image__modal">
    <div class="modal-body">
      <div v-if="isLoading && !isCarousel" class="text-center p-4">
        <Spinner large />
      </div>
      <Carousel v-if="isCarousel" :sources="imageUrl" />
      <div
        v-else
        class="align-items-center justify-content-center"
        :class="{
          'd-none': isLoading || !display,
          'd-flex': !isLoading && display,
        }"
      >
        <img
          v-if="display"
          :src="display"
          @load="() => (isLoading = false)"
          style="max-width: 100%"
        />
      </div>
    </div>
  </Modal>
</template>
