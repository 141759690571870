<script setup lang="ts">
import useMapsApi from '@/js/composables/useMapsApi';
import { useStore } from '@/js/store';
import useEvalu8Store from '@/js/stores/evalu8';
import useFigureStore from '@/js/stores/figure';
import useLayerModelStore from '@/js/stores/layer-model';
import useSampleStore from '@/js/stores/sample';
import { findFieldByIdFromApps } from '@component-library/business-logic/app';
import { hasCompanyRole } from '@component-library/company';
import useAuth from '@component-library/composables/useAuth';
import { App as GatherApp, isItemNonSpatial } from '@component-library/gather';
import { useToastStore } from '@component-library/store/toasts';
import { waitFor } from '@component-library/utils/wait-for';
import Map, {
  checkIsValidLatLng,
  findBasemapApiById,
  getModelId,
  getType,
} from '@maps/lib/olbm';
import { MapType } from '@maps/lib/olbm/common/types';
import { DEFAULT_ZOOM, FitTarget } from '@maps/lib/olbm/common/view';
import type {
  PoiAddEventPayload,
  PoiDeleteEventPayload,
  PoiLonLatChangeEventPayload,
} from '@maps/lib/olbm/event/types';
import type { SampleStyle } from '@maps/lib/olbm/layer/sample/types';
import { checkIsRenderableNonSpatialSample } from '@maps/lib/olbm/layer/sample/utils';
import type { SingleShapeLayerModel } from '@maps/lib/olbm/layer/shape/types';
import type {
  App,
  Extent,
  FeatureClickEventPayload,
  Field,
  Figure,
  GeolocationChangeEventPayload,
  Id,
  Layer,
  LayerModel,
  LegacyLatLng,
  LonLat,
  Pid,
  Sample,
  SampleLayer,
  ShapeLayer,
} from '@maps/lib/olbm/types';
import { BasemapId, Event, LayerType } from '@maps/lib/olbm/types';
import _isEqual from 'lodash/isEqual';
import { getUid } from 'ol';
import 'ol-ext/dist/ol-ext.css';
import Popup from 'ol-ext/overlay/Popup';
import { Coordinate } from 'ol/coordinate';
import { Geometry } from 'ol/geom';
import 'ol/ol.css';
import type { ProjectionLike } from 'ol/proj';
import { get as getProjection } from 'ol/proj';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    projection?: ProjectionLike;
    center: LonLat;
    zoom?: number;
    basemapId?: BasemapId | null;
    apps: App[];
    isAppsLoaded: boolean;
  }>(),
  {
    projection: () => getProjection('EPSG:3857'),
    zoom: DEFAULT_ZOOM,
    basemapId: BasemapId.OPEN_STREET_MAP,
  }
);

const emit = defineEmits([
  'mounted',
  'unmounted',
  'feature-click',
  'geolocation-enabled',
  'geolocation-disabled',
  'geolocation-error',
  'geolocation-change',
  'poi-add',
  'poi-lonlat-change',
  'poi-delete',
]);

const auth = useAuth();

const { ensureGatherFigure, loadSamples } = useMapsApi();

const store = useStore();
const toastStore = useToastStore();

const figureStore = useFigureStore();
const {
  loadFigures,
  getProject,
  getSelectedFigure,
  setSelectedFigureId,
  findFigureStylingRulesByFigureId,
} = figureStore;
const { figures } = storeToRefs(figureStore);

const layerModelStore = useLayerModelStore();
const { layerModels, integrations } = storeToRefs(layerModelStore);
const {
  findLayerModelById,
  findLayerModelsByType,
  getAllSampleGroups,
  getDefaultSampleGroup,
  getSampleLayerModel,
  addLayerModel,
  removeLayerModel,
  clearTempLayerModels,
  updateLayerModel,
  updateGeojson,
  toRenderableLayerModels,
  checkIsLayerModelHidden,
  checkIsRenderableNonSpatialSampleGroup,
  loadSubFolders,
  getStylableLayerModels,
  loadIntegrations,
} = layerModelStore;
const unsubscribeToLayerModelStore = layerModelStore.$onAction(
  ({ name, args, after }) => {
    if (name === 'loadLayerModels') {
      after(async () => {
        await waitFor(() => props.isAppsLoaded);
        const { layerManager } = map;
        layerManager.clear();
        const renderableLayerModels = toRenderableLayerModels(
          layerModels.value
        );
        for (let i = 0; i < renderableLayerModels.length; i++) {
          const renderableModel = renderableLayerModels[i];

          try {
            if (!checkIsLayerModelHidden(renderableModel.id)) {
              const layers = await layerManager.createLayers(renderableModel);
              layers.forEach((layer) => {
                layerManager.addLayer(layer);
              });
            }
          } catch (e) {
            console.log(e);
          }
        }
        layerManager.sort();
      });
    } else if (name === 'updateLayerModel') {
      const [layerModelId] = args;
      after(() => {
        const { layerManager } = map;
        const layer = layerManager.findLayerByModelId(layerModelId);
        // layer is undefined when it is hidden.
        if (layer) {
          layer.refresh();
        }
      });
    } else if (name === 'updateGeojson') {
      const [sample] = args;
      after(() => {
        refreshLayerBySample(sample);
      });
    } else if (name === 'clearTempLayerModels') {
      after((tempModelIds) => {
        map.layerManager.removeLayersByModelIds(tempModelIds);
      });
    }
  }
);

const sampleStore = useSampleStore();
const {
  addSamples,
  addSample,
  removeSample,
  findSampleById,
  findSampleByIdAsync,
  getScopedSamples,
  findPolySampleByLayerModelId,
  updateSample,
} = sampleStore;
const unsubscribeToSampleStore = sampleStore.$onAction(
  ({ name, args, after }) => {
    if (name === 'addSample') {
      const [sample] = args;
      after(() => {
        refreshLayerBySample(sample);
      });
    } else if (name === 'removeSample') {
      const [sampleId] = args;
      const sample = findSampleById(sampleId)!;
      after(() => {
        const layerModel = getSampleLayerModel(sample)!;
        const layer = map.layerManager.findLayerByModelId(layerModel.id)!;
        // The layer doesn't exist if sample is a non-spatial item.
        if (!layer) {
          return;
        }
        if (getType(layer) === LayerType.SAMPLE) {
          map.layerManager.removeSampleFeature(layer, sampleId);
        } else {
          removeLayerModel(layerModel.id);
          map.layerManager.removeLayer(layer);
        }
      });
    } else if (name === 'updateSample') {
      const [{ id }] = args;
      const sample = findSampleById(id);
      after(() => {
        refreshLayerBySample(sample);
      });
    }
  }
);

const evalu8Store = useEvalu8Store();
const { findScenarioById, getExceedance, getExceededCriteriaTypes } =
  evalu8Store;

let map: Map;
let ro: ResizeObserver;

const target = ref<HTMLDivElement | undefined>(undefined);

function refreshLayerBySample(sample) {
  const model = getSampleLayerModel(sample)!;
  const layer = map.layerManager.findLayerByModelId(model.id);

  if (!layer) {
    return;
  }

  const type = getType(layer);
  if (type !== LayerType.SAMPLE) {
    const feature = (layer as ShapeLayer).getFirstFeature()!;
    feature.setId(sample.id);
    feature.set('layerUid', getUid(layer));
    layer.refresh();
  } else if (
    (sample.is_non_spatial &&
      (!checkIsRenderableNonSpatialSample(sample) ||
        map.checkIsFilteredOutBySampleGroup(sample))) ||
    isItemNonSpatial(sample)
  ) {
    return;
  } else if (!(layer as SampleLayer).getSource().getFeatureById(sample.id)) {
    map.layerManager.addSampleFeature(layer as SampleLayer, sample, false);

    // The sample's styling may revert to the corresponding app's styling.
    const otherSampleLayers = map.layerManager
      .findLayersByType(LayerType.SAMPLE)
      .filter((_layer) => _layer !== layer);
    otherSampleLayers.forEach((otherSampleLayer) => {
      map.layerManager.removeSampleFeature(
        otherSampleLayer as SampleLayer,
        sample.id
      );
    });
  } else {
    map.layerManager.updateSampleFeature(layer as SampleLayer, sample);
  }
}

onMounted(async () => {
  const hedgeImage = await new Promise<HTMLImageElement>((resolve) => {
    const hedgeImage = new Image();
    hedgeImage.src = '/images/map_icons/hedge.svg';
    hedgeImage.onload = () => resolve(hedgeImage);
  });

  // Integrations must be ready before map is created.
  await loadIntegrations();

  const popup = new Popup({
    id: Map.POPUP_ID,
    popupClass: 'default',
    closeBox: true,
    positioning: 'auto',
    autoPan: {
      animation: { duration: 250 },
    },
  });

  map = new Map({
    type: MapType.GATHER_MAIN,
    target: target.value!,
    projection: props.projection,
    center: props.center,
    zoom: props.zoom,
    basemapId: props.basemapId,
    storeApi: {
      // Figure related
      getProject,
      getSelectedFigure,
      findFigureStylingRulesByFigureId,
      getLayerModels() {
        return layerModels.value;
      },
      // Layer model related
      findLayerModelById,
      findLayerModelsByType,
      getAllSampleGroups,
      getDefaultSampleGroup,
      getSampleLayerModel,
      addLayerModel,
      removeLayerModel,
      clearTempLayerModels,
      // Sample related
      async loadSamples(
        sampleGroupId: Id,
        extentInWgs84: Extent
      ): Promise<Sample[]> {
        const samples = await loadSamples(sampleGroupId, extentInWgs84);
        addSamples(samples);
        return samples;
      },
      addSample,
      removeSample,
      findSampleById,
      findSampleByIdAsync,
      findPolySampleByLayerModelId,
      getScopedSamples,
      // Evalu8 related
      findScenarioById,
      getExceedance,
      getExceededCriteriaTypes,
      // App related
      findAppById(id: Pid): App | undefined {
        return props.apps.find((app) => app.id === id);
      },
      findFieldById(id: Pid): Field | undefined {
        return findFieldByIdFromApps(props.apps as GatherApp[], id);
      },
      getSampleOnlyExport(): boolean {
        return false;
      },
      getStylableLayerModels,
      getIntegrations() {
        return integrations.value;
      },
      showError(error: string) {
        toastStore.error(error);
      },
      showWarning(warning: string) {
        toastStore.warning(warning);
      },
      checkHasPermission(p) {
        return hasCompanyRole(auth.user(), p);
      },
      checkIsRenderableNonSpatialSampleGroup(layerModelId) {
        return checkIsRenderableNonSpatialSampleGroup(layerModelId);
      },
    },
    hedgeImage,
    interactions: {
      doubleClickZoom: false,
      keyboard: false,
      shiftDragZoom: false,
    },
    overlays: [popup],
  });

  map.addEventListener('feature-click', (event) => {
    emit(
      'feature-click',
      (event as Event<FeatureClickEventPayload>).getPayload()
    );
  });
  map.addEventListener('geolocation-enabled', () => {
    emit('geolocation-enabled');
  });
  map.addEventListener('geolocation-disabled', () => {
    emit('geolocation-disabled');
  });
  map.addEventListener('geolocation-error', () => {
    map.disableGeolocation(true);
    emit('geolocation-error');
  });
  map.addEventListener('geolocation-change', (event) => {
    const payload = (
      event as Event<GeolocationChangeEventPayload>
    ).getPayload();

    emit('geolocation-change', payload);
  });

  map.interactionManager.addEventListener('poi-add', (event) => {
    const payload = (event as Event<PoiAddEventPayload>).getPayload();
    emit('poi-add', payload);
  });
  map.interactionManager.addEventListener('poi-lonlat-change', (event) => {
    const payload = (event as Event<PoiLonLatChangeEventPayload>).getPayload();
    emit('poi-lonlat-change', payload);
  });
  map.interactionManager.addEventListener('poi-delete', (event) => {
    const payload = (event as Event<PoiDeleteEventPayload>).getPayload();
    emit('poi-delete', payload);
  });

  ro = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.target === target.value) {
        map.updateSize();
      }
    });
  });
  ro.observe(target.value!);

  await ensureGatherFigure();
  await loadFigures();

  const { selectedFigureId } = store.state;

  const figureId =
    figures.value.find((figure) => figure.id === selectedFigureId)?.id ??
    figures.value.find((figure) => figure.gather_access)!.id;

  setSelectedFigureId(figureId);

  await loadSubFolders();

  emit('mounted');
});

onBeforeUnmount(() => {
  ro.disconnect();
  setSelectedFigureId(undefined);
  unsubscribeToSampleStore();
  unsubscribeToLayerModelStore();
  emit('unmounted');
});

watch(
  () => props.projection,
  async (newValue) => {
    await waitFor(() => !!map);
    map.changeProjection(newValue);
  }
);

watch(
  () => props.center,
  async (newValue: LonLat, oldValue: LonLat | undefined) => {
    await waitFor(() => !!map);
    if (!_isEqual(newValue, oldValue)) {
      map.animateView(newValue);
    }
  }
);

watch(
  () => props.apps,
  () => {
    map?.refreshLayersAffectedByStylingRules();
  }
);

defineExpose({
  findBasemapApiById,
  useBasemapApi(basemapId: BasemapId): Layer {
    return map.useBasemapApi(basemapId);
  },
  getLayerManager() {
    return map.layerManager;
  },
  getInteractionManager() {
    return map.interactionManager;
  },
  getSelectedFigure,
  findSampleById,
  removeSample,
  findPolySampleByLayerModelId,
  updateSample,
  getSampleLayerModel,
  findLayerModelById,
  getLayerModelId: getModelId,
  addLayerModel: async (
    figure: Figure,
    layerModel: LayerModel,
    afterLayerId?: number
  ) => {
    await addLayerModel(figure, layerModel, afterLayerId);
    const layers = await map.layerManager.createLayers(layerModel);
    layers.forEach((layer) => map.layerManager.addLayer(layer));
    map.layerManager.sort();
    return layerModel;
  },
  removeLayerModel: async (id) => {
    await removeLayerModel(id);
    map.layerManager.removeLayersByModelIds([id]);
  },
  updateLayerModel,
  updateGeojson,
  getZoom() {
    return map.getZoom();
  },
  getProjection() {
    return map.getProjection();
  },
  animateView(centerLonLat: LonLat | LegacyLatLng | Coordinate, zoom: number) {
    map.animateView(centerLonLat, zoom);
  },
  fit(target: FitTarget, options?: object) {
    map.fit(target, options);
  },
  checkIsValidLatLng,
  fromLegacyLatLng(latLng: LegacyLatLng): Coordinate {
    return map.fromLegacyLatLng(latLng);
  },
  toLegacyLatLng(coord: Coordinate): LegacyLatLng {
    return map.toLegacyLatLng(coord);
  },
  getLayerModelIcon(model: LayerModel) {
    return map.getLayerModelIcon(model);
  },
  fromGeoJSON(object) {
    return map.fromGeoJSON(object);
  },
  toGeoJSON(object) {
    return map.toGeoJSON(object);
  },
  addScaleLine() {
    map.addScaleLine();
  },
  removeScaleLine() {
    map.removeScaleLine();
  },
  enableGeolocation(trackingOptions) {
    map.enableGeolocation(trackingOptions);
  },
  disableGeolocation() {
    map.disableGeolocation();
  },
  getCenterLonLat() {
    return map.getCenterLonLat();
  },
  getCurrentLocationLonLat() {
    return map.getCurrentLocationLonLat();
  },
  setMinZoom(value) {
    map.setMinZoom(value);
  },
  getHoveredPoi() {
    return map.getHoveredPoi();
  },
  getClickedPoi() {
    return map.getClickedPoi();
  },
  clearClickedPoi() {
    map.clearClickedPoi();
  },
  centerGeometry(geom: Geometry, occupiedBottomHeight: number) {
    map.centerGeometry(geom, occupiedBottomHeight);
  },
  getShapeStyle(layerModel: SingleShapeLayerModel) {
    return map.getShapeStyle(layerModel);
  },
  getFigureStylingRulesOnLayer(layerModelId: Id) {
    return map.getFigureStylingRulesOnLayer(layerModelId);
  },
  deleteSampleStyleFromCache(sample: Sample) {
    map.deleteSampleStyleFromCache(sample);
  },
  checkIsRenderableNonSpatialSampleGroup(layerModelId: Id): boolean {
    return map.checkIsRenderableNonSpatialSampleGroup(layerModelId);
  },
  getSampleStyle(sample: Sample): SampleStyle | undefined {
    return map.getSampleStyle(sample);
  },
});
</script>

<template>
  <div ref="target" class="map"></div>
</template>

<style lang="scss" scoped>
.map {
  :deep(.editable-sample-icon-container) {
    background-color: rgba(51, 136, 255, 0.1);
    border: 0.0625em dashed var(--primary-color);
  }

  :deep(.editable-sample-label-container) {
    background-color: rgba(51, 136, 255, 0.1);
    border: 0.0625em dashed var(--primary-color);
  }

  :deep(.ol-measure-control) {
    left: 0.5em;
    top: calc(0.5em + 1.375em * 2 + 4px + 0.5em);
  }

  :deep(.ol-touch .ol-measure-control) {
    left: 0.5em;
    top: calc(0.5em + (1.375em * 2) * 1.5 + 4px + 0.5em);
  }

  :deep(.ol-simulate-control) {
    left: 0.5em;
    top: calc(0.5em + 1.375em * 2 + 4px + 0.5em + 1.375em + 4px + 0.5em);
  }

  :deep(.ol-touch .ol-simulate-control) {
    left: 0.5em;
    top: calc(
      0.5em + (1.375em * 2) * 1.5 + 4px + 0.5em + (1.375em * 1.5) + 4px + 0.5em
    );
  }
}
</style>
