<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import FileUploader from '@component-library/components/FileUploader.vue';
import { goToApp } from '@component-library/switcher/handover';
import {
  getAppShareGroupTitle,
  getAppShareGroupDescription,
} from '@component-library/business-logic/app';
import { useRouter } from 'vue-router/composables';
import { useStore } from '@/js/store';
import { App } from '@component-library/gather';
import axios from 'axios';
import { useToastStore } from '@component-library/store/toasts';
import { UploadStatus } from '@component-library/file-utils';
import Spinner from '@component-library/components/Spinner.vue';

const props = defineProps<{ app: App }>();
const emit = defineEmits(['getApps']);
const router = useRouter();
const store = useStore();
const toastStore = useToastStore();

const name = ref<string>(),
  description = ref<string>(),
  iconFile = ref(null),
  iconUploadStatus = ref({
    progress: 0,
    pending: false,
    complete: false,
    error: null,
  } as UploadStatus),
  updating = ref(false);

watch(() => props.app, setAppSettings);

function submitIconUpload(file) {
  if (!file) {
    return;
  }

  iconFile.value = file;
}

function goToMaster() {
  goToApp({
    route: 'template_editor',
    app: 'gather',
    isMiddleClick: true,
    project: {
      project_id: props.app.template_tab?.project_id ?? props.app?.project_id,
    },
    $router: router,
    $store: store,
    isGather: true,
  });
}

async function update() {
  if (!props.app.share_group) {
    iconUploadStatus.value.error = 'Unable to update this app.';
    return;
  }

  const uploader = {
    onUploadProgress: (progressEvent) =>
      (iconUploadStatus.value.progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )),
  };

  function getFormData(fields) {
    let formData = new FormData();

    Object.keys(fields).forEach((key) => {
      if (fields[key]) {
        formData.set(key, fields[key]);
      }
    });

    return formData;
  }

  try {
    updating.value = true;

    if (iconFile.value) {
      iconUploadStatus.value.pending = true;
    }

    await axios.post(
      `api/template/app-group/${props.app.share_group}/update`,
      getFormData({
        name: name.value,
        description: description.value,
        icon: iconFile.value,
      }),
      uploader
    );

    iconUploadStatus.value.complete = true;

    emit('getApps');
  } catch (err) {
    iconUploadStatus.value.error = 'Failed to upload logo.';
    toastStore.unexpected();
    throw err;
  } finally {
    iconUploadStatus.value.pending = false;
    updating.value = false;
  }
}

function setAppSettings() {
  name.value = getAppShareGroupTitle(props.app);
  description.value = getAppShareGroupDescription(props.app);
}

onMounted(setAppSettings);
</script>

<template>
  <div>
    <hr />

    <div class="alert alert-info">
      This is only accessible to admins, it allows you to update the apps
      information.
    </div>

    <label class="form-label">Title</label>
    <input class="form-control flex-fill" v-model="name" />

    <label class="form-label mt-2"> Description </label>
    <textarea class="form-control flex-fill mb-2" v-model="description" />

    <FileUploader
      labelText="Icon"
      :supportedType="['png', 'jpeg', 'svg', 'jpg']"
      containerId="iconUpload"
      :pending="iconUploadStatus.pending"
      :progress="iconUploadStatus.progress"
      :complete="iconUploadStatus.complete"
      :error="iconUploadStatus.error"
      @uploader="submitIconUpload"
      class="mb-0"
      :hideIcon="true"
    />
    <small class="d-block mt-2 text-muted">
      We recommend your icon is in 1:1 ratio (equal width and height.)
    </small>

    <button class="btn btn-primary w-100 mt-2" @click="update">
      <Spinner v-if="updating" small />
      <span v-else>Update</span>
    </button>

    <button class="btn btn-outline-secondary w-100 mt-2" @click="goToMaster">
      Go to Master Template
    </button>
  </div>
</template>
