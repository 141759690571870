import { ol_coordinate_offsetCoords } from 'ol-ext/geom/GeomUtils';
import { Coordinate } from 'ol/coordinate';
import { LineString } from 'ol/geom';
import { Stroke, Style } from 'ol/style';
import type { Shape } from '../layer/shape/types';

export default function createChainageHashMarkStyle(
  color,
  offset,
  markWidth,
  unitPixels,
  weight,
  res
): Style {
  return new Style({
    stroke: new Stroke({
      color,
      width: offset > 0 ? 2 * offset : -2 * offset,
      lineDash: [markWidth, unitPixels - markWidth],
      lineCap: 'butt',
      lineJoin: 'bevel',
    }),
    // Offset geometry
    geometry: function (feature) {
      let coords = (feature.getGeometry()! as Shape).getCoordinates();

      let size;

      // offset by border
      const halfBorderSize = (weight / 2) * res;
      size = offset > 0 ? halfBorderSize : -halfBorderSize;
      coords = ol_coordinate_offsetCoords(coords, size) as Coordinate[];

      // offset by hash mark
      size = offset * res;
      coords = ol_coordinate_offsetCoords(coords, size) as Coordinate[];

      return new LineString(coords);
    },
  });
}
