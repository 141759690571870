<template>
  <div
    v-if="section && fields"
    class="template-section"
    :class="{
      'draggable-header': !isLabelInputFocused,
    }"
    @click="!selected && $emit('selectSection', sectionIndex)"
  >
    <div
      class="section-header p-3 ps-0 text-white d-flex align-items-center not-rounded-bottom"
      :class="{
        'bg-dark': !selected,
        'bg-primary': selected,
      }"
      style="cursor: grab"
      @click="
        fieldIndex = null;
        $emit('selectSection', sectionIndex);
      "
    >
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <div class="draggable-layer">
            <div class="draggable-control"></div>
          </div>

          <InfoButton
            v-if="invalidNameError"
            class="me-2"
            backgroundColor="#FF0000"
            :info="invalidNameError"
          />

          <span
            v-if="!isLabelEditable"
            class="h6 mb-0"
            v-tooltip="invalidNameError"
            :class="{ 'text-danger': invalidNameError }"
          >
            {{ label }}
          </span>
          <input
            v-else
            type="text"
            class="border-0 bg-transparent h6 mb-0 w-100"
            :class="{
              'text-danger is-invalid': invalidNameError,
              'text-white': !invalidNameError,
            }"
            v-model="label"
            v-tooltip="invalidNameError"
            @keyup.enter="$root.$emit('clearSection')"
            @focus="handleLabelInputFocus"
            @blur="handleLabelInputBlur"
            placeholder="Name section here"
            ref="section-label"
          />
        </div>

        <div v-if="isUpdating" class="d-flex align-items-center">
          <Spinner small />
        </div>

        <div v-if="!isUpdating && value.id" class="d-flex align-items-center">
          <span
            v-if="hasPublic && value.is_public_form"
            class="badge bg-danger ms-2"
          >
            <i class="fal fa-globe-asia"></i>
            Included on Public Form
          </span>
          <span v-if="value.is_repeatable" class="badge bg-dark ms-2">
            <i class="fal fa-repeat"></i>
            Repeatable
          </span>

          <template v-if="selected && index == null && !value.is_permanent">
            <button
              v-if="!value.is_gps_point_metadata && !showDeleteConfirmation"
              class="btn btn-light btn-sm ms-2 me-1"
              @click.stop="$emit('duplicate')"
              :disabled="isTemplateEditorOperating"
            >
              <i v-if="!isDuplicating" class="fal fa-copy"></i>
              <Spinner small v-else />
            </button>

            <div
              v-if="showDeleteConfirmation"
              class="btn-group btn-group-sm ms-2"
            >
              <button
                class="btn btn-outline-light"
                @click="() => (showDeleteConfirmation = false)"
              >
                <i class="fal fa-times" />
              </button>
              <button
                class="btn btn-outline-danger"
                @click="$emit('delete', sectionIndex)"
                :disabled="isTemplateEditorOperating"
              >
                <i class="fal fa-check" />
              </button>
            </div>
            <button
              v-else
              data-cy="section-remove"
              @click="() => (showDeleteConfirmation = true)"
              class="btn btn-outline-danger btn-sm ms-1"
              :disabled="isTemplateEditorOperating"
            >
              <i class="fal fa-trash-alt"></i>
            </button>
          </template>

          <h6
            class="fas mb-0 clickable ms-2"
            :class="{
              'fa-chevron-down': section.collapsed,
              'fa-chevron-up': !section.collapsed,
            }"
            @click="toggleSectionCollapsed(section)"
          />
        </div>
      </div>
    </div>
    <div
      data-cy="list-group-fields"
      class="list-group"
      v-show="!section.collapsed"
    >
      <div
        @click="
          mobileSize &&
            value.template_fields.length === 0 &&
            $emit('openAddFields')
        "
        :class="{
          clickable:
            mobileSize && value.template_fields.length === 0 && !disabled,
        }"
      >
        <Draggable
          :group="fieldsDraggableGroup"
          :value="value.template_fields"
          draggable=".field-draggable"
          :disabled="disabled || checkDisabled"
          :sort="!isTemplateEditorOperating"
          class="position-relative"
          :handle="getFieldHandle"
          @change="handleFieldsChange"
        >
          <template v-if="value.template_fields.length == 0">
            <div class="empty-fields py-5 px-3" v-if="value.id">
              <h1 class="fal fa-line-columns"></h1>
              <h6 v-if="mobileSize">Click here to get started!</h6>
              <h6 v-else>Drag a field here to get started!</h6>
              <p class="text-muted mb-0">
                It appears that you have not added any fields yet.
              </p>
            </div>
            <div v-else>
              <div
                class="p-4 d-flex align-items-center justify-content-center flex-column text-center w-100"
              >
                <Spinner large />
                <small class="text-muted mt-2 d-block"
                  >Creating section...</small
                >
              </div>
            </div>
          </template>
          <template v-else>
            <TemplateFieldDraggable
              v-for="(field, aIndex) of value.template_fields"
              :key="`${getFieldKey(field)}`"
              :value="value.template_fields[aIndex]"
              @input="updateField"
              @updateLabel="updateLabel"
              :index="aIndex"
              :tab="tab"
              :section="section"
              :selected="fieldIndex == aIndex && selected"
              :disabled="disabled || !field.id"
              :isTemplateEditorOperating="isTemplateEditorOperating"
              @selectField="selectField"
              @delete="deleteField(field)"
            />
          </template>
        </Draggable>
        <div
          v-if="value.template_fields.length > 0 && !disabled"
          class="has-fields"
          :class="{
            clickable:
              mobileSize && value.template_fields.length > 0 && !disabled,
          }"
          @click="$emit('openAddFields')"
        >
          {{ mobileSize ? 'tap to add fields' : 'drag and drop fields above' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Section from '@/js/classes/Section.js';
import { findAppByFieldId } from '@component-library/business-logic/app';
import InfoButton from '@component-library/components/InfoButton.vue';
import { fieldTypes } from '@component-library/fields';
import { getSectionReferenceError } from '@component-library/gather';
import makeId from '@component-library/local-id.mjs';
import Draggable from 'vuedraggable';
import TemplateFieldDraggable from './TemplateFieldDraggable.vue';
import Spinner from '@component-library/components/Spinner.vue';

export default {
  components: { Draggable, TemplateFieldDraggable, InfoButton, Spinner },
  name: 'TemplateSectionDraggable',
  props: [
    'index',
    'selected',
    'disabled',
    'tab',
    'tabs',
    'value',
    'sectionIndex',
    'isUpdating',
    'isDuplicating',
    'isTemplateEditorOperating',
    'mobileSize',
  ],
  data: () => ({
    showDeleteConfirmation: false,
    isLabelInputFocused: false,
    labelValue: null,
  }),
  computed: {
    checkDisabled() {
      if (this.value instanceof Section) return this.value.isDisabled();
      return true;
    },
    section: {
      get() {
        return this.value;
      },
    },
    label: {
      get() {
        return this.labelValue;
      },
      set(updated) {
        this.labelValue = updated;
        const label = updated.trim();
        if (!label) {
          return;
        }

        this.$emit('input', {
          ...this.value,
          label,
        });
      },
    },
    invalidNameError() {
      return getSectionReferenceError(this.value, this.tab);
    },
    fields() {
      return this.value.template_fields;
    },
    hasPublic() {
      return this.tab?.public_link;
    },
    fieldIndex: {
      get() {
        return this.index;
      },
      set(updated) {
        this.$emit('update:index', updated);
      },
    },
    shownTypes() {
      return fieldTypes.filter((type) => !type.is_hidden);
    },
    getFieldHandle() {
      return window.innerWidth < 500 ? '.draggable-field' : '.list-group-item';
    },
    fieldsDraggableGroup() {
      return {
        name: 'fields',
        pull: !this.isTemplateEditorOperating,
        put: (to, from) => {
          return (
            to.options.group.name === 'fields' &&
            from.options.group.name === 'fields' &&
            !this.isTemplateEditorOperating
          );
        },
      };
    },
    isLabelEditable() {
      return (
        this.selected &&
        !this.section.is_gps_point_metadata &&
        !this.section.options?.disabled
      );
    },
  },
  watch: {
    selected() {
      if (!this.selected) {
        this.fieldIndex = null;
      }
    },
    'value.label'() {
      if (!this.isLabelInputFocused) {
        this.labelValue = this.value.label;
      }
    },
  },
  methods: {
    getFieldKey(field) {
      return `section-field-${field.id ?? makeId()}`;
    },
    updateField({ index, value }) {
      this.$emit('updateField', {
        sectionIndex: this.sectionIndex,
        fieldIndex: index,
        value,
      });
    },
    updateLabel({ index, label }) {
      this.$emit('updateFieldLabel', {
        index: this.sectionIndex,
        fieldIndex: index,
        label,
      });
    },
    async deleteField(field) {
      if (field.options?.disabled || this.disabled) {
        this.$toastStore.error('This field cannot be deleted');
        return;
      }
      try {
        const app = findAppByFieldId(this.tabs, field.id);
        const data = await field.delete();
        this.value.template_fields.splice(
          this.value.template_fields.indexOf(field),
          1
        );
        this.value.template_fields.forEach((item, index) => {
          item.order = index;
        });
        this.fieldIndex = null;
        this.$emit('deleteField', { field, app });
      } catch (e) {
        this.$toastStore.error(
          'Failed to delete field, please refresh and try again'
        );
        throw e;
      }
    },
    selectField(index) {
      if (this.disabled) {
        this.fieldIndex = null;
        return;
      }

      this.$emit('selectSection', this.sectionIndex);
      this.$nextTick(() => {
        this.fieldIndex = index;
      });
    },
    toggleSectionCollapsed(section) {
      this.$set(section, 'collapsed', !section.collapsed);
    },
    handleFieldsChange({ added, moved }) {
      const { value: section, sectionIndex } = this;
      if (added) {
        if (section.is_gps_point_metadata) {
          this.$toastStore.info(
            'Adding a new field into this section is not allowed.'
          );
          return;
        }

        const { element, newIndex } = added;
        const { id: template_section_id, template_fields } = section;
        // When template_fields is empty, the newIndex is 1 because of the placeholder
        // in the draggable component. It needs to be corrected to 0.
        const fieldIndex = template_fields.length ? newIndex : 0;
        if (!element.id) {
          this.$emit('createField', {
            sectionIndex,
            fieldIndex,
            value: { ...element, template_section_id },
          });
          this.selectField(fieldIndex);
        } else {
          this.$emit('updateField', {
            sectionIndex,
            fieldIndex,
            value: element,
          });
        }
      } else if (moved) {
        const { element, oldIndex: fieldIndex, newIndex: order } = moved;
        this.$emit('updateField', {
          sectionIndex,
          fieldIndex,
          value: { ...element, order },
        });
      }
    },
    handleLabelInputFocus() {
      this.isLabelInputFocused = true;
    },
    handleLabelInputBlur() {
      this.isLabelInputFocused = false;
    },
  },
  mounted() {
    this.labelValue = this.value.label;
    this.$root.$on('selectField', (field) => {
      this.$nextTick(() => {
        this.fieldIndex = field;
      });
    });

    this.$root.$on('clearField', () => {
      this.fieldIndex = null;
    });

    if (this.isLabelEditable) {
      const labelInput = this.$refs['section-label'];
      labelInput.focus();
      labelInput.select();
    }
  },
  beforeDestroy() {
    this.$root.$off('selectField');
    this.$root.$off('clearField');
  },
};
</script>
<style scoped>
.section-header .draggable-layer {
  height: 30px;
}

.section-header:hover .draggable-control {
  visibility: visible;
}

.list-group {
  border: 1px solid #f4f4f4;
  border-top: 0px;
}

.empty-fields {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.has-fields {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757da1;
  font-size: 0.875em;
  border: 1px dashed rgba(0, 0, 0, 0.125);
  border-top: none;
  background: #f7f7f7;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
</style>
