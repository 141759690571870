export const AVAILABLE_PERMISSIONS = {
  MAPS: 'maps',
  GATHER: 'gather',
  GATHER_APP_EDITOR: 'gather.app-editor',
  EVALU8: 'evalu8',
  VISUAL_REPORTS: 'visual-report',
  AUTO_DOCS_EXCEL: 'auto-docs-excel',
  AUTO_DOCS_WORD: 'auto-docs-word',
  VECTOR_EDITOR: 'vector-editor',
  FILE_BROWSER: 'file-browser',
  DATA_EVENTS: 'data-events',
  DATA_INSIGHTS: 'data-insights',
  DELIVER: 'deliver',
  PROJECT_SETTINGS: 'project-settings',
};

export type PERMISSION_ID =
  (typeof AVAILABLE_PERMISSIONS)[keyof typeof AVAILABLE_PERMISSIONS];

export const MAPPED_PERMISSION_TITLES: Record<PERMISSION_ID, string> = {
  [AVAILABLE_PERMISSIONS.MAPS]: 'Maps',
  [AVAILABLE_PERMISSIONS.GATHER]: 'Gather',
  [AVAILABLE_PERMISSIONS.GATHER_APP_EDITOR]: 'Gather App Editor',
  [AVAILABLE_PERMISSIONS.EVALU8]: 'Evalu8',
  [AVAILABLE_PERMISSIONS.VISUAL_REPORTS]: 'Visual Report',
  [AVAILABLE_PERMISSIONS.AUTO_DOCS_EXCEL]: 'Auto Docs Excel',
  [AVAILABLE_PERMISSIONS.AUTO_DOCS_WORD]: 'Auto Docs Word',
  [AVAILABLE_PERMISSIONS.VECTOR_EDITOR]: 'Conceptual Site Model',
  [AVAILABLE_PERMISSIONS.FILE_BROWSER]: 'File Browser',
  [AVAILABLE_PERMISSIONS.DATA_EVENTS]: 'Data Events',
  [AVAILABLE_PERMISSIONS.DATA_INSIGHTS]: 'Data Insights',
  [AVAILABLE_PERMISSIONS.DELIVER]: 'Deliver',
  [AVAILABLE_PERMISSIONS.PROJECT_SETTINGS]: 'Project Settings',
};

// Allows for both read/write access
export function supportsFullAccess(featureKey: string) {
  return [
    AVAILABLE_PERMISSIONS.MAPS,
    AVAILABLE_PERMISSIONS.GATHER_APP_EDITOR,
    AVAILABLE_PERMISSIONS.GATHER
  ].includes(
    featureKey
  );
}

export type CompanyRoleProfile = {
  id?: number;
  title: string | null;
};

export enum AccessType {
  READ = 0,
  WRITE = 1,
}

export type ProjectMemberPermission = {
  feature_key: PERMISSION_ID;
  access_type: AccessType;
};

export type CompanyRoleProfileWithAccess = CompanyRoleProfile & {
  permissions: ProjectMemberPermission[];
};
