import { Map } from 'ol';
import { Tile as TileLayer } from 'ol/layer';
import { XYZ } from 'ol/source';
import { Code, throwError } from '../../common/error';
import { proxify } from '../../common/network';
import { patchTileGrid } from '../../measurement/scale';
import { EPSG_3857 } from '../../projection/common';
import type { LayerUsage } from '../constants';
import { LayerType } from '../types';
import { createLayerProperties, getVisibleExtent } from '../utils';
import enableLoadingEvents from './enableLoadingEvents';
import type { XyzFolderLayerModel, XyzLayer } from './types';

export default function createXyzLayer(
  map: Map,
  folderModel: XyzFolderLayerModel,
  SourceClass: typeof XYZ = XYZ,
  layerType: LayerType = LayerType.BASEMAP_SERVICE,
  layerUsage?: LayerUsage
): XyzLayer {
  const { url, attributions, shouldUseCorsProxy } =
    folderModel.geojson.properties.service;
  const visibleExtent = getVisibleExtent(
    folderModel,
    map.getView().getProjection()
  );
  const projection =
    folderModel.geojson.properties.service.projection ?? EPSG_3857;
  const layer = new TileLayer({
    source: new SourceClass({
      crossOrigin: 'anonymous',
      url: shouldUseCorsProxy ? proxify(url) : url,
      attributions,
      maxZoom: folderModel.geojson.properties.service.maxZoom ?? 24,
      projection,
    }),
    extent: visibleExtent,
    properties: createLayerProperties(folderModel.id, layerType, layerUsage),
  }) as XyzLayer;
  patchTileGrid(map, layer.getSource().getTileGrid(), projection);

  layer.getFirstFeature = function () {
    return undefined;
  };

  layer.checkHasFeature = function (feature) {
    return false;
  };

  layer.toGeoJSON = function () {
    throwError(Code.MethodNotImplemented, 'toGeoJSON');
  };

  layer.getBounds = function (padding = 0) {
    throwError(Code.MethodNotImplemented, 'getBounds');
  };

  layer.refresh = function () {};

  enableLoadingEvents(layer);

  return layer;
}
