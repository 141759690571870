import { WGS84_WORLD_EXTENT } from '../../../common/extent';
import { ServiceType } from '../../service/types';
import { LayerType } from '../../types';
import type { BasemapXyzApi } from '../types';
import { BasemapId } from '../types';
import hosts from './hosts';

const host = hosts.MAPBOX;

const basemapApi: BasemapXyzApi = {
  id: BasemapId.MAP_BOX,
  title: 'MapBox',
  subtitle: 'Low quality aerial imagery covering world',
  type: 'satellite',
  host,
  layerModel: {
    id: 'map-box-folder',
    geojson: {
      properties: {
        type: LayerType.FOLDER,
        title: 'MapBox',
        service: {
          type: ServiceType.Xyz,
          url: `https://${host}/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=pk.eyJ1IjoiZW50dWl0aXZlIiwiYSI6ImNqeXl5YjZhNzAwOGEzaHFod3ptOGJ2enMifQ.onSyhLh_3bbSVv6u-6pqyQ`,
          title: 'MapBox',
          description: 'Low quality aerial imagery covering world',
          extent: WGS84_WORLD_EXTENT,
          visibleExtent: [...WGS84_WORLD_EXTENT],
          attributions: '© Mapbox',
          shouldUseCorsProxy: false,
          maxZoom: 19,
          projection: 'EPSG:3857',
        },
      },
    },
    visible: true,
    is_visible_in_basemap_figure: false,
    children: [],
  },
};

export default basemapApi;
