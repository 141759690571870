<template>
  <a v-if="srcCheck" href="#" class="fw-medium" @click.prevent="viewImage">
    View Drawing
  </a>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Object,
    },
    field: {
      required: true,
      type: Object,
    },
    values: Array,
  },
  data: () => ({
    show: false,
  }),
  computed: {
    srcCheck() {
      return !!this.value.value;
    },
  },
  methods: {
    viewImage() {
      this.$emit('viewImage', this.value.value);
    },
  },
};
</script>
